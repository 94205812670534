@import "vars";
.dev-page {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
}
.dev-page__img {
  display: block;
  height: 196px;
  width: 196px;
}
.dev-page__accent {
  display: block;
  font-family: $font;
  color: transparent;
  @include scale(font-size, 32, 40);
  @include scale(line-height, 40, 48);
  font-weight: 600;
  background: linear-gradient(135deg,#0cc 0,#00cbcc 6.67%,#00cacc 13.33%,#00c6cc 20%,#00c2cc 26.67%,#00bccc 33.33%,#00b5cc 40%,#00aecc 46.67%,#00a6cc 53.33%,#009fcc 60%,#0098cc 66.67%,#0092cc 73.33%,#008ecc 80%,#008acc 86.67%,#0089cc 93.33%,#08c 100%);
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
}
.dev-page__title {
  display: block;
  @include font($font, 400, 32px, 40px, $text-color);
  margin: 0;
  text-align: center;
}
.dev-page__subtitle {
  display: block;
  @include font($font, 400, 16px, 24px, $text-color-secondary);
  margin: 0;
  text-align: center;
}
