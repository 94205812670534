@import "vars";

/* FORMS */

textarea,
input[type="text"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="search"],
input[type="password"], {
  -webkit-border-style: solid;
  -moz-border-style: solid;
  border-style: solid;
  -moz-appearance:none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
}
select:focus {
  outline: none;
}

.form-container {
  display: block;
  margin: 48px 0;
  width: 100%;
  // background: $gray-20;
  //border: 1px solid $border-color;
  //@include scale(padding, 16, 32);
  //@include scale(border-radius, 12, 24);
}
.text a.main-link,
.main-link {
  display: flex;
  @include font ($font, 600, 16px, 24px, $text-color);
  padding: 8px 0;
  width: fit-content;
  &:hover {
    color: $main;
  }
  &::before {
    content: url("../img/Link.svg");
    display: block;
    @include size(40px, 40px);
    flex: none;
    margin: -6px 16px 0 0;
  }
}
.main-btn_outline {
  display: inline-block;
  padding: 11px 39px;
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  background-color: transparent;
  color: $main;
  border: 1px solid $main;
  border-radius: 4px;
  text-align: center;
  transition: .2s;
}
.main-btn_outline:hover {
  color: #fff;
  background-color: $main;
}


.text a.main-close-btn,
a.main-close-btn {
  display: grid;
  place-items: center;
  position: absolute;
  top: 24px;
  right: 24px;
  @include size(24px, 24px);
  span {
    position: relative;
    @include size(1.5px, 20px);
  }
  span::before,
  span::after {
    content: '';
    position: absolute;
    top: 0;
    transform-origin: center;
    display: block;
    @include size(2px, 20px);
    background-color: #BFC1C7;
    transition: 0.2s;
  }
  span::before {
    transform: rotate(45deg);
  }
  span::after {
    transform: rotate(-45deg);
  }
}

.form-container .form__title,
.form__title_modal {
  @include font($font, 600, 24px, 32px, $text-color);
  margin-bottom: 0;
  max-width: 800px;
}

.form-container .form__description,
.form__description_modal {
  @include font($font, normal, 16px, 24px, $text-color);
  margin-bottom: 32px;
}

.form__item {
  padding: 12px 0;
  display: grid;
  grid-template-rows: auto max-content;
  width: 100%;
  height: 100%;
  label{
    @include font ($font, normal, 14px, 24px, $text-color);
    margin-left: 12px;
    align-self: end;
    a {
      margin: 0 8px;
    }
  }
  select {
    cursor: pointer;
  }
  .select,
  textarea,
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  select {
    height: auto;
    border: 1px solid $border-color;
    border-radius: 12px;
    padding: 12px 24px;
    @include font ($font, normal, 16px, 22px, $text-color);
    transition: border-color 0.2s ease-out;
    width: 100%;
    &:focus {
      border-color: $border-color-active;
    }
  }
  input.error:invalid {
    border-color: $error;
  }
  .select.show {
    border-radius: 12px 12px 0 0;
  }
}

.form-field_danger {
  @include font ($font, normal, 14px, 24px, unset);
  margin-left: 12px;
}

.checkbox,
.form__checkbox {
  input[type="checkbox"] {
    display: none;
  }
  label {
    display: flex;
    align-items: flex-start;
    &::before {
      content: '';
      color: #fff;
      display: block;
      height: 16px;
      width: 16px;
      flex: none;
      border: 2px solid $border-color-active;
      border-radius: 3px;
      margin-right: 8px;
      margin-top: 4px;
    }
  }
  &._special {
    label {
      align-items: flex-start;
      @include fnt();
      &::before {
        margin-top: 5px;
      }
    }
  }
  input[type="checkbox"].error:invalid + label::before {
    border-color: $error;
  }
  input[type="checkbox"]:checked + label::before {
    border-color: $main;
    background: url("../img/Check.svg") $main center no-repeat;
    background-size: contain;
  }
}
.file-input{
  display: grid;
  align-items: center;
  grid-template-columns: max-content auto;
  //padding-top: 4px;
  input[type="file"] {
    display: none;
  }

  label {
    display: inline-flex;
    align-items: center;
    @include font($font, 400, 14px, 16px, $text-color);
    background-color: #E0E1E6;
    margin-bottom: 0;
    padding: 8px 24px;
    @include scale(border-radius, 8, 12);
    border-radius: 16px;
    margin-left: 0;
    transition: background-color .2s ease-out;
    &:hover {
      cursor: pointer;
      background-color: #D4D6DB;
    }
    &::before {
      //content: '';
      display: block;
      height: 30px;
      width: 30px;
      background: url("../img/upload-arrow-up-square.svg") center no-repeat;
      background-size: contain;
      margin-right: 12px;
    }
  }
  .uploaded {
    @include font($font, 400, 14px, 32px, $text-color);
    min-height: 100%;
    margin-left: 8px;
    &.fill {
      color: $main;
    }
  }
}
.form__success {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @include scale(padding, 24, 48);
  margin: 16px 0;
  width: 100%;
  border-radius: 24px;
  background-color: $gray-20;
  svg {
    height: 60px;
    width: 60px;
  }
  &-message {
    @include font($font, 400, 22px, 48px, $text-color);
    margin-top: 4px;
  }
}


/*  ---- Form land booking ----  */


.form-land-booking {
  .form__item {
    height: unset;
  }
}

.booking-card {
  background-color: $gray-20;
  border: 1px solid $border-color;
  border-radius: 12px;
  box-shadow: $shadow;
  margin: 30px 0;
  overflow-x: auto;
  @include scale(padding, 16, 32);
  .booking-card__title {
    display: block;
    @include font($font, 600, 24px, 32px, $text-color);
    margin-bottom: 12px;
  }
  .booking-card__kadastr {
    @include font($font, 600, 20px, 32px, $text-color);
  }
  ._bold {
    font-weight: 600;
  }
  ._blue {
    @include font($font, 600, 24px, 32px, $main);
    white-space: nowrap;
  }
  .booking-card__wrap {
    display: inline-grid;
    grid-gap: 12px;
    grid-template-columns: 1fr auto;
    margin-bottom: 8px;
    .booking-card__label {
      @include font($font, 400, 16px, 24px, $text-color-secondary);
    }
    span {
      align-self: center;
    }

  }
  .booking-card__description {
    @include font($font, 400, 16px, 24px, $text-color-secondary);
  }
}
.text .m-modal,
.m-modal {
  &__header {}
  &__title {
    @include fnt(24px, 32px, $weight: 600);
    margin: 0 0 4px;
  }
  &__description {
    @include fnt(14px, 20px, $text-color-secondary);
    margin: 0;
  }
  &__btn {
    display: block;
    width: 100%;
    text-align: center;
    @include fnt(16px, 24px, #fff, 600);
    background-color: $main;
    padding: 12px 24px;
    border-radius: 8px;
    border: none;
    margin: 32px 0 0;
    outline: none;
    box-shadow: none;
  }
}
.modal-btn {
  @include btn(#fff, $main, true)
}
.modal-dialog {
  max-width: 560px;
  .form__item {
    padding: 16px 0 0;
  }
}
.modal-form-btn {
  @include font($font, 600, 16px, 24px, $text-color);
  display: block;
  border: 1px solid $border-color;
  padding: 32px;
  transition: .2s;
}
.modal-form-btn:hover {
  border-color: $border-color-active;
  color: $text-color;
}

.modal-content {
  padding: 40px 64px;
  border-radius: 16px;
}
.form-container .form__description,
.form__description_modal {
  margin-bottom: 0;
}
.form-error {
  @include fnt($color: $error);
  margin-top: 24px;
}

//                mailing form
.mailing-form-block{
  display: flex;
  justify-content: center;
  //margin-top: 116px;
}
.mailing-form{
  display: grid;
  grid-template-columns: auto 144px;
  //grid-gap: 24px;
  flex-direction: column;
  max-width: 864px;
  width: 100%;
  background: $gray-40;
  border-radius: 24px;
  padding: 24px;
  .main-btn{
    background: $main;
    border-radius: 8px;
    @include font($font, 600, 16px, 24px, white);
    padding: 12px 32px;
    width: 100%;
    text-align: center;
    margin-top: 40px;
    &.disabled{
      background: transparent;
      outline: 1px solid #FFFFFF;
      opacity: 0.5;
    }
  }
  .news-subscribe-form{
    grid-column: 1/3;
  }
}
.mailing-form__title{
  @include font($font, 600, 24px, 32px, $text-color);
  margin: 0 0 4px 0;
}
.mailing__form__sub-title{
  @include font($font, 400, 14px, 20px, $text-color);
  margin: 0 0 40px;
}
.mailing._input-field{
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 16, 61, 0.12);

  border-radius: 8px;
  padding: 12px 16px;
  @include font($font, 400, 16px, 24px, $text-color);
  width: 100%;
  &.warning{
    background: url("../img/Warning-red.svg") white 96% no-repeat;
    background-size: contain;
    margin-right: 16px;
    background-size: 24px;
    flex: none;
    padding: 12px 40px 12px 16px;
    outline: 1px solid #ED0A34
  }
}
.mail-box__img{
  width: 144px;
  height: 144px;
  margin-top: -40px;
  flex: none;
  aspect-ratio: 1/1;

}
.mailing-form__fields{
  width: 100%;
}
.mailing-form__text{
  @include font($font, 400, 12px, 16px, $text-color-secondary);
}
.mailing-form__link{
  @include font($font, 600, 12px, 16px, $text-color-secondary);
  &:hover{
    cursor: pointer;
    text-decoration: underline;
    color: $text-color-secondary;
  }
}
.mailing-form__agreement{
  margin-top: 8px;
  grid-column: 1/3;
}
.mailing__help-block{
  @include font($font, 400, 14px, 24px, #eb5757);
  margin-left: 12px;
}
.mailing-check{
  width: 100%;
  flex: none;
}
.form-success-message{
  grid-column: 1/3;
  .mailing-form__title{
    text-align: center;
  }
  .mailing__form__sub-title{
    text-align: center;
  }
}
.bottom-mailing-block{
  .mailing-form-block{
    margin-top: 80px;
  }
}
@media screen and (max-width: 1024px) {

  .mailing-form{
    grid-gap: 0;
  }
}
@media screen and (max-width: 600px) {
  .media{
    flex-direction: column;
    .mailing-form__fields{
      max-width: unset;
    }
    .main-btn{
      margin-left: 0px;
      width: 100%;
      margin-top: 12px;
    }
  }

  .mailing-form-block{
    margin-top: 32px;
  }
  .bottom-mailing-block{
    .mailing-form-block{
      margin-top: 32px;
    }
  }
}

.text .beautiful-agreement,
.beautiful-agreement {
  margin: 16px 0 0;
  input {
    display: none;
  }
  label {
    display: flex;
    @include fnt(14px, 20px)
    a {
      text-decoration: none;
      @include fnt(14px, 20px, $color: $main);
    }
  }
  label:before {
    content: '';
    color: #fff;
    display: block;
    height: 16px;
    width: 16px;
    flex: none;
    border: 2px solid rgba(0,16,61,.24);
    border-radius: 3px;
    margin-right: 8px;
    margin-top: 2px;
  }
  input:checked + label:before {
    border-color: #0CC;
    background: url(../img/Check.svg) #0CC center no-repeat;
    background-size: contain;
  }
}

button,
a {
  transition: filter 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
button._disabled,
a._disabled {
  pointer-events: none;
  cursor: unset;
  opacity: 0.2;
  filter: grayscale(1);
}



//                                          BIK-market form

.form_bik-market{
  //width: 100%;
  //max-width: 528px;
  //background: white;
  //box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  //border-radius: 16px;
  //padding: 16px 32px 32px 32px;
}



//                                           Классы для формы с новым дизайном тут (.new-type__form)
.bik-market{
  &._grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 20px;
    margin-bottom: 32px;
  }
}
.form_bik-market,
.new-type__form {
  &__background {
    width: 100%;
    max-width: 640px;
    background: white;
    //box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
    //border-radius: 16px;
    //padding: 16px 32px 32px 32px;
  }

  .bik-market {
    &__title {
      @include font($font, 600, 16px, 32px, $text-color);
      margin-bottom: 20px;
    }

    &__subtitle {
      @include font($font, 400, 14px, 20px, $text-color-secondary);
      margin-bottom: 18px;
    }
    &__photo-tutorial{
      @include font($font, 500, 14px, 20px, $main-text);
      display: flex;
      margin-bottom: 16px;
      &:before{
        content:'';
        background: url("../img/download-arrow.svg") center no-repeat;
        width: 24px;
        height: 24px;
        background-size: 24px;
        display: block;
        flex: none;
        margin-right: 4px;
      }
    }
  }

  .file-input {
    .plus-button {
      background: $main-text;
      padding: 4px 16px;
      border-radius: 16px;
      @include font($font, 400, 14px, 24px, white);
      width: fit-content;
    }

    .in-row {
      display: flex;
      align-items: center;

      .plus-button {
        margin-right: 16px;
      }

      .uploaded {
        width: calc(100% - 118px);

        div {
          margin-top: 0;
        }
      }

    }
  }


  .uploaded {
    width: 100%;

    div {
      display: flex;
      margin-top: 16px;

      &:before {
        content: '';
        background: url("../img/file.svg") center no-repeat;
        display: block;
        flex: none;
        width: 18px;
        height: 24px;
        background-size: 18px 24px;
        margin-right: 8px;
      }

      p {
        @include font($font, 400, 14px, 24px, $text-color);
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .delete-file {
      background: url("../img/delete-bin.svg") center no-repeat;
      width: 18px;
      height: 24px;
      background-size: 18px 24px;
      display: block;
      margin-left: 8px;
      flex: none;
    }
  }

  .form__item {
    padding: 0;

    &.grid-start-1 {
      grid-column-start: 1;
    }

    &.grid-start-2 {
      grid-column-start: 2;
    }

    &.grid-start-3 {
      grid-column-start: 3;
    }

    &.grid-start-4 {
      grid-column-start: 4;
    }

    &.grid-end-2 {
      grid-column-end: 2;
    }

    &.grid-end-3 {
      grid-column-end: 3;
    }

    &.grid-end-4 {
      grid-column-end: 4;
    }

    &.grid-end-5 {
      grid-column-end: 5;
    }
  }

  .form__file-item {
    margin-bottom: 16px;

    .form__file-label {
      @include font($font, normal, 14px, 24px, $text-color);
      margin: 0 0 4px 12px;

      a {
        margin: 0 8px;
      }
    }
  }

  .main-btn {
    background-color: $main-text;
    @include font($font, 600, 16px, 24px, white);
    padding: 12px 24px;
    border-radius: 8px;
    margin-top: 24px;
    width: fit-content;
  }

  .form__checkbox {

    input[type="checkbox"]:checked + label::before {
      border-color: $cyan;
      background: url("../img/Check.svg") $cyan center no-repeat;
      background-size: contain;
    }
  }

  .market__price-field,
  .market__area-field {

    ::-webkit-input-placeholder {
      text-align: right;
    }
    :-moz-placeholder {
      text-align: right;
    }
  }
  .form-field_danger{
    font-size: 12px;
    line-height: 20px;
    margin-bottom: -24px;
  }
  .bik-market__success{
    padding: 12px 24px;
    border-radius: 16px;
    &__title{
      @include gradient-color;
      @include font($font, 600, 32px, 48px, transparent);
      margin: 0 0 8px 0;
      width: fit-content;
    }
    &__text{
      @include font($font, normal, 16px, 24px, $text-color);
      margin: 0 0 12px 0;
      max-width: 464px;
    }
    &__sub-title{
      @include font($font, normal, 14px, 24px, $text-color-secondary);
      margin: 0 0 20px 0;
    }
    .bik-market__success__button{
      padding: 12px 24px;
      background: $main-text;
      border-radius: 8px;
      @include font($font, 600, 16px, 24px, white);
    }
  }
}
