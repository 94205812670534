@import "vars";

/*XL*/

@media screen and (max-width: 2560px) {

}


/*LG*/

@media screen and (max-width: 1920px) {

}

/*MD*/

@media screen and (max-width: 1680px) {

}


/* ~~SM - MD~~ */
@media screen and (max-width: 1440px) {
  .top-block__title{
    font-size: calc(40px + (64 - 40) * ((100vw - 360px) / (1440 - 360)));
    line-height: calc(40px + (64 - 40) * ((100vw - 360px) / (1440 - 360)));
  }
  .top-block__title:before{
    width: calc(35px + (56 - 35) * ((100vw - 360px) / (1440 - 360)));
    height:  calc(35px + (56 - 35) * ((100vw - 360px) / (1440 - 360)));
    margin-right: calc(12px + (20 - 12) * ((100vw - 360px) / (1440 - 360)));
  }
  .top-block__subtitle{
    font-size: calc(16px + (32 - 16) * ((100vw - 480px) / (1440 - 480)));
    line-height: calc(24px + (40 - 24) * ((100vw - 480px) / (1440 - 480)));
  }
  .top-block__grid{
    grid-row-gap: clamp(24px,calc(24px + (48 - 24) * ((100vw - 1280px) / (1440 - 1280))),48px);
  }
  .bik-market-banner__text{
    //max-width: clamp(350px,calc(350px + (720 - 350) * ((100vw - 1024px) / (1440 - 1024))),720px);
    font-size: clamp(14px,calc(14px + (16 - 14) * ((100vw - 1024px) / (1440 - 1024))),16px);
    line-height: clamp(20px,calc(20px + (24 - 20) * ((100vw - 1024px) / (1440 - 1024))),24px);
  }
  .bik-market-banner {
    background-size: clamp(20px,calc(20px + (24 - 20) * ((100vw - 1024px) / (1440 - 1024))),24px);
  }

}
@media screen and (max-width: 1366px) {
  .container-center,
  .service-container {
    padding: 0 16px;
  }
}

@media screen and (max-width: 1280px) {
  .bik-market-banner .market-logo{
    max-width: clamp(300px,calc(300px + (528 - 300) * ((100vw - 1024px) / (1280 - 1024))),528px);
  }
  .top-block__container{
    padding: 32px 32px 0 32px;
  }
  .top-block__img{
    margin-bottom: -48px
  }
  .top-block__grid-right{
    justify-content: flex-start;
  }
  .cabinet__specials {
    grid-template-columns: repeat(2, 1fr);
  }
  .entity-container._sale {
    grid-template-columns: repeat(2,1fr);
  }
}


/*SM*/

@media screen and (max-width: 1024px) {
  .service-container{
    grid-template-columns: 100%;
    grid-gap: 24px 0px;
    &__swiper{
      grid-row: 2/3;
    }
    &__title-block{
      grid-row: 1/2;

    }
    &__description-block{
      grid-row: 3/4;
    }
    .modal-btn{
      width: 100%;
      border-radius: 0;
      text-align: center;
    }
    .service-description+p{
      margin-bottom: 8px;
    }
    .service-kadastr{
      display: none;
      &._mob{
        display: block;
        margin-bottom: 24px;
      }
    }
  }

  .sale-card__slider .hover-pagination-wrap{
    display: none;
  }
  .bik-market-banner{
    aspect-ratio: 82/24;
  }
  .bik-market-banner__img {
    background: url("../img/market-background-small.png") #E6E7EB right no-repeat;
    background-size: cover;
  }
  .bik-market-banner__text{
    display: none;
  }
  .alert {
    display: none !important;
  }
  .header-logo {
    height: 28px;
  }
  .content__container-center,
  .container-geos{
    padding: 0 32px;
  }
  .top-block__img {
    margin-bottom: clamp(-64px, calc(-24px + (-64 + 24) * ((100vw - 320px) / (1024 - 320))), -24px);
  }

  .top-block__grid-right{
    margin-top: 0;
  }
  .change__btn{
    display: flex;
  }
  .top-block__grid{
    grid-template-columns: 100%;
    grid-template-rows: auto;
    margin-bottom: clamp(24px, calc(24px + (64 - 24) * ((100vw - 320px) / (1024 - 320))), 64px);
  }
  .grid-box-1{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    justify-content: center;
  }
  .grid-box-2{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .grid-box-3{
    display: none;
  }
  //.row {
  //  margin-left: -16px;
  //  margin-right: -16px;
  //}

  .container-center {
    padding: 0 16px;
  }
  .sub-banner {
    margin-right: -16px;
    margin-left: -16px;
    padding: 24px 16px;
  }
  .banner-wrap {
    grid-template-columns: 100%;
  }
  .card-leader__body,
  .leader__body {
    grid-template-columns: 100%;
  }
  .entity-container {
    &._block {
      grid-template-columns: repeat(2,1fr);
      .block-item:first-child {
        grid-column: 1/3;
      }
    }
  }

  .sub-banner {
    &__item {
      &:hover {
        margin-top: 0;
        margin-bottom: 0;
        box-shadow: 0 8px 16px rgba(1, 1, 1, 0.1);
      }
    }
  }
  .register-block__btn:hover{
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: none;
  }
  .banner__next-btn {
    right: 16px;
  }
  .banner__prev-btn {
    right: 56px;
  }
  .footer__navigation {
    & > .footer__page {
      & > .footer__page-wrap {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 8px;
      }
    }
  }
  .cabinet__areas {
    grid-template-columns: repeat(2, 1fr);
  }

  .category-wrap .entity-container._block{
    .sale_block-item{
      .sale-card__slider .swiper-wrapper{
        max-width: unset;
      }
    }
  }
  .category-wrap._banner-block,
  .category-wrap._form-block{
    grid-template-columns: 100%;
  }
  .entity-container._block .sale_block-item:first-child{
    grid-column: unset;
  }
  .category-wrap{
    .entity-container._block{
      .sale_block-item{
        border-bottom: 1px solid rgba(0,16,61,.12);
        &:nth-last-child(-n + 1) {
          border: none;
        }
      }
      .block-item{
        border-bottom: 1px solid rgba(0,16,61,.12);
        &:nth-last-child(-n + 1) {
          border: none;
        }
      }
    }
  }
  .entity-container._block .block-item:nth-last-child(-n+3){
    border-bottom: 1px solid rgba(0,16,61,.12);
  }
  .entity-container._block .block-item {
    border-bottom: 1px solid rgba(0,16,61,.12);
    &:nth-last-child(-n+2){
      border: none;
    }
  }
  .entity-container._banner-block{
    grid-column: unset;
  }
}
@media screen and (max-width: 960px) {
  .footer {
    margin-top: 48px;
  }
  .slider-block {
    height: unset;
    width: 100%;
    aspect-ratio: 4/3;
    .slider {
      grid-template-rows: auto auto 1fr 1fr;
      &__title {
        grid-column: 1/3;
        grid-row: 1/2;
        margin-top: 16px;
      }
      &__button {
        grid-row: 2/3;
      }
      &__img-block {
        grid-row: 2/5;
        grid-column: 1/3;
        padding-top: 0;
      }
      &__img {
        object-position: bottom right;
      }
    }
  }
  .market-banner {
    flex-direction: column;
    padding: 20px;
    &__title {
      text-align: center;
    }
    &__caption {
      text-align: center;
    }
    &__img-separator,
    &__link {
      height: 48px;
    }
    &__img {
      width: 100%;
      object-fit: contain;
      object-position: center;
      &-wrap {
        grid-gap: 24px;
        padding: 24px 0 0;
        max-width: 100%;
      }
    }
  }
  .bik-market__header-filter{
    flex-direction: column-reverse;
    align-items: flex-start;
    .modal-btn{
      width: 100%;
      margin-bottom: 16px;
    }
  }
}


@media screen and (max-width: 800px) {

  .bik-market-banner__img {
    background: unset;
  }
  .bik-market-banner{
    aspect-ratio: 358/204;
    display: block;
    background: url("../img/market-background-small.png") #E6E7EB right no-repeat;
    background-size: cover;
  }
  .entity-container._sale {
    grid-template-columns: repeat(1,1fr);
  }
  .reg-text{
    font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (800 - 320)));
    line-height: calc(24px + (32 - 24) * ((100vw - 320px) / (800 - 320)));
  }
  .message-white,
  .message-green{
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (800 - 320)));
  }
  .chat-text{
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (800 - 320)));
    line-height: calc(20px + (24 - 20) * ((100vw - 320px) / (800 - 320)));
  }
  .gray-text{
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (800 - 320)));
    line-height: calc(20px + (24 - 20) * ((100vw - 320px) / (800 - 320)));
  }
  .problems-block .gray-subtitle,
  .register-block__text {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (800 - 320)));
    line-height: calc(24px + (32 - 24) * ((100vw - 320px) / (800 - 320)));
  }
  .connection-list__title{
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (800 - 320)));
  }
  .connection-list__item,
  .connection-list__item a{
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (800 - 320)));
  }

  h1, h2, h3, h4 {
    @include font($font, 600, 24px, 32px, $text-color);
  }
  .banner-block {
    padding: 24px 0 0;
    .banner__prev-btn,
    .banner__next-btn {
      display: none;
    }
  }
  .content__container-center{
    padding: 0 24px;
  }
  .landing__register-block{
    flex-direction: column;
    padding: 24px;
    align-items: center;
  }
  .register-block__text{
    margin-bottom: 16px;
    margin-right: 0;
    text-align: center;
  }
  .slider-block {
    .slider__title {
      margin-bottom: 16px;
    }
  }
  .footer__navigation {
    & > .footer__page {
      & > .footer__page-wrap {
        grid-template-columns: 100%;
      }
    }
  }
  .cabinet__areas {
    grid-template-columns: 1fr;
  }
  .cabinet__specials {
    grid-template-columns: 1fr;
  }
  .a-card .a-card {
    &__header {
      display: flex;
      flex-direction: column-reverse;
      align-items: start;
      justify-content: normal;
    }
    &__img {
      margin: 0 0 16px !important;
    }
  }
  .text .picture,
  .picture {
    margin: 32px 0 48px;
  }
  .bik-market-banner__title{
    padding: 16px;
  }
}

/*XS*/

@media screen and (max-width: 600px) {
  .container-geos{
    padding: 0 16px;
  }
  .form_bik-market,
  .new-type__form
  {
    .form__item {
      padding: 0;
      &.grid-start-1,
      &.grid-start-2,
      &.grid-start-3,
      &.grid-start-4,
      &.grid-end-2,
      &.grid-end-3,
      &.grid-end-4,
      &.grid-end-5 {
        grid-column-start: unset;
        grid-column-end: unset;
      }
    }
    &__background{
      //padding: 16px;
    }
  }
  .entity-container._banner-block,
  .entity-container._form-block{
    grid-template-columns: 100%;
  }
  .entity-container._block .block-item:nth-last-child(-n+3){
    border-bottom: 1px solid rgba(0,16,61,.12);
  }
  .entity-container._block .block-item {
    border-bottom: 1px solid rgba(0,16,61,.12);
    &:nth-last-child(-n+1){
      border: none;
    }
  }
  .bik-market._grid{
    grid-template-columns: 1fr;
  }
  .market-logo{
    display: none;
  }
  .bik-market-banner .market-logo__white{
    display: block;
  }
  .bik-market-banner .market-logo__white{
    max-width: clamp(240px,calc(240px + (300 - 240) * ((100vw - 390px) / (600 - 390))),300px);
  }
  .footer__phone-wrap {
    align-items: flex-start;
  }
  .connection-list{
    margin-top: 48px;
  }
  .landing__register-block{
    margin-top: 32px;
  }
  .landing__problems-list{
    flex-direction: column;
  }
  .problems-block{
    margin-top: 32px;
  }
  .chat-window{
    margin-top: 32px;
  }
  .pt-48{
    padding-top: 24px;
  }
  .change__btn:before,
  .register-block__btn:before{
    width: 24px;
    height: 24px;
    background-size: 24px;
    margin-right: 12px;
  }
  .change__btn,
  .register-block__btn{
    font-size: 16px;
    line-height: 24px;
    padding: 12px 32px 12px 16px;
  }
  .top-block__container {
    padding: 16px 20px 0 20px;
  }
  .content__container-center{
    padding: 0 20px;
  }
  .sub-banner {
    grid-gap: 16px;
  }

  .banner-block {
    padding: 32px 0 0;
    height: 400px;
  }
  .entity-container {
    &._block {
      grid-template-columns: 100%;
      .sale_block-item:first-child,
      .block-item:first-child {
        grid-column: 1/2;
      }
    }
  }
  .category-wrap {
    .category-title,
    .category-title__is-link {
      grid-column: 1/3;
    }
    .category-title__button {
      grid-row: 3/4;
      grid-column: 2/3;
    }
    .paginator-container {
      grid-column: 1/2;
      justify-self: self-start;
    }
  }
  .event-card__title_block {
    font-size: 18px;
  }
  .banner {
    grid-template-columns: auto 60px;
    grid-auto-rows: auto;
  }
  .slider-banner__square-img,
  .banner__square-img {
    height: 120px;
    width: 120px;
    grid-row: 3/4;
    grid-column: 1/3;
    margin-left: 0;
    justify-self: self-end;
  }
  .slider-banner__title,
  .banner__title {
    grid-column: 1/3;
    font-size: 16px;
  }

  .slider-banner__caption,
  .banner__caption {
    grid-column: 1/3;
    grid-row: 2/3;
    align-self: self-start;
    line-height: 20px;
  }
  .dev-page__title {
    font-size: 24px;
    line-height: 32px;
  }
  a.sale{
    &._list {
      grid-auto-flow: row;
      grid-template-columns: 100%;
      grid-template-rows: min-content auto min-content;
    }
    .sale__info {
      grid-template-columns: 100%;
    }
  }
  .slider-block {
    aspect-ratio: 1/1;
  }
  .breadcrumb {
    padding-bottom: 0;
    & > * {
      display: none !important;
    }
  }
  .cookie {
    max-width: unset;
    width: calc(100% - 32px);
    bottom: 16px;
    right: 16px;
    padding: 48px 20px 20px;
    &__img {
      right: unset;
      left: 20px;
      @include size(96px, 96px)
    }
  }
}
@media screen and (max-width: 420px) {
  .mailing-form{
    grid-template-columns: auto 96px;
  }
  .mail-box__img{
    width: 96px;
    height: 96px;
  }
}


