@import "vars";

html {
  scroll-behavior: smooth;
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
}
.main-content {
  flex: 1 0 auto;
}

h1, .h1 {
  @include font($font, 600, 48px, 56px, $text-color);
  margin: 0 0 32px;
}
h2, .h2 {
  @include font($font, 600, 32px, 48px, $text-color);
  margin: 24px 0 16px;
}
h3, h4, h5, .h3, .h4, .h5 {
  @include font($font, 600, 24px, 32px, $text-color);
  margin: 24px 0 8px;
}

a,
a:hover {
  text-decoration: none;
}
.info {
  border-radius: 12px;
  border: 1px solid $border-color;
  background-color: $gray-20;
  padding: 16px 24px;
  @include fnt(14px, 24px);
  width: fit-content;
  max-width: 100%;
  & > * {
    @include fnt(14px, 24px);
    margin-bottom: 0;
  }
}

hr {
  margin: 0;
  color: $border-color;
  background-color: $border-color;
  opacity: 1;
}
.text hr {
  margin: 16px 0;
}
p {
  margin: 0;
}
.text {
  p{
    @include font($font, 400, 20px, 32px, $text-color);
    margin-bottom: 16px;
  }
  a {
    display: unset;
    word-wrap: break-word;
    color: $main;
    transition: color 0.2s ease-out;
    &:hover {
      color: $main - #111;
    }
  }
}

$indents: 4, 8, 12, 16, 24, 32, 48, 54;
.m {
  @each $margin in $indents {
    &-#{$margin} {
      margin: #{$margin}px !important;
    }
    &t-#{$margin} {
      margin-top: #{$margin}px !important;
    }
    &b-#{$margin} {
      margin-bottom: #{$margin}px !important;
    }
    &l-#{$margin} {
      margin-left: #{$margin}px !important;
    }
    &r-#{$margin} {
      margin-right: #{$margin}px !important;
    }
    &y-#{$margin} {
      margin-top: #{$margin}px !important;
      margin-bottom: #{$margin}px !important;
    }
    &x-#{$margin} {
      margin-right: #{$margin}px !important;
      margin-left: #{$margin}px !important;
    }
  }
}
.p {
  @each $padding in $indents {
    &-#{$padding} {
      padding: #{$padding}px !important;
    }
    &t-#{$padding} {
      padding-top: #{$padding}px !important;
    }
    &b-#{$padding} {
      padding-bottom: #{$padding}px !important;
    }
    &l-#{$padding} {
      padding-left: #{$padding}px !important;
    }
    &r-#{$padding} {
      padding-right: #{$padding}px !important;
    }
    &y-#{$padding} {
      padding-top: #{$padding}rem !important;
      padding-bottom: #{$padding}px !important;
    }
    &x-#{$padding} {
      padding-right: #{$padding}px !important;
      padding-left: #{$padding}px !important;
    }
  }
}

.text-main {
  color: $main;
}

blockquote {
  display: block;
  position: relative;
  padding: 48px ;
  background: $grey;
  margin: 24px 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 8px;
    background: linear-gradient(0deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);;
  }
}

.link-title {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 20px;
}
.link-title:hover {
  color: $main;
}

#wcag-collapse-button::after {
  content: url("../img/wsag-icon.svg");
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 2px;
  margin-left: 6px;
}

.open-arrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -10px;
  height: 24px;
  width: 100%;
  &::before {
    content: 'Свернуть';
    @include font($font, 400, 14px, 14px, $text-color-secondary);
    text-align: center;
    margin-right: 4px;
    display: block;
    bottom: 10px;
    height: 16px;
  }
  &::after {
    content: '';
    display: block;
    bottom: -8px;
    height: 10px;
    width: 10px;
    border-right: 1px solid $text-color-secondary;
    border-bottom: 1px solid $text-color-secondary;
    transform: rotate(225deg) translate(-7px, 2px);

    transition: 0.2s;
  }
  &.collapsed {
    &::before {
      content: 'Развернуть';
    }

    &::after {
      transform:rotate(45deg) translate(2px, -5px);
      bottom: 0;
    }
  }
}
//.row{
//  margin-left: 0;
//  margin-right: 0;
//}


.inner-search .search-btn,
.text .main-btn,
.main-btn {
  display: inline-block;
  padding: 12px 24px;
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #FFF;
  background-color: $main;
  border-radius: 8px;
  text-align: center;
  border: none;
  &._block {
    width: 100%;
    text-align: center;
  }
}
.inner-search .search-btn:hover,
.main-btn:hover {
  color: #fff;
}
//-----------------------------------------------------

.content-title{
  font-family: $font;
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  background: linear-gradient(90deg, #00CCCC 0%, #0088CC 100%);
  //color:#00CCCC;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  margin-bottom: 24px;
  display: inline-block;
  margin-left: 48px;
  margin-top: 0;
  align-items: flex-start;
}
.mobile-img__wrap{
  position: relative;
}
.btn{
  text-align: left;
  padding: 12px 24px;
  background: #F3F3F5;
  border-radius: 12px;
}

//bik

.container-center{
  max-width: 1440px;
  width: 100%;
  padding: 0 64px;
  margin: 0 auto;
  flex-grow: 1;
}



.overflow-hidden{
  overflow: hidden;
}

.cut-img-center {
  display: block;
  height: 100%;
  width: 100%;
}

// Spinner
.spinner {
  display: block;
  pointer-events: none;
  width: 24px;
  height: 24px;
  border: 2px solid transparent;
  border-color: $grey;
  border-top-color: $darker-grey;
  border-radius: 50%;
  animation: spin 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes spin {
  0%   { transform: rotateZ(0); }
  100% { transform: rotateZ(360deg); }
}

.admin-panel {
  position: fixed;
  width: fit-content;
  max-width: 100%;
  right: 12px;
  bottom: 12px;
  background: #323232;
  padding: 16px 24px;
  border-radius: 12px;
  box-shadow: $shadow;
  z-index: 50;
  a {
    @include font($font, 600, 16px, 24px, #fff);
    cursor: pointer;
    display: block;
    &:hover {
      color: $yellow;
      text-decoration: none;
    }
  }
}


.wait-btn {
  display: flex;
  margin-top: 48px;
  position: relative;
  height: 46px;
  border: 1px solid $main;
  border-radius: 24px;
  @include font($font, 400, 16px, 24pc, $main);
  align-items: center;
  padding: 11px 23px 11px 47px;
  .wait-animate {
    position: absolute;
    top: 11px;
    left: 15px;
    display: block;
    height: 24px;
    width: 24px;
    background: url("../img/GearMyHome.svg") center no-repeat;
    animation: spin 8s infinite linear;
  }
}

// Dropdown like select


.dropdown {
  display: block;
  width: 100%
}
a.select {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding-right: 48px;
  &::after {
    position: absolute;
    top: 22px;
    right: 18px;
  }
  &+.dropdown-menu {
    width: 100%;
    border-radius: 0 0 12px 12px;
    border: 1px solid $border-color;
    border-top: none;
    overflow: hidden;
    li .dropdown-item {
      @include fnt();
      padding: 8px 24px;
      transition: 0.2s ;
      white-space: normal;
      &:hover {
        background-color: $gray-40 !important;
        color: $text-color !important;
      }
    }
  }
}

.alert {
  padding: 8px 16px !important;
  margin-bottom: 8px !important;
  margin-top: 16px !important;
  border-radius: 8px !important;
  border: none !important;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 8px !important;
  font-family: $font;
  font-size: 16px !important;
  font-weight: 24 !important;
  .bold {
    font-weight: 600;
  }
  /*&::before {
    content: '';
    height: 18px;
    width: 18px;
    display: block;
    margin-right: 4px;
    background: url("../img/Warning.svg") no-repeat center;
    background-size: contain;*/


  &.alert-info {
    background-color: #DAEAF2 !important;
    color: $text-color !important;
  }
  .alert__text-wrap {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    grid-column: 1/2;
  }
  .alert__close {
    justify-self: flex-end;
  }
}

.share-title {
  @include font($font, 400, 16px, 24px, $text-color-secondary);
  margin: 24px 0 12px;
  &::after {
    content: ':';
  }
}
.share-buttons {
  display: flex;
  align-items: center;
  .social_button,
  .copyToClipBoardBtn {
    cursor: pointer;
  }
  .copyToClipBoardBtn {
    display: flex;
    & + .copy_tooltip {
      @include font($font, 400, 16px, 32px, $main);
      margin-left: 8px;
    }
  }
}

/* ------- геос ------- */


.container-geos {
  padding: 0 64px;
}
.geos-page__title {
  font-size: 32px;
  line-height: 48px;
  margin: 24px 0 8px;
}

.geos-alert {
  display: flex;
  margin: 8px 0 24px;
  border-radius: 8px;
  max-width: 1200px;
  img {
    display: block;
    height: 20px;
    margin-right: 6px;
    width: 20px;
    margin-top: 1px;
  }
  p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: $text-color-secondary;
  }
}
.geos-alert-btn {
  display: block;
  @include btn();
  &:hover {
    color: #fff;
  }
}

/* ------- геос end ------- */

/* ------- picture ------- */

.text .picture,
.picture {
  margin: 32px 0 64px;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
  color: $text-color;
  box-sizing: border-box;
  &:hover {
    text-decoration: none;
    color: $text-color;
  }
  &__img {
    width: fit-content;
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
    margin-bottom: 16px;
  }
  &__title {
    @include font($font, 600, 24px, 32px, $text-color);
    margin: 0;
    box-sizing: border-box;
  }
  &__caption {
    @include font($font, 400, 16px, 24px, $text-color-secondary);
    margin: 0;
    box-sizing: border-box;
  }
}


/* ------- picture end ------- */


@import "accordion";
@import "header";
@import "search";
@import "nav";
@import "breadcrumbs";
@import "page";
@import "page_dev";
@import "page_category";
@import "page_filters";
@import "page_navigation";
@import "maintenance_page";
@import "slider";
@import "event";
@import "organisation";
@import "service";
@import "sale";
@import "documents";
@import "structure";
@import "useful_links";
@import "steps";
@import "table";
@import "forms";
@import "pagination";
@import "footer";
@import "preloader";
@import "calculator";
@import "print";
@import "bot_landing";
@import "tos_landing";
@import "login-register";
@import "cabinet";
@import "emergendy";
@import "vacancy";
@import "cookie";


@import "snow_page";
@import "assistant";
