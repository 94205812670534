@import "vars";

.cookie {
  display: grid;
  position: fixed;
  bottom: 24px;
  right: 24px;
  grid-template-columns: auto 1fr;
  max-width: 480px;
  padding: 32px;
  background: rgba(255, 255, 255, .85);
  backdrop-filter: blur(12px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 999;
  &__title {
    @include fnt(16px, 24px);
    margin: 0 0 8px;
    grid-column: 1/3;
    grid-row: 1/2;
  }
  &__description {
    @include fnt(12px, 16px, $text-color-secondary);
    margin: 0 0 16px;
    grid-column: 1/3;
    grid-row: 2/3;
    a {
      @include fnt(12px, 16px, $text-color-secondary, 600);
    }
  }
  &__button {
    padding: 8px 24px 8px 16px;
    cursor: pointer;
    &._success {
      display: flex;
      @include fnt(14px, 24px, #fff, 500);
      background-color: $main;
      border-radius: 4px;
      grid-row: 3/4;
      grid-column: 1/2;
      transition: background-color 0.3s ease-out;
      &::before {
        display: block;
        content: '';
        background: url("../img/button-check.svg") center no-repeat;
        background-size: contain;
        @include size(24px, 24px);
        margin-right: 4px;
      }
      &:hover {
        background-color: $main-darker;
      }
    }
    &._close {
      @include fnt(14px, 24px, $text-color-secondary);
      margin: 0 0 0 16px;
      grid-column: 2/3;
      grid-row: 3/4;
      transition: color 0.3s ease-out;
      &:hover {
        color: $text-color;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__img {
    display: block;
    position: absolute;
    top: 0;
    right: 24px;
    transform: translateY(-50%);
    background: url("../img/cookie.png") center no-repeat;
    @include size(120px, 120px);
    background-size: contain;
  }
}
