@import "vars";

/* LEADER */


.structure__title {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $text-color;
  margin: 40px 0 0 8px;
}


.contact-leader {
  display: grid;
  grid-auto-columns: 200px 1fr;
  background-color: $gray-20;
  border: none;
  border-radius: 8px;
  min-height: 304px;
  overflow: hidden;
}

.contact-leader__img {
  object-fit: cover;
  overflow: hidden;
  height: 100%;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 2;
}
.contact-leader__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  grid-column-start: 2;
  grid-column-end: 3;
}
.contact-leader__name {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $text-color;
  margin-bottom: 12px;
}
.contact-leader__position {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $text-color;
  margin-bottom: 16px;
}

.card-leader {
  padding: 32px;
  background-color: $gray-20;
}
.card-leader__head {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid $border-color;
}
.card-leader__img {
  flex: none;
  height: 128px;
  width: 128px;
  border-radius: 64px;
  object-fit: cover;
}
.card-leader__name {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $text-color;
  margin-bottom: 8px;
}
.card-leader__position {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: $text-color-secondary;
  margin-bottom: 0;
}

.card-leader__contact-item {
  display: flex;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 24px;
}
.card-leader__contact-item:last-child {
  margin-bottom: 0;
}
.card-leader__icon {
  display: block;
  height: 24px;
  width: 24px;
  margin-right: 12px;
}
.leader_search,
.leader {
  display: grid;
  width: 100%;
  max-width: 650px;
  grid-template-columns: clamp(68px, calc(68px + (128 - 68) * ((100vw - 320px) / (1920 - 320))), 128px) auto;
  grid-auto-rows: auto;
  grid-column-gap: 32px;
  position: relative;
  padding: 24px 0;
  //border: 1px solid $border-color;
  transition: 0.2s ease-out;
  &._sub {
    grid-column: 1 / 3;
  }
  &.leader_lv-1 {
    //background-color: #F3F3F5;
  }
  &_parent {
    background: #F3F3F5;
    position: relative;
  }
  &.leader_lv-2::after {
    content: '';
    display: block;
    position: absolute;
    border-left: 2px dotted #d9d9de;
    height: calc(100% - 26px - clamp(68px,calc(68px + (128 - 68) * ((100vw - 320px)/ (1920 - 320))),128px));
    width: 1px;
    left: (clamp(32px,calc(32px + (64 - 32) * ((100vw - 320px)/ (1920 - 320))),64px));
    bottom: 2px;
  }
}
.leader_search {
  padding: 32px 0;
  border: none;
  border-bottom: 1px solid $border-color;
}

.leader__arrow_parent {
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
  transition: 0.2s ease-out;
}
.leader__arrow_parent:hover {
  cursor: pointer;
}
.active > .leader__arrow_parent,
.active > .leader_parent > .leader__arrow_parent {
  transform: rotate(180deg);
}

.leader__img {
  flex: none;
  @include scale(width, 68, 128);
  @include scale(height, 68, 128);
  @include scale(border-radius, 34, 64);
  object-fit: cover;
}
.leader__head {
  //border-bottom: 1px solid $border-color;
  //padding-bottom: 16px;
}
.sub-units-wrap .leader__head {
  border-bottom: none;
  padding-bottom: 16px;
}
.sub-units-wrap .leader__body {
  padding-top: 0;
}
.leader__position {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: $text-color-secondary;
  margin-bottom: 0;
}
.leader__name {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $text-color;
  margin-bottom: 0;
}
.leader__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.card-leader__body,
.leader__body {
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  padding-top: 16px;
}
.leader__contact-item {
  display: flex;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
}
.leader__icon {
  display: block;
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.sub-units-wrap {
  position: relative;
  height: 0px;
  @include scale(padding-left, 68, 128);
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  overflow: hidden;
  padding-top: 1px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    @include scale(left, 32, 64);
    @include scale(padding-left, 34, 64);
    height: 100%;
    width: 0;
    border-left: 2px dotted $border-color;
  }
}
.sub-units-wrap_lv-4 {
  height: 0px;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  overflow: hidden;
  border-radius:  0 0 8px 8px;
}
.leader_lv-4 {
  padding: 32px;
  display: flex;
  border-bottom: 1px solid $border-color;
}
.leader_lv-4:last-child {
  border-bottom: none;
}
.sub-units-wrap.active {
  height: auto;
  overflow: visible;
  opacity: 1;
  visibility: visible;
}
.active > .sub-units-wrap_lv-4 {
  height: auto;
  overflow: visible;
  opacity: 1;
  visibility: visible;
  border: 1px solid $border-color;
  border-top: none;
}
.leader_lv-3 {
  position: relative;
}
.leader__main_lv-3 {
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 32px;
  display: flex;
}
.active > .leader__main_lv-3 {
  border-bottom: none;
  border-radius: 8px 8px 0 0;
}
.sub-units-wrap > .leader::before {
  content: '';
  display: block;
  position: absolute;
  border-top: 2px dotted #D9D9DE;
  height: 1px;
  @include scale(width, 32, 64);
  left: calc(clamp(32px, calc(36px + (64 - 36) * ((100vw - 320px)/ (1920 - 320))), 64px) * -1);
  @include scale(top, 57, 89);
}
.sub-units-wrap > .leader:last-child::after {
  content: '';
  display: block;
  position: absolute;
  background-color: $gray-20;
  left: calc(clamp(36px, calc(36px + (64 - 36) * ((100vw - 320px)/ (1920 - 320))), 64px) * -1);
  height: calc(100% - clamp(58px, calc(32px + (90 - 58) * ((100vw - 320px)/ (1920 - 320))), 90px));
  width: 4px;
  @include scale(top, 58, 90);
}

.leader-page__title {
  @include font($font, 600, 40px, 56px, $text-color);
  margin-bottom: 8px;
}
.leader-page__lead {
  @include font($font, normal, 32px, 48px, $text-color);
  margin-bottom: 24px;
}
.leader-page__content-label {
  @include font($font, normal, 16px, 24px, $text-color);
  margin-bottom: 4px;
  margin-top: 24px;
}
.leader-page__content-item {
  @include font($font, normal, 24px, 32px, $text-color);
  margin-bottom: 24px;
}
.leader-page__content-url {
  @include font($font, normal, 24px, 32px, $text-color);
  text-decoration: underline;
  margin-bottom: 24px;
  transition: .2s;
}
.leader-page__content-url:hover {
  color: $main;
  text-decoration: underline;
}
.leader__child-wrap {
  grid-column: 1/3;
  @include scale(padding-left, 12, 124);
}
.leader._small {
  padding: 12px 0;
  grid-template-columns: clamp(48px, calc(48px + (80 - 48) * ((100vw - 320px) / (1920 - 320))),80px) auto;
  @include scale(grid-gap, 12, 24);
  .leader__img {
    @include scale(width, 48, 80);
    @include scale(height, 48, 80);
  }
  .leader__head {
    padding-bottom: 8px;
  }
  .leader__position {
    font-size: 14px;
    line-height: 20px;
  }
  .leader__name {
    font-size: 18px;
    line-height: 24px;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    border-left: 2px dotted #d9d9de;
    height: calc(100% - clamp(48px,calc(48px + (80 - 48) * ((100vw - 320px)/ (1920 - 320))),80px));
    width: 1px;
    @include scale(left, 24, 40);
    bottom: -12px;
  }
  &:last-child::after {
    display: none;
  }
}
.orgunit_container-over-3 {
  padding-left: 0;
  & > a.collapse-arrow {
    display: block;
    position: relative;
    @include fnt(16px, 24px, $text-color);
    border: 1px solid $border-color;
    border-radius: 12px;
    padding: 12px 48px 12px 24px;
    margin-top: 12px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 18px;
      right: 16px;
      height: 10px;
      width: 10px;
      border-right: 1px solid $text-color-secondary;
      border-bottom: 1px solid $text-color-secondary;
      transform: rotate(-135deg) translate(-2px, -2px);
      transition: .2s;
      transform-origin: 5px;
    }
    &.collapsed::after {
      transform: rotate(45deg) translate(-2px, -2px);
      bottom: 0;
    }
  }
  .leader__child-wrap {
    padding-left: clamp(4px,calc(4px + (32 - 4) * ((100vw - 320px)/ (1920 - 320))), 32px);
  }
  .card-leader__body,
  .leader__body {
    grid-template-columns: 100%;
    .leader__contact-item {
      span {
        font-size: 14px;
        line-height: 20px;
      }
      .leader__icon {
        height: 20px;
        width: 20px;
      }
    }
  }
  .leader._small {
    grid-template-columns: clamp(48px,calc(48px + (64 - 48) * ((100vw - 320px)/ (1920 - 320))),64px) auto;
    &::after {
      height: calc(100% - clamp(48px,calc(48px + (64 - 48) * ((100vw - 320px)/ (1920 - 320))),64px));
      left: clamp(24px,calc(24px + (32 - 24) * ((100vw - 320px)/ (1920 - 320))),32px);
    }
    .leader__img {
      width: clamp(48px,calc(48px + (64 - 48) * ((100vw - 320px)/ (1920 - 320))),64px);
      height: clamp(48px,calc(48px + (64 - 48) * ((100vw - 320px)/ (1920 - 320))),64px);
    }
    .leader__position {
      font-size: 12px;
      line-height: 16px;
    }
    .leader__name {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
