@import "vars";

/* SLIDER */

.swiper {
  border-radius: 24px;
  .slider-next-btn,
  .slider-prev-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    height: 48px;
    width: 48px;
    &.swiper-button-disabled {
      cursor: unset;
      pointer-events: none;
      opacity: 0.5;
    }
    &:before {
      content: '';
      display: block;
      height: 26px;
      width: 14px;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='26' viewBox='0 0 14 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071L1.70711 25.7071C1.31658 26.0976 0.683416 26.0976 0.292892 25.7071C-0.0976326 25.3166 -0.0976325 24.6834 0.292892 24.2929L11.5858 13L0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976317 1.31658 -0.0976317 1.70711 0.292893L13.7071 12.2929Z' fill='white'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  .slider-next-btn {
    right: 0;
  }
  .slider-prev-btn {
    left: 0;
    &:before {
      transform: rotate(180deg);
    }
  }
}
.slider-block {
  height: 360px;
  background: linear-gradient(90deg, #0088CC 0%, #00CCCC 100%);
  position: relative;
  .slider {
    display: grid;
    height: 100%;
    grid-template-rows: 1fr auto auto 1fr;
    grid-template-columns: auto auto;
    &__title {
      grid-row: 2/3;
      grid-column: 1/2;
      align-self: end;
      @include fnt(32px, 40px, #fff,600);
      font-family: $font;
      color: #fff;
      font-weight: 600;
      @include scale(font-size, 20, 32);
      @include scale(line-height, 28, 40);
      margin-bottom: 24px;
    }
    &__button {
      display: inline-block;
      grid-row: 3/4;
      grid-column: 1/2;
      @include fnt(16px, 24px, #fff);
      padding: 11px 31px;
      border: 1px solid #fff;
      border-radius: 4px;
      background-color: transparent;
      transition: background-color 0.3s ease-out;
      width: fit-content;
      &:hover {
        background-color: #fff;
        color: $main;
      }
    }
    &__img-block {
      grid-row: 1/5;
      grid-column: 2/3;
      height: 100%;
      width: 100%;
      //padding-right: clamp(4px, calc(4px + (160 - 4) * ((100vw - 960px)/(1440 - 960))), 160px)
    }
    &__img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: bottom center;
    }
  }
}

/* BANNER */


.banner-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 32px;
  padding: 13px 0;
  margin: 0 0 72px;
}
.banner {
  display: grid !important;
  grid-template-columns: auto 136px;
  grid-auto-rows: 2fr 3fr;
  height: 100%;
  align-items: center;
  min-height: 144px;
  background-color: $gray-40;
  border-radius: 12px;
  padding: 24px 32px;
  &._single {
    grid-column: 1/3;
  }
}
.banner__slide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 55% 45%;
  height: 100%;
}
.banner__more-btn {
  grid-column: 1/2;
  grid-row: 2/3;
  align-self: self-start;
}
.banner__text-block {
  align-self: center;
}
.banner__img-block {
  height: 100%;
  width: 100%;
  justify-self: center;
  grid-row: 1/3;
}
.banner__img {
  width: 100%;
  height: 100%;
  border-radius: 32px;
  object-fit: contain;
  object-position: center bottom;
}

.banner__square-img {
  grid-column: 2/3;
  grid-row: 1/3;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  margin-left: 16px;
  object-fit: contain;
  object-position: center;
}

.banner__title {
  grid-column: 1/2;
  grid-row: 1/2;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  @include scale(line-height, 28, 32);
  color: $text-color;
  margin-bottom: 8px;
}

.banner__title {
  line-height: 28px;
}

.banner__caption {
  grid-column: 1/2;
  grid-row: 2/3;
  align-self: start;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $text-color-secondary;
}



.flexslider.control-nav {
  padding: 0  !important;
  border: none !important;
  box-shadow: none  !important;
}

.flex-direction-nav a:before,
.flex-direction-nav a.flex-next:before {
  content: url("../img/slider-arrow.svg") !important;
}
.flex-direction-nav a.flex-prev:before {
  transform: rotate(180deg);
}

.swiper-pagination-bullet-active {
  background-color: $main !important;
}


.banner-block {
  display: block;
  width: 100%;
  height: 360px;
  padding: 16px 0 0;
  background: linear-gradient(90deg, #0088CC 0%, #00CCCC 100%);
}

.banner__more-btn {
  @include font($font, 400, 16px, 24px, white);
  padding: 12px 32px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  width: fit-content;
  &:hover {
    color: white;
    text-decoration: none;
  }
}
.swiper {
  border-radius: 0;
}
.banner__next-btn {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 64px;
  bottom: 48px;
  &:after {
    content: url("../img/arrow_right.svg");
    height: 32px;
    width: 32px;
    display: block;
  }
  &.swiper-button-disabled {
    opacity: 40%;
  }
}
.banner__prev-btn {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 104px;
  bottom: 48px;
  &:after {
    transform: rotate(180deg);
    content: url("../img/arrow_right.svg");
    height: 32px;
    width: 32px;
    display: block;
  }
  &.swiper-button-disabled {
    opacity: 40%;
  }
}

// slider banner

.slider-banner {
  position: relative;
  overflow: hidden;
  margin: 16px 0 48px;

  &__slide {
    display: block;
    border-radius: 24px;
    overflow: hidden;
  }
  &__img {
    display: block;
    width: 100%;
  }
  .swiper-pagination-bullet {
    background-color: #ffffff !important;
    opacity: 0.5 !important;
    &.swiper-pagination-bullet-active {
      background-color: #ffffff !important;
      opacity: 1 !important;
    }
  }
}


@media screen and (max-width: 1366px) {
  .slider-banner {
    margin: 16px -16px 48px;
    padding: 0 16px;
  }
}


/* SUB-BANNER */

.sub-banner {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 32px;
  overflow-x: auto;
  margin-top: -48px;
  margin-right: -64px;
  margin-left: -64px;
  padding: 16px 64px 24px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  &__item {
    display: block;
    background: rgba(243, 243, 245, 0.5);
    backdrop-filter: blur(12px);
    border-radius: 12px;
    padding: 56px 24px;
    box-shadow: 0 8px 16px rgba(1, 1, 1, 0.1);
    z-index: 3;
    transition: 0.3s ease-out;
    min-width: 270px;
    &:hover {
      margin-top: -12px;
      margin-bottom: 12px;
      box-shadow: 0 14px 24px rgba(1, 1, 1, 0.1);
    }
  }
}
.subbanner-title {
  @include font($font, 400, 14px, 24px, $main);
  margin-bottom: 8px;
}
.subbanner-subtitle {
  @include font($font, 600, 16px, 24px, $text-color);
}


/* GALLERY */

.gallery__slide {
  display: grid;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: $text-color;
  .gallery__info {
    position: absolute;
    background-color: rgba(10, 10, 10, 0.66);
    padding: 16px 32px;
    bottom: 0;
    right: 0;
    left: 0;
    .gallery__title {
      @include font($font, 600, 24px, 32px, #fff);
      margin-bottom: 0;
    }
    .gallery__description {
      @include font($font, 400, 18px, 24px, #fff);
      margin-bottom: 0;
    }
  }
  .gallery__img {
    width: 100%;
    aspect-ratio: 3 / 2;
    object-fit: cover;
    //filter: contrast(1.05) saturate(1.2) hue-rotate(-5deg);
  }
}
.gallery__prev-btn,
.gallery__next-btn {
  &::after{
    content: '';
    display: block;
    height: 32px;
    width: 32px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-135deg);
    filter: drop-shadow(0 0 1px $text-color);
    opacity: 0.7;
  }
  display: grid;
  place-items: center;
  position: absolute;
  height: 100%;
  width: clamp(42px, 20%, 400px);
  top: 0;
  z-index: 10;
  transition: 0.2s ease-out;
  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0;
  }
}
.gallery__next-btn {
  right: 0;
  &::after{
    transform: rotate(45deg);
  }
}

.market-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFF;
  border-radius: 24px;
  padding: 24px 48px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  &-wrap {
    margin: 72px 0;
  }
  &__title {
    @include fnt(24px, 32px, $weight: 600);
    margin: 0;
    &-wrap {
      display: flex;
      flex-direction: column;
    }
  }
  &__caption {
    @include fnt(16px, 24px, $text-color-secondary);
    margin: 8px 0 0;
  }
  &__link {
    display: block;
    height: 100%;
    &._green-shadow {
      transition: filter 0.3s ease-out;
      filter: drop-shadow(0px 0px 0px rgba(4, 224, 97, 0));
      &:hover {
        filter: drop-shadow(0px 0px 24px rgba(4, 224, 97, 0.7))
      }
    }
    &._blue-shadow {
      transition: filter 0.3s ease-out;
      filter: drop-shadow(0px 0px 0px rgba(4, 104, 255, 0));
      &:hover {
        filter: drop-shadow(0px 0px 24px rgba(4, 104, 255, 0.7));
      }
    }
  }
  &__img {
    height: 100%;
    width: auto;
    &-wrap {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 32px;
      padding: 0 0 0 32px;
    }
    &-separator {
      display: block;
      @include size(100%, 1px);
      background-color: $border-color;
    }
  }
}
.square {
  .swiper-slide {
    width: 80%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

