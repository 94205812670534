@import "vars";


.container-registration{
  max-width: 560px;
  padding: 0 64px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.registration-background{
  background: #FFFFFF;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 32px 64px 40px 64px;
}
.main-background-registration{
  background: #F9F9FA;
}
.registration-title{
  @include font($font, 600, 32px, 40px, $text-color);
}
.registration-second-title{
  @include font($font, 600, 24px, 32px, $text-color);
}
.registration-subtitle{
  @include font($font, 400, 14px, 20px, $text-color-secondary);
  margin-bottom: 16px;
}
.registration-form{
  .form__item{
    padding: 0 0 16px 0;
  }
  .form-check-input{
    display: inline-flex;
    flex: none;
    width: 18px;
    height: 18px;
    border: 1px solid #2C2D2E;
    border-radius: 4px;
    margin-right: 8px;
    @include font($font, 400, 14px, 20px, $text-color);
  }
  .form-check-input:checked[type=checkbox] {
    background-image: url(../img/V.svg);
    background-size: 24px;
    border-radius: 4px;
    background-color: #00CCCC;
    border: none;
  }
}
.restore-password{
  @include font($font, 400, 14px, 20px, #0088CC);
  margin-bottom: 16px;
  display: block;
  &:hover{
    cursor: pointer;
    color:  #0088CC;
  }
}
.registration-btn{
  @include font($font, 600, 16px, 24px, white);
  border-radius: 8px;
  width: 100%;
  padding: 12px 0;
  border: none;
  &:hover{
    color: white;
  }
  &._login{
    background: #0088CC;
    margin-bottom: 16px;
  }
  &._registration{
    background: #00CCCC;
    display: block;
    text-align: center;
  }
}
.links-agreement{
  @include font($font, 400, 14px, 20px, #0088CC);
    cursor: pointer;
    &:hover{
      color: #0088CC;
    }
}
.field-error{
  @include font($font, 400, 14px, 16px, #f35);
  display: block;
  margin-top: -8px;
  margin-bottom: 16px;
  margin-left: 8px;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #dc3545!important;
}
.register-checkbox{
  p{
    @include font($font, 400, 14px, 20px, $text-color);
    color: $text-color!important;
  }
}
