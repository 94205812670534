@import "vars";
.sale__price {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  @include scale(font-size, 18, 24);
  @include scale(line-height, 24, 32);
  color: $main;
  margin-bottom: 8px;
}

.sale__info {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  @include scale(padding-right, 16, 32);
  @include scale(padding-left, 16, 32);
  @include scale(padding-top, 8, 16);
  @include scale(padding-bottom, 16, 32);
  height: 100%;
}
.sale__type{
  @include font($font, 400, 14px, 20px, $text-color);
  margin-bottom: 12px;
}
.sale__description{
  p{
    @include font($font, 400, 14px, 20px, $text-color);
  }
}
.sale__region{
  @include font($font, 600, 24px, 32px, $text-color);
  margin-bottom: 4px;
}
.sale__title {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  @include scale(font-size, 18, 24);
  @include scale(line-height, 24, 32);
  color: $text-color;
  margin-bottom: 8px;
}
.sale__buy {
  justify-self: right;
  @include btn(#fff, $main, true);
}
.sale__description {
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  @include scale(font-size, 12, 16);
  @include scale(line-height, 18, 24);
  color: $text-color;
  //margin-bottom: 8px;
}
.sale__organisation {
  align-self: center;
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $main;
  margin-bottom: 0;
}
.sale__img {
  display: block;
  width: 100%;
  aspect-ratio: 2/1;
  object-fit: cover;
  object-position: bottom;
  flex: none;
  height: 100%;
}

a.sale{
  display: grid;
  //border-radius: 12px;
  overflow: hidden;
  //box-shadow: $shadow;
  //min-height: 100%;
  background-color: #fff;
  transition: .2s ease-out;
  grid-template-rows: min-content auto min-content;
  aspect-ratio: 416/520;

  &._list {
    grid-auto-flow: column;
    grid-template-columns: 100%;
    cursor: pointer;
    height: 100%;
    padding-bottom: 32px;
    //grid-template-rows: auto;
    .sale__area{
      @include font($font, 400, 16px, 24px, $text-color);
      background: $gray-40;
      padding: 0 8px;
      border-radius: 16px;
      width: fit-content;
      height: fit-content;
    }
    .sale__img{
      aspect-ratio: 11/6;
    }
    .sale__kadastr{
      @include font($font, 400, 14px, 16px, $text-color-secondary);
      margin-bottom: 4px;
    }
    .sale__description{
      margin-bottom: 19px;
    }
    .sale__price{
      @include font($font, 400, 20px, 24px, $text-color);
      margin-bottom: 0;
    }
    .sale-card__separator{
      display: block;
      width: 100%;
      height: 1px;
      background: rgba(0, 16, 61, 0.12);
      margin-bottom: 20px;
    }
    .sale__title{
      margin: 4px 0;
    }
  }

  &._block {
    .sale__info {
      grid-template-rows: min-content min-content auto min-content;
    }
    .sale__title {
      grid-column: 1/3;
      grid-row: 1/2;
    }
    .sale__price {
      grid-column: 1/3;
      grid-row: 2/3;
      justify-self: left;
    }
    .sale__description {
      grid-row: 3/4;
    }
    .sale__organisation {
      grid-row: 4/5;
      grid-column: 1/2;
    }
    .sale__buy {
      grid-row: 4/5;
      grid-column: 2/3;
    }
  }
  &:hover {
    //box-shadow: $shadow-active;
  }
}





// slider-toggle checkbox
.switcher-checkbox{
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.switcher-text{
  @include font($font, 400, 14px, 16px, $text-color);
}

.switch {
  display: inline-block;
  height: 16px;
  position: relative;
  width: 28px;
  margin-right: 6px;
}

.switch input {
  display:none;
}

.slider {
  background-color: $gray-60;
  border: 1px solid $gray-120;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  content: "";
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  position: absolute;
  left: 2px;
  bottom: 50%;
  transition: .4s;
  height: 12px;
  width: 12px;
  transform: translateY(50%);
}

input:checked + .slider {
  background-color: $main;
  //outline: 1px solid $gray-120;

}

input:checked + .slider:before {
  transform: translateX(10px) translateY(50%);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}














.filter-block {
  &._market{
    .rubric__header {
      display: none;
    }
    .rubric__container {
      display: flex;
      flex-wrap: wrap;
      .main-checkbox{
        background: none;
        margin: 0 24px 0 0;
        padding: 0;
        display: flex;
        align-items: center;
        &.active{
          background: none;
          .main-checkbox__text{
            color: $text-color;
            margin: 0;
          }
          &:before{
            background-color: $cyan;
            border: none;
            background-size: 12px;
            border-radius: 4px;
          }
        }
        &:hover{
          background: none;
        }
        &:before{
          border: 2px solid $icon-color-secondary;
          border-radius: 4px;
          border-radius: 4px;
          margin: 0 6px 0 0;
        }
        &__text{
          @include font($font, 400, 14px, 24px, $text-color);
          margin: 0;
        }
      }
    }
  }
}
.bik-market-banner{
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #E6E7EB;
  border-radius: 24px;
  aspect-ratio: 82/17;
  width: 100%;
  margin-bottom: 32px;
  box-shadow: none;
  overflow: hidden;

  &__title{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 32px 12px 32px 32px;
  }
  &__img{
    background: url("../img/market-banner-new.png") #E6E7EB right no-repeat;
    background-size: cover;
  }
  .market-logo,
  .market-logo__white{
    width: 100%;
    height: auto;
    max-width: 528px;
  }
  .market-logo__white{
    display: none;
  }
  &__text{
    @include font($font, 400, 16px, 24px, $text-color);
    max-width: 720px;
  }
}
.bik-market__header-filter{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
  .category-page__filter{
    display: flex;
    align-items: center;
  }
  .modal-btn{
    @include font($font, 400, 14px, 24px, white);
    padding: 8px 16px;
    background: #0073E5;
    border-radius: 8px;
    border: none;
    height: fit-content;
    &:hover{
      background: #005999;
    }
  }
}
.sale-card__slider{
  border-radius: 24px;
  position: relative;
  //.swiper-pagination{
  //  display: flex;
  //  justify-content: space-between;
  //  width: 100%;
  //  height: 100%;
  //  bottom: 0 !important;
  //  //.swiper-pagination-bullet{
  //  //  width: 100%;
  //  //  border-radius: 0;
  //  //  height: 100%;
  //  //  opacity: 0;
  //  //}
  //}
  &:hover{
    .swiper-pagination{
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%);
      transition: background 0.3s;
      .swiper-pagination-bullet,
      .swiper-pagination-bullet-active{
        display: block;
      }
      &.swiper-pagination-lock{
        background: transparent;
      }
    }
  }
  .swiper-pagination{
    z-index: 20;
    bottom: 0!important;
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 12px 16px;
    transition: background 0.3s;
    .swiper-pagination-bullet{
      display: none;
      background: rgba(255, 255, 255, 0.50);
      opacity: unset;
    }
    .swiper-pagination-bullet-active{
      display: none;
      background: white!important;
      opacity: unset;
    }
  }
  .hover-pagination-wrap{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 10;
    div{
      width: 100%;
      height: 100%;
    }
  }
  .swiper-wrapper{
    //max-width: 416px;
  }
  .rubrics_wrap{
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    .sale__rubrics{
      margin-right: 4px;
      @include font($font, 400, 14px, 24px, $text-color);
      padding: 0 8px;
      border-radius: 12px;
      width: fit-content;
      background-color: white;
      margin-bottom: 4px;
    }
    .sale__rubrics-bik{
      @include font($font, 400, 14px, 24px, white);
      background-color: rgb(0, 126, 217);
      padding: 0 8px;
      border-radius: 12px;
      width: fit-content;
      margin-right: 4px;
      margin-bottom: 4px;
      background-blend-mode: multiply;
    }
  }
}

.sale-card__price{
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.bik-market-swiper{
  height: 100%;
  border-radius: 24px;
  .swiper-slide{
    width: 100%!important;
  }
}

.sale-card__hidden-content{
  border-top: 1px solid rgba(0, 16, 61, 0.12);
  padding-top: 12px;
}


