@import "vars";

.breadcrumb {
  background-color: transparent;
  padding: 0 0 16px 12px;
  margin-bottom: 0;
  flex-wrap: nowrap;
  max-width: 100%;
}
.breadcrumb-item {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #919399;
  display: block;
  //max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.breadcrumb-item+.breadcrumb-item::before {
  content: '·';
}
