@media print {
  .main-btn,
  section.header,
  .alert,
  footer {
    display: none !important;
  }

  .calculator-page {
    .form__item,
    .dropdown,
    #download,
    #special_container,
    h1,
    .calculator__description,
    .calculator__card,
    .calculator__result,
    .calculator__button,
    .share-block
    {
      display: none !important;
    }
    .calculator__table {
      box-shadow: none;
      margin: 0;
    }
    .table__cell {
      padding: 4px 8px;
      font-size: 14px;
    }
    .text-right{
      text-align: right !important;
    }
  }
}
