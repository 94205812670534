@import "vars";

.organisation {
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: #fff;
  height: 100%;
  box-shadow: $shadow;
  transition: 0.3s ease-out;
  border: 1px solid $border-color;
  border-radius: 12px;
  &:hover {
    box-shadow: $shadow-active;
  }
}
.organisation.side {
  height: fit-content;
}
.organisation__img {
  width: 128px;
  height: 128px;
  overflow: hidden;
  object-position: center;
  object-fit: contain;
  margin-bottom: 12px;
}
.organisation__title {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: $text-color;
  margin-bottom: 12px;
}
.organisation__description {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $text-color-secondary;
  margin-bottom: 12px;
}
.organisation__item {
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 12px;
}
.organisation__url {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #005BD1;
  margin-bottom: 0;
}
