@import "vars";
$chat-green-color: #CCFFCC; //#CCFFFF
$chat-top-padding: 64px;
$chat-bottom-padding: $chat-top-padding;
.assistant {
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(300px, 3fr) minmax(0, 1fr);
  height: calc(100vh - 165px - 22px);
  grid-gap: 32px;
  &-bg {
    width: 100%;
    background: linear-gradient(
        0deg,
        #00CCCC 0%, #00CBCC 6.67%,
        #00CACC 13.33%, #00C6CC 20%,
        #00C2CC 26.67%, #00BCCC 33.33%,
        #00B5CC 40%, #00AECC 46.67%,
        #00A6CC 53.33%, #009FCC 60%,
        #0098CC 66.67%, #0092CC 73.33%,
        #008ECC 80%, #008ACC 86.67%,
        #0089CC 93.33%, #0088CC 100%);
    overflow: hidden;
  }
  &__cat {
    display: grid;
    place-items: end center;
    grid-template-rows: auto 1fr;
    .cat__info {
      display: none;
    }
    .cat__image {
      &-wrap {
        width: 100%;
        aspect-ratio: 5/9;
        overflow: hidden;
      }
      height: auto;
      width: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }
  &__chat {
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    height: fit-content;
    min-height: 100%;
    overflow-y: hidden;
    padding: 0 0 0 8px;
    &_wrap {
      height: 100%;
      overflow-y: auto;
      padding: 0 24px 0 0;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
      }
      &::-webkit-scrollbar-button {
        height: $chat-bottom-padding;
      }
    }
    .chat__bubble {
      &._question {
        margin: 8px 0 0 auto;
        transform: translateX(0);
        &._actual {
          &:hover {
            transform: translateX(-8px);
          }
        }
      }
      &._answer {
        margin: 24px auto 0 0;
      }
    }
    .chat__history,
    .chat__answers,
    .chat__questions {
      display: contents;
    }
    .chat__navigation {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 $chat-bottom-padding auto;
    }
    .chat__history {
      overflow: hidden;
      .chat__bubble {
        transition: opacity .3s, transform .3s;
        opacity: 0;
        &._actual {
          opacity: 1;
        }
        &._history {
          opacity: 0.6;
          &:hover {
            opacity: 1;
          }
        }
        &:first-child {
          margin-top: $chat-top-padding;
        }
      }
    }
  }
  .assistant__chat_wrap .assistant__chat .loading {
    @include fnt(16px, 24px, #fff, 400);
    margin: 16px 0 0;
    background: transparent !important;
    &:after {
      content: '.';
      animation: ellipsis 1s infinite;
    }
  }
}
@keyframes ellipsis {
  0% {
    content: '.';
  }
  50% {
    content: '..';
  }
  100% {
    content: '...';
  }
}
.to-main._white {
  display: flex;
  justify-self: start;
  @include fnt(14px, 24px, #fff);
  margin: 16px 0;
  white-space: nowrap;
  &::before {
    content: '';
    display: block;
    @include size(24px, 24px);
    background: url("../img/assistant/arrow-right-alt.svg") center no-repeat;
    margin: 0 4px 0 0;
  }
}

.chat__bubble {
  flex: none;
  padding: 10px 16px;
  width: fit-content;
  max-width: 100%;
  .bubble__name {
    @include fnt(14px, 20px, $main, 600);
    margin: 0;
  }
  .bubble__text {
    p {
      @include fnt(14px, 20px);
      cursor: inherit;
      margin: 0;
      a {
        font-weight: 600;
        color: $main;
        text-decoration: none;
      }
    }
  }
  &._answer {
    background-color: #fff;
    border-radius: 0px 12px 12px 8px;
    margin: 24px auto 0 0;
  }
  &._question {
    background-color: $chat-green-color;
    border-radius: 12px 8px 0px 12px;
    margin: 0 0 12px 12px;
    transition: box-shadow 0.3s;
    &:hover{
      box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.25);
    }
  }
  &._button {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    @include fnt(14px, 20px);
    margin: 12px 0 0 8px;
    img {
      margin: 0 4px 0 0;
    }
  }
}


// plugin

$card-top-margin: 82px;
.assistant-card {
  display: grid;
  grid-template-columns: 3fr 8fr;
  background: linear-gradient(88.56deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  width: 100%;
  margin-top: calc(#{$card-top-margin} + 64px);
  &__cat,
  &__bubble {
    margin-top: -#{$card-top-margin};
  }
  &__cat {
    grid-column: 1/2;
    grid-row: 1/3;
    display: block;
    width: 100%;
    height: calc(100% + #{$card-top-margin});
    overflow: hidden;
    position: relative;
    .cat__image {
      position: absolute;
      max-width: 100%;
      top: 0;
      right: 0;
    }
  }
  .cat__info {
    display: none;
  }
  &__bubble {
    grid-column: 2/3;
    .chat__bubble._answer {
      margin: 32px 0 0;
      border-radius: 8px 12px 12px 0;
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
    }
  }
  &__questions {
    padding: 30px 64px 32px 0;
    display: flex;
    align-items: center;
    &-wrap {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }
  &__subtitle {
    @include fnt(14px !important, 24px !important);
    margin: 8px 0 0 76px;
    margin-bottom: 0;
    span {
      @include fnt(14px !important, 24px !important, #00CCCC, 600);
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        @include size(6px, 6px);
        border-radius: 3px;
        background-color: #00CCCC;
        left: -10px;
        top: 6px;
        animation: blinker 1000ms infinite linear;
      }
      @keyframes blinker {
        //from { opacity: 1.0; }
        //to { opacity: 0.25; }
        0%{opacity: 1.0;}
        50%{opacity: 0.25;}
        100%{opacity: 1.0;}
      }
    }
  }
}



@media screen and (max-width: 1023px) {
  .assistant {
    height: calc(100vh - 88px);
  }
}
@media screen and (max-width: 920px) {
  .assistant-card {
    grid-template-columns: 104px 1fr;
    grid-template-rows: auto auto auto;
    padding: 16px;
    border-radius: 16px;
    margin-top: 64px;
    background: linear-gradient(0deg,
      #00CCCC 0%, #00CBCC 6.67%,
      #00CACC 13.33%, #00C6CC 20%,
      #00C2CC 26.67%, #00BCCC 33.33%,
      #00B5CC 40%, #00AECC 46.67%,
      #00A6CC 53.33%, #009FCC 60%,
      #0098CC 66.67%, #0092CC 73.33%,
      #008ECC 80%, #008ACC 86.67%,
      #0089CC 93.33%, #0088CC 100%);
    &__subtitle {
      display: none;
    }
    &__cat {
      grid-column: 1/2;
      grid-row: 1/2;
      height: 104px;
      width: 104px;
      display: flex;
      margin-top: 0;
      background-color: #fff;
      border-radius: 52px;
      .cat__image {
        max-width: unset;
        width: 180px;
        height: 422px;
        top: 8px;
        right: 7px;
      }
    }
    .cat__info {
      grid-column: 2/3;
      grid-row: 1/2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 16px;
      .info__name {
        @include fnt(20px, 24px, #fff, 600);
        margin: 0;
      }
      .info__position {
        @include fnt(14px, 20px, #fff);
        margin: 0;
      }
      .info__status {
        @include fnt(14px, 20px, #00CCCC);
        margin: 0;
      }
    }
    &__bubble {
      grid-column: 1/3;
      grid-row: 2/3;
      margin-top: 0;
      .chat__bubble._answer {
        margin: 24px 0 16px;
      }
    }
    &__questions {
      grid-column: 1/3;
      grid-row: 3/4;
      padding: 0;
      &-wrap {
        justify-content: end;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .assistant {
    grid-gap: 16px;
    grid-template-columns: minmax(200px, 1fr) minmax(300px, 3fr);
  }
}
@media screen and (max-width: 600px) {
  .assistant {
    grid-gap: 0;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    &__cat {
      grid-template-columns: auto 1fr;
      grid-column-gap: 16px;
      padding-bottom: 20px;
      .to-main {
        grid-column: 1/3;
      }
      .cat__image {
        &-wrap {
          height: 64px;
          width: 64px;
          background-color: #fff;
          border-radius: 52px;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
        }
        height: auto;
        width: 124px;
        margin-left: -62px;
        margin-top: 5px;
        use {
          width: 100%;
          height: 100%;
        }
      }
      .cat__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include size(100%, 100%);
        .info__name {
          @include fnt(20px, 24px, #fff, 600);
          margin: 0;
        }
        .info__position {
          @include fnt(14px, 20px, #fff);
          margin: 0;
        }
        .info__status {
          @include fnt(14px, 20px, #00CCCC);
          margin: 0;
        }
      }
    }
    &__chat {
      &_wrap {
        border-top: 1px solid #FFFFFF;
        padding: 0 8px 0 0;
        &::-webkit-scrollbar {
          width: 4px;
        }
        .chat__history {
          .chat__bubble {
            opacity: 1;
          }
        }
      }
    }
  }
}
