@import "vars";

.maintenance {
  font-family: $font;
  color: #fff;
  height: 100vh;
  min-height: fit-content;
  width: 100%;
  background: linear-gradient(135deg, #007ED9 0%, #007FD9 6.67%, #0081D8 13.33%, #0085D8 20.00%, #008AD7 26.67%, #0090D6 33.33%, #0098D5 40.00%, #00A1D3 46.67%, #00AAD2 53.33%, #00B2D0 60.00%, #00BACF 66.67%, #00C1CE 73.33%, #00C6CD 80.00%, #00C9CC 86.67%, #00CBCC 93.33%, #0CC 100%);
  &__container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    height: 100%;
    width: 100%;
    max-width: 1920px;
    padding: 48px 48px 64px;
  }
  &__img {
    display: block;
    height: 32px;
    width: auto;
  }
  &__content {
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 48px;
      font-weight: 600;
      line-height: 56px;
      color: #fff;
      margin: 0;
    }
    p {
      font-size: 24px;
      line-height: 32px;
      color: #fff;
      margin: 0;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    .cat {
      display: block;
      height: 96px;
      width: 96px;
    }
    .phone-wrap {
      display: flex;
      flex-direction: column;
    }
    .phone-title {
      color: #FFF;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
    .phone {
      color: #FFF;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }
  }
}
