//$main: #0079b3;
$main: #007ED9;
$main-darker: #006c9c;
$main-text: #0088CC;
$cyan: #00CCCC;
$accent: #ED0A34;
$text-color: #2C2D2E;
$text-color-secondary: #919399;
$icon-color-secondary: #BFC1C7;
$gray-20: #F9F9FA;
$gray-40: #F3F3F5;
$yellow: #FDE953;
$error: #ff0019;
$gray-60: #ECEDF0;
$gray-120: #DADCE0;
$font: 'Golos', sans-srif;
$border-color: rgba(0, 16, 61, 0.12);
$border-color-active: rgba(0, 16, 61, 0.24);
$shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
$shadow-active: 0 12px 32px rgba(0, 0, 0, 0.15);
$grey:   #f2f2f2;
$darker-grey: #c8c8c8;

@mixin gradient-color () {
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}


@mixin size ($height, $width) {
  height: $height;
  width: $width;
}
@mixin fnt ($size: 16px, $height: 24px, $color: $text-color, $weight: 400) {
  font-family: $font;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
  color: $color;
}
@mixin font ($font, $weight, $size, $height, $color) {
  font-family: $font;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
  color: $color;
}

@mixin btn($bg-color: $main, $color: #fff, $outline: false) {
  @include font($font, 600, 16px, 24px, $color);
  display: inline-block;
  padding: 12px 24px;
  background-color: $bg-color;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  @if $outline {
    border: 2px solid $color;
    padding: 8px 20px;
  } @else {
    border: none;
  }
  &:hover {
    @if $outline {
      background-color: $color;
      color: $bg-color!important;
    } @else {
      background-color: $bg-color - #111;
    }
  }
}

@mixin scale($selector, $top-min, $top-max, $left-min: 0, $left-max: 0) {
  @if $left-min != 0 or $left-max != 0 {
    #{$selector}: clamp(#{$top-min}px, calc(#{$top-min}px + (#{$top-max} - #{$top-min}) * ((100vw - 320px) / (1440 - 320))), #{$top-max}px) clamp(#{$left-min}px, calc(#{$left-min}px + (#{$left-max} - #{$left-min}) * ((100vw - 320px) / (1920 - 320))), #{$left-max}px);
  } @else {
    #{$selector}: clamp(#{$top-min}px, calc(#{$top-min}px + (#{$top-max} - #{$top-min}) * ((100vw - 320px) / (1440 - 320))), #{$top-max}px);
  }
}
