@import "vars";

/* FILTERS */

.data-chooser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  padding: 12px 14px;
  border-radius: 8px;
  background-color: $gray-40;
}
.data-chooser::after {
  border: none;
  display: block;
  height: 24px;
  width: 24px;
  content: url("../img/small-arrow.svg");
}

.data-chooser__dropdown-menu {
  width: 100px;
  background-color: #fff;
  padding: 8px 0;
  border: none;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  .data-chooser__dropdown-item {
    width: 100%;
    display: block;
    white-space: unset !important;
    @include font($font, 400, 16px, 24px, $text-color);
    padding: 8px 16px;
    text-decoration: none;
    :hover,
    :active {
      background-color: $gray-20;
    }
  }
}

.rubric__header {
  display: flex;
  align-items: center;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
}

.rubric__header.active::after {
  transform: rotate(-180deg);
}
.rubric__container .rubric__container {
  margin-left: 16px;
}
.main-checkbox {
  display: inline-flex;
  align-items: center;
  background: $gray-40;
  border-radius: 8px;
  padding: 11px 12px;
  margin: 8px 0;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    background: $darker-grey;
  }
  input {
    display: none;
  }
}
.main-checkbox.active {

}

.main-checkbox::before {
  content: '';
  display: block;
  flex: none;
  height: 16px;
  width: 16px;
  border: 2px solid #333;
  border-radius: 2px;
}
.main-checkbox.active::before {
  border: 2px solid $main;
  background: url(../img/checked.svg) no-repeat center $main;
  background-size: contain;
}
.main-checkbox__text {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 0 12px;
  color: $text-color;
}
.date-select {
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 8px;
  @include font($font, normal, 16px, 24px, $text-color);
  &:hover {
    cursor: pointer;
  }
}
.date-select__icon {
  display: block;
  height: 24px;
  width: 24px;
  object-fit: contain;
  object-position: center;
  margin: 0 8px;
}
.date-range_wrap {
  margin: 24px 0;
}
input.date-range-input {
  border: 1px solid $gray-40;
  min-height: 46px;
  background-color: $gray-40;
  transition: border-color 0.2s ease-out, background-color 0.2s ease-out;
  border-radius: 8px;
  padding: 2px 8px;
  color: #333;
  font-size: 14px;
  line-height: 42px;
  width: 100%;
  grid-column: 1/3;
  white-space: break-spaces;
  &:active,
  &:focus {
    border-color: $border-color;
    background-color: transparent;
    outline: none;
  }
}
.date-select__close {
  @include font($font, normal, 12px, 18px, $text-color-secondary);
}
