@import "vars";


.text .a,
.a {
  &-wrap {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 24px;
    width: 100%;
    max-width: 640px;
  }

  &-card {
    padding: 23px;
    border: 1px solid $border-color;
    box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    background-color: #fff;

    .a-card {
      &__header {
        display: flex;
        align-items: start;
        justify-content: space-between;
      }
      &__img {
        display: flex;
        height: 40px;
        width: auto;
        margin: 0 0 0 16px;
      }
      &__category {
        @include fnt (14px, 24px, #fff, 600);
        background-color: $main;
        padding: 0 8px;
        margin: 0 0 4px;
      }
      &__title {
        display: block;
        @include fnt(20px, 24px, $weight: 600);
        margin: 0;
      }

      &__sub-title {
        display: block;
        @include fnt(14px, 24px, #5B87A6, 600);
        margin: 8px 0 0;
      }

      &__info {
        display: block;
        @include fnt(20px, 24px);
        margin: 8px 0 4px;
      }

      &__btn {
        display: block;
        @include fnt(14px, 16px, #fff);
        background-color: $main;
        padding: 12px 24px;
        margin: 12px 0 0;
        border-radius: 8px;
        transition: background-color 0.3s ease-out;

        &:hover {
          background-color: $main - #111;
        }
      }

      &__body {
        padding: 16px 0 0;
        h3 {
          @include fnt(14px, 16px, $text-color, 600);
          margin: 16px 0 4px;
        }
        p {
          @include fnt();
          margin: 0 0 4px;
          a {
            @include fnt($color: $main, $weight: 600);
          }
        }
      }
    }

    .e-collapse__btn {
      margin-top: 12px;
      display: flex;
    }

    &._sales {
      .a-card {
        &__title {
          @include fnt(24px, 32px, $weight: 600);
        }
        &__sub-title {
          @include fnt(14px, 24px, $text-color-secondary);
          margin: 0;
        }
        &__info {
          display: block;
          width: fit-content;
          @include fnt(14px, 24px, #fff, 600);
          margin: 12px 0 0;
          background-color: $main;
          padding: 0 8px;
        }
        &__body {
          h3 {
            @include fnt(14px, 16px, $text-color-secondary);
            margin: 12px 0 4px;
          }
        }
      }
    }
  }
}
