@import "vars";

/* PAGE */

.page__date {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $text-color-secondary;
  margin-bottom: 24px;
}
.page__lead {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: $text-color;
}
.page__main-img {
  width: 100%;
  height: auto;
  margin-bottom: 32px;
}
.page__description {
  @include fnt(16px, 24px, $text-color-secondary);
  margin-bottom: 32px;
}
