@import "vars";

/* USEFUL LINKS */

.u-links {
  height: 100%;
  position: relative;
  margin: 0 -16px;
}
.u-links__item {
  display: block;
  width: 100%;
  height: 100%;
  transition: .2s;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  }
}
.u-links__title {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  -webkit-line-clamp: 3; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden;
}
.u-links__link {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
}
.u-links__img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.usefulLinksSwiper {
  &.swiper {
    padding: 48px 16px;
  }
  .swiper-slide {
    aspect-ratio: 304 / 96;
  }
}

.u-swiper-nav {
  display: block;
  position: absolute;
  top: 50%;
  height: 32px;
  width: 32px;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M8 20L16 12L8 4' stroke='%232C2D2E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.2;
  transition: opacity 0.2s ease-out;
  &-next {
    right: -72px;
  }
  &-prev {
    left: -72px;
    transform: translateY(-50%) rotate(180deg);
  }
  &:hover {
    opacity: 0.5;
  }
  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0;
  }
}

@media screen and (max-width: 1520px) {
  .u-swiper-nav-next {
    right: -16px;
  }
  .u-swiper-nav-prev {
    left: -16px;
  }
}

@media screen and (max-width: 1023px) {
  .u-swiper-nav-next {
    display: none;
  }
  .u-swiper-nav-prev {
    display: none;
  }
}
