@import "vars";

.tos__top-img{
  position: relative;
  aspect-ratio: 48/11;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.tos__mobile-block{
  position: relative;
  aspect-ratio: 48/11;
  justify-content: center;
  overflow: hidden;
  display: none;
}
.tos-img{
  object-fit: cover;
  display: block;
  height: 100%;
  width: auto;
}
.tos-img__mobile{
  display: none;
  object-fit: cover;
  width: 100%;
}
.tos-gradient{
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 159px;
  background: linear-gradient(180deg, rgba(249, 249, 250, 0) 0%, #F9F9FA 100%);
}
.main-background-tos{
  background: #F9F9FA;
}
.tos__main-container{
  max-width: 1440px;
  padding: 40px 64px 0 64px;
  width: 100%;
  margin: 0 auto;
}
.tos__header-logo{
  width: auto;
  height: 32px;
  flex: none;
}
.tos_header{
  margin-bottom: 32px;
}
.tos__back-link{
  @include font($font, 400, 14px, 24px, #919399);
  display: flex;
  &:before{
    content:"";
    background: url("../img/landing/Arrow.svg") center no-repeat;
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    transition: 0.3s;
  }
  &:hover{
    color: #919399;
    text-decoration: none;
    &:before{
      margin-right: 8px;
    }
  }
}
.tos__main-title-first{
  font-weight: 700;
  font-size: 80px;
  line-height: 96px;
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%,
    #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-right: 32px;
}
.tos__main-title-second{
  @include font($font, 700, 32px, 80px, #0088CC);
  display: flex;
  align-items: flex-end;
  &:after{
    content:"";
    background: url("../img/landing/Location.svg") center no-repeat;
    display: block;
    width: 32px;
    height: 80px;
  }
}
.tos__main-title_big{
  font-weight: 700;
  font-size: 224px;
  line-height: 224px;
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  //margin-bottom: -88px;
  margin-bottom: clamp(-104px,calc(-88px + (-104 + 88) * ((100vw - 1920px) / (2560 - 1920))),-88px);
}
.tos__title{
  margin-bottom: -40px;
}
.tos__definition-block{
  background: #FFFFFF;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 23px;
  max-width: 864px;
  margin-bottom: 32px;
  margin-top: -72px;
  z-index: 3;
  position: relative;
}
.definition-text{
  @include font($font, normal, 20px, 32px, $text-color);
  ._bold{
    @include font($font, 600, 20px, 32px, $text-color);
  }
}
.info-img{
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
.tos__bik-text{
  @include font($font, normal, 16px, 28px, $text-color);
  display: block;
  max-width: 864px;
  margin-bottom: 96px;
  ._bold{
    @include font($font, 600, 16px, 28px, $text-color);
  }
}
.tos__page-title{
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 24px;
}
.tos__reasons-block{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  grid-gap: 32px;
  margin-bottom: 52px;
}
.reasons-block__rectangle{
  display: block;
  height: 8px;
  width: 64px;
  background: #00CCCC;
  margin-bottom: 8px;
}
.reasons-block__title{
  @include font($font, 600, 24px, 32px, $text-color);
  margin-bottom: 12px;
}
.reasons-block__text{
  @include font($font, normal, 20px, 32px, $text-color);
  ._bold{
    @include font($font, 600, 20px, 32px, $text-color);
  }
}
.tos__achievements{
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 100%;
  margin-bottom: 64px;
}
.tos__achievements-text{
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 864px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}
.tos__achievements-img {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.mb-12{
  margin-bottom: 12px;
}
.skate_img{
  width: 528px;
  height: auto;
  display: block;
}
.skate_img_mobile{
  width: 100%;
  height: auto;
  display: none;
}
.steps__description{
  @include font($font, 400, 32px, 48px, $text-color);
  margin-bottom: 24px;
}
.steps-block{
  max-width: 864px;
  .tos__page-title{
    margin-bottom: 0px;
  }
  .reasons-block__text{
    margin-bottom: 16px;
  }
}
.tos-list-title{
  @include font($font, 600, 20px, 32px, $text-color);
  margin-top: 32px;
}
.tos-list-item{
  @include font($font, 400, 20px, 32px, $text-color);
  margin-top: 12px;
  display: flex;
  &:before{
    content:"";
    background: url("../img/landing/marker.svg") center no-repeat;
    display: block;
    background-size: 12px;
    width: 12px;
    height: 32px;
    margin-right: 12px;
    flex: none;
  }
}
.steps__highlight{
  @include font($font, 400, 20px, 32px, $text-color);
  background: #ECEDF0;
  border-left: 8px solid #00CCCC;
  padding: 8px 32px;
  margin-bottom: 16px;
}
.steps-block__step{
  margin-bottom: 64px;
}
.tos__accordion-item{
  background: #FFFFFF;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-bottom: 48px;
  .accordion-header{
    padding: 12px 12px 12px 24px;
  }
  .accordion-body{
    padding: 12px 24px 24px 24px;
    .point{
      @include font($font, 400, 16px, 24px, $text-color);
      margin-bottom: 16px;
    }

  }
}
.tos__accordion-btn{
  @include font($font, 400, 20px, 32px, $text-color);
  text-align: left;
  border: none;
  background: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:focus{
    border: none;
    outline: none;
  }
  .accordion-arrow{
    transition: 0.3s;
    transform: rotate(180deg);
    margin-left: 12px;
  }
  &.collapsed{
    .accordion-arrow{
      transform: rotate(0deg);
    }
  }
}
.mr-8{
  margin-right: 8px;
}
.tos__registration-text{
  @include font($font, 400, 24px, 32px, $text-color);
  margin-bottom: 32px;
}
.period-block{
  @include font($font, 400, 16px, 24px, $text-color);
  padding: 12px 24px;
  background: #ECEDF0;
  margin-bottom: 64px;
}
.skate_img{
  animation: skate_img ease-in-out 3s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}

@keyframes skate_img{
  0% {
    transform:  translate(0, -8px)  ;
  }
  50% {
    transform:  translate(0, 8px)  ;
  }
  100% {
    transform:  translate(0, -8px)  ;
  }
}
.skate_img_mobile{
  animation: skate_img__mobile ease-in-out 3s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}
@keyframes skate_img__mobile{
  0% {
    transform:  translate(0, -4px)  ;
  }
  50% {
    transform:  translate(0, 4px)  ;
  }
  100% {
    transform:  translate(0, -4px)  ;
  }
}
.tos__doc-download{
  @include font($font, 600, 20px, 32px, $text-color);
  margin-top: 16px;
  display: flex;
  &:before{
    content:"";
    background: url("../img/landing/File.svg") center no-repeat;
    display: block;
    background-size: 32px;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    flex: none;
  }
}
.mb-20{
  margin-bottom: 20px;
}





















@media screen and (max-width: 1440px) {
  .tos__main-title_big{
    font-size: clamp(50px,calc(50px + (224 - 50) * ((100vw - 320px) / (1440 - 320))),224px);
    line-height: clamp(80px,calc(80px + (224 - 80) * ((100vw - 390px) / (1440 - 390))),224px);
  }
  .tos__main-title-first{
    font-size: clamp(40px,calc(40px + (80 - 40) * ((100vw - 390px) / (1440 - 390))),80px);
    line-height: clamp(48px,calc(48px + (96 - 48) * ((100vw - 390px) / (1440 - 390))),96px);
  }
  .tos__main-title-second{
    font-size: clamp(20px,calc(20px + (32 - 20) * ((100vw - 340px) / (1440 - 340))),32px);
    line-height: clamp(40px,calc(40px + (80 - 40) * ((100vw - 340px) / (1440 - 340))),80px);
  }
  .tos__main-title-second:after {
    width:  clamp(20px,calc(20px + (32 - 20) * ((100vw - 390px) / (1440 - 390))),32px);
    background-size: clamp(20px,calc(20px + (32 - 20) * ((100vw - 390px) / (1440 - 390))),32px);
    height: clamp(40px,calc(40px + (80 - 40) * ((100vw - 390px) / (1440 - 390))),80px);
  }
  .tos__main-title_big{
    margin-bottom: clamp(-88px,calc(-76px + (-88 + 76) * ((100vw - 1280px) / (1440 - 1280))),-76px);
  }
  .tos__achievements-text {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .skate_img {
    width: 528px;
    width:  clamp(324px,calc(324px + (528 - 324) * ((100vw - 1024px) / (1440 - 1024))),528px);
  }
}


@media screen and (max-width: 1280px) {
  .tos__accordion-item .accordion-body .point{
    font-size: clamp(14px,calc(14px + (16 - 14) * ((100vw - 340px) / (1280 - 340))),16px);
    line-height: clamp(20px,calc(20px + (24 - 20) * ((100vw - 340px) / (1280 - 340))),24px);
  }
  .accordion-arrow{
    width:  clamp(24px,calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))),32px);
    height: clamp(24px,calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))),32px);
  }
  .tos__accordion-btn{
    font-size: clamp(16px,calc(16px + (20 - 16) * ((100vw - 340px) / (1280 - 340))),20px);
    line-height: clamp(24px,calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))),32px);
  }
  .tos__registration-text{
    font-size: clamp(16px,calc(16px + (24 - 16) * ((100vw - 340px) / (1280 - 340))),24px);
    line-height: clamp(24px,calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))),32px);
  }
  .tos-list-item:before{
    height: clamp(24px,calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))),32px);
  }
  .steps__highlight,
  .tos-list-title,
  .tos-list-item{
    font-size: clamp(16px,calc(16px + (20 - 16) * ((100vw - 340px) / (1280 - 340))),20px);
    line-height: clamp(24px,calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))),32px);
  }
  .steps__description{
    font-size: clamp(20px,calc(20px + (32 - 20) * ((100vw - 340px) / (1280 - 340))),32px);
    line-height: clamp(32px,calc(32px + (48 - 32) * ((100vw - 340px) / (1280 - 340))),48px);
  }
  .tos__main-title_big{
    margin-bottom: clamp(-96px,calc(-76px + (-96 + 76) * ((100vw - 1024px) / (1280 - 1024))),-76px);
  }
  .tos__top-img {
    aspect-ratio: 9/4;
  }
  .tos__main-container {
    padding: 40px 32px 0 32px;
  }
  .tos__title {
    margin-bottom: clamp(-40px,calc(-16px + (-40 + 16) * ((100vw - 340px) / (1280 - 340))),-16px);
  }
  .tos__page-title{
    font-size: clamp(32px,calc(32px + (48 - 32) * ((100vw - 340px)/ (1280 - 340))),48px);
    line-height: clamp(40px,calc(40px + (64 - 40) * ((100vw - 340px)/ (1280 - 340))),64px);
  }
  .reasons-block__title{
    font-size: clamp(20px,calc(20px + (24 - 20) * ((100vw - 340px) / (1280 - 340))),24px);
    line-height: clamp(24px,calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))),32px);
  }
  .reasons-block__text,
  .reasons-block__text ._bold,
  .tos__doc-download,
  .definition-text,
  .definition-text ._bold
  {
    font-size: clamp(16px,calc(16px + (20 - 16) * ((100vw - 340px) / (1280 - 340))),20px);
    line-height: clamp(24px,calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))),32px);
  }
  .tos__doc-download:before {
    width:  clamp(24px,calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))),32px);
    height: clamp(24px,calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))),32px);
    background-size: clamp(24px,calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))),32px);
  }
}


@media screen and (max-width: 1024px) {
  .tos__top-img{
    aspect-ratio: unset;
  }
  .tos-img{
    display: none;
  }
  .tos-img__mobile{
    display: block;
  }
  .tos__main-title-first{
    margin-right: 12px;
  }

  .skate_img{
    display: none;
  }
  .skate_img_mobile{
    display: block;
    max-width: 628px;
    margin-top: 48px;
  }
  .tos__achievements {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    margin-bottom: 116px;
  }
  .tos__achievements-img{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    display: flex;
    justify-content: center;
  }

  .tos__reasons-block {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    margin-bottom: 64px;
  }
  .tos__main-container {
    padding: 40px 24px 0 24px;
  }
  .tos__definition-block{
    padding: 15px;
  }
  .info-img{
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .tos__bik-text,
  .tos__bik-text ._bold
  {
    font-size: 14px;
    line-height: 24px;
  }
}


@media screen and (max-width: 800px) {

  .tos__main-title_big{
    margin-bottom: clamp(-68px,calc(-40px + (-68 + 40) * ((100vw - 390px) / (800 - 390))),-40px);
  }
  .tos__main-container {
    padding: 40px 16px 0 16px;
  }
  .tos__definition-block{
    margin-bottom: 24px;
  }
  .tos__bik-text{
    margin-bottom: 40px;
  }
  .tos__page-title{
    margin-bottom: 16px;
  }
  .reasons-block__title{
    margin-bottom: 8px;
  }
  .tos__page-title{
    margin-bottom: 16px;
  }
}


@media screen and (max-width: 600px) {
  .steps__highlight{
    padding: 8px 16px;
  }
  .tos__accordion-item .accordion-header{
    padding: 12px 12px 12px 16px;
  }
  .tos__accordion-item .accordion-body {
    padding: 12px 16px 16px 16px;
  }
  .skate_img_mobile {
    margin-top: 24px;
  }
  .tos__achievements {
    margin-bottom: 48px;
  }
  .steps-block__step {
    margin-bottom: 48px;
  }
  .period-block{
    margin-bottom: 32px;
  }
  .tos__accordion-btn{
    align-items: center;
  }
  .reasons-block__rectangle {
    width: 48px;
  }
  .tos__main-container{
    padding: 16px 16px 0 16px;
  }
  .steps-block{
    .tos__page-title{
      margin-bottom: 8px;
    }
  }
}
