@import "vars";

body {
  //background-color: $gray-20;
  background-color: white;
}
.cabinet {
  &__header {
    background: linear-gradient(90.85deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  }
  &__info {
    display: flex;
    flex-direction: column;
    padding: 32px 0 48px;
  }
  &__cabinet {
    @include fnt(14px, 20px, #fff);
    margin: 0;
  }
  &__name {
    @include fnt(32px, 40px, #fff, 600);
    margin: 0;
  }
  &__areas {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 32px;
    margin-top: -32px;
    margin-bottom: 64px;
    .area {
      display: flex;
      position: relative;
      flex-direction: column;
      padding: 32px;
      background-color: #fff;
      border-radius: 16px;
      border: 1px solid $border-color;
      box-shadow: $shadow-active;
      &__img {
        position: absolute;
        @include size(96px, 96px);
        right: 16px;
        bottom: 16px;
        z-index: 1;
      }
      &__header {
        @include fnt(14px, 20px, $text-color-secondary);
        margin: 0;
        z-index: 2;
      }
      &__cadastre {
        @include fnt(24px, 32px, $text-color, 600);
        margin: 0;
        z-index: 2;
      }
      &__address {
        @include fnt();
        margin: 8px 0 0;
        z-index: 2;
      }
      &__description {
        @include fnt(14px, 20px, $text-color-secondary);
        margin: 0;
        z-index: 2;
      }
    }
  }
  &__actions {
    display: flex;
    grid-column: 1/4;
    .action {
      display: flex;
      @include fnt(14px, 24px, #5B87A6, 600);
      padding: 7px 15px;
      border: 1px solid $border-color;
      background-color: #fff;
      border-radius: 20px;
      margin-right: 12px;
      box-shadow: $shadow;
      transition: box-shadow .3s ease-out;
      &__img {
        margin-right: 4px;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        box-shadow: $shadow-active;
      }
    }
  }
  &__feedback {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    .feedback {
      &__title {
        @include fnt(32px, 48px, $weight: 600);
        margin: 0;
      }
      &__description {
        @include fnt();
        margin: 0 0 8px;
      }
      &__post-description {
        @include fnt(14px, 20px, $text-color-secondary);
        margin: 0 0 24px;
      }
      &__button {
        @include btn($accent);
        display: block;
        width: fit-content;
      }
    }
  }
}
