@import "vars";

.steps-wrap {
  @include scale(padding-top, 16, 32);
  @include scale(padding-bottom, 16, 32);
  .step {
    display: grid;
    grid-template-columns: clamp(48px,calc(48px + (80 - 48) * ((100vw - 320px)/ (1920 - 320))),80px) auto;
    align-items: center;
    padding: 12px 0;
    &__position {
      display: grid;
      place-items: center;
      background: linear-gradient(135deg, #00CCCC 0%,
        #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%,
        #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%,
        #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%,
        #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%,
        #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
      @include scale(height, 48, 80);
      @include scale(width, 48, 80);
      @include scale(border-radius, 8, 12);
      font-family: $font;
      font-weight: 600;
      color: #fff;
      @include scale(font-size, 16, 32);
      @include scale(line-height, 16, 32);
    }
    &__card {
      background: rgba(255, 255, 255, 0.5);
      box-shadow: $shadow;
      backdrop-filter: blur(8px);
      margin-right: 0;
      margin-left: clamp(-20px,calc(-12px - (20 - 12) * ((100vw - 320px)/ (1920 - 320))),-12px);
      @include scale(border-radius, 8, 12);
      @include scale(padding, 12, 24, 16, 32);
    }
    &__title {
      font-family: $font;
      font-weight: 600;
      @include scale(font-size, 16, 20);
      @include scale(line-height, 16, 32);
      color: $text-color;
      margin-bottom: 0;
    }
    &__text {
      p, li, span, blockquote {
        font-family: $font;
        font-weight: 400;
        @include scale(font-size, 12, 16);
        @include scale(line-height, 14, 24);
        color: $text-color;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
