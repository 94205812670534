@import "vars";

/* DOCUMENTS */


.document {
  display: flex !important;
  align-items: flex-start;
  padding: 12px 0;
  &.single {
    @include scale(margin-bottom, 8, 12);
  }
  &__art {
    font-family: $font;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $text-color-secondary;
    margin-bottom: 0;
  }
  &._list &__title,
  &._block &__title {
    font-family: $font;
    font-style: normal;
    font-weight: normal;
    @include scale(font-size, 14, 16);
    @include scale(line-height, 16, 24);
    color: $text-color;
    margin-bottom: 4px;
  }
  &._list &__description,
  &._block &__description {
    font-family: $font;
    font-style: normal;
    font-weight: normal;
    @include scale(font-size, 12, 14);
    @include scale(line-height, 14, 18);
    color: $text-color-secondary;
    margin-bottom: 4px;
  }
  &._list &__info,
  &._block &__info {
    display: flex;
    align-items: center;
  }
  &._list &__type,
  &._block &__type {
    display: grid;
    place-items: center;
    position: relative;
    height: 40px;
    width: 40px;
    background: url("../img/doc-icon.svg") center no-repeat;
    margin-right: 16px;
    span {
      display: block;
      height: 16px;
      width: 40px;
      background-color: $main;
      border-radius: 2px;
      font-family: $font;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }
  &._list &__open,
  &._block &__open {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $main;
    margin-bottom: 0;
  }
  &__size,
  &__date {
    font-family: $font;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $text-color-secondary;
    margin-bottom: 0;
  }
  &._block {
    padding: 32px;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: 0.2s ease-out;
  }
  &._block &__title_block {
    font-family: $font;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $text-color;
    margin-bottom: 20px;
    flex-grow: 1;
    height: 118px;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &._block &__size {
    margin-left: 8px;
  }
  &._list &__date {
    margin-left: 12px;
  }
  &._block &__info {
    display: flex;
    align-items: center;
    border-top: 1px solid $border-color;
    padding-top: 20px;
  }
  &._block &__date {
    margin-bottom: 12px;
  }
  &._list {}
}

.document._list.single {
  .document__title {
    @include scale(font-size, 18, 20);
    @include scale(font-size, 18, 20);
  }
}

.document._block.side {
  height: unset;
  margin: 16px 0;
}
