@import "vars";
@import "vars";

/* FOOTER */

/* SMALL NAVIGATE */


.nav-counter {
  @include font($font, normal, 14px, 31px, #919399);
  padding-right: 8px;
}
.nav-arrow {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 31px;
  width: 31px;
  border: 1px solid $border-color-active;
  border-radius: 50%;
  margin-left: 8px;
}
.nav-arrow:hover {
  cursor: pointer;
}

.nav-arrow::before {
  content: url("../img/small-arrow.svg");
  height: 22px;
}
.nav-arrow.prev::before {
  transform: rotate(90deg);
}
.nav-arrow.next::before {
  transform: rotate(-90deg);
}
.nav-arrow.disable {
  opacity: 0.5;
  pointer-events: none;
}
.footer {
  flex: 0 0 auto;
  margin-top: 100px;
}

.address__title {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 8px;
}
.address__address {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 8px;
}
.phones__title {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 8px;
}
.phones__link {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  color: $text-color;
  margin-bottom: 8px;
}
.phones__link:hover {
  text-decoration-line: underline;
  color: $main;
}
.footer__subnav__item {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 0;
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
}
.footer__subnav__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer__subnav__list__item {
  display: block;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $text-color;
  width: fit-content;
  margin-bottom: 0;
  &:hover {
    text-decoration: underline;
  }
}

.footer__c-info {
  display: flex;
  align-items: center;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  color: $gray-20;
  margin: 0;
}
.footer__socials-title {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  color: #BFC1C7;
  margin: 0;
}
.footer__teh-title {
  margin-bottom: 0;
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: #E0E1E6;
}
.footer__teh-subtitle {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #E0E1E6;
  margin-bottom: 0;
}

.up-button__button {
  position: absolute;
  top: 16px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  width: 43px;
  border: 1px solid $main;
  border-radius: 22px;
}
.up-button__button::before {
  content: url("../img/ArrowToTop.svg");
  height: 28px;
  width: 20px;
}
.sitemap__ul {
  list-style: none;
  margin: 0;
  padding: 0;
  .sitemap__ul {
    padding: 0 0 0 16px;
    .sitemap__a {
      padding: 8px 15px;
      border-left: 1px solid $border-color;
    }
  }
}
.sitemap__li {
  transition: .2s;
  list-style: none;
  &:hover {
    cursor: pointer;
  }
}
.sitemap__a {
  display: block;
  position: relative;
  @include font($font, normal, 16px, 24px, $text-color);
  text-decoration: none;
  background-color: transparent;
  padding: 8px 15px;
  &:hover {
    color: $main;
    text-decoration: none;
  }
}

.bg-footer {
  background-color: #004466;
  padding: 28px 0 20px;
}
.bg-footer_gradient {
  background: linear-gradient(90.05deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  padding: 8px 0;
}
.footer-text{
  @include font($font, 400, 12px, 20px, white);
  opacity: 0.8;
  margin: 0;
}
.footer-logo-F{
  align-self: flex-start;
  width: auto;
  height: 36px;
  margin-bottom: 48px;
}
.footer-logo-B{
  align-self: flex-start;
  width: auto;
  height: 24px;
  margin-top: 10px;
  margin-bottom: 48px;
}
.footer__link {
  display: block;
  @include fnt(14px, 24px, #fff);
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    color: #fff;
    opacity: 0.8;
    cursor: pointer;
  }
}
.footer {
  &__phone-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__phone {
    display: block;
    @include  fnt(24px, 24px, #fff, 600);
    margin-bottom: 0;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      color: #fff;
      opacity: 0.8;
    }
  }
  &__phone-title {
    display: block;
    @include  fnt(14px, 24px, #fff);
    margin-bottom: 0;
  }
}
.footer__navigation {
  .footer__page-link {
    display: block;
    @include fnt(14px, 20px, $weight: 400, $color: #fff);
    text-decoration: none;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      color: #fff;
      opacity: 0.8;
      cursor: pointer;
    }
  }
  & > .footer__page {
    margin-bottom: 16px;
    & > .footer__page-link {
      @include fnt(16px, 24px, $weight: 600, $color: #fff);
      margin-bottom: 4px;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
      &:hover {
        color: #fff;
        opacity: 0.8;
        cursor: pointer;
      }
    }
    & > .footer__page-wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: auto;
      grid-column-gap: 16px;
      grid-row-gap: 4px;
      & > .footer__page > .footer__page-link {
        margin-bottom: 0px;
      }
      .footer__page-wrap {
        margin-bottom: 4px;
        .footer__page-link {
          @include fnt(12px, 16px, $color: #fff);
          opacity: 0.8;
          transition: opacity 0.2s ease-in-out;
          &:hover {
            color: #fff;
            opacity: 1;
          }
        }
      }
    }
  }
}

