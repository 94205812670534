@import "vars";


#menu-app {
  min-height: 70px;
}

.header {
  position: relative;
  padding-top: 20px;
  padding-bottom: 12px;
  //box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  z-index: 50;
  background-color: #fff;
}

.header-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.header-logo {
  height: 32px;
  flex: none;
  margin-right: 48px;
}
.header-info {
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 12px;
}
.header-menu {
  display: flex;
  align-items: center;
}
.header-phone {
  @include font($font, 400, 16px, 24px, $text-color);
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: $text-color;
  }
}
.first-menu {
  display: flex;
  align-items: center;
}
.phone-img{
  width: 16px;
  height: 24px;
  flex: none;
  margin-right: 8px;
}
.login-button {
  display: inline-block;
  padding: 12px 24px;
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFF;
  background-color:$main;
  border-radius: 4px;
  text-align: center;
  border: none;
  margin-left: 24px;
  &:hover{
    text-decoration: none;
    color: #FFF;
  }
}
.social_button{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 16px;
  background: $main;
  object-position: center;
  object-fit: scale-down;
  &:after {
    content: '';
    display: block;
    height: 16px;
    width: 16px;
  }
  &._vk{
    background:  #2787F5;
    &:after {
      background: url("../img/socials/vk.svg") no-repeat center;
    }
  }
  &._odnoklassniki{
    background: #EE8208;
    &:after {
      background: url("../img/socials/ok.svg") no-repeat center;
    }

  }
  &._telegram{
    background: linear-gradient(203.21deg, #37AEE2 21.68%, #1E96C8 69.99%);
    &:after {
      background: url("../img/socials/telegram.svg") no-repeat center;
    }
  }
  &._facebook{
    content: 'f';
  }
  &._youtube{
    content: 'YT';
  }
  &._instagram{
    content: 'i';
  }
  &._twitter{
    content: 't';
  }
  &._rutube{
    background: #14191F;
    &:after {
      background: url("../img/socials/rutube.svg") no-repeat center;
    }
  }
}

/* START Mobile header */

.header-mobile {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  //box-shadow: $shadow;
  margin-bottom: 16px;
  z-index: 90;
  &__wrap {
    z-index: 91;
    display: grid;
    position: relative;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: auto max-content min-content;
    grid-column-gap: 16px;
    width: 100%;
    padding: 8px 16px;
  }
  .beta {
    display: block;
    @include fnt(11px, 12px, $main);
    height: 20px;
    padding: 3px 7px;
    border: 1px solid $main;
    border-radius: 20px;
  }
}

.burger {
  @include size(28px, 24px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.burger span {
  position: relative;
}

.burger span::before,
.burger span::after {
  content: '';
  position: absolute;
  top: 0;
  transform-origin: center;
}
.burger span::before,
.burger span::after,
.burger span {
  display: block;
  @include size(2px, 24px);
  background-color: $icon-color-secondary;
  transition: 0.2s ease-out;
}

.burger span::before {
  transform: translateY(-6px);
}
.burger span::after {
  transform: translateY(6px);
}
.active {
  overflow: hidden !important;
  &.burger span {
    background-color: transparent;
  }
  &.burger span::before {
    transform: rotate(45deg);
  }
  &.burger span::after {
    transform: rotate(-45deg);
  }
}


/* END Mobile header */
