@import "vars";

/* START First menu */

.nav__link {
  @include fnt(14px, 24px, $color: $text-color);
  margin-right: 24px;
  transition: opacity 0.2s ease-out;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    opacity: 0.8;
  }
  &.active {
    font-weight: 600;
  }
}

/* END First menu */


/* START Second menu */

.second-menu {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-template-rows: max-content;
  grid-gap: 24px;
}
.second-menu__link {
  @include font($font, 400, 14px, 20px, $text-color);
  text-align: center;
  margin-top: 8px;
  white-space: nowrap;
  transition: .2s;
}
.second-menu__item{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: 0.2s ease-out;
  &.active {
    .second-menu__link {
      font-weight: 600;
    }
  }
  &:hover{
    text-decoration: none;
    .second-menu__icon {
      opacity: .65;
    }
    .second-menu__link {
      color: $text-color + #555;
    }
  }
}
.second-menu__icon{
  width: 32px;
  height: 32px;
  flex: none;
}
.second-menu__childrens{
  margin-top: 12px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.10);
  border-radius: 4px;
  padding: 8px 0;
  border: none;
  width: fit-content;
  .dropdown-item{
    padding: 8px 24px;
    @include font($font, 400, 16px, 24px, $text-color);
    white-space: nowrap;
    &:hover{
      background: $gray-20;
      border: none;
      border-radius: 0;
    }
  }
}

.external {
  position: relative;
  line-height: inherit;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    flex: none;
    margin-left: 4px;
    @include size(12px, 12px);
    background: url("../img/external-link.svg");
  }
}

/* END Second menu */

/* START Mobile nav */

.m-dropdown {
  position: relative;
  .nav__link {
    display: flex;
    align-items: center;
    &::after {
      content: '';
      display: block;
      height: 0;
      width: 0;
      margin: 4px 0 0 4px;
      border: 4px solid transparent;
      border-top: 4px solid $border-color-active;
    }
  }
  &__wrap {
    display: grid;
    grid-auto-rows: auto;
    position: absolute;
    border: 1px solid $border-color;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    top: 100%;
    left: 0;
    background-color: #fff;
    width: 320px;
    padding: 8px 0;
    border-radius: 8px;
    z-index: 20;
  }
  &__link {
    width: 100%;
    color: $text-color;
    @include scale(padding, 4, 8, 24, 32);
    @include scale(font-size, 12, 14);
    @include scale(line-height, 16, 20);
    background-color: transparent;
    font-family: $font;
    transition: 0.2s;
    &.active {
      font-weight: 600;
    }
    &:hover {
      background-color: $gray-20;
      color: $text-color;
    }
  }
}
.second-menu .m-dropdown__wrap {
  top: calc(100% + 12px + 8px)
}
.header-mobile {
  .nav-mobile {
    display: grid; //none
    grid-template-rows: min-content auto min-content;
    position: absolute;
    top: 100%;
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 100%);
    padding: 0 16px 0px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .nav__link {
    line-height: 48px;
    padding: 0;
    font-size: 18px;
    width: 100%;
  }
  .first-menu {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 24px;
  }
  .menu._first {
    margin: 0 -16px;
  }
  .menu._second {
    width: 100%;
    & > div {
      width: 100%;
      overflow-y: auto;
      background-color: $gray-20;
      z-index: 92;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .second-menu {
    width: fit-content;
    grid-auto-flow: column;
    grid-gap: 16px;
    padding: 10px 16px;
    &__item {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      &.active {
        font-weight: 600;
      }
      .second-menu__icon {
        display: none;
      }
      &:hover .second-menu__link {
        color: $text-color;
      }
      &._toggle::after {
        content: '';
        display: block;
        @include size(0px, 0px);
        border: 4px solid transparent;
        border-top: 4px solid $icon-color-secondary;
        margin-left: 4px;
        margin-top: 4px;
      }
      &._toggle.open::after {
        transform: rotate(-180deg);
        margin-top: -4px;
      }
    }
  }
  .m-dropdown {
    &__link {
      padding: 8px 24px;
      font-size: 14px;
      line-height: 20px;
      color: $text-color;
    }
    &__wrap {
      max-width: calc(100% - 32px) !important;
      margin: 8px 16px 0 16px;
      background-color: #fff !important;
    }
    .nav__link.open::after {
      transform: rotate(180deg) translateY(4px);
    }
    .nav__link + .m-dropdown__wrap,
    .second-menu__item + .m-dropdown__wrap {
      display: none;
    }
    .nav__link.open + .m-dropdown__wrap,
    .second-menu__item.open + .m-dropdown__wrap {
      display: grid;
    }
  }
  .second-menu__link {
    font-size: 14px;
    line-height: 24px;
    margin-top: 0;
  }
  .m-collapse {
    position: relative;
    width: 100%;
    &__title {
      @include fnt(14px, 24px);
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 23px 12px 16px;
      &._toggle {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 14px;
          right: 23px;
          @include size(0, 0);
          margin-left: 12px;
          border: 5px solid transparent;
          border-top: 5px solid $icon-color-secondary;
          transform: translateY(50%);
        }
      }
    }
    &__header._top {
      background-color: $gray-20;
    }
    .m-collapse {
      padding: 0 0 0 16px;
      .m-collapse {
        padding: 0 0 0 12px;
        &__header {
          border-left: 1px solid $border-color;
          padding: 12px 23px 12px 12px;
        }
      }
      &__wrap {
        display: none;
        ._top {
          background-color: transparent;
        }
      }
    }
    &.active {
      & > .m-collapse__header{
        border-left-color: $text-color;
      }
      & > .m-collapse__header .m-collapse__title{
        font-weight: 600;
      }
      & > .m-collapse__header._toggle::after {
        border-top-color: $text-color;
      }
    }
    &.open {
      & > .m-collapse__wrap {
        display: block;
      }
      & > .m-collapse__header._toggle::after {
        transform: rotate(-180deg);
      }
    }
  }
  .login-button {
    margin-left: 0;
    margin-right: 16px;
  }
}
.header__menu_active {
  .nav-mobile {
    display: grid;
  }
}

.nav-mobile {
  &__phone-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__phone {
    display: block;
    @include fnt(20px, 20px, $weight: 600);
    margin-bottom: 0;
  }
  &__phone-title {
    @include fnt(12px, 20px);
    margin-bottom: 0;
  }
  &__socials {
    display: grid;
    margin: 16px -16px 0;
    grid-auto-flow: column;
    justify-content: center;
    grid-gap: 12px;
    background-color: $text-color;
    padding: 12px 0 100px;
    .social_button {
      margin-right: 0;
      @include size(40px, 40px);
      border-radius: 20px;
      background: unset;
      background-color: #fff;
      &::after {
        @include size(20px, 20px);
          filter: brightness(0);
          opacity: 0.7;
      }
    }
  }
}

/* END Mobile nav */
