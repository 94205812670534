@import "vars";

.vacancy_empty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 24px;
  width: 100%;
  max-width: 640px;
  @include fnt($color: $text-color-secondary);
  border: 1px solid $border-color;
  border-radius: 16px;
  margin: 0;
  &::before {
    content: '';
    display: block;
    height: 24px;
    width: 24px;
    margin-right: 8px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.25 14.25L9 12' stroke='%23BFC1C7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 10.5L11.25 14.25' stroke='%23BFC1C7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21C7.02944 21 3 16.9706 3 12V12C3 7.02944 7.02944 3 12 3V3C16.9706 3 21 7.02944 21 12V12C21 16.9706 16.9706 21 12 21V21Z' stroke='%23BFC1C7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}
