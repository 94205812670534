@import "vars";

.search-container {
  pointer-events: none;
  position: absolute;
  height: 70px;
  width: 100%;
  top: 1px;
  left: 0;
  opacity: 0;
  background-color: #fff;
  transition: .2s ease-out;
}
.search-container.active {
  pointer-events: unset;
  opacity: 1;
  left: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
input[type="search"],
input[type="search"]::-webkit-search-cancel-button {
  -moz-appearance:none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
}
.inner-search {
  display: flex;
  align-items: center;
  position: relative;
}
.inner-search .ac-results {
  position: absolute;
  top: 71px;
  left: 0;
  background-color: #fff;
  padding: 8px 0;
  width: 100%;
  z-index: 40;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-bottom: 3px solid $main;
}
.inner-search .ac-result {
  display: block;
  width: 100%;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  padding: 8px 16px;
  color: #2C2D2E;
  text-decoration: none;
}
.inner-search .ac-result:hover {
  background-color: $gray-20;
  color: #2C2D2E;
}
.inner-search .search-input {
  height: 100%;
  border: none;
  flex-grow: 1;
}
.inner-search .search-icon {
  display: block;
  height: 32px;
  width: 32px;
  background: url(../img/search-black.svg) center no-repeat;
  margin-right: 24px;
}

.inner-search .search-btn {
  margin: 0 24px;
}
.inner-search .close-btn {
  display: block;
  height: 32px;
  width: 32px;
  background: url(../img/cross.svg) center no-repeat;
}
.search-input_mobile:focus,
.inner-search .search-input:focus {
  border: none;
  outline: none;
}
.searchInputBox,
.searchInputBox .form-search,
.searchInputBox .inner-search {
  height: 100%;
}
.search-icon {
  display: block;
  height: 32px;
  width: 32px;
  background: url("../img/search.svg") center no-repeat;
}
.ac-results_mobile {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 8px 0;
  width: 100%;
  z-index: 50;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-bottom: 3px solid $main;
  .ac-result_mobile {
    display: block;
    width: 100%;
    font-family: $font;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    padding: 8px 16px;
    color: #2C2D2E;
    text-decoration: none;
  }
}
