@charset "UTF-8";
/*!
 * Bootstrap v5.0.0-alpha1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@font-face {
  font-family: 'Golos';
  src: url("golos/Golos-Text_Regular.woff2") format("woff2"), url("golos/Golos-Text_Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Golos';
  src: url("golos/Golos-Text_Medium.woff2") format("woff2"), url("golos/Golos-Text_Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Golos';
  src: url("golos/Golos-Text_DemiBold.woff2") format("woff2"), url("golos/Golos-Text_DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Golos';
  src: url("golos/Golos-Text_Bold.woff2") format("woff2"), url("golos/Golos-Text_Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

:root {
  --bs-blue: #0079b3;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #EB5757;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #27AE60;
  --bs-teal: #20c997;
  --bs-cyan: #007283;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #484A4D;
  --bs-primary: #0079b3;
  --bs-secondary: #F3F3F5;
  --bs-success: #27AE60;
  --bs-info: #007283;
  --bs-warning: #ffc107;
  --bs-danger: #EB5757;
  --bs-light: #F3F3F5;
  --bs-dark: #484A4D;
  --bs-font-sans-serif: "Golos", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.4375rem + 2.25vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 3.125rem;
  }
}

h2, .h2 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.5rem;
  }
}

h3, .h3 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.875rem;
  }
}

h4, .h4 {
  font-size: calc(1.28125rem + 0.375vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5625rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #0079b3;
  text-decoration: underline;
}

a:hover {
  color: #004567;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  -ms-overflow-style: scrollbar;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  white-space: normal;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.28125rem + 0.375vw);
  font-weight: 300;
}

@media (min-width: 1200px) {
  .lead {
    font-size: 1.5625rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.28125rem + 0.375vw);
}

@media (min-width: 1200px) {
  .blockquote {
    font-size: 1.5625rem;
  }
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 8rem;
  padding-left: 8rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 600px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 1024px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 1680px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1920px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 2560px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1296px;
  }
}

.row {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.5rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.5rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 1rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 1rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 2rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 2rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 3rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 3rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 4rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 4rem;
}

@media (min-width: 600px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 4rem;
  }
}

@media (min-width: 1024px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 1rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 1rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 2rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 2rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 3rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 3rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 4rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 4rem;
  }
}

@media (min-width: 1680px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 4rem;
  }
}

@media (min-width: 1920px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 4rem;
  }
}

@media (min-width: 2560px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 4rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
  border-bottom-width: 1px;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #b8d9ea;
  --bs-table-striped-bg: #afcede;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #a6c3d3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #aac9d8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #a6c3d3;
}

.table-secondary {
  --bs-table-bg: #fcfcfc;
  --bs-table-striped-bg: #efefef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e3e3e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e9e9e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e3e3e3;
}

.table-success {
  --bs-table-bg: #c3e8d2;
  --bs-table-striped-bg: #b9dcc8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b0d1bd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #b4d7c2;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b0d1bd;
}

.table-info {
  --bs-table-bg: #b8d8dc;
  --bs-table-striped-bg: #afcdd1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #a6c2c6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #aac8cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #a6c2c6;
}

.table-warning {
  --bs-table-bg: #ffeeba;
  --bs-table-striped-bg: #f2e2b1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d6a7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdcac;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d6a7;
}

.table-danger {
  --bs-table-bg: #f9d0d0;
  --bs-table-striped-bg: #edc6c6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e0bbbb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e6c0c0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e0bbbb;
}

.table-light {
  --bs-table-bg: #F3F3F5;
  --bs-table-striped-bg: #e7e7e9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dbdbdd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e1e1e3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dbdbdd;
}

.table-dark {
  --bs-table-bg: #484A4D;
  --bs-table-striped-bg: #515356;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #5a5c5f;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #56585a;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #5a5c5f;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 599.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1023.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1679.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1919.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 2559.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.875rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 1.09375rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  min-height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  color: #484A4D;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control:focus {
  color: #484A4D;
  background-color: #fff;
  border-color: #34bdff;
  outline: 0;
  box-shadow: none;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #F3F3F5;
  opacity: 1;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 1.09375rem;
  border-radius: 0.2rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.5rem;
}

@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.875rem;
  }
}

.form-control-color {
  max-width: 3rem;
  padding: 0.375rem;
}

.form-control-color::-moz-color-swatch {
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  color: #484A4D;
  vertical-align: middle;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.form-select:focus {
  border-color: #34bdff;
  outline: 0;
  box-shadow: none;
}

.form-select:focus::-ms-value {
  color: #484A4D;
  background-color: #fff;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  color: #6c757d;
  background-color: #F3F3F5;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #484A4D;
}

.form-select-sm {
  height: calc(1.6em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1.09375rem;
}

.form-select-lg {
  height: calc(1.6em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.875rem;
  }
}

.form-check {
  display: block;
  min-height: 2rem;
  padding-left: 1.75em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.75em;
}

.form-check-input {
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.175em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #34bdff;
  outline: 0;
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #0079b3;
  border-color: #0079b3;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0079b3;
  border-color: #0079b3;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2334bdff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.form-file {
  --bs-form-file-height: calc(1.6em + 0.75rem + 2px);
  position: relative;
}

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: var(--bs-form-file-height);
  margin: 0;
  opacity: 0;
}

.form-file-input:focus-within ~ .form-file-label {
  border-color: #34bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 121, 179, 0.25);
}

.form-file-input[disabled] ~ .form-file-label .form-file-text,
.form-file-input:disabled ~ .form-file-label .form-file-text {
  background-color: #F3F3F5;
}

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: var(--bs-form-file-height);
  border-color: #ced4da;
  border-radius: 0.25rem;
}

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.6;
  color: #484A4D;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  line-height: 1.6;
  color: #484A4D;
  background-color: #F3F3F5;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.form-file-sm {
  --bs-form-file-height: calc(1.6em + 0.5rem + 2px);
  font-size: 1.09375rem;
}

.form-file-sm .form-file-text,
.form-file-sm .form-file-button {
  padding: 0.25rem 0.5rem;
}

.form-file-lg {
  --bs-form-file-height: calc(1.6em + 1rem + 2px);
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  .form-file-lg {
    font-size: 1.875rem;
  }
}

.form-file-lg .form-file-text,
.form-file-lg .form-file-button {
  padding: 0.5rem 1rem;
}

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: none;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 121, 179, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 121, 179, 0.25);
}

.form-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 121, 179, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0079b3;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #67ceff;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0079b3;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #67ceff;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #0079b3;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-ms-thumb {
    transition: none;
  }
}

.form-range::-ms-thumb:active {
  background-color: #67ceff;
}

.form-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.form-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.form-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-file .form-file-input:focus ~ .form-file-label {
  z-index: 3;
}

.input-group > .form-file > .form-file-input:focus {
  z-index: 4;
}

.input-group > .form-file:not(:last-child) > .form-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-file:not(:first-child) > .form-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  color: #484A4D;
  text-align: center;
  white-space: nowrap;
  background-color: #F3F3F5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control {
  min-height: calc(1.6em + 1rem + 2px);
}

.input-group-lg > .form-select {
  height: calc(1.6em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.5rem;
}

@media (min-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 1.875rem;
  }
}

.input-group-sm > .form-control {
  min-height: calc(1.6em + 0.5rem + 2px);
}

.input-group-sm > .form-select {
  height: calc(1.6em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.09375rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem;
}

.input-group > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #27AE60;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1.09375rem;
  color: #000;
  background-color: rgba(39, 174, 96, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #27AE60;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2327AE60' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #27AE60;
  box-shadow: 0 0 0 0.2rem rgba(39, 174, 96, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #27AE60;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2327AE60' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #27AE60;
  box-shadow: 0 0 0 0.2rem rgba(39, 174, 96, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #27AE60;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #27AE60;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 174, 96, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #27AE60;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .form-file-input:valid ~ .form-file-label, .form-file-input.is-valid ~ .form-file-label {
  border-color: #27AE60;
}

.was-validated .form-file-input:valid:focus ~ .form-file-label, .form-file-input.is-valid:focus ~ .form-file-label {
  border-color: #27AE60;
  box-shadow: 0 0 0 0.2rem rgba(39, 174, 96, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #EB5757;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1.09375rem;
  color: #000;
  background-color: rgba(235, 87, 87, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #EB5757;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EB5757' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EB5757' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #EB5757;
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #EB5757;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EB5757' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EB5757' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #EB5757;
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #EB5757;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #EB5757;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #EB5757;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .form-file-input:invalid ~ .form-file-label, .form-file-input.is-invalid ~ .form-file-label {
  border-color: #EB5757;
}

.was-validated .form-file-input:invalid:focus ~ .form-file-label, .form-file-input.is-invalid:focus ~ .form-file-label {
  border-color: #EB5757;
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.25);
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-check:checked + .btn,
.btn-check:active + .btn, .btn:active, .btn.active {
  box-shadow: none;
}

.btn-check:checked + .btn:focus,
.btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
  box-shadow: none;
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background-color: #0079b3;
  border-color: #0079b3;
}

.btn-primary:hover {
  color: #fff;
  background-color: #005f8d;
  border-color: #005780;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #005f8d;
  border-color: #005780;
  box-shadow: 0 0 0 0.2rem rgba(38, 141, 190, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #005780;
  border-color: #004e73;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 141, 190, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #0079b3;
  border-color: #0079b3;
}

.btn-secondary {
  color: #000;
  background-color: #F3F3F5;
  border-color: #F3F3F5;
}

.btn-secondary:hover {
  color: #000;
  background-color: #dedee4;
  border-color: #d7d7de;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000;
  background-color: #dedee4;
  border-color: #d7d7de;
  box-shadow: 0 0 0 0.2rem rgba(207, 207, 208, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #d7d7de;
  border-color: #d0d0d8;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 207, 208, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000;
  background-color: #F3F3F5;
  border-color: #F3F3F5;
}

.btn-success {
  color: #000;
  background-color: #27AE60;
  border-color: #27AE60;
}

.btn-success:hover {
  color: #000;
  background-color: #208f4f;
  border-color: #1e8449;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000;
  background-color: #208f4f;
  border-color: #1e8449;
  box-shadow: 0 0 0 0.2rem rgba(33, 148, 82, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e8449;
  border-color: #1b7a43;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 148, 82, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #000;
  background-color: #27AE60;
  border-color: #27AE60;
}

.btn-info {
  color: #fff;
  background-color: #007283;
  border-color: #007283;
}

.btn-info:hover {
  color: #fff;
  background-color: #00515d;
  border-color: #004650;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #00515d;
  border-color: #004650;
  box-shadow: 0 0 0 0.2rem rgba(38, 135, 150, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #004650;
  border-color: #003b43;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 135, 150, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #007283;
  border-color: #007283;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #000;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(217, 164, 6, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 164, 6, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #000;
  background-color: #EB5757;
  border-color: #EB5757;
}

.btn-danger:hover {
  color: #000;
  background-color: #e73535;
  border-color: #e62929;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #000;
  background-color: #e73535;
  border-color: #e62929;
  box-shadow: 0 0 0 0.2rem rgba(200, 74, 74, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: #e62929;
  border-color: #e41e1e;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 74, 74, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #000;
  background-color: #EB5757;
  border-color: #EB5757;
}

.btn-light {
  color: #000;
  background-color: #F3F3F5;
  border-color: #F3F3F5;
}

.btn-light:hover {
  color: #000;
  background-color: #dedee4;
  border-color: #d7d7de;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #dedee4;
  border-color: #d7d7de;
  box-shadow: 0 0 0 0.2rem rgba(207, 207, 208, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #d7d7de;
  border-color: #d0d0d8;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 207, 208, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #F3F3F5;
  border-color: #F3F3F5;
}

.btn-dark {
  color: #fff;
  background-color: #484A4D;
  border-color: #484A4D;
}

.btn-dark:hover {
  color: #fff;
  background-color: #363739;
  border-color: #2f3133;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #363739;
  border-color: #2f3133;
  box-shadow: 0 0 0 0.2rem rgba(99, 101, 104, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #2f3133;
  border-color: #292a2c;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 101, 104, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #484A4D;
  border-color: #484A4D;
}

.btn-outline-primary {
  color: #0079b3;
  border-color: #0079b3;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0079b3;
  border-color: #0079b3;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 121, 179, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0079b3;
  border-color: #0079b3;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 121, 179, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #0079b3;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #F3F3F5;
  border-color: #F3F3F5;
}

.btn-outline-secondary:hover {
  color: #000;
  background-color: #F3F3F5;
  border-color: #F3F3F5;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 243, 245, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #F3F3F5;
  border-color: #F3F3F5;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 243, 245, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #F3F3F5;
  background-color: transparent;
}

.btn-outline-success {
  color: #27AE60;
  border-color: #27AE60;
}

.btn-outline-success:hover {
  color: #000;
  background-color: #27AE60;
  border-color: #27AE60;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 174, 96, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #27AE60;
  border-color: #27AE60;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 174, 96, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #27AE60;
  background-color: transparent;
}

.btn-outline-info {
  color: #007283;
  border-color: #007283;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #007283;
  border-color: #007283;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 114, 131, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #007283;
  border-color: #007283;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 114, 131, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #007283;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #EB5757;
  border-color: #EB5757;
}

.btn-outline-danger:hover {
  color: #000;
  background-color: #EB5757;
  border-color: #EB5757;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #000;
  background-color: #EB5757;
  border-color: #EB5757;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 87, 87, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #EB5757;
  background-color: transparent;
}

.btn-outline-light {
  color: #F3F3F5;
  border-color: #F3F3F5;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #F3F3F5;
  border-color: #F3F3F5;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 243, 245, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #F3F3F5;
  border-color: #F3F3F5;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 243, 245, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #F3F3F5;
  background-color: transparent;
}

.btn-outline-dark {
  color: #484A4D;
  border-color: #484A4D;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #484A4D;
  border-color: #484A4D;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 74, 77, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #484A4D;
  border-color: #484A4D;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 74, 77, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #484A4D;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0079b3;
  text-decoration: underline;
}

.btn-link:hover {
  color: #004567;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.5rem;
}

@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    font-size: 1.875rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.09375rem;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1.25rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 600px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1024px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1680px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1920px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 2560px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0079b3;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1.09375rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #F3F3F5 #F3F3F5 #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #484A4D;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0079b3;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-right: 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  text-decoration: none;
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .navbar-brand {
    font-size: 1.875rem;
  }
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  align-items: center;
  width: 100%;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: calc(1.3125rem + 0.75vw);
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (min-width: 1200px) {
  .navbar-toggler {
    font-size: 1.875rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.2rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

@media (min-width: 600px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1024px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1680px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1920px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 2560px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 1.5rem;
}

@media (min-width: 600px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #F3F3F5;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0079b3;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #004567;
  background-color: #F3F3F5;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 121, 179, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0079b3;
  border-color: #0079b3;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  .pagination-lg .page-link {
    font-size: 1.875rem;
  }
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1.09375rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.875rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem 1rem;
  color: inherit;
}

.alert-primary {
  color: #003f5d;
  background-color: #cce4f0;
  border-color: #b8d9ea;
}

.alert-primary .alert-link {
  color: #001c2a;
}

.alert-secondary {
  color: #7e7e7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}

.alert-secondary .alert-link {
  color: #656565;
}

.alert-success {
  color: #145a32;
  background-color: #d4efdf;
  border-color: #c3e8d2;
}

.alert-success .alert-link {
  color: #0b301b;
}

.alert-info {
  color: #003b44;
  background-color: #cce3e6;
  border-color: #b8d8dc;
}

.alert-info .alert-link {
  color: #000f11;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #7a2d2d;
  background-color: #fbdddd;
  border-color: #f9d0d0;
}

.alert-danger .alert-link {
  color: #551f1f;
}

.alert-light {
  color: #7e7e7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}

.alert-light .alert-link {
  color: #656565;
}

.alert-dark {
  color: #252628;
  background-color: #dadbdb;
  border-color: #cccccd;
}

.alert-dark .alert-link {
  color: #0c0d0e;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.9375rem;
  background-color: #F3F3F5;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0079b3;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #484A4D;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #484A4D;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #F3F3F5;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0079b3;
  border-color: #0079b3;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 600px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1024px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1680px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1920px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 2560px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #003f5d;
  background-color: #b8d9ea;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #003f5d;
  background-color: #a4cee4;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #003f5d;
  border-color: #003f5d;
}

.list-group-item-secondary {
  color: #7e7e7f;
  background-color: #fcfcfc;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #7e7e7f;
  background-color: #efefef;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #7e7e7f;
  border-color: #7e7e7f;
}

.list-group-item-success {
  color: #145a32;
  background-color: #c3e8d2;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #145a32;
  background-color: #b1e1c4;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #145a32;
  border-color: #145a32;
}

.list-group-item-info {
  color: #003b44;
  background-color: #b8d8dc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #003b44;
  background-color: #a7cfd4;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #003b44;
  border-color: #003b44;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #7a2d2d;
  background-color: #f9d0d0;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7a2d2d;
  background-color: #f6b9b9;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7a2d2d;
  border-color: #7a2d2d;
}

.list-group-item-light {
  color: #7e7e7f;
  background-color: #fcfcfc;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7e7e7f;
  background-color: #efefef;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7e7e7f;
  border-color: #7e7e7f;
}

.list-group-item-dark {
  color: #252628;
  background-color: #cccccd;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #252628;
  background-color: #bfbfc0;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #252628;
  border-color: #252628;
}

.close {
  font-size: calc(1.3125rem + 0.75vw);
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

@media (min-width: 1200px) {
  .close {
    font-size: 1.875rem;
  }
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:hover, .close:focus {
  opacity: .75;
}

.close:disabled, .close.disabled {
  pointer-events: none;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(173, 181, 189, 0.2);
  border-radius: 0.5rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem 2rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.modal-header .close {
  padding: 2rem 2rem;
  margin: -2rem -2rem -2rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 1.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 600px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 470px;
  }
}

@media (min-width: 1680px) {
  .modal-lg,
  .modal-xl {
    max-width: 690px;
  }
}

@media (min-width: 1920px) {
  .modal-xl {
    max-width: 720px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 599.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1023.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1679.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1919.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 2559.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.09375rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .tooltip-arrow, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .tooltip-arrow, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.09375rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.5rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[x-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}

.bs-popover-right > .popover-arrow::before, .bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .popover-arrow::after, .bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}

.bs-popover-left > .popover-arrow::before, .bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .popover-arrow::after, .bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1.25rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0079b3;
}

.link-primary:hover, .link-primary:focus {
  color: #004567;
}

.link-secondary {
  color: #F3F3F5;
}

.link-secondary:hover, .link-secondary:focus {
  color: #c9c9d2;
}

.link-success {
  color: #27AE60;
}

.link-success:hover, .link-success:focus {
  color: #19703e;
}

.link-info {
  color: #007283;
}

.link-info:hover, .link-info:focus {
  color: #002f37;
}

.link-warning {
  color: #ffc107;
}

.link-warning:hover, .link-warning:focus {
  color: #ba8b00;
}

.link-danger {
  color: #EB5757;
}

.link-danger:hover, .link-danger:focus {
  color: #db1a1a;
}

.link-light {
  color: #F3F3F5;
}

.link-light:hover, .link-light:focus {
  color: #c9c9d2;
}

.link-dark {
  color: #484A4D;
}

.link-dark:hover, .link-dark:focus {
  color: #232425;
}

.embed-responsive {
  position: relative;
  width: 100%;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 600px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1024px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1680px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1920px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 2560px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0079b3 !important;
}

.border-secondary {
  border-color: #F3F3F5 !important;
}

.border-success {
  border-color: #27AE60 !important;
}

.border-info {
  border-color: #007283 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #EB5757 !important;
}

.border-light {
  border-color: #F3F3F5 !important;
}

.border-dark {
  border-color: #484A4D !important;
}

.border-white {
  border-color: #fff !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.m-3 {
  margin: 2rem !important;
}

.m-4 {
  margin: 3rem !important;
}

.m-5 {
  margin: 4rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-2 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-3 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-4 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-5 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-3 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-4 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-5 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 2rem !important;
}

.mt-4 {
  margin-top: 3rem !important;
}

.mt-5 {
  margin-top: 4rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.mr-3 {
  margin-right: 2rem !important;
}

.mr-4 {
  margin-right: 3rem !important;
}

.mr-5 {
  margin-right: 4rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 2rem !important;
}

.mb-4 {
  margin-bottom: 3rem !important;
}

.mb-5 {
  margin-bottom: 4rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.ml-3 {
  margin-left: 2rem !important;
}

.ml-4 {
  margin-left: 3rem !important;
}

.ml-5 {
  margin-left: 4rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.p-3 {
  padding: 2rem !important;
}

.p-4 {
  padding: 3rem !important;
}

.p-5 {
  padding: 4rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-2 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-3 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-4 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-5 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-3 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-4 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-5 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pt-3 {
  padding-top: 2rem !important;
}

.pt-4 {
  padding-top: 3rem !important;
}

.pt-5 {
  padding-top: 4rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.5rem !important;
}

.pr-2 {
  padding-right: 1rem !important;
}

.pr-3 {
  padding-right: 2rem !important;
}

.pr-4 {
  padding-right: 3rem !important;
}

.pr-5 {
  padding-right: 4rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pb-3 {
  padding-bottom: 2rem !important;
}

.pb-4 {
  padding-bottom: 3rem !important;
}

.pb-5 {
  padding-bottom: 4rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.5rem !important;
}

.pl-2 {
  padding-left: 1rem !important;
}

.pl-3 {
  padding-left: 2rem !important;
}

.pl-4 {
  padding-left: 3rem !important;
}

.pl-5 {
  padding-left: 4rem !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #0079b3 !important;
}

.text-secondary {
  color: #F3F3F5 !important;
}

.text-success {
  color: #27AE60 !important;
}

.text-info {
  color: #007283 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #EB5757 !important;
}

.text-light {
  color: #F3F3F5 !important;
}

.text-dark {
  color: #484A4D !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-primary {
  background-color: #0079b3 !important;
}

.bg-secondary {
  background-color: #F3F3F5 !important;
}

.bg-success {
  background-color: #27AE60 !important;
}

.bg-info {
  background-color: #007283 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #EB5757 !important;
}

.bg-light {
  background-color: #F3F3F5 !important;
}

.bg-dark {
  background-color: #484A4D !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.font-italic {
  font-style: italic !important;
}

.font-normal {
  font-style: normal !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 600px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.5rem !important;
  }
  .m-sm-2 {
    margin: 1rem !important;
  }
  .m-sm-3 {
    margin: 2rem !important;
  }
  .m-sm-4 {
    margin: 3rem !important;
  }
  .m-sm-5 {
    margin: 4rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-4 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-5 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-4 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-5 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-2 {
    margin-top: 1rem !important;
  }
  .mt-sm-3 {
    margin-top: 2rem !important;
  }
  .mt-sm-4 {
    margin-top: 3rem !important;
  }
  .mt-sm-5 {
    margin-top: 4rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mr-sm-1 {
    margin-right: 0.5rem !important;
  }
  .mr-sm-2 {
    margin-right: 1rem !important;
  }
  .mr-sm-3 {
    margin-right: 2rem !important;
  }
  .mr-sm-4 {
    margin-right: 3rem !important;
  }
  .mr-sm-5 {
    margin-right: 4rem !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .ml-sm-1 {
    margin-left: 0.5rem !important;
  }
  .ml-sm-2 {
    margin-left: 1rem !important;
  }
  .ml-sm-3 {
    margin-left: 2rem !important;
  }
  .ml-sm-4 {
    margin-left: 3rem !important;
  }
  .ml-sm-5 {
    margin-left: 4rem !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.5rem !important;
  }
  .p-sm-2 {
    padding: 1rem !important;
  }
  .p-sm-3 {
    padding: 2rem !important;
  }
  .p-sm-4 {
    padding: 3rem !important;
  }
  .p-sm-5 {
    padding: 4rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-4 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-5 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-4 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-5 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-2 {
    padding-top: 1rem !important;
  }
  .pt-sm-3 {
    padding-top: 2rem !important;
  }
  .pt-sm-4 {
    padding-top: 3rem !important;
  }
  .pt-sm-5 {
    padding-top: 4rem !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pr-sm-1 {
    padding-right: 0.5rem !important;
  }
  .pr-sm-2 {
    padding-right: 1rem !important;
  }
  .pr-sm-3 {
    padding-right: 2rem !important;
  }
  .pr-sm-4 {
    padding-right: 3rem !important;
  }
  .pr-sm-5 {
    padding-right: 4rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .pl-sm-1 {
    padding-left: 0.5rem !important;
  }
  .pl-sm-2 {
    padding-left: 1rem !important;
  }
  .pl-sm-3 {
    padding-left: 2rem !important;
  }
  .pl-sm-4 {
    padding-left: 3rem !important;
  }
  .pl-sm-5 {
    padding-left: 4rem !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 1024px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.5rem !important;
  }
  .m-md-2 {
    margin: 1rem !important;
  }
  .m-md-3 {
    margin: 2rem !important;
  }
  .m-md-4 {
    margin: 3rem !important;
  }
  .m-md-5 {
    margin: 4rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-4 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-5 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-4 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-5 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.5rem !important;
  }
  .mt-md-2 {
    margin-top: 1rem !important;
  }
  .mt-md-3 {
    margin-top: 2rem !important;
  }
  .mt-md-4 {
    margin-top: 3rem !important;
  }
  .mt-md-5 {
    margin-top: 4rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mr-md-1 {
    margin-right: 0.5rem !important;
  }
  .mr-md-2 {
    margin-right: 1rem !important;
  }
  .mr-md-3 {
    margin-right: 2rem !important;
  }
  .mr-md-4 {
    margin-right: 3rem !important;
  }
  .mr-md-5 {
    margin-right: 4rem !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-2 {
    margin-bottom: 1rem !important;
  }
  .mb-md-3 {
    margin-bottom: 2rem !important;
  }
  .mb-md-4 {
    margin-bottom: 3rem !important;
  }
  .mb-md-5 {
    margin-bottom: 4rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .ml-md-1 {
    margin-left: 0.5rem !important;
  }
  .ml-md-2 {
    margin-left: 1rem !important;
  }
  .ml-md-3 {
    margin-left: 2rem !important;
  }
  .ml-md-4 {
    margin-left: 3rem !important;
  }
  .ml-md-5 {
    margin-left: 4rem !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.5rem !important;
  }
  .p-md-2 {
    padding: 1rem !important;
  }
  .p-md-3 {
    padding: 2rem !important;
  }
  .p-md-4 {
    padding: 3rem !important;
  }
  .p-md-5 {
    padding: 4rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-4 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-5 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-4 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-5 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.5rem !important;
  }
  .pt-md-2 {
    padding-top: 1rem !important;
  }
  .pt-md-3 {
    padding-top: 2rem !important;
  }
  .pt-md-4 {
    padding-top: 3rem !important;
  }
  .pt-md-5 {
    padding-top: 4rem !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pr-md-1 {
    padding-right: 0.5rem !important;
  }
  .pr-md-2 {
    padding-right: 1rem !important;
  }
  .pr-md-3 {
    padding-right: 2rem !important;
  }
  .pr-md-4 {
    padding-right: 3rem !important;
  }
  .pr-md-5 {
    padding-right: 4rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-2 {
    padding-bottom: 1rem !important;
  }
  .pb-md-3 {
    padding-bottom: 2rem !important;
  }
  .pb-md-4 {
    padding-bottom: 3rem !important;
  }
  .pb-md-5 {
    padding-bottom: 4rem !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .pl-md-1 {
    padding-left: 0.5rem !important;
  }
  .pl-md-2 {
    padding-left: 1rem !important;
  }
  .pl-md-3 {
    padding-left: 2rem !important;
  }
  .pl-md-4 {
    padding-left: 3rem !important;
  }
  .pl-md-5 {
    padding-left: 4rem !important;
  }
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 1680px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.5rem !important;
  }
  .m-lg-2 {
    margin: 1rem !important;
  }
  .m-lg-3 {
    margin: 2rem !important;
  }
  .m-lg-4 {
    margin: 3rem !important;
  }
  .m-lg-5 {
    margin: 4rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-4 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-5 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-4 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-5 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-2 {
    margin-top: 1rem !important;
  }
  .mt-lg-3 {
    margin-top: 2rem !important;
  }
  .mt-lg-4 {
    margin-top: 3rem !important;
  }
  .mt-lg-5 {
    margin-top: 4rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mr-lg-1 {
    margin-right: 0.5rem !important;
  }
  .mr-lg-2 {
    margin-right: 1rem !important;
  }
  .mr-lg-3 {
    margin-right: 2rem !important;
  }
  .mr-lg-4 {
    margin-right: 3rem !important;
  }
  .mr-lg-5 {
    margin-right: 4rem !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .ml-lg-1 {
    margin-left: 0.5rem !important;
  }
  .ml-lg-2 {
    margin-left: 1rem !important;
  }
  .ml-lg-3 {
    margin-left: 2rem !important;
  }
  .ml-lg-4 {
    margin-left: 3rem !important;
  }
  .ml-lg-5 {
    margin-left: 4rem !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.5rem !important;
  }
  .p-lg-2 {
    padding: 1rem !important;
  }
  .p-lg-3 {
    padding: 2rem !important;
  }
  .p-lg-4 {
    padding: 3rem !important;
  }
  .p-lg-5 {
    padding: 4rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-4 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-5 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-4 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-5 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-2 {
    padding-top: 1rem !important;
  }
  .pt-lg-3 {
    padding-top: 2rem !important;
  }
  .pt-lg-4 {
    padding-top: 3rem !important;
  }
  .pt-lg-5 {
    padding-top: 4rem !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pr-lg-1 {
    padding-right: 0.5rem !important;
  }
  .pr-lg-2 {
    padding-right: 1rem !important;
  }
  .pr-lg-3 {
    padding-right: 2rem !important;
  }
  .pr-lg-4 {
    padding-right: 3rem !important;
  }
  .pr-lg-5 {
    padding-right: 4rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .pl-lg-1 {
    padding-left: 0.5rem !important;
  }
  .pl-lg-2 {
    padding-left: 1rem !important;
  }
  .pl-lg-3 {
    padding-left: 2rem !important;
  }
  .pl-lg-4 {
    padding-left: 3rem !important;
  }
  .pl-lg-5 {
    padding-left: 4rem !important;
  }
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1920px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.5rem !important;
  }
  .m-xl-2 {
    margin: 1rem !important;
  }
  .m-xl-3 {
    margin: 2rem !important;
  }
  .m-xl-4 {
    margin: 3rem !important;
  }
  .m-xl-5 {
    margin: 4rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-4 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-5 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-4 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-5 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-2 {
    margin-top: 1rem !important;
  }
  .mt-xl-3 {
    margin-top: 2rem !important;
  }
  .mt-xl-4 {
    margin-top: 3rem !important;
  }
  .mt-xl-5 {
    margin-top: 4rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .mr-xl-1 {
    margin-right: 0.5rem !important;
  }
  .mr-xl-2 {
    margin-right: 1rem !important;
  }
  .mr-xl-3 {
    margin-right: 2rem !important;
  }
  .mr-xl-4 {
    margin-right: 3rem !important;
  }
  .mr-xl-5 {
    margin-right: 4rem !important;
  }
  .mr-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .ml-xl-1 {
    margin-left: 0.5rem !important;
  }
  .ml-xl-2 {
    margin-left: 1rem !important;
  }
  .ml-xl-3 {
    margin-left: 2rem !important;
  }
  .ml-xl-4 {
    margin-left: 3rem !important;
  }
  .ml-xl-5 {
    margin-left: 4rem !important;
  }
  .ml-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.5rem !important;
  }
  .p-xl-2 {
    padding: 1rem !important;
  }
  .p-xl-3 {
    padding: 2rem !important;
  }
  .p-xl-4 {
    padding: 3rem !important;
  }
  .p-xl-5 {
    padding: 4rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-4 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-5 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-4 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-5 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-2 {
    padding-top: 1rem !important;
  }
  .pt-xl-3 {
    padding-top: 2rem !important;
  }
  .pt-xl-4 {
    padding-top: 3rem !important;
  }
  .pt-xl-5 {
    padding-top: 4rem !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .pr-xl-1 {
    padding-right: 0.5rem !important;
  }
  .pr-xl-2 {
    padding-right: 1rem !important;
  }
  .pr-xl-3 {
    padding-right: 2rem !important;
  }
  .pr-xl-4 {
    padding-right: 3rem !important;
  }
  .pr-xl-5 {
    padding-right: 4rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .pl-xl-1 {
    padding-left: 0.5rem !important;
  }
  .pl-xl-2 {
    padding-left: 1rem !important;
  }
  .pl-xl-3 {
    padding-left: 2rem !important;
  }
  .pl-xl-4 {
    padding-left: 3rem !important;
  }
  .pl-xl-5 {
    padding-left: 4rem !important;
  }
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 2560px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.5rem !important;
  }
  .m-xxl-2 {
    margin: 1rem !important;
  }
  .m-xxl-3 {
    margin: 2rem !important;
  }
  .m-xxl-4 {
    margin: 3rem !important;
  }
  .m-xxl-5 {
    margin: 4rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-4 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-5 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-4 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-5 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-2 {
    margin-top: 1rem !important;
  }
  .mt-xxl-3 {
    margin-top: 2rem !important;
  }
  .mt-xxl-4 {
    margin-top: 3rem !important;
  }
  .mt-xxl-5 {
    margin-top: 4rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-0 {
    margin-right: 0 !important;
  }
  .mr-xxl-1 {
    margin-right: 0.5rem !important;
  }
  .mr-xxl-2 {
    margin-right: 1rem !important;
  }
  .mr-xxl-3 {
    margin-right: 2rem !important;
  }
  .mr-xxl-4 {
    margin-right: 3rem !important;
  }
  .mr-xxl-5 {
    margin-right: 4rem !important;
  }
  .mr-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-0 {
    margin-left: 0 !important;
  }
  .ml-xxl-1 {
    margin-left: 0.5rem !important;
  }
  .ml-xxl-2 {
    margin-left: 1rem !important;
  }
  .ml-xxl-3 {
    margin-left: 2rem !important;
  }
  .ml-xxl-4 {
    margin-left: 3rem !important;
  }
  .ml-xxl-5 {
    margin-left: 4rem !important;
  }
  .ml-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.5rem !important;
  }
  .p-xxl-2 {
    padding: 1rem !important;
  }
  .p-xxl-3 {
    padding: 2rem !important;
  }
  .p-xxl-4 {
    padding: 3rem !important;
  }
  .p-xxl-5 {
    padding: 4rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-4 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-5 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-4 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-5 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-2 {
    padding-top: 1rem !important;
  }
  .pt-xxl-3 {
    padding-top: 2rem !important;
  }
  .pt-xxl-4 {
    padding-top: 3rem !important;
  }
  .pt-xxl-5 {
    padding-top: 4rem !important;
  }
  .pr-xxl-0 {
    padding-right: 0 !important;
  }
  .pr-xxl-1 {
    padding-right: 0.5rem !important;
  }
  .pr-xxl-2 {
    padding-right: 1rem !important;
  }
  .pr-xxl-3 {
    padding-right: 2rem !important;
  }
  .pr-xxl-4 {
    padding-right: 3rem !important;
  }
  .pr-xxl-5 {
    padding-right: 4rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 4rem !important;
  }
  .pl-xxl-0 {
    padding-left: 0 !important;
  }
  .pl-xxl-1 {
    padding-left: 0.5rem !important;
  }
  .pl-xxl-2 {
    padding-left: 1rem !important;
  }
  .pl-xxl-3 {
    padding-left: 2rem !important;
  }
  .pl-xxl-4 {
    padding-left: 3rem !important;
  }
  .pl-xxl-5 {
    padding-left: 4rem !important;
  }
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
}

.main-content {
  flex: 1 0 auto;
}

h1, .h1, .h1 {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: #2C2D2E;
  margin: 0 0 32px;
}

h2, .h2, .h2 {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #2C2D2E;
  margin: 24px 0 16px;
}

h3, .h3, h4, .h4, h5, .h5, .h3, .h4, .h5 {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin: 24px 0 8px;
}

a,
a:hover {
  text-decoration: none;
}

.info {
  border-radius: 12px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  background-color: #F9F9FA;
  padding: 16px 24px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  width: fit-content;
  max-width: 100%;
}

.info > * {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 0;
}

hr {
  margin: 0;
  color: rgba(0, 16, 61, 0.12);
  background-color: rgba(0, 16, 61, 0.12);
  opacity: 1;
}

.text hr {
  margin: 16px 0;
}

p {
  margin: 0;
}

.text p {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 16px;
}

.text a {
  display: unset;
  word-wrap: break-word;
  color: #007ED9;
  transition: color 0.2s ease-out;
}

.text a:hover {
  color: #006dc8;
}

.m-4 {
  margin: 4px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mx-4 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.m-8 {
  margin: 8px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mx-8 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.m-12 {
  margin: 12px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.mx-12 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.m-16 {
  margin: 16px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.mx-16 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.m-24 {
  margin: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.mx-24 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.m-32 {
  margin: 32px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.mx-32 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.m-48 {
  margin: 48px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.mx-48 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

.m-54 {
  margin: 54px !important;
}

.mt-54 {
  margin-top: 54px !important;
}

.mb-54 {
  margin-bottom: 54px !important;
}

.ml-54 {
  margin-left: 54px !important;
}

.mr-54 {
  margin-right: 54px !important;
}

.my-54 {
  margin-top: 54px !important;
  margin-bottom: 54px !important;
}

.mx-54 {
  margin-right: 54px !important;
  margin-left: 54px !important;
}

.p-4 {
  padding: 4px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.py-4 {
  padding-top: 4rem !important;
  padding-bottom: 4px !important;
}

.px-4 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.p-8 {
  padding: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.py-8 {
  padding-top: 8rem !important;
  padding-bottom: 8px !important;
}

.px-8 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.p-12 {
  padding: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.py-12 {
  padding-top: 12rem !important;
  padding-bottom: 12px !important;
}

.px-12 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.p-16 {
  padding: 16px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.py-16 {
  padding-top: 16rem !important;
  padding-bottom: 16px !important;
}

.px-16 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.p-24 {
  padding: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.py-24 {
  padding-top: 24rem !important;
  padding-bottom: 24px !important;
}

.px-24 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.p-32 {
  padding: 32px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.py-32 {
  padding-top: 32rem !important;
  padding-bottom: 32px !important;
}

.px-32 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.p-48 {
  padding: 48px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.py-48 {
  padding-top: 48rem !important;
  padding-bottom: 48px !important;
}

.px-48 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}

.p-54 {
  padding: 54px !important;
}

.pt-54 {
  padding-top: 54px !important;
}

.pb-54 {
  padding-bottom: 54px !important;
}

.pl-54 {
  padding-left: 54px !important;
}

.pr-54 {
  padding-right: 54px !important;
}

.py-54 {
  padding-top: 54rem !important;
  padding-bottom: 54px !important;
}

.px-54 {
  padding-right: 54px !important;
  padding-left: 54px !important;
}

.text-main {
  color: #007ED9;
}

blockquote {
  display: block;
  position: relative;
  padding: 48px;
  background: #f2f2f2;
  margin: 24px 0;
}

blockquote:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 8px;
  background: linear-gradient(0deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
}

.link-title {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 20px;
}

.link-title:hover {
  color: #007ED9;
}

#wcag-collapse-button::after {
  content: url("../img/wsag-icon.svg");
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 2px;
  margin-left: 6px;
}

.open-arrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -10px;
  height: 24px;
  width: 100%;
}

.open-arrow::before {
  content: 'Свернуть';
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #919399;
  text-align: center;
  margin-right: 4px;
  display: block;
  bottom: 10px;
  height: 16px;
}

.open-arrow::after {
  content: '';
  display: block;
  bottom: -8px;
  height: 10px;
  width: 10px;
  border-right: 1px solid #919399;
  border-bottom: 1px solid #919399;
  transform: rotate(225deg) translate(-7px, 2px);
  transition: 0.2s;
}

.open-arrow.collapsed::before {
  content: 'Развернуть';
}

.open-arrow.collapsed::after {
  transform: rotate(45deg) translate(2px, -5px);
  bottom: 0;
}

.inner-search .search-btn,
.text .main-btn,
.main-btn {
  display: inline-block;
  padding: 12px 24px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #FFF;
  background-color: #007ED9;
  border-radius: 8px;
  text-align: center;
  border: none;
}

.inner-search .search-btn._block,
.text .main-btn._block,
.main-btn._block {
  width: 100%;
  text-align: center;
}

.inner-search .search-btn:hover,
.main-btn:hover {
  color: #fff;
}

.content-title {
  font-family: "Golos", sans-srif;
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  background: linear-gradient(90deg, #00CCCC 0%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  margin-bottom: 24px;
  display: inline-block;
  margin-left: 48px;
  margin-top: 0;
  align-items: flex-start;
}

.mobile-img__wrap {
  position: relative;
}

.btn {
  text-align: left;
  padding: 12px 24px;
  background: #F3F3F5;
  border-radius: 12px;
}

.container-center {
  max-width: 1440px;
  width: 100%;
  padding: 0 64px;
  margin: 0 auto;
  flex-grow: 1;
}

.overflow-hidden {
  overflow: hidden;
}

.cut-img-center {
  display: block;
  height: 100%;
  width: 100%;
}

.spinner {
  display: block;
  pointer-events: none;
  width: 24px;
  height: 24px;
  border: 2px solid transparent;
  border-color: #f2f2f2;
  border-top-color: #c8c8c8;
  border-radius: 50%;
  animation: spin 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.admin-panel {
  position: fixed;
  width: fit-content;
  max-width: 100%;
  right: 12px;
  bottom: 12px;
  background: #323232;
  padding: 16px 24px;
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 50;
}

.admin-panel a {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
  display: block;
}

.admin-panel a:hover {
  color: #FDE953;
  text-decoration: none;
}

.wait-btn {
  display: flex;
  margin-top: 48px;
  position: relative;
  height: 46px;
  border: 1px solid #007ED9;
  border-radius: 24px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24pc;
  color: #007ED9;
  align-items: center;
  padding: 11px 23px 11px 47px;
}

.wait-btn .wait-animate {
  position: absolute;
  top: 11px;
  left: 15px;
  display: block;
  height: 24px;
  width: 24px;
  background: url("../img/GearMyHome.svg") center no-repeat;
  animation: spin 8s infinite linear;
}

.dropdown {
  display: block;
  width: 100%;
}

a.select {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding-right: 48px;
}

a.select::after {
  position: absolute;
  top: 22px;
  right: 18px;
}

a.select + .dropdown-menu {
  width: 100%;
  border-radius: 0 0 12px 12px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-top: none;
  overflow: hidden;
}

a.select + .dropdown-menu li .dropdown-item {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  padding: 8px 24px;
  transition: 0.2s;
  white-space: normal;
}

a.select + .dropdown-menu li .dropdown-item:hover {
  background-color: #F3F3F5 !important;
  color: #2C2D2E !important;
}

.alert {
  padding: 8px 16px !important;
  margin-bottom: 8px !important;
  margin-top: 16px !important;
  border-radius: 8px !important;
  border: none !important;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 8px !important;
  font-family: "Golos", sans-srif;
  font-size: 16px !important;
  font-weight: 24 !important;
  /*&::before {
    content: '';
    height: 18px;
    width: 18px;
    display: block;
    margin-right: 4px;
    background: url("../img/Warning.svg") no-repeat center;
    background-size: contain;*/
}

.alert .bold {
  font-weight: 600;
}

.alert.alert-info {
  background-color: #DAEAF2 !important;
  color: #2C2D2E !important;
}

.alert .alert__text-wrap {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  grid-column: 1/2;
}

.alert .alert__close {
  justify-self: flex-end;
}

.share-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #919399;
  margin: 24px 0 12px;
}

.share-title::after {
  content: ':';
}

.share-buttons {
  display: flex;
  align-items: center;
}

.share-buttons .social_button,
.share-buttons .copyToClipBoardBtn {
  cursor: pointer;
}

.share-buttons .copyToClipBoardBtn {
  display: flex;
}

.share-buttons .copyToClipBoardBtn + .copy_tooltip {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #007ED9;
  margin-left: 8px;
}

/* ------- геос ------- */
.container-geos {
  padding: 0 64px;
}

.geos-page__title {
  font-size: 32px;
  line-height: 48px;
  margin: 24px 0 8px;
}

.geos-alert {
  display: flex;
  margin: 8px 0 24px;
  border-radius: 8px;
  max-width: 1200px;
}

.geos-alert img {
  display: block;
  height: 20px;
  margin-right: 6px;
  width: 20px;
  margin-top: 1px;
}

.geos-alert p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #919399;
}

.geos-alert-btn {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  display: inline-block;
  padding: 12px 24px;
  background-color: #007ED9;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  border: none;
}

.geos-alert-btn:hover {
  background-color: #006dc8;
}

.geos-alert-btn:hover {
  color: #fff;
}

/* ------- геос end ------- */
/* ------- picture ------- */
.text .picture,
.picture {
  margin: 32px 0 64px;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
  color: #2C2D2E;
  box-sizing: border-box;
}

.text .picture:hover,
.picture:hover {
  text-decoration: none;
  color: #2C2D2E;
}

.text .picture__img,
.picture__img {
  width: fit-content;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.text .picture__title,
.picture__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin: 0;
  box-sizing: border-box;
}

.text .picture__caption,
.picture__caption {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #919399;
  margin: 0;
  box-sizing: border-box;
}

/* ------- picture end ------- */
.text .a-wrap,
.a-wrap {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 24px;
  width: 100%;
  max-width: 640px;
}

.text .a-card,
.a-card {
  padding: 23px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background-color: #fff;
}

.text .a-card .a-card__header,
.a-card .a-card__header {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.text .a-card .a-card__img,
.a-card .a-card__img {
  display: flex;
  height: 40px;
  width: auto;
  margin: 0 0 0 16px;
}

.text .a-card .a-card__category,
.a-card .a-card__category {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  background-color: #007ED9;
  padding: 0 8px;
  margin: 0 0 4px;
}

.text .a-card .a-card__title,
.a-card .a-card__title {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 0;
}

.text .a-card .a-card__sub-title,
.a-card .a-card__sub-title {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #5B87A6;
  margin: 8px 0 0;
}

.text .a-card .a-card__info,
.a-card .a-card__info {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 8px 0 4px;
}

.text .a-card .a-card__btn,
.a-card .a-card__btn {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  background-color: #007ED9;
  padding: 12px 24px;
  margin: 12px 0 0;
  border-radius: 8px;
  transition: background-color 0.3s ease-out;
}

.text .a-card .a-card__btn:hover,
.a-card .a-card__btn:hover {
  background-color: #006dc8;
}

.text .a-card .a-card__body,
.a-card .a-card__body {
  padding: 16px 0 0;
}

.text .a-card .a-card__body h3, .text .a-card .a-card__body .h3,
.a-card .a-card__body h3,
.a-card .a-card__body .h3 {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #2C2D2E;
  margin: 16px 0 4px;
}

.text .a-card .a-card__body p,
.a-card .a-card__body p {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 0 0 4px;
}

.text .a-card .a-card__body p a,
.a-card .a-card__body p a {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #007ED9;
}

.text .a-card .e-collapse__btn,
.a-card .e-collapse__btn {
  margin-top: 12px;
  display: flex;
}

.text .a-card._sales .a-card__title,
.a-card._sales .a-card__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
}

.text .a-card._sales .a-card__sub-title,
.a-card._sales .a-card__sub-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
  margin: 0;
}

.text .a-card._sales .a-card__info,
.a-card._sales .a-card__info {
  display: block;
  width: fit-content;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin: 12px 0 0;
  background-color: #007ED9;
  padding: 0 8px;
}

.text .a-card._sales .a-card__body h3, .text .a-card._sales .a-card__body .h3,
.a-card._sales .a-card__body h3,
.a-card._sales .a-card__body .h3 {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #919399;
  margin: 12px 0 4px;
}

#menu-app {
  min-height: 70px;
}

.header {
  position: relative;
  padding-top: 20px;
  padding-bottom: 12px;
  z-index: 50;
  background-color: #fff;
}

.header-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header-logo {
  height: 32px;
  flex: none;
  margin-right: 48px;
}

.header-info {
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 12px;
}

.header-menu {
  display: flex;
  align-items: center;
}

.header-phone {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  text-decoration: none;
}

.header-phone:hover {
  text-decoration: none;
  color: #2C2D2E;
}

.first-menu {
  display: flex;
  align-items: center;
}

.phone-img {
  width: 16px;
  height: 24px;
  flex: none;
  margin-right: 8px;
}

.login-button {
  display: inline-block;
  padding: 12px 24px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFF;
  background-color: #007ED9;
  border-radius: 4px;
  text-align: center;
  border: none;
  margin-left: 24px;
}

.login-button:hover {
  text-decoration: none;
  color: #FFF;
}

.social_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 16px;
  background: #007ED9;
  object-position: center;
  object-fit: scale-down;
}

.social_button:after {
  content: '';
  display: block;
  height: 16px;
  width: 16px;
}

.social_button._vk {
  background: #2787F5;
}

.social_button._vk:after {
  background: url("../img/socials/vk.svg") no-repeat center;
}

.social_button._odnoklassniki {
  background: #EE8208;
}

.social_button._odnoklassniki:after {
  background: url("../img/socials/ok.svg") no-repeat center;
}

.social_button._telegram {
  background: linear-gradient(203.21deg, #37AEE2 21.68%, #1E96C8 69.99%);
}

.social_button._telegram:after {
  background: url("../img/socials/telegram.svg") no-repeat center;
}

.social_button._facebook {
  content: 'f';
}

.social_button._youtube {
  content: 'YT';
}

.social_button._instagram {
  content: 'i';
}

.social_button._twitter {
  content: 't';
}

.social_button._rutube {
  background: #14191F;
}

.social_button._rutube:after {
  background: url("../img/socials/rutube.svg") no-repeat center;
}

/* START Mobile header */
.header-mobile {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  margin-bottom: 16px;
  z-index: 90;
}

.header-mobile__wrap {
  z-index: 91;
  display: grid;
  position: relative;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: auto max-content min-content;
  grid-column-gap: 16px;
  width: 100%;
  padding: 8px 16px;
}

.header-mobile .beta {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: #007ED9;
  height: 20px;
  padding: 3px 7px;
  border: 1px solid #007ED9;
  border-radius: 20px;
}

.burger {
  height: 28px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.burger span {
  position: relative;
}

.burger span::before,
.burger span::after {
  content: '';
  position: absolute;
  top: 0;
  transform-origin: center;
}

.burger span::before,
.burger span::after,
.burger span {
  display: block;
  height: 2px;
  width: 24px;
  background-color: #BFC1C7;
  transition: 0.2s ease-out;
}

.burger span::before {
  transform: translateY(-6px);
}

.burger span::after {
  transform: translateY(6px);
}

.active {
  overflow: hidden !important;
}

.active.burger span {
  background-color: transparent;
}

.active.burger span::before {
  transform: rotate(45deg);
}

.active.burger span::after {
  transform: rotate(-45deg);
}

/* END Mobile header */
.search-container {
  pointer-events: none;
  position: absolute;
  height: 70px;
  width: 100%;
  top: 1px;
  left: 0;
  opacity: 0;
  background-color: #fff;
  transition: .2s ease-out;
}

.search-container.active {
  pointer-events: unset;
  opacity: 1;
  left: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"],
input[type="search"]::-webkit-search-cancel-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

input[type="search"]:focus,
input[type="search"]::-webkit-search-cancel-button:focus {
  outline: none;
}

.inner-search {
  display: flex;
  align-items: center;
  position: relative;
}

.inner-search .ac-results {
  position: absolute;
  top: 71px;
  left: 0;
  background-color: #fff;
  padding: 8px 0;
  width: 100%;
  z-index: 40;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-bottom: 3px solid #007ED9;
}

.inner-search .ac-result {
  display: block;
  width: 100%;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  padding: 8px 16px;
  color: #2C2D2E;
  text-decoration: none;
}

.inner-search .ac-result:hover {
  background-color: #F9F9FA;
  color: #2C2D2E;
}

.inner-search .search-input {
  height: 100%;
  border: none;
  flex-grow: 1;
}

.inner-search .search-icon {
  display: block;
  height: 32px;
  width: 32px;
  background: url(../img/search-black.svg) center no-repeat;
  margin-right: 24px;
}

.inner-search .search-btn {
  margin: 0 24px;
}

.inner-search .close-btn {
  display: block;
  height: 32px;
  width: 32px;
  background: url(../img/cross.svg) center no-repeat;
}

.search-input_mobile:focus,
.inner-search .search-input:focus {
  border: none;
  outline: none;
}

.searchInputBox,
.searchInputBox .form-search,
.searchInputBox .inner-search {
  height: 100%;
}

.search-icon {
  display: block;
  height: 32px;
  width: 32px;
  background: url("../img/search.svg") center no-repeat;
}

.ac-results_mobile {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 8px 0;
  width: 100%;
  z-index: 50;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-bottom: 3px solid #007ED9;
}

.ac-results_mobile .ac-result_mobile {
  display: block;
  width: 100%;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  padding: 8px 16px;
  color: #2C2D2E;
  text-decoration: none;
}

/* START First menu */
.nav__link {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  margin-right: 24px;
  transition: opacity 0.2s ease-out;
}

.nav__link:last-child {
  margin-right: 0;
}

.nav__link:hover {
  opacity: 0.8;
}

.nav__link.active {
  font-weight: 600;
}

/* END First menu */
/* START Second menu */
.second-menu {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-template-rows: max-content;
  grid-gap: 24px;
}

.second-menu__link {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2C2D2E;
  text-align: center;
  margin-top: 8px;
  white-space: nowrap;
  transition: .2s;
}

.second-menu__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: 0.2s ease-out;
}

.second-menu__item.active .second-menu__link {
  font-weight: 600;
}

.second-menu__item:hover {
  text-decoration: none;
}

.second-menu__item:hover .second-menu__icon {
  opacity: .65;
}

.second-menu__item:hover .second-menu__link {
  color: #818283;
}

.second-menu__icon {
  width: 32px;
  height: 32px;
  flex: none;
}

.second-menu__childrens {
  margin-top: 12px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
  border: none;
  width: fit-content;
}

.second-menu__childrens .dropdown-item {
  padding: 8px 24px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  white-space: nowrap;
}

.second-menu__childrens .dropdown-item:hover {
  background: #F9F9FA;
  border: none;
  border-radius: 0;
}

.external {
  position: relative;
  line-height: inherit;
}

.external::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  flex: none;
  margin-left: 4px;
  height: 12px;
  width: 12px;
  background: url("../img/external-link.svg");
}

/* END Second menu */
/* START Mobile nav */
.m-dropdown {
  position: relative;
}

.m-dropdown .nav__link {
  display: flex;
  align-items: center;
}

.m-dropdown .nav__link::after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  margin: 4px 0 0 4px;
  border: 4px solid transparent;
  border-top: 4px solid rgba(0, 16, 61, 0.24);
}

.m-dropdown__wrap {
  display: grid;
  grid-auto-rows: auto;
  position: absolute;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  top: 100%;
  left: 0;
  background-color: #fff;
  width: 320px;
  padding: 8px 0;
  border-radius: 8px;
  z-index: 20;
}

.m-dropdown__link {
  width: 100%;
  color: #2C2D2E;
  padding: clamp(4px, calc(4px + (8 - 4) * ((100vw - 320px) / (1440 - 320))), 8px) clamp(24px, calc(24px + (32 - 24) * ((100vw - 320px) / (1920 - 320))), 32px);
  font-size: clamp(12px, calc(12px + (14 - 12) * ((100vw - 320px) / (1440 - 320))), 14px);
  line-height: clamp(16px, calc(16px + (20 - 16) * ((100vw - 320px) / (1440 - 320))), 20px);
  background-color: transparent;
  font-family: "Golos", sans-srif;
  transition: 0.2s;
}

.m-dropdown__link.active {
  font-weight: 600;
}

.m-dropdown__link:hover {
  background-color: #F9F9FA;
  color: #2C2D2E;
}

.second-menu .m-dropdown__wrap {
  top: calc(100% + 12px + 8px);
}

.header-mobile .nav-mobile {
  display: grid;
  grid-template-rows: min-content auto min-content;
  position: absolute;
  top: 100%;
  background-color: #fff;
  width: 100%;
  height: calc(100vh - 100%);
  padding: 0 16px 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.header-mobile .nav__link {
  line-height: 48px;
  padding: 0;
  font-size: 18px;
  width: 100%;
}

.header-mobile .first-menu {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 24px;
}

.header-mobile .menu._first {
  margin: 0 -16px;
}

.header-mobile .menu._second {
  width: 100%;
}

.header-mobile .menu._second > div {
  width: 100%;
  overflow-y: auto;
  background-color: #F9F9FA;
  z-index: 92;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.header-mobile .menu._second > div::-webkit-scrollbar {
  display: none;
}

.header-mobile .second-menu {
  width: fit-content;
  grid-auto-flow: column;
  grid-gap: 16px;
  padding: 10px 16px;
}

.header-mobile .second-menu__item {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-mobile .second-menu__item.active {
  font-weight: 600;
}

.header-mobile .second-menu__item .second-menu__icon {
  display: none;
}

.header-mobile .second-menu__item:hover .second-menu__link {
  color: #2C2D2E;
}

.header-mobile .second-menu__item._toggle::after {
  content: '';
  display: block;
  height: 0px;
  width: 0px;
  border: 4px solid transparent;
  border-top: 4px solid #BFC1C7;
  margin-left: 4px;
  margin-top: 4px;
}

.header-mobile .second-menu__item._toggle.open::after {
  transform: rotate(-180deg);
  margin-top: -4px;
}

.header-mobile .m-dropdown__link {
  padding: 8px 24px;
  font-size: 14px;
  line-height: 20px;
  color: #2C2D2E;
}

.header-mobile .m-dropdown__wrap {
  max-width: calc(100% - 32px) !important;
  margin: 8px 16px 0 16px;
  background-color: #fff !important;
}

.header-mobile .m-dropdown .nav__link.open::after {
  transform: rotate(180deg) translateY(4px);
}

.header-mobile .m-dropdown .nav__link + .m-dropdown__wrap,
.header-mobile .m-dropdown .second-menu__item + .m-dropdown__wrap {
  display: none;
}

.header-mobile .m-dropdown .nav__link.open + .m-dropdown__wrap,
.header-mobile .m-dropdown .second-menu__item.open + .m-dropdown__wrap {
  display: grid;
}

.header-mobile .second-menu__link {
  font-size: 14px;
  line-height: 24px;
  margin-top: 0;
}

.header-mobile .m-collapse {
  position: relative;
  width: 100%;
}

.header-mobile .m-collapse__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
}

.header-mobile .m-collapse__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 23px 12px 16px;
}

.header-mobile .m-collapse__header._toggle::after {
  content: '';
  display: block;
  position: absolute;
  top: 14px;
  right: 23px;
  height: 0;
  width: 0;
  margin-left: 12px;
  border: 5px solid transparent;
  border-top: 5px solid #BFC1C7;
  transform: translateY(50%);
}

.header-mobile .m-collapse__header._top {
  background-color: #F9F9FA;
}

.header-mobile .m-collapse .m-collapse {
  padding: 0 0 0 16px;
}

.header-mobile .m-collapse .m-collapse .m-collapse {
  padding: 0 0 0 12px;
}

.header-mobile .m-collapse .m-collapse .m-collapse__header {
  border-left: 1px solid rgba(0, 16, 61, 0.12);
  padding: 12px 23px 12px 12px;
}

.header-mobile .m-collapse .m-collapse__wrap {
  display: none;
}

.header-mobile .m-collapse .m-collapse__wrap ._top {
  background-color: transparent;
}

.header-mobile .m-collapse.active > .m-collapse__header {
  border-left-color: #2C2D2E;
}

.header-mobile .m-collapse.active > .m-collapse__header .m-collapse__title {
  font-weight: 600;
}

.header-mobile .m-collapse.active > .m-collapse__header._toggle::after {
  border-top-color: #2C2D2E;
}

.header-mobile .m-collapse.open > .m-collapse__wrap {
  display: block;
}

.header-mobile .m-collapse.open > .m-collapse__header._toggle::after {
  transform: rotate(-180deg);
}

.header-mobile .login-button {
  margin-left: 0;
  margin-right: 16px;
}

.header__menu_active .nav-mobile {
  display: grid;
}

.nav-mobile__phone-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.nav-mobile__phone {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #2C2D2E;
  margin-bottom: 0;
}

.nav-mobile__phone-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #2C2D2E;
  margin-bottom: 0;
}

.nav-mobile__socials {
  display: grid;
  margin: 16px -16px 0;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 12px;
  background-color: #2C2D2E;
  padding: 12px 0 100px;
}

.nav-mobile__socials .social_button {
  margin-right: 0;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background: unset;
  background-color: #fff;
}

.nav-mobile__socials .social_button::after {
  height: 20px;
  width: 20px;
  filter: brightness(0);
  opacity: 0.7;
}

/* END Mobile nav */
.breadcrumb {
  background-color: transparent;
  padding: 0 0 16px 12px;
  margin-bottom: 0;
  flex-wrap: nowrap;
  max-width: 100%;
}

.breadcrumb-item {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #919399;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '·';
}

/* PAGE */
.page__date {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #919399;
  margin-bottom: 24px;
}

.page__lead {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
}

.page__main-img {
  width: 100%;
  height: auto;
  margin-bottom: 32px;
}

.page__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #919399;
  margin-bottom: 32px;
}

.dev-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.dev-page__img {
  display: block;
  height: 196px;
  width: 196px;
}

.dev-page__accent {
  display: block;
  font-family: "Golos", sans-srif;
  color: transparent;
  font-size: clamp(32px, calc(32px + (40 - 32) * ((100vw - 320px) / (1440 - 320))), 40px);
  line-height: clamp(40px, calc(40px + (48 - 40) * ((100vw - 320px) / (1440 - 320))), 48px);
  font-weight: 600;
  background: linear-gradient(135deg, #0cc 0, #00cbcc 6.67%, #00cacc 13.33%, #00c6cc 20%, #00c2cc 26.67%, #00bccc 33.33%, #00b5cc 40%, #00aecc 46.67%, #00a6cc 53.33%, #009fcc 60%, #0098cc 66.67%, #0092cc 73.33%, #008ecc 80%, #008acc 86.67%, #0089cc 93.33%, #08c 100%);
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
}

.dev-page__title {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #2C2D2E;
  margin: 0;
  text-align: center;
}

.dev-page__subtitle {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #919399;
  margin: 0;
  text-align: center;
}

.category-page__filter-toggle {
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  background-color: #F3F3F5;
  border-radius: 8px;
  margin-top: 8px;
  justify-content: center;
  flex: none;
  transition: 0.2s;
}

.category-page__filter-toggle.collapsed {
  background-color: transparent;
}

.category-page__filter-toggle span {
  position: relative;
  width: 14px;
}

.category-page__filter-toggle span::before,
.category-page__filter-toggle span::after {
  content: '';
  position: absolute;
  top: 0;
  transform-origin: center;
}

.category-page__filter-toggle span::before,
.category-page__filter-toggle span::after,
.category-page__filter-toggle span {
  display: block;
  height: 2px;
  background-color: #BFC1C7;
  transition: 0.2s;
}

.category-page__filter-toggle span::before {
  transform: translateY(-6px);
  width: 20px;
  left: -3px;
}

.category-page__filter-toggle span::after {
  transform: translateY(6px);
  width: 8px;
  left: 3px;
}

.category-wrap {
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: auto;
  grid-column-gap: 24px;
}

.category-wrap._banner-block {
  grid-template-columns: repeat(3, 1fr);
  margin: 16px 0 32px;
  grid-gap: 32px;
}

.category-wrap._form-block {
  grid-template-columns: 1fr 2fr;
  margin: 0 0 32px;
  grid-gap: 32px;
}

.category-wrap._form-block .form_block-item {
  border-top: 1px solid rgba(0, 16, 61, 0.12);
  padding-top: 32px;
}

.category-wrap._form-block .category-form_form-item {
  border-top: 1px solid rgba(0, 16, 61, 0.12);
  padding-top: 32px;
}

.category-wrap .entity-container {
  grid-column: 1/3;
  grid-row: 2/3;
}

.category-wrap .paginator-container {
  display: flex;
  align-items: center;
  align-self: center;
  justify-self: end;
  grid-row-start: 3;
  grid-column: 2/3;
  padding: 8px 0;
}

.category-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #2C2D2E;
}

.category-title:hover {
  color: #2C2D2E;
}

.category-title_side, .category-title_side__is-link {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
}

.category-title__is-link {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;
  color: #2C2D2E;
  display: flex;
  align-items: center;
  transition: .2s;
  margin-bottom: 24px;
}

.category-title__is-link h2, .category-title__is-link .h2 {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;
  color: #2C2D2E;
}

.category-title__is-link:hover {
  color: #007ED9;
}

.category-title__button_side {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24x;
  color: #2C2D2E;
  display: flex;
  align-items: center;
}

.category-title__button_side:hover {
  color: #007ED9;
}

.text a.category-title__button,
.category-title__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #BFC1C7;
  white-space: nowrap;
  align-self: center;
  justify-self: end;
  transition: color .2s;
}

.text a.category-title__button:hover,
.category-title__button:hover {
  color: #2C2D2E;
}

.text a.category-title__button:hover .category-title__arrow,
.category-title__button:hover .category-title__arrow {
  background: url("../img/arrow-up-right.svg") white center no-repeat;
}

.text a.category-title__button .category-title__arrow,
.category-title__button .category-title__arrow {
  display: block;
  background: url("../img/arrow-up-right-gray.svg") white center no-repeat;
  background-size: 24px;
  height: 24px;
  width: 24px;
  margin-left: 4px;
  transition: background 0.2s;
}

.category-title__button_side .category-title__button-svg,
.category-title__button .category-title__button-svg {
  stroke: #007ED9;
  margin-right: 12px;
}

.category-title__is-link .category-title__button-svg {
  stroke: #007ED9;
  margin-left: 20px;
  transform: scale(1.25);
  margin-top: -2px;
}

.category-title__is-link h1, .category-title__is-link .h1, .category-title__is-link h2, .category-title__is-link .h2, .category-title__is-link h3, .category-title__is-link .h3 {
  transition: 0.2s;
}

.category-title__is-link:hover {
  color: #007ED9;
}

.category-title__is-link:hover h1, .category-title__is-link:hover .h1, .category-title__is-link:hover h2, .category-title__is-link:hover .h2, .category-title__is-link:hover h3, .category-title__is-link:hover .h3 {
  color: #007ED9;
}

.category-title__is-link_side,
.category-small-title__is-link {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s;
}

.category-title__is-link_side h4, .category-title__is-link_side .h4, .category-title__is-link_side h3, .category-title__is-link_side .h3, .category-title__is-link_side h2, .category-title__is-link_side .h2, .category-title__is-link_side span, .category-title__is-link_side p,
.category-small-title__is-link h4,
.category-small-title__is-link .h4,
.category-small-title__is-link h3,
.category-small-title__is-link .h3,
.category-small-title__is-link h2,
.category-small-title__is-link .h2,
.category-small-title__is-link span,
.category-small-title__is-link p {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
}

.category-title__is-link_side h4:hover, .category-title__is-link_side .h4:hover, .category-title__is-link_side h3:hover, .category-title__is-link_side .h3:hover, .category-title__is-link_side h2:hover, .category-title__is-link_side .h2:hover, .category-title__is-link_side span:hover, .category-title__is-link_side p:hover,
.category-small-title__is-link h4:hover,
.category-small-title__is-link .h4:hover,
.category-small-title__is-link h3:hover,
.category-small-title__is-link .h3:hover,
.category-small-title__is-link h2:hover,
.category-small-title__is-link .h2:hover,
.category-small-title__is-link span:hover,
.category-small-title__is-link p:hover {
  color: #007ED9;
}

.category-title__is-link_side:hover,
.category-small-title__is-link:hover {
  color: #007ED9;
}

.category-title__is-link_side:hover h1, .category-title__is-link_side:hover .h1, .category-title__is-link_side:hover h2, .category-title__is-link_side:hover .h2, .category-title__is-link_side:hover h3, .category-title__is-link_side:hover .h3,
.category-small-title__is-link:hover h1,
.category-small-title__is-link:hover .h1,
.category-small-title__is-link:hover h2,
.category-small-title__is-link:hover .h2,
.category-small-title__is-link:hover h3,
.category-small-title__is-link:hover .h3 {
  color: #007ED9;
}

.category-title__is-link_side .category-title__button-svg,
.category-small-title__is-link .category-title__button-svg {
  stroke: #007ED9;
  margin-left: 16px;
  transform: scale(1.2);
  margin-top: -4px;
}

.entity-container {
  display: grid;
  grid-auto-rows: auto;
}

.entity-container._block {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
}

.entity-container._block .sale_block-item {
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
}

.entity-container._block .sale_block-item:nth-last-child(-n + 3) {
  border: none;
}

.entity-container._block .block-item {
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  padding-bottom: 32px;
}

.entity-container._block .block-item:nth-last-child(-n + 3) {
  border: none;
}

.entity-container._text-block {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
}

.entity-container._sale {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 32px;
}

.entity-container._sale .sale-item {
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
}

.entity-container._sale .sale-item:nth-last-child(-n + 3) {
  border: none;
}

.entity-container._list {
  grid-template-columns: 100%;
  grid-gap: 32px;
}

.entity-container._banner-block {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
  grid-column: 2/4;
  grid-row: unset;
}

.entity-container._banner-block .banner_block-item {
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  padding-bottom: 32px;
}

.entity-container._banner-block .banner_block-item a.sale._list {
  padding-bottom: 0;
}

.entity-container._form-block {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
  grid-column: unset;
  grid-row: unset;
}

.banner-block_banner-item {
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
}

.banner-block_banner-item .banner-container {
  width: 100%;
  aspect-ratio: 416/520;
}

.banner-block_banner-item .banner-container .banner-block_arrow {
  position: absolute;
  right: 24px;
  bottom: 24px;
  z-index: 2;
  width: 24px;
  background: url("../img/arrow-narrow-right.svg") white center no-repeat;
  padding: 24px;
  border-radius: 50%;
  background-size: 24px;
  flex: none;
}

.banner-block_banner-item .banner-container .banner {
  background-color: #F3F3F5;
}

/* FILTERS */
.data-chooser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  padding: 12px 14px;
  border-radius: 8px;
  background-color: #F3F3F5;
}

.data-chooser::after {
  border: none;
  display: block;
  height: 24px;
  width: 24px;
  content: url("../img/small-arrow.svg");
}

.data-chooser__dropdown-menu {
  width: 100px;
  background-color: #fff;
  padding: 8px 0;
  border: none;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
}

.data-chooser__dropdown-menu .data-chooser__dropdown-item {
  width: 100%;
  display: block;
  white-space: unset !important;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  padding: 8px 16px;
  text-decoration: none;
}

.data-chooser__dropdown-menu .data-chooser__dropdown-item :hover,
.data-chooser__dropdown-menu .data-chooser__dropdown-item :active {
  background-color: #F9F9FA;
}

.rubric__header {
  display: flex;
  align-items: center;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
}

.rubric__header.active::after {
  transform: rotate(-180deg);
}

.rubric__container .rubric__container {
  margin-left: 16px;
}

.main-checkbox {
  display: inline-flex;
  align-items: center;
  background: #F3F3F5;
  border-radius: 8px;
  padding: 11px 12px;
  margin: 8px 0;
  transition: 0.2s;
}

.main-checkbox:hover {
  cursor: pointer;
  background: #c8c8c8;
}

.main-checkbox input {
  display: none;
}

.main-checkbox::before {
  content: '';
  display: block;
  flex: none;
  height: 16px;
  width: 16px;
  border: 2px solid #333;
  border-radius: 2px;
}

.main-checkbox.active::before {
  border: 2px solid #007ED9;
  background: url(../img/checked.svg) no-repeat center #007ED9;
  background-size: contain;
}

.main-checkbox__text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 0 12px;
  color: #2C2D2E;
}

.date-select {
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 8px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
}

.date-select:hover {
  cursor: pointer;
}

.date-select__icon {
  display: block;
  height: 24px;
  width: 24px;
  object-fit: contain;
  object-position: center;
  margin: 0 8px;
}

.date-range_wrap {
  margin: 24px 0;
}

input.date-range-input {
  border: 1px solid #F3F3F5;
  min-height: 46px;
  background-color: #F3F3F5;
  transition: border-color 0.2s ease-out, background-color 0.2s ease-out;
  border-radius: 8px;
  padding: 2px 8px;
  color: #333;
  font-size: 14px;
  line-height: 42px;
  width: 100%;
  grid-column: 1/3;
  white-space: break-spaces;
}

input.date-range-input:active, input.date-range-input:focus {
  border-color: rgba(0, 16, 61, 0.12);
  background-color: transparent;
  outline: none;
}

.date-select__close {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #919399;
}

.page__navigation {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  background: #F9F9FA;
  margin-bottom: 16px;
}

.page__navigation-item {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  background: #FFF;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  transition: border-color .2s ease-in-out, background .2s ease-in-out;
}

.page__navigation-item:hover {
  color: #2C2D2E;
  border-color: #007ED9;
  background: rgba(0, 126, 217, 0.15);
}

.maintenance {
  font-family: "Golos", sans-srif;
  color: #fff;
  height: 100vh;
  min-height: fit-content;
  width: 100%;
  background: linear-gradient(135deg, #007ED9 0%, #007FD9 6.67%, #0081D8 13.33%, #0085D8 20%, #008AD7 26.67%, #0090D6 33.33%, #0098D5 40%, #00A1D3 46.67%, #00AAD2 53.33%, #00B2D0 60%, #00BACF 66.67%, #00C1CE 73.33%, #00C6CD 80%, #00C9CC 86.67%, #00CBCC 93.33%, #0CC 100%);
}

.maintenance__container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  padding: 48px 48px 64px;
}

.maintenance__img {
  display: block;
  height: 32px;
  width: auto;
}

.maintenance__content {
  display: flex;
  flex-direction: column;
}

.maintenance__content h1, .maintenance__content .h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
  color: #fff;
  margin: 0;
}

.maintenance__content p {
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  margin: 0;
}

.maintenance__footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.maintenance__footer .cat {
  display: block;
  height: 96px;
  width: 96px;
}

.maintenance__footer .phone-wrap {
  display: flex;
  flex-direction: column;
}

.maintenance__footer .phone-title {
  color: #FFF;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.maintenance__footer .phone {
  color: #FFF;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

/* SLIDER */
.swiper {
  border-radius: 24px;
}

.swiper .slider-next-btn,
.swiper .slider-prev-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  height: 48px;
  width: 48px;
}

.swiper .slider-next-btn.swiper-button-disabled,
.swiper .slider-prev-btn.swiper-button-disabled {
  cursor: unset;
  pointer-events: none;
  opacity: 0.5;
}

.swiper .slider-next-btn:before,
.swiper .slider-prev-btn:before {
  content: '';
  display: block;
  height: 26px;
  width: 14px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='26' viewBox='0 0 14 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071L1.70711 25.7071C1.31658 26.0976 0.683416 26.0976 0.292892 25.7071C-0.0976326 25.3166 -0.0976325 24.6834 0.292892 24.2929L11.5858 13L0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976317 1.31658 -0.0976317 1.70711 0.292893L13.7071 12.2929Z' fill='white'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.swiper .slider-next-btn {
  right: 0;
}

.swiper .slider-prev-btn {
  left: 0;
}

.swiper .slider-prev-btn:before {
  transform: rotate(180deg);
}

.slider-block {
  height: 360px;
  background: linear-gradient(90deg, #0088CC 0%, #00CCCC 100%);
  position: relative;
}

.slider-block .slider {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr auto auto 1fr;
  grid-template-columns: auto auto;
}

.slider-block .slider__title {
  grid-row: 2/3;
  grid-column: 1/2;
  align-self: end;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #fff;
  font-family: "Golos", sans-srif;
  color: #fff;
  font-weight: 600;
  font-size: clamp(20px, calc(20px + (32 - 20) * ((100vw - 320px) / (1440 - 320))), 32px);
  line-height: clamp(28px, calc(28px + (40 - 28) * ((100vw - 320px) / (1440 - 320))), 40px);
  margin-bottom: 24px;
}

.slider-block .slider__button {
  display: inline-block;
  grid-row: 3/4;
  grid-column: 1/2;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding: 11px 31px;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.3s ease-out;
  width: fit-content;
}

.slider-block .slider__button:hover {
  background-color: #fff;
  color: #007ED9;
}

.slider-block .slider__img-block {
  grid-row: 1/5;
  grid-column: 2/3;
  height: 100%;
  width: 100%;
}

.slider-block .slider__img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: bottom center;
}

/* BANNER */
.banner-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 32px;
  padding: 13px 0;
  margin: 0 0 72px;
}

.banner {
  display: grid !important;
  grid-template-columns: auto 136px;
  grid-auto-rows: 2fr 3fr;
  height: 100%;
  align-items: center;
  min-height: 144px;
  background-color: #F3F3F5;
  border-radius: 12px;
  padding: 24px 32px;
}

.banner._single {
  grid-column: 1/3;
}

.banner__slide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 55% 45%;
  height: 100%;
}

.banner__more-btn {
  grid-column: 1/2;
  grid-row: 2/3;
  align-self: self-start;
}

.banner__text-block {
  align-self: center;
}

.banner__img-block {
  height: 100%;
  width: 100%;
  justify-self: center;
  grid-row: 1/3;
}

.banner__img {
  width: 100%;
  height: 100%;
  border-radius: 32px;
  object-fit: contain;
  object-position: center bottom;
}

.banner__square-img {
  grid-column: 2/3;
  grid-row: 1/3;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  margin-left: 16px;
  object-fit: contain;
  object-position: center;
}

.banner__title {
  grid-column: 1/2;
  grid-row: 1/2;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: clamp(28px, calc(28px + (32 - 28) * ((100vw - 320px) / (1440 - 320))), 32px);
  color: #2C2D2E;
  margin-bottom: 8px;
}

.banner__title {
  line-height: 28px;
}

.banner__caption {
  grid-column: 1/2;
  grid-row: 2/3;
  align-self: start;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
}

.flexslider.control-nav {
  padding: 0  !important;
  border: none !important;
  box-shadow: none  !important;
}

.flex-direction-nav a:before,
.flex-direction-nav a.flex-next:before {
  content: url("../img/slider-arrow.svg") !important;
}

.flex-direction-nav a.flex-prev:before {
  transform: rotate(180deg);
}

.swiper-pagination-bullet-active {
  background-color: #007ED9 !important;
}

.banner-block {
  display: block;
  width: 100%;
  height: 360px;
  padding: 16px 0 0;
  background: linear-gradient(90deg, #0088CC 0%, #00CCCC 100%);
}

.banner__more-btn {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: white;
  padding: 12px 32px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  width: fit-content;
}

.banner__more-btn:hover {
  color: white;
  text-decoration: none;
}

.swiper {
  border-radius: 0;
}

.banner__next-btn {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 64px;
  bottom: 48px;
}

.banner__next-btn:after {
  content: url("../img/arrow_right.svg");
  height: 32px;
  width: 32px;
  display: block;
}

.banner__next-btn.swiper-button-disabled {
  opacity: 40%;
}

.banner__prev-btn {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 104px;
  bottom: 48px;
}

.banner__prev-btn:after {
  transform: rotate(180deg);
  content: url("../img/arrow_right.svg");
  height: 32px;
  width: 32px;
  display: block;
}

.banner__prev-btn.swiper-button-disabled {
  opacity: 40%;
}

.slider-banner {
  position: relative;
  overflow: hidden;
  margin: 16px 0 48px;
}

.slider-banner__slide {
  display: block;
  border-radius: 24px;
  overflow: hidden;
}

.slider-banner__img {
  display: block;
  width: 100%;
}

.slider-banner .swiper-pagination-bullet {
  background-color: #ffffff !important;
  opacity: 0.5 !important;
}

.slider-banner .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ffffff !important;
  opacity: 1 !important;
}

@media screen and (max-width: 1366px) {
  .slider-banner {
    margin: 16px -16px 48px;
    padding: 0 16px;
  }
}

/* SUB-BANNER */
.sub-banner {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 32px;
  overflow-x: auto;
  margin-top: -48px;
  margin-right: -64px;
  margin-left: -64px;
  padding: 16px 64px 24px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sub-banner::-webkit-scrollbar {
  display: none;
}

.sub-banner__item {
  display: block;
  background: rgba(243, 243, 245, 0.5);
  backdrop-filter: blur(12px);
  border-radius: 12px;
  padding: 56px 24px;
  box-shadow: 0 8px 16px rgba(1, 1, 1, 0.1);
  z-index: 3;
  transition: 0.3s ease-out;
  min-width: 270px;
}

.sub-banner__item:hover {
  margin-top: -12px;
  margin-bottom: 12px;
  box-shadow: 0 14px 24px rgba(1, 1, 1, 0.1);
}

.subbanner-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #007ED9;
  margin-bottom: 8px;
}

.subbanner-subtitle {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
}

/* GALLERY */
.gallery__slide {
  display: grid;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #2C2D2E;
}

.gallery__slide .gallery__info {
  position: absolute;
  background-color: rgba(10, 10, 10, 0.66);
  padding: 16px 32px;
  bottom: 0;
  right: 0;
  left: 0;
}

.gallery__slide .gallery__info .gallery__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 0;
}

.gallery__slide .gallery__info .gallery__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0;
}

.gallery__slide .gallery__img {
  width: 100%;
  aspect-ratio: 3 / 2;
  object-fit: cover;
}

.gallery__prev-btn,
.gallery__next-btn {
  display: grid;
  place-items: center;
  position: absolute;
  height: 100%;
  width: clamp(42px, 20%, 400px);
  top: 0;
  z-index: 10;
  transition: 0.2s ease-out;
}

.gallery__prev-btn::after,
.gallery__next-btn::after {
  content: '';
  display: block;
  height: 32px;
  width: 32px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(-135deg);
  filter: drop-shadow(0 0 1px #2C2D2E);
  opacity: 0.7;
}

.gallery__prev-btn.swiper-button-disabled,
.gallery__next-btn.swiper-button-disabled {
  pointer-events: none;
  opacity: 0;
}

.gallery__next-btn {
  right: 0;
}

.gallery__next-btn::after {
  transform: rotate(45deg);
}

.market-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFF;
  border-radius: 24px;
  padding: 24px 48px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

.market-banner-wrap {
  margin: 72px 0;
}

.market-banner__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin: 0;
}

.market-banner__title-wrap {
  display: flex;
  flex-direction: column;
}

.market-banner__caption {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #919399;
  margin: 8px 0 0;
}

.market-banner__link {
  display: block;
  height: 100%;
}

.market-banner__link._green-shadow {
  transition: filter 0.3s ease-out;
  filter: drop-shadow(0px 0px 0px rgba(4, 224, 97, 0));
}

.market-banner__link._green-shadow:hover {
  filter: drop-shadow(0px 0px 24px rgba(4, 224, 97, 0.7));
}

.market-banner__link._blue-shadow {
  transition: filter 0.3s ease-out;
  filter: drop-shadow(0px 0px 0px rgba(4, 104, 255, 0));
}

.market-banner__link._blue-shadow:hover {
  filter: drop-shadow(0px 0px 24px rgba(4, 104, 255, 0.7));
}

.market-banner__img {
  height: 100%;
  width: auto;
}

.market-banner__img-wrap {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 32px;
  padding: 0 0 0 32px;
}

.market-banner__img-separator {
  display: block;
  height: 100%;
  width: 1px;
  background-color: rgba(0, 16, 61, 0.12);
}

.square .swiper-slide {
  width: 80%;
}

.square .swiper-slide img {
  width: 100%;
  height: 100%;
}

/* EVENTS */
.event-card_list {
  display: flex;
  padding: 0 0 32px;
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
}

.event-card_side {
  display: flex;
  padding: 12px 0;
  color: #2C2D2E;
}

.event-card_side:hover {
  color: #2C2D2E;
}

.event-card__date_list {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2C2D2E;
  color: #919399;
  margin-bottom: 8px;
}

.event-card__date_side {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #919399;
  margin-bottom: 8px;
}

.event-card__title_side {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: calc(16px + 4 * ((100vw - 320px)/ 1600));
  line-height: calc(24px + 8 * ((100vw - 320px)/ 1600));
  color: #2C2D2E;
  margin-bottom: 0;
}

.event-card__title_list {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
}

.event-card__img-container_list {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 54%;
}

.event-card__img_list {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-position: center;
  object-fit: cover;
}

.event-card_block {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: 0.2s ease-out;
}

.event-card_block:hover .event-card__more_block:after {
  background: url("../img/dots-horizontal-bl.svg") center right no-repeat;
}

.event-card_block:hover .event-card__title_block {
  color: #007ED9;
}

.event-card_block:hover .event-card__title_block:after {
  background: url("../img/arrow-up-right-blue.svg") center right no-repeat;
}

.event-card_block:hover .event-card__img_block {
  border-radius: 0;
}

.event-card__img-container_block {
  display: block;
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 55.8%;
}

.event-card__img_block {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  border-radius: 24px;
  transition: border-radius 0.3s;
}

.event-card__body_block {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 12px;
}

.event-card__title_block {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #2C2D2E;
  margin-bottom: 4px;
  margin-top: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: flex;
  justify-content: space-between;
  transition: color 0.3s;
}

.event-card__title_block:after {
  content: '';
  background: url("../img/arrow-up-right.svg") center right no-repeat;
  display: block;
  background-size: 24px 28px;
  width: 24px;
  height: 28px;
  flex: none;
  margin-left: 4px;
  transition: background 0.3s;
}

.event-card__subtitle_block {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #919399;
  margin-bottom: 16px;
  margin-top: 0;
}

.event-card__date_block {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 0;
  padding: 0 8px;
  border-radius: 8px;
  background: #F3F3F5;
}

.event-card__more_block {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 0;
  display: flex;
}

.event-card__more_block:after {
  content: '';
  background: url("../img/dots-horizontal.svg") center right no-repeat;
  display: block;
  background-size: 24px;
  width: 24px;
  height: 24px;
  flex: none;
  margin-left: 4px;
  transition: background 0.3s;
}

.preview-event-card_block {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.preview-event-card__img-container_block {
  display: block;
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 58%;
}

.preview-event-card__img-container_block._hide-anons {
  display: none;
}

.preview-event-card__img_block {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.preview-event-card__body_block {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 24px;
}

.preview-event-card__title_block,
.preview-event-card__title_list {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 8px;
}

.preview-event-card__date_block {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #919399;
  margin-bottom: 0;
}

.preview-event-card_list {
  display: block;
  margin-bottom: 32px;
}

.preview-event-card__date_list {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #919399;
}

.sub-events__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #2C2D2E;
}

.sub-event {
  margin: 32px 0;
}

.sub-event__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 8px;
}

.sub-event__date {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #919399;
}

.event-card__body_block.side {
  background-color: #F9F9FA;
  padding: 24px 32px 32px;
  margin-top: 0;
}

.top-news {
  margin-bottom: 16px;
}

.top-news .right-col .news-item:first-child {
  display: none;
}

.more-news.side_wraper {
  display: block;
  width: 100%;
  padding: 32px;
  background: #F9F9FA;
  border-radius: 8px;
}

.more-news__title {
  display: block;
  width: 100%;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 16px;
}

.more-news__item {
  display: block;
  width: 100%;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 16px;
}

.more-news__item:last-child {
  margin-bottom: 0;
}

.more-news__item.active,
.more-news__item:hover {
  text-decoration: underline;
}

.event-card_block.side {
  height: unset;
  margin: 16px 0;
}

.organisation {
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  transition: 0.3s ease-out;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 12px;
}

.organisation:hover {
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.15);
}

.organisation.side {
  height: fit-content;
}

.organisation__img {
  width: 128px;
  height: 128px;
  overflow: hidden;
  object-position: center;
  object-fit: contain;
  margin-bottom: 12px;
}

.organisation__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 12px;
}

.organisation__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
  margin-bottom: 12px;
}

.organisation__item {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 12px;
}

.organisation__url {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #005BD1;
  margin-bottom: 0;
}

a.service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  min-height: 100%;
  background-color: #fff;
  transition: .2s ease-out;
}

a.service > div {
  padding: clamp(16px, calc(16px + (32 - 16) * ((100vw - 320px) / (1440 - 320))), 32px);
  width: 100%;
}

a.service .service__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: clamp(18px, calc(18px + (24 - 18) * ((100vw - 320px) / (1440 - 320))), 24px);
  line-height: clamp(24px, calc(24px + (32 - 24) * ((100vw - 320px) / (1440 - 320))), 32px);
  color: #2C2D2E;
  margin-bottom: 8px;
}

a.service .service__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2C2D2E;
  margin-bottom: 8px;
}

a.service .service__organisation {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #007ED9;
  margin-bottom: 8px;
}

a.service .service__img {
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
  flex: none;
}

a.service._list {
  flex-direction: row;
}

a.service._list .service__img {
  width: clamp(100px, 30%, 300px);
  padding: 24px;
  object-fit: contain;
}

a.service:hover {
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.15);
}

.service-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr auto;
  grid-gap: 12px 32px;
  max-width: 1440px;
  padding: 0 64px;
  margin: 0 auto;
  flex-grow: 1;
}

.service-container__swiper {
  grid-row: 1/3;
}

.service-container .service-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin: 0;
}

.service-container .service-area {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
  background: #f3f3f5;
  padding: 0 12px;
  border-radius: 16px;
  width: fit-content;
  height: fit-content;
  margin-top: 12px;
}

.service-container .service-subtitle {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
}

.service-container .service-kadastr {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #919399;
  margin-bottom: 12px;
}

.service-container .service-kadastr._mob {
  display: none;
}

.service-container .service-description + p {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 24px;
}

.service-container .service-price {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  color: #2C2D2E;
  margin-bottom: 24px;
}

.service-container .modal-btn {
  padding: 16px 32px;
  color: white;
  background-color: #007ED9;
  border: unset;
}

.service-container .modal-btn:hover {
  background-color: #005999;
}

.service-container .sale-card__slider {
  aspect-ratio: 640/428;
}

.service-container .sale-card__slider .bik-market-swiper {
  border-radius: 12px;
}

.service-container p {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
}

.sale__price {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: clamp(18px, calc(18px + (24 - 18) * ((100vw - 320px) / (1440 - 320))), 24px);
  line-height: clamp(24px, calc(24px + (32 - 24) * ((100vw - 320px) / (1440 - 320))), 32px);
  color: #007ED9;
  margin-bottom: 8px;
}

.sale__info {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  padding-right: clamp(16px, calc(16px + (32 - 16) * ((100vw - 320px) / (1440 - 320))), 32px);
  padding-left: clamp(16px, calc(16px + (32 - 16) * ((100vw - 320px) / (1440 - 320))), 32px);
  padding-top: clamp(8px, calc(8px + (16 - 8) * ((100vw - 320px) / (1440 - 320))), 16px);
  padding-bottom: clamp(16px, calc(16px + (32 - 16) * ((100vw - 320px) / (1440 - 320))), 32px);
  height: 100%;
}

.sale__type {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2C2D2E;
  margin-bottom: 12px;
}

.sale__description p {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2C2D2E;
}

.sale__region {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 4px;
}

.sale__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: clamp(18px, calc(18px + (24 - 18) * ((100vw - 320px) / (1440 - 320))), 24px);
  line-height: clamp(24px, calc(24px + (32 - 24) * ((100vw - 320px) / (1440 - 320))), 32px);
  color: #2C2D2E;
  margin-bottom: 8px;
}

.sale__buy {
  justify-self: right;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #007ED9;
  display: inline-block;
  padding: 12px 24px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  border: 2px solid #007ED9;
  padding: 8px 20px;
}

.sale__buy:hover {
  background-color: #007ED9;
  color: #fff !important;
}

.sale__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(12px, calc(12px + (16 - 12) * ((100vw - 320px) / (1440 - 320))), 16px);
  line-height: clamp(18px, calc(18px + (24 - 18) * ((100vw - 320px) / (1440 - 320))), 24px);
  color: #2C2D2E;
}

.sale__organisation {
  align-self: center;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #007ED9;
  margin-bottom: 0;
}

.sale__img {
  display: block;
  width: 100%;
  aspect-ratio: 2/1;
  object-fit: cover;
  object-position: bottom;
  flex: none;
  height: 100%;
}

a.sale {
  display: grid;
  overflow: hidden;
  background-color: #fff;
  transition: .2s ease-out;
  grid-template-rows: min-content auto min-content;
  aspect-ratio: 416/520;
}

a.sale._list {
  grid-auto-flow: column;
  grid-template-columns: 100%;
  cursor: pointer;
  height: 100%;
  padding-bottom: 32px;
}

a.sale._list .sale__area {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  background: #F3F3F5;
  padding: 0 8px;
  border-radius: 16px;
  width: fit-content;
  height: fit-content;
}

a.sale._list .sale__img {
  aspect-ratio: 11/6;
}

a.sale._list .sale__kadastr {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #919399;
  margin-bottom: 4px;
}

a.sale._list .sale__description {
  margin-bottom: 19px;
}

a.sale._list .sale__price {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 0;
}

a.sale._list .sale-card__separator {
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(0, 16, 61, 0.12);
  margin-bottom: 20px;
}

a.sale._list .sale__title {
  margin: 4px 0;
}

a.sale._block .sale__info {
  grid-template-rows: min-content min-content auto min-content;
}

a.sale._block .sale__title {
  grid-column: 1/3;
  grid-row: 1/2;
}

a.sale._block .sale__price {
  grid-column: 1/3;
  grid-row: 2/3;
  justify-self: left;
}

a.sale._block .sale__description {
  grid-row: 3/4;
}

a.sale._block .sale__organisation {
  grid-row: 4/5;
  grid-column: 1/2;
}

a.sale._block .sale__buy {
  grid-row: 4/5;
  grid-column: 2/3;
}

.switcher-checkbox {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.switcher-text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2C2D2E;
}

.switch {
  display: inline-block;
  height: 16px;
  position: relative;
  width: 28px;
  margin-right: 6px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #ECEDF0;
  border: 1px solid #DADCE0;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  content: "";
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  position: absolute;
  left: 2px;
  bottom: 50%;
  transition: .4s;
  height: 12px;
  width: 12px;
  transform: translateY(50%);
}

input:checked + .slider {
  background-color: #007ED9;
}

input:checked + .slider:before {
  transform: translateX(10px) translateY(50%);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.filter-block._market .rubric__header {
  display: none;
}

.filter-block._market .rubric__container {
  display: flex;
  flex-wrap: wrap;
}

.filter-block._market .rubric__container .main-checkbox {
  background: none;
  margin: 0 24px 0 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.filter-block._market .rubric__container .main-checkbox.active {
  background: none;
}

.filter-block._market .rubric__container .main-checkbox.active .main-checkbox__text {
  color: #2C2D2E;
  margin: 0;
}

.filter-block._market .rubric__container .main-checkbox.active:before {
  background-color: #00CCCC;
  border: none;
  background-size: 12px;
  border-radius: 4px;
}

.filter-block._market .rubric__container .main-checkbox:hover {
  background: none;
}

.filter-block._market .rubric__container .main-checkbox:before {
  border: 2px solid #BFC1C7;
  border-radius: 4px;
  border-radius: 4px;
  margin: 0 6px 0 0;
}

.filter-block._market .rubric__container .main-checkbox__text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 0;
}

.bik-market-banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #E6E7EB;
  border-radius: 24px;
  aspect-ratio: 82/17;
  width: 100%;
  margin-bottom: 32px;
  box-shadow: none;
  overflow: hidden;
}

.bik-market-banner__title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 32px 12px 32px 32px;
}

.bik-market-banner__img {
  background: url("../img/market-banner-new.png") #E6E7EB right no-repeat;
  background-size: cover;
}

.bik-market-banner .market-logo,
.bik-market-banner .market-logo__white {
  width: 100%;
  height: auto;
  max-width: 528px;
}

.bik-market-banner .market-logo__white {
  display: none;
}

.bik-market-banner__text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  max-width: 720px;
}

.bik-market__header-filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
}

.bik-market__header-filter .category-page__filter {
  display: flex;
  align-items: center;
}

.bik-market__header-filter .modal-btn {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: white;
  padding: 8px 16px;
  background: #0073E5;
  border-radius: 8px;
  border: none;
  height: fit-content;
}

.bik-market__header-filter .modal-btn:hover {
  background: #005999;
}

.sale-card__slider {
  border-radius: 24px;
  position: relative;
}

.sale-card__slider:hover .swiper-pagination {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  transition: background 0.3s;
}

.sale-card__slider:hover .swiper-pagination .swiper-pagination-bullet,
.sale-card__slider:hover .swiper-pagination .swiper-pagination-bullet-active {
  display: block;
}

.sale-card__slider:hover .swiper-pagination.swiper-pagination-lock {
  background: transparent;
}

.sale-card__slider .swiper-pagination {
  z-index: 20;
  bottom: 0 !important;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 12px 16px;
  transition: background 0.3s;
}

.sale-card__slider .swiper-pagination .swiper-pagination-bullet {
  display: none;
  background: rgba(255, 255, 255, 0.5);
  opacity: unset;
}

.sale-card__slider .swiper-pagination .swiper-pagination-bullet-active {
  display: none;
  background: white !important;
  opacity: unset;
}

.sale-card__slider .hover-pagination-wrap {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.sale-card__slider .hover-pagination-wrap div {
  width: 100%;
  height: 100%;
}

.sale-card__slider .rubrics_wrap {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
}

.sale-card__slider .rubrics_wrap .sale__rubrics {
  margin-right: 4px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  padding: 0 8px;
  border-radius: 12px;
  width: fit-content;
  background-color: white;
  margin-bottom: 4px;
}

.sale-card__slider .rubrics_wrap .sale__rubrics-bik {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: white;
  background-color: #007ed9;
  padding: 0 8px;
  border-radius: 12px;
  width: fit-content;
  margin-right: 4px;
  margin-bottom: 4px;
  background-blend-mode: multiply;
}

.sale-card__price {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.bik-market-swiper {
  height: 100%;
  border-radius: 24px;
}

.bik-market-swiper .swiper-slide {
  width: 100% !important;
}

.sale-card__hidden-content {
  border-top: 1px solid rgba(0, 16, 61, 0.12);
  padding-top: 12px;
}

/* DOCUMENTS */
.document {
  display: flex !important;
  align-items: flex-start;
  padding: 12px 0;
}

.document.single {
  margin-bottom: clamp(8px, calc(8px + (12 - 8) * ((100vw - 320px) / (1440 - 320))), 12px);
}

.document__art {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
  margin-bottom: 0;
}

.document._list .document__title,
.document._block .document__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(14px, calc(14px + (16 - 14) * ((100vw - 320px) / (1440 - 320))), 16px);
  line-height: clamp(16px, calc(16px + (24 - 16) * ((100vw - 320px) / (1440 - 320))), 24px);
  color: #2C2D2E;
  margin-bottom: 4px;
}

.document._list .document__description,
.document._block .document__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(12px, calc(12px + (14 - 12) * ((100vw - 320px) / (1440 - 320))), 14px);
  line-height: clamp(14px, calc(14px + (18 - 14) * ((100vw - 320px) / (1440 - 320))), 18px);
  color: #919399;
  margin-bottom: 4px;
}

.document._list .document__info,
.document._block .document__info {
  display: flex;
  align-items: center;
}

.document._list .document__type,
.document._block .document__type {
  display: grid;
  place-items: center;
  position: relative;
  height: 40px;
  width: 40px;
  background: url("../img/doc-icon.svg") center no-repeat;
  margin-right: 16px;
}

.document._list .document__type span,
.document._block .document__type span {
  display: block;
  height: 16px;
  width: 40px;
  background-color: #007ED9;
  border-radius: 2px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.document._list .document__open,
.document._block .document__open {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #007ED9;
  margin-bottom: 0;
}

.document__size, .document__date {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
  margin-bottom: 0;
}

.document._block {
  padding: 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: 0.2s ease-out;
}

.document._block .document__title_block {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 20px;
  flex-grow: 1;
  height: 118px;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.document._block .document__size {
  margin-left: 8px;
}

.document._list .document__date {
  margin-left: 12px;
}

.document._block .document__info {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 16, 61, 0.12);
  padding-top: 20px;
}

.document._block .document__date {
  margin-bottom: 12px;
}

.document._list.single .document__title {
  font-size: clamp(18px, calc(18px + (20 - 18) * ((100vw - 320px) / (1440 - 320))), 20px);
  font-size: clamp(18px, calc(18px + (20 - 18) * ((100vw - 320px) / (1440 - 320))), 20px);
}

.document._block.side {
  height: unset;
  margin: 16px 0;
}

/* LEADER */
.structure__title {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 40px 0 0 8px;
}

.contact-leader {
  display: grid;
  grid-auto-columns: 200px 1fr;
  background-color: #F9F9FA;
  border: none;
  border-radius: 8px;
  min-height: 304px;
  overflow: hidden;
}

.contact-leader__img {
  object-fit: cover;
  overflow: hidden;
  height: 100%;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 2;
}

.contact-leader__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  grid-column-start: 2;
  grid-column-end: 3;
}

.contact-leader__name {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 12px;
}

.contact-leader__position {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #2C2D2E;
  margin-bottom: 16px;
}

.card-leader {
  padding: 32px;
  background-color: #F9F9FA;
}

.card-leader__head {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
}

.card-leader__img {
  flex: none;
  height: 128px;
  width: 128px;
  border-radius: 64px;
  object-fit: cover;
}

.card-leader__name {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 8px;
}

.card-leader__position {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #919399;
  margin-bottom: 0;
}

.card-leader__contact-item {
  display: flex;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 24px;
}

.card-leader__contact-item:last-child {
  margin-bottom: 0;
}

.card-leader__icon {
  display: block;
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.leader_search,
.leader {
  display: grid;
  width: 100%;
  max-width: 650px;
  grid-template-columns: clamp(68px, calc(68px + (128 - 68) * ((100vw - 320px) / (1920 - 320))), 128px) auto;
  grid-auto-rows: auto;
  grid-column-gap: 32px;
  position: relative;
  padding: 24px 0;
  transition: 0.2s ease-out;
}

.leader_search._sub,
.leader._sub {
  grid-column: 1 / 3;
}

.leader_search_parent,
.leader_parent {
  background: #F3F3F5;
  position: relative;
}

.leader_search.leader_lv-2::after,
.leader.leader_lv-2::after {
  content: '';
  display: block;
  position: absolute;
  border-left: 2px dotted #d9d9de;
  height: calc(100% - 26px - clamp(68px,calc(68px + (128 - 68) * ((100vw - 320px)/ (1920 - 320))),128px));
  width: 1px;
  left: clamp(32px, calc(32px + (64 - 32) * ((100vw - 320px)/ (1920 - 320))), 64px);
  bottom: 2px;
}

.leader_search {
  padding: 32px 0;
  border: none;
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
}

.leader__arrow_parent {
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
  transition: 0.2s ease-out;
}

.leader__arrow_parent:hover {
  cursor: pointer;
}

.active > .leader__arrow_parent,
.active > .leader_parent > .leader__arrow_parent {
  transform: rotate(180deg);
}

.leader__img {
  flex: none;
  width: clamp(68px, calc(68px + (128 - 68) * ((100vw - 320px) / (1440 - 320))), 128px);
  height: clamp(68px, calc(68px + (128 - 68) * ((100vw - 320px) / (1440 - 320))), 128px);
  border-radius: clamp(34px, calc(34px + (64 - 34) * ((100vw - 320px) / (1440 - 320))), 64px);
  object-fit: cover;
}

.sub-units-wrap .leader__head {
  border-bottom: none;
  padding-bottom: 16px;
}

.sub-units-wrap .leader__body {
  padding-top: 0;
}

.leader__position {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #919399;
  margin-bottom: 0;
}

.leader__name {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 0;
}

.leader__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.card-leader__body,
.leader__body {
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  padding-top: 16px;
}

.leader__contact-item {
  display: flex;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
}

.leader__icon {
  display: block;
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.sub-units-wrap {
  position: relative;
  height: 0px;
  padding-left: clamp(68px, calc(68px + (128 - 68) * ((100vw - 320px) / (1440 - 320))), 128px);
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  overflow: hidden;
  padding-top: 1px;
}

.sub-units-wrap::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: clamp(32px, calc(32px + (64 - 32) * ((100vw - 320px) / (1440 - 320))), 64px);
  padding-left: clamp(34px, calc(34px + (64 - 34) * ((100vw - 320px) / (1440 - 320))), 64px);
  height: 100%;
  width: 0;
  border-left: 2px dotted rgba(0, 16, 61, 0.12);
}

.sub-units-wrap_lv-4 {
  height: 0px;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
}

.leader_lv-4 {
  padding: 32px;
  display: flex;
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
}

.leader_lv-4:last-child {
  border-bottom: none;
}

.sub-units-wrap.active {
  height: auto;
  overflow: visible;
  opacity: 1;
  visibility: visible;
}

.active > .sub-units-wrap_lv-4 {
  height: auto;
  overflow: visible;
  opacity: 1;
  visibility: visible;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-top: none;
}

.leader_lv-3 {
  position: relative;
}

.leader__main_lv-3 {
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 8px;
  padding: 32px;
  display: flex;
}

.active > .leader__main_lv-3 {
  border-bottom: none;
  border-radius: 8px 8px 0 0;
}

.sub-units-wrap > .leader::before {
  content: '';
  display: block;
  position: absolute;
  border-top: 2px dotted #D9D9DE;
  height: 1px;
  width: clamp(32px, calc(32px + (64 - 32) * ((100vw - 320px) / (1440 - 320))), 64px);
  left: calc(clamp(32px, calc(36px + (64 - 36) * ((100vw - 320px)/ (1920 - 320))), 64px) * -1);
  top: clamp(57px, calc(57px + (89 - 57) * ((100vw - 320px) / (1440 - 320))), 89px);
}

.sub-units-wrap > .leader:last-child::after {
  content: '';
  display: block;
  position: absolute;
  background-color: #F9F9FA;
  left: calc(clamp(36px, calc(36px + (64 - 36) * ((100vw - 320px)/ (1920 - 320))), 64px) * -1);
  height: calc(100% - clamp(58px, calc(32px + (90 - 58) * ((100vw - 320px)/ (1920 - 320))), 90px));
  width: 4px;
  top: clamp(58px, calc(58px + (90 - 58) * ((100vw - 320px) / (1440 - 320))), 90px);
}

.leader-page__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  color: #2C2D2E;
  margin-bottom: 8px;
}

.leader-page__lead {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 48px;
  color: #2C2D2E;
  margin-bottom: 24px;
}

.leader-page__content-label {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 4px;
  margin-top: 24px;
}

.leader-page__content-item {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 24px;
}

.leader-page__content-url {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  text-decoration: underline;
  margin-bottom: 24px;
  transition: .2s;
}

.leader-page__content-url:hover {
  color: #007ED9;
  text-decoration: underline;
}

.leader__child-wrap {
  grid-column: 1/3;
  padding-left: clamp(12px, calc(12px + (124 - 12) * ((100vw - 320px) / (1440 - 320))), 124px);
}

.leader._small {
  padding: 12px 0;
  grid-template-columns: clamp(48px, calc(48px + (80 - 48) * ((100vw - 320px) / (1920 - 320))), 80px) auto;
  grid-gap: clamp(12px, calc(12px + (24 - 12) * ((100vw - 320px) / (1440 - 320))), 24px);
}

.leader._small .leader__img {
  width: clamp(48px, calc(48px + (80 - 48) * ((100vw - 320px) / (1440 - 320))), 80px);
  height: clamp(48px, calc(48px + (80 - 48) * ((100vw - 320px) / (1440 - 320))), 80px);
}

.leader._small .leader__head {
  padding-bottom: 8px;
}

.leader._small .leader__position {
  font-size: 14px;
  line-height: 20px;
}

.leader._small .leader__name {
  font-size: 18px;
  line-height: 24px;
}

.leader._small::after {
  content: '';
  display: block;
  position: absolute;
  border-left: 2px dotted #d9d9de;
  height: calc(100% - clamp(48px,calc(48px + (80 - 48) * ((100vw - 320px)/ (1920 - 320))),80px));
  width: 1px;
  left: clamp(24px, calc(24px + (40 - 24) * ((100vw - 320px) / (1440 - 320))), 40px);
  bottom: -12px;
}

.leader._small:last-child::after {
  display: none;
}

.orgunit_container-over-3 {
  padding-left: 0;
}

.orgunit_container-over-3 > a.collapse-arrow {
  display: block;
  position: relative;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 12px;
  padding: 12px 48px 12px 24px;
  margin-top: 12px;
}

.orgunit_container-over-3 > a.collapse-arrow::after {
  content: '';
  display: block;
  position: absolute;
  top: 18px;
  right: 16px;
  height: 10px;
  width: 10px;
  border-right: 1px solid #919399;
  border-bottom: 1px solid #919399;
  transform: rotate(-135deg) translate(-2px, -2px);
  transition: .2s;
  transform-origin: 5px;
}

.orgunit_container-over-3 > a.collapse-arrow.collapsed::after {
  transform: rotate(45deg) translate(-2px, -2px);
  bottom: 0;
}

.orgunit_container-over-3 .leader__child-wrap {
  padding-left: clamp(4px, calc(4px + (32 - 4) * ((100vw - 320px)/ (1920 - 320))), 32px);
}

.orgunit_container-over-3 .card-leader__body,
.orgunit_container-over-3 .leader__body {
  grid-template-columns: 100%;
}

.orgunit_container-over-3 .card-leader__body .leader__contact-item span,
.orgunit_container-over-3 .leader__body .leader__contact-item span {
  font-size: 14px;
  line-height: 20px;
}

.orgunit_container-over-3 .card-leader__body .leader__contact-item .leader__icon,
.orgunit_container-over-3 .leader__body .leader__contact-item .leader__icon {
  height: 20px;
  width: 20px;
}

.orgunit_container-over-3 .leader._small {
  grid-template-columns: clamp(48px, calc(48px + (64 - 48) * ((100vw - 320px)/ (1920 - 320))), 64px) auto;
}

.orgunit_container-over-3 .leader._small::after {
  height: calc(100% - clamp(48px,calc(48px + (64 - 48) * ((100vw - 320px)/ (1920 - 320))),64px));
  left: clamp(24px, calc(24px + (32 - 24) * ((100vw - 320px)/ (1920 - 320))), 32px);
}

.orgunit_container-over-3 .leader._small .leader__img {
  width: clamp(48px, calc(48px + (64 - 48) * ((100vw - 320px)/ (1920 - 320))), 64px);
  height: clamp(48px, calc(48px + (64 - 48) * ((100vw - 320px)/ (1920 - 320))), 64px);
}

.orgunit_container-over-3 .leader._small .leader__position {
  font-size: 12px;
  line-height: 16px;
}

.orgunit_container-over-3 .leader._small .leader__name {
  font-size: 16px;
  line-height: 20px;
}

/* USEFUL LINKS */
.u-links {
  height: 100%;
  position: relative;
  margin: 0 -16px;
}

.u-links__item {
  display: block;
  width: 100%;
  height: 100%;
  transition: .2s;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.u-links__item:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.u-links__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  -webkit-line-clamp: 3;
  /* Число отображаемых строк */
  display: -webkit-box;
  /* Включаем флексбоксы */
  -webkit-box-orient: vertical;
  /* Вертикальная ориентация */
  overflow: hidden;
}

.u-links__link {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
}

.u-links__img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.usefulLinksSwiper.swiper {
  padding: 48px 16px;
}

.usefulLinksSwiper .swiper-slide {
  aspect-ratio: 304 / 96;
}

.u-swiper-nav {
  display: block;
  position: absolute;
  top: 50%;
  height: 32px;
  width: 32px;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M8 20L16 12L8 4' stroke='%232C2D2E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.2;
  transition: opacity 0.2s ease-out;
}

.u-swiper-nav-next {
  right: -72px;
}

.u-swiper-nav-prev {
  left: -72px;
  transform: translateY(-50%) rotate(180deg);
}

.u-swiper-nav:hover {
  opacity: 0.5;
}

.u-swiper-nav.swiper-button-disabled {
  pointer-events: none;
  opacity: 0;
}

@media screen and (max-width: 1520px) {
  .u-swiper-nav-next {
    right: -16px;
  }
  .u-swiper-nav-prev {
    left: -16px;
  }
}

@media screen and (max-width: 1023px) {
  .u-swiper-nav-next {
    display: none;
  }
  .u-swiper-nav-prev {
    display: none;
  }
}

.steps-wrap {
  padding-top: clamp(16px, calc(16px + (32 - 16) * ((100vw - 320px) / (1440 - 320))), 32px);
  padding-bottom: clamp(16px, calc(16px + (32 - 16) * ((100vw - 320px) / (1440 - 320))), 32px);
}

.steps-wrap .step {
  display: grid;
  grid-template-columns: clamp(48px, calc(48px + (80 - 48) * ((100vw - 320px)/ (1920 - 320))), 80px) auto;
  align-items: center;
  padding: 12px 0;
}

.steps-wrap .step__position {
  display: grid;
  place-items: center;
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  height: clamp(48px, calc(48px + (80 - 48) * ((100vw - 320px) / (1440 - 320))), 80px);
  width: clamp(48px, calc(48px + (80 - 48) * ((100vw - 320px) / (1440 - 320))), 80px);
  border-radius: clamp(8px, calc(8px + (12 - 8) * ((100vw - 320px) / (1440 - 320))), 12px);
  font-family: "Golos", sans-srif;
  font-weight: 600;
  color: #fff;
  font-size: clamp(16px, calc(16px + (32 - 16) * ((100vw - 320px) / (1440 - 320))), 32px);
  line-height: clamp(16px, calc(16px + (32 - 16) * ((100vw - 320px) / (1440 - 320))), 32px);
}

.steps-wrap .step__card {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(8px);
  margin-right: 0;
  margin-left: clamp(-20px, calc(-12px - (20 - 12) * ((100vw - 320px)/ (1920 - 320))), -12px);
  border-radius: clamp(8px, calc(8px + (12 - 8) * ((100vw - 320px) / (1440 - 320))), 12px);
  padding: clamp(12px, calc(12px + (24 - 12) * ((100vw - 320px) / (1440 - 320))), 24px) clamp(16px, calc(16px + (32 - 16) * ((100vw - 320px) / (1920 - 320))), 32px);
}

.steps-wrap .step__title {
  font-family: "Golos", sans-srif;
  font-weight: 600;
  font-size: clamp(16px, calc(16px + (20 - 16) * ((100vw - 320px) / (1440 - 320))), 20px);
  line-height: clamp(16px, calc(16px + (32 - 16) * ((100vw - 320px) / (1440 - 320))), 32px);
  color: #2C2D2E;
  margin-bottom: 0;
}

.steps-wrap .step__text p, .steps-wrap .step__text li, .steps-wrap .step__text span, .steps-wrap .step__text blockquote {
  font-family: "Golos", sans-srif;
  font-weight: 400;
  font-size: clamp(12px, calc(12px + (16 - 12) * ((100vw - 320px) / (1440 - 320))), 16px);
  line-height: clamp(14px, calc(14px + (24 - 14) * ((100vw - 320px) / (1440 - 320))), 24px);
  color: #2C2D2E;
  margin-bottom: 8px;
}

.steps-wrap .step__text p:last-child, .steps-wrap .step__text li:last-child, .steps-wrap .step__text span:last-child, .steps-wrap .step__text blockquote:last-child {
  margin-bottom: 0;
}

.table {
  margin-bottom: 56px;
}

.table-wrap {
  overflow-x: auto;
}

.table-wrap::-webkit-scrollbar {
  width: 0 !important;
  display: none !important;
}

.table::-webkit-scrollbar {
  display: none !important;
}

.table > :not(:last-child) > :last-child > * {
  white-space: nowrap !important;
  color: #007ED9;
}

.table > :not(caption) > * > * {
  padding: 12px 24px;
}

td:nth-child(2) {
  text-align: center;
}

td:nth-child(3) {
  text-align: center;
}

td:nth-child(4) {
  text-align: end;
}

th:nth-child(2) {
  text-align: center;
}

th:nth-child(3) {
  text-align: center;
}

th:nth-child(4) {
  text-align: end;
}

.page table {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  border-collapse: collapse;
  border: 1px solid rgba(0, 16, 61, 0.12);
}

.page table thead th {
  text-align: center;
  border: 1px solid rgba(0, 16, 61, 0.12);
}

.page table td {
  border: 1px solid rgba(0, 16, 61, 0.12);
}

.page table a {
  display: block;
  text-decoration: none;
  color: #007ED9;
  font-weight: 600;
  margin-bottom: 8px;
}

.page table a:hover {
  text-decoration: underline;
}

/* FORMS */
textarea,
input[type="text"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="search"],
input[type="password"] {
  -webkit-border-style: solid;
  -moz-border-style: solid;
  border-style: solid;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

textarea:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="search"]:focus,
input[type="password"]:focus {
  outline: none;
}

select:focus {
  outline: none;
}

.form-container {
  display: block;
  margin: 48px 0;
  width: 100%;
}

.text a.main-link,
.main-link {
  display: flex;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  padding: 8px 0;
  width: fit-content;
}

.text a.main-link:hover,
.main-link:hover {
  color: #007ED9;
}

.text a.main-link::before,
.main-link::before {
  content: url("../img/Link.svg");
  display: block;
  height: 40px;
  width: 40px;
  flex: none;
  margin: -6px 16px 0 0;
}

.main-btn_outline {
  display: inline-block;
  padding: 11px 39px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  background-color: transparent;
  color: #007ED9;
  border: 1px solid #007ED9;
  border-radius: 4px;
  text-align: center;
  transition: .2s;
}

.main-btn_outline:hover {
  color: #fff;
  background-color: #007ED9;
}

.text a.main-close-btn,
a.main-close-btn {
  display: grid;
  place-items: center;
  position: absolute;
  top: 24px;
  right: 24px;
  height: 24px;
  width: 24px;
}

.text a.main-close-btn span,
a.main-close-btn span {
  position: relative;
  height: 1.5px;
  width: 20px;
}

.text a.main-close-btn span::before,
.text a.main-close-btn span::after,
a.main-close-btn span::before,
a.main-close-btn span::after {
  content: '';
  position: absolute;
  top: 0;
  transform-origin: center;
  display: block;
  height: 2px;
  width: 20px;
  background-color: #BFC1C7;
  transition: 0.2s;
}

.text a.main-close-btn span::before,
a.main-close-btn span::before {
  transform: rotate(45deg);
}

.text a.main-close-btn span::after,
a.main-close-btn span::after {
  transform: rotate(-45deg);
}

.form-container .form__title,
.form__title_modal {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 0;
  max-width: 800px;
}

.form-container .form__description,
.form__description_modal {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 32px;
}

.form__item {
  padding: 12px 0;
  display: grid;
  grid-template-rows: auto max-content;
  width: 100%;
  height: 100%;
}

.form__item label {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  margin-left: 12px;
  align-self: end;
}

.form__item label a {
  margin: 0 8px;
}

.form__item select {
  cursor: pointer;
}

.form__item .select,
.form__item textarea,
.form__item input[type="text"],
.form__item input[type="tel"],
.form__item input[type="email"],
.form__item input[type="number"],
.form__item input[type="password"],
.form__item select {
  height: auto;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 12px;
  padding: 12px 24px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #2C2D2E;
  transition: border-color 0.2s ease-out;
  width: 100%;
}

.form__item .select:focus,
.form__item textarea:focus,
.form__item input[type="text"]:focus,
.form__item input[type="tel"]:focus,
.form__item input[type="email"]:focus,
.form__item input[type="number"]:focus,
.form__item input[type="password"]:focus,
.form__item select:focus {
  border-color: rgba(0, 16, 61, 0.24);
}

.form__item input.error:invalid {
  border-color: #ff0019;
}

.form__item .select.show {
  border-radius: 12px 12px 0 0;
}

.form-field_danger {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: unset;
  margin-left: 12px;
}

.checkbox input[type="checkbox"],
.form__checkbox input[type="checkbox"] {
  display: none;
}

.checkbox label,
.form__checkbox label {
  display: flex;
  align-items: flex-start;
}

.checkbox label::before,
.form__checkbox label::before {
  content: '';
  color: #fff;
  display: block;
  height: 16px;
  width: 16px;
  flex: none;
  border: 2px solid rgba(0, 16, 61, 0.24);
  border-radius: 3px;
  margin-right: 8px;
  margin-top: 4px;
}

.checkbox._special label,
.form__checkbox._special label {
  align-items: flex-start;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
}

.checkbox._special label::before,
.form__checkbox._special label::before {
  margin-top: 5px;
}

.checkbox input[type="checkbox"].error:invalid + label::before,
.form__checkbox input[type="checkbox"].error:invalid + label::before {
  border-color: #ff0019;
}

.checkbox input[type="checkbox"]:checked + label::before,
.form__checkbox input[type="checkbox"]:checked + label::before {
  border-color: #007ED9;
  background: url("../img/Check.svg") #007ED9 center no-repeat;
  background-size: contain;
}

.file-input {
  display: grid;
  align-items: center;
  grid-template-columns: max-content auto;
}

.file-input input[type="file"] {
  display: none;
}

.file-input label {
  display: inline-flex;
  align-items: center;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2C2D2E;
  background-color: #E0E1E6;
  margin-bottom: 0;
  padding: 8px 24px;
  border-radius: clamp(8px, calc(8px + (12 - 8) * ((100vw - 320px) / (1440 - 320))), 12px);
  border-radius: 16px;
  margin-left: 0;
  transition: background-color .2s ease-out;
}

.file-input label:hover {
  cursor: pointer;
  background-color: #D4D6DB;
}

.file-input label::before {
  display: block;
  height: 30px;
  width: 30px;
  background: url("../img/upload-arrow-up-square.svg") center no-repeat;
  background-size: contain;
  margin-right: 12px;
}

.file-input .uploaded {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #2C2D2E;
  min-height: 100%;
  margin-left: 8px;
}

.file-input .uploaded.fill {
  color: #007ED9;
}

.form__success {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: clamp(24px, calc(24px + (48 - 24) * ((100vw - 320px) / (1440 - 320))), 48px);
  margin: 16px 0;
  width: 100%;
  border-radius: 24px;
  background-color: #F9F9FA;
}

.form__success svg {
  height: 60px;
  width: 60px;
}

.form__success-message {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 48px;
  color: #2C2D2E;
  margin-top: 4px;
}

/*  ---- Form land booking ----  */
.form-land-booking .form__item {
  height: unset;
}

.booking-card {
  background-color: #F9F9FA;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  margin: 30px 0;
  overflow-x: auto;
  padding: clamp(16px, calc(16px + (32 - 16) * ((100vw - 320px) / (1440 - 320))), 32px);
}

.booking-card .booking-card__title {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 12px;
}

.booking-card .booking-card__kadastr {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
}

.booking-card ._bold {
  font-weight: 600;
}

.booking-card ._blue {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #007ED9;
  white-space: nowrap;
}

.booking-card .booking-card__wrap {
  display: inline-grid;
  grid-gap: 12px;
  grid-template-columns: 1fr auto;
  margin-bottom: 8px;
}

.booking-card .booking-card__wrap .booking-card__label {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #919399;
}

.booking-card .booking-card__wrap span {
  align-self: center;
}

.booking-card .booking-card__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #919399;
}

.text .m-modal__title,
.m-modal__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin: 0 0 4px;
}

.text .m-modal__description,
.m-modal__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #919399;
  margin: 0;
}

.text .m-modal__btn,
.m-modal__btn {
  display: block;
  width: 100%;
  text-align: center;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background-color: #007ED9;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  margin: 32px 0 0;
  outline: none;
  box-shadow: none;
}

.modal-btn {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #007ED9;
  display: inline-block;
  padding: 12px 24px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  border: 2px solid #007ED9;
  padding: 8px 20px;
}

.modal-btn:hover {
  background-color: #007ED9;
  color: #fff !important;
}

.modal-dialog {
  max-width: 560px;
}

.modal-dialog .form__item {
  padding: 16px 0 0;
}

.modal-form-btn {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  display: block;
  border: 1px solid rgba(0, 16, 61, 0.12);
  padding: 32px;
  transition: .2s;
}

.modal-form-btn:hover {
  border-color: rgba(0, 16, 61, 0.24);
  color: #2C2D2E;
}

.modal-content {
  padding: 40px 64px;
  border-radius: 16px;
}

.form-container .form__description,
.form__description_modal {
  margin-bottom: 0;
}

.form-error {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ff0019;
  margin-top: 24px;
}

.mailing-form-block {
  display: flex;
  justify-content: center;
}

.mailing-form {
  display: grid;
  grid-template-columns: auto 144px;
  flex-direction: column;
  max-width: 864px;
  width: 100%;
  background: #F3F3F5;
  border-radius: 24px;
  padding: 24px;
}

.mailing-form .main-btn {
  background: #007ED9;
  border-radius: 8px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: white;
  padding: 12px 32px;
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.mailing-form .main-btn.disabled {
  background: transparent;
  outline: 1px solid #FFFFFF;
  opacity: 0.5;
}

.mailing-form .news-subscribe-form {
  grid-column: 1/3;
}

.mailing-form__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin: 0 0 4px 0;
}

.mailing__form__sub-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2C2D2E;
  margin: 0 0 40px;
}

.mailing._input-field {
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 8px;
  padding: 12px 16px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  width: 100%;
}

.mailing._input-field.warning {
  background: url("../img/Warning-red.svg") white 96% no-repeat;
  background-size: contain;
  margin-right: 16px;
  background-size: 24px;
  flex: none;
  padding: 12px 40px 12px 16px;
  outline: 1px solid #ED0A34;
}

.mail-box__img {
  width: 144px;
  height: 144px;
  margin-top: -40px;
  flex: none;
  aspect-ratio: 1/1;
}

.mailing-form__fields {
  width: 100%;
}

.mailing-form__text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #919399;
}

.mailing-form__link {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #919399;
}

.mailing-form__link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #919399;
}

.mailing-form__agreement {
  margin-top: 8px;
  grid-column: 1/3;
}

.mailing__help-block {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #eb5757;
  margin-left: 12px;
}

.mailing-check {
  width: 100%;
  flex: none;
}

.form-success-message {
  grid-column: 1/3;
}

.form-success-message .mailing-form__title {
  text-align: center;
}

.form-success-message .mailing__form__sub-title {
  text-align: center;
}

.bottom-mailing-block .mailing-form-block {
  margin-top: 80px;
}

@media screen and (max-width: 1024px) {
  .mailing-form {
    grid-gap: 0;
  }
}

@media screen and (max-width: 600px) {
  .media {
    flex-direction: column;
  }
  .media .mailing-form__fields {
    max-width: unset;
  }
  .media .main-btn {
    margin-left: 0px;
    width: 100%;
    margin-top: 12px;
  }
  .mailing-form-block {
    margin-top: 32px;
  }
  .bottom-mailing-block .mailing-form-block {
    margin-top: 32px;
  }
}

.text .beautiful-agreement,
.beautiful-agreement {
  margin: 16px 0 0;
}

.text .beautiful-agreement input,
.beautiful-agreement input {
  display: none;
}

.text .beautiful-agreement label,
.beautiful-agreement label {
  display: flex;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2C2D2E;
}

.text .beautiful-agreement label a,
.beautiful-agreement label a {
  text-decoration: none;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #007ED9;
}

.text .beautiful-agreement label:before,
.beautiful-agreement label:before {
  content: '';
  color: #fff;
  display: block;
  height: 16px;
  width: 16px;
  flex: none;
  border: 2px solid rgba(0, 16, 61, 0.24);
  border-radius: 3px;
  margin-right: 8px;
  margin-top: 2px;
}

.text .beautiful-agreement input:checked + label:before,
.beautiful-agreement input:checked + label:before {
  border-color: #0CC;
  background: url(../img/Check.svg) #0CC center no-repeat;
  background-size: contain;
}

button,
a {
  transition: filter 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

button._disabled,
a._disabled {
  pointer-events: none;
  cursor: unset;
  opacity: 0.2;
  filter: grayscale(1);
}

.bik-market._grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  margin-bottom: 32px;
}

.form_bik-market__background,
.new-type__form__background {
  width: 100%;
  max-width: 640px;
  background: white;
}

.form_bik-market .bik-market__title,
.new-type__form .bik-market__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 20px;
}

.form_bik-market .bik-market__subtitle,
.new-type__form .bik-market__subtitle {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #919399;
  margin-bottom: 18px;
}

.form_bik-market .bik-market__photo-tutorial,
.new-type__form .bik-market__photo-tutorial {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0088CC;
  display: flex;
  margin-bottom: 16px;
}

.form_bik-market .bik-market__photo-tutorial:before,
.new-type__form .bik-market__photo-tutorial:before {
  content: '';
  background: url("../img/download-arrow.svg") center no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px;
  display: block;
  flex: none;
  margin-right: 4px;
}

.form_bik-market .file-input .plus-button,
.new-type__form .file-input .plus-button {
  background: #0088CC;
  padding: 4px 16px;
  border-radius: 16px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: white;
  width: fit-content;
}

.form_bik-market .file-input .in-row,
.new-type__form .file-input .in-row {
  display: flex;
  align-items: center;
}

.form_bik-market .file-input .in-row .plus-button,
.new-type__form .file-input .in-row .plus-button {
  margin-right: 16px;
}

.form_bik-market .file-input .in-row .uploaded,
.new-type__form .file-input .in-row .uploaded {
  width: calc(100% - 118px);
}

.form_bik-market .file-input .in-row .uploaded div,
.new-type__form .file-input .in-row .uploaded div {
  margin-top: 0;
}

.form_bik-market .uploaded,
.new-type__form .uploaded {
  width: 100%;
}

.form_bik-market .uploaded div,
.new-type__form .uploaded div {
  display: flex;
  margin-top: 16px;
}

.form_bik-market .uploaded div:before,
.new-type__form .uploaded div:before {
  content: '';
  background: url("../img/file.svg") center no-repeat;
  display: block;
  flex: none;
  width: 18px;
  height: 24px;
  background-size: 18px 24px;
  margin-right: 8px;
}

.form_bik-market .uploaded div p,
.new-type__form .uploaded div p {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form_bik-market .uploaded .delete-file,
.new-type__form .uploaded .delete-file {
  background: url("../img/delete-bin.svg") center no-repeat;
  width: 18px;
  height: 24px;
  background-size: 18px 24px;
  display: block;
  margin-left: 8px;
  flex: none;
}

.form_bik-market .form__item,
.new-type__form .form__item {
  padding: 0;
}

.form_bik-market .form__item.grid-start-1,
.new-type__form .form__item.grid-start-1 {
  grid-column-start: 1;
}

.form_bik-market .form__item.grid-start-2,
.new-type__form .form__item.grid-start-2 {
  grid-column-start: 2;
}

.form_bik-market .form__item.grid-start-3,
.new-type__form .form__item.grid-start-3 {
  grid-column-start: 3;
}

.form_bik-market .form__item.grid-start-4,
.new-type__form .form__item.grid-start-4 {
  grid-column-start: 4;
}

.form_bik-market .form__item.grid-end-2,
.new-type__form .form__item.grid-end-2 {
  grid-column-end: 2;
}

.form_bik-market .form__item.grid-end-3,
.new-type__form .form__item.grid-end-3 {
  grid-column-end: 3;
}

.form_bik-market .form__item.grid-end-4,
.new-type__form .form__item.grid-end-4 {
  grid-column-end: 4;
}

.form_bik-market .form__item.grid-end-5,
.new-type__form .form__item.grid-end-5 {
  grid-column-end: 5;
}

.form_bik-market .form__file-item,
.new-type__form .form__file-item {
  margin-bottom: 16px;
}

.form_bik-market .form__file-item .form__file-label,
.new-type__form .form__file-item .form__file-label {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 0 0 4px 12px;
}

.form_bik-market .form__file-item .form__file-label a,
.new-type__form .form__file-item .form__file-label a {
  margin: 0 8px;
}

.form_bik-market .main-btn,
.new-type__form .main-btn {
  background-color: #0088CC;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  margin-top: 24px;
  width: fit-content;
}

.form_bik-market .form__checkbox input[type="checkbox"]:checked + label::before,
.new-type__form .form__checkbox input[type="checkbox"]:checked + label::before {
  border-color: #00CCCC;
  background: url("../img/Check.svg") #00CCCC center no-repeat;
  background-size: contain;
}

.form_bik-market .market__price-field ::-webkit-input-placeholder,
.form_bik-market .market__area-field ::-webkit-input-placeholder,
.new-type__form .market__price-field ::-webkit-input-placeholder,
.new-type__form .market__area-field ::-webkit-input-placeholder {
  text-align: right;
}

.form_bik-market .market__price-field :-moz-placeholder,
.form_bik-market .market__area-field :-moz-placeholder,
.new-type__form .market__price-field :-moz-placeholder,
.new-type__form .market__area-field :-moz-placeholder {
  text-align: right;
}

.form_bik-market .form-field_danger,
.new-type__form .form-field_danger {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: -24px;
}

.form_bik-market .bik-market__success,
.new-type__form .bik-market__success {
  padding: 12px 24px;
  border-radius: 16px;
}

.form_bik-market .bik-market__success__title,
.new-type__form .bik-market__success__title {
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: transparent;
  margin: 0 0 8px 0;
  width: fit-content;
}

.form_bik-market .bik-market__success__text,
.new-type__form .bik-market__success__text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 0 0 12px 0;
  max-width: 464px;
}

.form_bik-market .bik-market__success__sub-title,
.new-type__form .bik-market__success__sub-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
  margin: 0 0 20px 0;
}

.form_bik-market .bik-market__success .bik-market__success__button,
.new-type__form .bik-market__success .bik-market__success__button {
  padding: 12px 24px;
  background: #0088CC;
  border-radius: 8px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: white;
}

/* PAGINATION */
.pagination {
  margin: 0;
}

.pagination-block {
  display: flex;
  justify-content: center;
  padding: 16px 0 0;
  margin: 16px 0 32px;
  width: 100%;
}

.paginate-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  min-width: 38px;
  border: 1px solid transparent;
  border-radius: 19px;
  padding: 7px 10px;
  margin: 0 4px;
  font-size: 14px;
  line-height: 20px;
  color: #2C2D2E;
  text-align: center;
  text-decoration: none;
}

.pagination__arrow use {
  stroke: rgba(0, 16, 61, 0.24);
}

.pagination__prev-arrow {
  transform: rotate(180deg) translate(1px, 0);
}

.pagination__next-arrow {
  transform: translate(1px, 0);
}

.paginate-item.active {
  border-color: rgba(0, 16, 61, 0.12);
}

.plugin-paginate .paginate-item {
  border-color: #333;
  margin: 0 6px;
}

.paginate-item:hover,
.paginate-item:hover .pagination__arrow use {
  text-decoration: none;
  border-color: rgba(0, 16, 61, 0.24);
  color: #007ED9;
  stroke: rgba(0, 16, 61, 0.24);
}

.paginate-item.disabled,
.disabled .pagination__arrow use,
.paginate-item.disabled:hover,
.paginate-item.disabled .pagination__arrow use {
  border-color: transparent;
  color: rgba(0, 16, 61, 0.24);
  stroke: rgba(0, 16, 61, 0.24);
  cursor: unset;
}

.plugin-paginate .paginate-item.disabled {
  border-color: #F9F9FA;
}

/* FOOTER */
/* SMALL NAVIGATE */
.nav-counter {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 31px;
  color: #919399;
  padding-right: 8px;
}

.nav-arrow {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 31px;
  width: 31px;
  border: 1px solid rgba(0, 16, 61, 0.24);
  border-radius: 50%;
  margin-left: 8px;
}

.nav-arrow:hover {
  cursor: pointer;
}

.nav-arrow::before {
  content: url("../img/small-arrow.svg");
  height: 22px;
}

.nav-arrow.prev::before {
  transform: rotate(90deg);
}

.nav-arrow.next::before {
  transform: rotate(-90deg);
}

.nav-arrow.disable {
  opacity: 0.5;
  pointer-events: none;
}

.footer {
  flex: 0 0 auto;
  margin-top: 100px;
}

.address__title {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 8px;
}

.address__address {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 8px;
}

.phones__title {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 8px;
}

.phones__link {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #2C2D2E;
  margin-bottom: 8px;
}

.phones__link:hover {
  text-decoration-line: underline;
  color: #007ED9;
}

.footer__subnav__item {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 0;
  width: fit-content;
}

.footer__subnav__item:hover {
  text-decoration: underline;
}

.footer__subnav__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer__subnav__list__item {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #2C2D2E;
  width: fit-content;
  margin-bottom: 0;
}

.footer__subnav__list__item:hover {
  text-decoration: underline;
}

.footer__c-info {
  display: flex;
  align-items: center;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  color: #F9F9FA;
  margin: 0;
}

.footer__socials-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  color: #BFC1C7;
  margin: 0;
}

.footer__teh-title {
  margin-bottom: 0;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: #E0E1E6;
}

.footer__teh-subtitle {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #E0E1E6;
  margin-bottom: 0;
}

.up-button__button {
  position: absolute;
  top: 16px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  width: 43px;
  border: 1px solid #007ED9;
  border-radius: 22px;
}

.up-button__button::before {
  content: url("../img/ArrowToTop.svg");
  height: 28px;
  width: 20px;
}

.sitemap__ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sitemap__ul .sitemap__ul {
  padding: 0 0 0 16px;
}

.sitemap__ul .sitemap__ul .sitemap__a {
  padding: 8px 15px;
  border-left: 1px solid rgba(0, 16, 61, 0.12);
}

.sitemap__li {
  transition: .2s;
  list-style: none;
}

.sitemap__li:hover {
  cursor: pointer;
}

.sitemap__a {
  display: block;
  position: relative;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  text-decoration: none;
  background-color: transparent;
  padding: 8px 15px;
}

.sitemap__a:hover {
  color: #007ED9;
  text-decoration: none;
}

.bg-footer {
  background-color: #004466;
  padding: 28px 0 20px;
}

.bg-footer_gradient {
  background: linear-gradient(90.05deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  padding: 8px 0;
}

.footer-text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: white;
  opacity: 0.8;
  margin: 0;
}

.footer-logo-F {
  align-self: flex-start;
  width: auto;
  height: 36px;
  margin-bottom: 48px;
}

.footer-logo-B {
  align-self: flex-start;
  width: auto;
  height: 24px;
  margin-top: 10px;
  margin-bottom: 48px;
}

.footer__link {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.footer__link:hover {
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
}

.footer__phone-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer__phone {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.footer__phone:hover {
  color: #fff;
  opacity: 0.8;
}

.footer__phone-title {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0;
}

.footer__navigation .footer__page-link {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.footer__navigation .footer__page-link:hover {
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
}

.footer__navigation > .footer__page {
  margin-bottom: 16px;
}

.footer__navigation > .footer__page > .footer__page-link {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 4px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.footer__navigation > .footer__page > .footer__page-link:hover {
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
}

.footer__navigation > .footer__page > .footer__page-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 4px;
}

.footer__navigation > .footer__page > .footer__page-wrap > .footer__page > .footer__page-link {
  margin-bottom: 0px;
}

.footer__navigation > .footer__page > .footer__page-wrap .footer__page-wrap {
  margin-bottom: 4px;
}

.footer__navigation > .footer__page > .footer__page-wrap .footer__page-wrap .footer__page-link {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}

.footer__navigation > .footer__page > .footer__page-wrap .footer__page-wrap .footer__page-link:hover {
  color: #fff;
  opacity: 1;
}

.preloader {
  display: grid;
  place-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1001;
  opacity: 1;
  transition: 0.3s ease-out;
}

.preloader__animation {
  display: block;
  position: relative;
  height: 62px;
  width: 270px;
  background: url("../img/Bik_logo.svg") center no-repeat;
  background-size: contain;
}

body.loaded {
  overflow: auto;
}

body.loaded .preloader {
  opacity: 0;
  z-index: -100;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.7) translate(-9px, -2px);
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.calculator__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #2C2D2E;
  margin: 0;
}

.calculator__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14;
  line-height: 24 #2C2D2E;
  color: #2C2D2E;
  margin: 0 0 24px;
}

.calculator__card {
  background-color: #fff;
  padding: 32px 48px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  max-width: 814px;
}

@media screen and (max-width: 768px) {
  .calculator__card {
    padding: 16px 24px;
  }
}

.calculator__table {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  margin: 32px 0;
  border-radius: 12px;
  overflow: hidden;
  overflow-x: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.calculator__header {
  background-color: #007ED9;
  border-bottom: none;
}

.calculator__body,
.calculator__header {
  min-width: 646px;
}

.calculator__row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-rows: 1fr;
  border-bottom: 1px solid rgba(0, 16, 61, 0.12);
}

.calculator__row:last-child {
  border-bottom: none;
}

.calculator__body {
  background-color: #F9F9FA;
}

.calculator__button {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  display: inline-block;
  padding: 12px 24px;
  background-color: #007ED9;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  border: none;
  color: #fff;
  width: 100%;
  margin-top: 24px;
  border-radius: 8px;
  background: linear-gradient(90deg, #0CC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #08C 100%);
}

.calculator__button:hover {
  background-color: #006dc8;
}

.calculator__button:hover {
  color: #fff;
}

.table__cell {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  padding: 8px 16px;
}

.table__cell._title {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #fff;
}

.calculator__result .result__wrap {
  padding: 4px 22px;
  border-left: 2px solid #0088CC;
}

.calculator__result .result__item {
  display: flex;
  flex-direction: column;
  margin: 0 0 16px;
}

.calculator__result .result__item:last-child {
  margin: 0;
}

.calculator__result .result__label {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #919399;
}

.calculator__result .result__value {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #2C2D2E;
}

.calculator__result .calculator__download-btn {
  display: flex;
  padding: 4px 12px 4px 8px;
  width: fit-content;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #5B87A6;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 20px;
  margin-top: 16px;
  gap: 4px;
}

.calculator__result .calculator__download-btn:before {
  content: '';
  display: block;
  height: 24px;
  width: 24px;
  background: url("../img/documents-file-text.svg") no-repeat center;
  background-size: 24px;
}

@media print {
  .main-btn,
  section.header,
  .alert,
  footer {
    display: none !important;
  }
  .calculator-page .form__item,
  .calculator-page .dropdown,
  .calculator-page #download,
  .calculator-page #special_container,
  .calculator-page h1,
  .calculator-page .h1,
  .calculator-page .calculator__description,
  .calculator-page .calculator__card,
  .calculator-page .calculator__result,
  .calculator-page .calculator__button,
  .calculator-page .share-block {
    display: none !important;
  }
  .calculator-page .calculator__table {
    box-shadow: none;
    margin: 0;
  }
  .calculator-page .table__cell {
    padding: 4px 8px;
    font-size: 14px;
  }
  .calculator-page .text-right {
    text-align: right !important;
  }
}

.main-background {
  background: #F3F3F5;
}

.top-block__background {
  width: 100%;
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
}

.top-block__container {
  max-width: 1440px;
  padding: 32px 64px 0 64px;
  width: 100%;
  margin: 0 auto;
}

.top-block__img {
  width: 100%;
  max-width: 752px;
  height: auto;
  margin-bottom: -80px;
}

.top-block__grid {
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-template-rows: auto 32px;
  position: relative;
  grid-column-gap: 32px;
  grid-row-gap: 48px;
  margin-bottom: 80px;
}

.grid-box-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  display: flex;
  align-items: flex-end;
}

.grid-box-2 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.grid-box-3 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  margin-bottom: -32px;
  display: flex;
  align-items: flex-end;
}

.top-block__grid-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 32px;
}

.top-block__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
  color: white;
  display: flex;
  margin-bottom: 8px;
}

.top-block__title:before {
  content: "";
  background: url("../img/landing/home-vector.svg") center no-repeat;
  background-size: cover;
  display: block;
  width: 56px;
  height: 56px;
  margin-right: 20px;
}

.top-block__subtitle {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: white;
}

.top-block__btn {
  transition: .3s ease-out;
  background: #FF9E00;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 16px 32px 16px 24px;
  width: fit-content;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: white;
  display: flex;
  margin-top: 48px;
}

.top-block__btn:before {
  content: url("../img/landing/tg.svg");
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.top-block__btn:hover {
  color: white;
  text-decoration: none;
  margin-top: -6px;
  margin-bottom: 6px;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
}

.change__btn {
  background: #FF9E00;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 16px 32px 16px 24px;
  width: fit-content;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: white;
  display: none;
  margin-top: 16px;
}

.change__btn:before {
  content: "";
  background: url("../img/landing/tg.svg") center no-repeat;
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.change__btn:hover {
  color: white;
  text-decoration: none;
}

.register-block__btn {
  transition: .3s ease-out;
  background: #0088CC;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 16px 32px 16px 24px;
  width: fit-content;
  height: fit-content;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: white;
  display: flex;
}

.register-block__btn:before {
  content: "";
  background: url("../img/landing/tg.svg") center no-repeat;
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.register-block__btn:hover {
  color: white;
  text-decoration: none;
  margin-top: -6px;
  margin-bottom: 6px;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
}

.pt-48 {
  padding-top: 48px;
}

.content__container-center {
  max-width: 992px;
  padding: 0 64px;
  width: 100%;
  margin: 0 auto;
}

.reg-text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
}

.chat-window {
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.15));
  border-radius: 32px;
  overflow: hidden;
  width: 100%;
  margin-top: 64px;
  border: 1px solid rgba(0, 16, 61, 0.05);
}

.chat-window__top {
  background: white;
  padding: 16px;
  height: fit-content;
  display: flex;
}

.chat-logo {
  display: block;
  align-items: center;
  justify-content: center;
  flex: none;
  height: 48px;
  width: 48px;
  margin-right: 12px;
  align-self: center;
  border-radius: 50%;
  background: url("../img/landing/home-vector-colored.svg") #ECEDF0 no-repeat center;
}

.chat-window__top-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2C2D2E;
}

.chat-window__top-subtitle {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
}

.chat-window__content {
  padding: 32px 16px;
  background: #DFE4E8;
  display: flex;
  flex-direction: column;
}

.message-white {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #2C2D2E;
  padding: 12px 16px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 0px;
  width: fit-content;
  align-self: flex-start;
  margin-bottom: 4px;
}

.message-green {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #2C2D2E;
  padding: 12px 32px 12px 16px;
  background: #EFFDDE;
  border-radius: 8px 8px 0px 8px;
  width: fit-content;
  align-self: flex-end;
  margin-top: 12px;
  position: relative;
}

.message-green:after {
  content: url("../img/landing/Done.svg");
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 8px;
  bottom: 0;
}

.chat-text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #919399;
  text-align: center;
  margin-top: 24px;
  max-width: 640px;
}

.gray-text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #919399;
  margin-top: 8px;
}

.problems-block {
  margin-top: 80px;
}

.problems-block__title {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.problems-block .gray-subtitle {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #919399;
}

.landing__problems-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}

.landing__problem-item {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #2C2D2E;
  background: #FFFFFF;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 12px 24px;
  width: fit-content;
  margin-right: 12px;
  margin-bottom: 12px;
}

.landing__register-block {
  margin-top: 80px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 24px 24px 24px 32px;
  display: flex;
  justify-content: space-between;
}

.register-block__text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
  margin-right: 24px;
}

.connection-list {
  margin-top: 84px;
}

.connection-list__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2C2D2E;
}

.connection-list__item {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #2C2D2E;
  margin-top: 12px;
}

.connection-list__item a {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0088CC;
  text-decoration: underline;
}

.tos__top-img {
  position: relative;
  aspect-ratio: 48/11;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.tos__mobile-block {
  position: relative;
  aspect-ratio: 48/11;
  justify-content: center;
  overflow: hidden;
  display: none;
}

.tos-img {
  object-fit: cover;
  display: block;
  height: 100%;
  width: auto;
}

.tos-img__mobile {
  display: none;
  object-fit: cover;
  width: 100%;
}

.tos-gradient {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 159px;
  background: linear-gradient(180deg, rgba(249, 249, 250, 0) 0%, #F9F9FA 100%);
}

.main-background-tos {
  background: #F9F9FA;
}

.tos__main-container {
  max-width: 1440px;
  padding: 40px 64px 0 64px;
  width: 100%;
  margin: 0 auto;
}

.tos__header-logo {
  width: auto;
  height: 32px;
  flex: none;
}

.tos_header {
  margin-bottom: 32px;
}

.tos__back-link {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
  display: flex;
}

.tos__back-link:before {
  content: "";
  background: url("../img/landing/Arrow.svg") center no-repeat;
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  transition: 0.3s;
}

.tos__back-link:hover {
  color: #919399;
  text-decoration: none;
}

.tos__back-link:hover:before {
  margin-right: 8px;
}

.tos__main-title-first {
  font-weight: 700;
  font-size: 80px;
  line-height: 96px;
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-right: 32px;
}

.tos__main-title-second {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 80px;
  color: #0088CC;
  display: flex;
  align-items: flex-end;
}

.tos__main-title-second:after {
  content: "";
  background: url("../img/landing/Location.svg") center no-repeat;
  display: block;
  width: 32px;
  height: 80px;
}

.tos__main-title_big {
  font-weight: 700;
  font-size: 224px;
  line-height: 224px;
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: clamp(-104px, calc(-88px + (-104 + 88) * ((100vw - 1920px) / (2560 - 1920))), -88px);
}

.tos__title {
  margin-bottom: -40px;
}

.tos__definition-block {
  background: #FFFFFF;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 23px;
  max-width: 864px;
  margin-bottom: 32px;
  margin-top: -72px;
  z-index: 3;
  position: relative;
}

.definition-text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
}

.definition-text ._bold {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
}

.info-img {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.tos__bik-text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #2C2D2E;
  display: block;
  max-width: 864px;
  margin-bottom: 96px;
}

.tos__bik-text ._bold {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #2C2D2E;
}

.tos__page-title {
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 24px;
}

.tos__reasons-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  grid-gap: 32px;
  margin-bottom: 52px;
}

.reasons-block__rectangle {
  display: block;
  height: 8px;
  width: 64px;
  background: #00CCCC;
  margin-bottom: 8px;
}

.reasons-block__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 12px;
}

.reasons-block__text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
}

.reasons-block__text ._bold {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
}

.tos__achievements {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 100%;
  margin-bottom: 64px;
}

.tos__achievements-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 864px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.tos__achievements-img {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.mb-12 {
  margin-bottom: 12px;
}

.skate_img {
  width: 528px;
  height: auto;
  display: block;
}

.skate_img_mobile {
  width: 100%;
  height: auto;
  display: none;
}

.steps__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: #2C2D2E;
  margin-bottom: 24px;
}

.steps-block {
  max-width: 864px;
}

.steps-block .tos__page-title {
  margin-bottom: 0px;
}

.steps-block .reasons-block__text {
  margin-bottom: 16px;
}

.tos-list-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
  margin-top: 32px;
}

.tos-list-item {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
  margin-top: 12px;
  display: flex;
}

.tos-list-item:before {
  content: "";
  background: url("../img/landing/marker.svg") center no-repeat;
  display: block;
  background-size: 12px;
  width: 12px;
  height: 32px;
  margin-right: 12px;
  flex: none;
}

.steps__highlight {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
  background: #ECEDF0;
  border-left: 8px solid #00CCCC;
  padding: 8px 32px;
  margin-bottom: 16px;
}

.steps-block__step {
  margin-bottom: 64px;
}

.tos__accordion-item {
  background: #FFFFFF;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-bottom: 48px;
}

.tos__accordion-item .accordion-header {
  padding: 12px 12px 12px 24px;
}

.tos__accordion-item .accordion-body {
  padding: 12px 24px 24px 24px;
}

.tos__accordion-item .accordion-body .point {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin-bottom: 16px;
}

.tos__accordion-btn {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
  text-align: left;
  border: none;
  background: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tos__accordion-btn:focus {
  border: none;
  outline: none;
}

.tos__accordion-btn .accordion-arrow {
  transition: 0.3s;
  transform: rotate(180deg);
  margin-left: 12px;
}

.tos__accordion-btn.collapsed .accordion-arrow {
  transform: rotate(0deg);
}

.mr-8 {
  margin-right: 8px;
}

.tos__registration-text {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin-bottom: 32px;
}

.period-block {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  padding: 12px 24px;
  background: #ECEDF0;
  margin-bottom: 64px;
}

.skate_img {
  animation: skate_img ease-in-out 3s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}

@keyframes skate_img {
  0% {
    transform: translate(0, -8px);
  }
  50% {
    transform: translate(0, 8px);
  }
  100% {
    transform: translate(0, -8px);
  }
}

.skate_img_mobile {
  animation: skate_img__mobile ease-in-out 3s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}

@keyframes skate_img__mobile {
  0% {
    transform: translate(0, -4px);
  }
  50% {
    transform: translate(0, 4px);
  }
  100% {
    transform: translate(0, -4px);
  }
}

.tos__doc-download {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #2C2D2E;
  margin-top: 16px;
  display: flex;
}

.tos__doc-download:before {
  content: "";
  background: url("../img/landing/File.svg") center no-repeat;
  display: block;
  background-size: 32px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  flex: none;
}

.mb-20 {
  margin-bottom: 20px;
}

@media screen and (max-width: 1440px) {
  .tos__main-title_big {
    font-size: clamp(50px, calc(50px + (224 - 50) * ((100vw - 320px) / (1440 - 320))), 224px);
    line-height: clamp(80px, calc(80px + (224 - 80) * ((100vw - 390px) / (1440 - 390))), 224px);
  }
  .tos__main-title-first {
    font-size: clamp(40px, calc(40px + (80 - 40) * ((100vw - 390px) / (1440 - 390))), 80px);
    line-height: clamp(48px, calc(48px + (96 - 48) * ((100vw - 390px) / (1440 - 390))), 96px);
  }
  .tos__main-title-second {
    font-size: clamp(20px, calc(20px + (32 - 20) * ((100vw - 340px) / (1440 - 340))), 32px);
    line-height: clamp(40px, calc(40px + (80 - 40) * ((100vw - 340px) / (1440 - 340))), 80px);
  }
  .tos__main-title-second:after {
    width: clamp(20px, calc(20px + (32 - 20) * ((100vw - 390px) / (1440 - 390))), 32px);
    background-size: clamp(20px, calc(20px + (32 - 20) * ((100vw - 390px) / (1440 - 390))), 32px);
    height: clamp(40px, calc(40px + (80 - 40) * ((100vw - 390px) / (1440 - 390))), 80px);
  }
  .tos__main-title_big {
    margin-bottom: clamp(-88px, calc(-76px + (-88 + 76) * ((100vw - 1280px) / (1440 - 1280))), -76px);
  }
  .tos__achievements-text {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .skate_img {
    width: 528px;
    width: clamp(324px, calc(324px + (528 - 324) * ((100vw - 1024px) / (1440 - 1024))), 528px);
  }
}

@media screen and (max-width: 1280px) {
  .tos__accordion-item .accordion-body .point {
    font-size: clamp(14px, calc(14px + (16 - 14) * ((100vw - 340px) / (1280 - 340))), 16px);
    line-height: clamp(20px, calc(20px + (24 - 20) * ((100vw - 340px) / (1280 - 340))), 24px);
  }
  .accordion-arrow {
    width: clamp(24px, calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))), 32px);
    height: clamp(24px, calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))), 32px);
  }
  .tos__accordion-btn {
    font-size: clamp(16px, calc(16px + (20 - 16) * ((100vw - 340px) / (1280 - 340))), 20px);
    line-height: clamp(24px, calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))), 32px);
  }
  .tos__registration-text {
    font-size: clamp(16px, calc(16px + (24 - 16) * ((100vw - 340px) / (1280 - 340))), 24px);
    line-height: clamp(24px, calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))), 32px);
  }
  .tos-list-item:before {
    height: clamp(24px, calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))), 32px);
  }
  .steps__highlight,
  .tos-list-title,
  .tos-list-item {
    font-size: clamp(16px, calc(16px + (20 - 16) * ((100vw - 340px) / (1280 - 340))), 20px);
    line-height: clamp(24px, calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))), 32px);
  }
  .steps__description {
    font-size: clamp(20px, calc(20px + (32 - 20) * ((100vw - 340px) / (1280 - 340))), 32px);
    line-height: clamp(32px, calc(32px + (48 - 32) * ((100vw - 340px) / (1280 - 340))), 48px);
  }
  .tos__main-title_big {
    margin-bottom: clamp(-96px, calc(-76px + (-96 + 76) * ((100vw - 1024px) / (1280 - 1024))), -76px);
  }
  .tos__top-img {
    aspect-ratio: 9/4;
  }
  .tos__main-container {
    padding: 40px 32px 0 32px;
  }
  .tos__title {
    margin-bottom: clamp(-40px, calc(-16px + (-40 + 16) * ((100vw - 340px) / (1280 - 340))), -16px);
  }
  .tos__page-title {
    font-size: clamp(32px, calc(32px + (48 - 32) * ((100vw - 340px)/ (1280 - 340))), 48px);
    line-height: clamp(40px, calc(40px + (64 - 40) * ((100vw - 340px)/ (1280 - 340))), 64px);
  }
  .reasons-block__title {
    font-size: clamp(20px, calc(20px + (24 - 20) * ((100vw - 340px) / (1280 - 340))), 24px);
    line-height: clamp(24px, calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))), 32px);
  }
  .reasons-block__text,
  .reasons-block__text ._bold,
  .tos__doc-download,
  .definition-text,
  .definition-text ._bold {
    font-size: clamp(16px, calc(16px + (20 - 16) * ((100vw - 340px) / (1280 - 340))), 20px);
    line-height: clamp(24px, calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))), 32px);
  }
  .tos__doc-download:before {
    width: clamp(24px, calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))), 32px);
    height: clamp(24px, calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))), 32px);
    background-size: clamp(24px, calc(24px + (32 - 24) * ((100vw - 340px) / (1280 - 340))), 32px);
  }
}

@media screen and (max-width: 1024px) {
  .tos__top-img {
    aspect-ratio: unset;
  }
  .tos-img {
    display: none;
  }
  .tos-img__mobile {
    display: block;
  }
  .tos__main-title-first {
    margin-right: 12px;
  }
  .skate_img {
    display: none;
  }
  .skate_img_mobile {
    display: block;
    max-width: 628px;
    margin-top: 48px;
  }
  .tos__achievements {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    margin-bottom: 116px;
  }
  .tos__achievements-img {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    display: flex;
    justify-content: center;
  }
  .tos__reasons-block {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    margin-bottom: 64px;
  }
  .tos__main-container {
    padding: 40px 24px 0 24px;
  }
  .tos__definition-block {
    padding: 15px;
  }
  .info-img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  .tos__bik-text,
  .tos__bik-text ._bold {
    font-size: 14px;
    line-height: 24px;
  }
}

@media screen and (max-width: 800px) {
  .tos__main-title_big {
    margin-bottom: clamp(-68px, calc(-40px + (-68 + 40) * ((100vw - 390px) / (800 - 390))), -40px);
  }
  .tos__main-container {
    padding: 40px 16px 0 16px;
  }
  .tos__definition-block {
    margin-bottom: 24px;
  }
  .tos__bik-text {
    margin-bottom: 40px;
  }
  .tos__page-title {
    margin-bottom: 16px;
  }
  .reasons-block__title {
    margin-bottom: 8px;
  }
  .tos__page-title {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 600px) {
  .steps__highlight {
    padding: 8px 16px;
  }
  .tos__accordion-item .accordion-header {
    padding: 12px 12px 12px 16px;
  }
  .tos__accordion-item .accordion-body {
    padding: 12px 16px 16px 16px;
  }
  .skate_img_mobile {
    margin-top: 24px;
  }
  .tos__achievements {
    margin-bottom: 48px;
  }
  .steps-block__step {
    margin-bottom: 48px;
  }
  .period-block {
    margin-bottom: 32px;
  }
  .tos__accordion-btn {
    align-items: center;
  }
  .reasons-block__rectangle {
    width: 48px;
  }
  .tos__main-container {
    padding: 16px 16px 0 16px;
  }
  .steps-block .tos__page-title {
    margin-bottom: 8px;
  }
}

.container-registration {
  max-width: 560px;
  padding: 0 64px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.registration-background {
  background: #FFFFFF;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 32px 64px 40px 64px;
}

.main-background-registration {
  background: #F9F9FA;
}

.registration-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #2C2D2E;
}

.registration-second-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
}

.registration-subtitle {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #919399;
  margin-bottom: 16px;
}

.registration-form .form__item {
  padding: 0 0 16px 0;
}

.registration-form .form-check-input {
  display: inline-flex;
  flex: none;
  width: 18px;
  height: 18px;
  border: 1px solid #2C2D2E;
  border-radius: 4px;
  margin-right: 8px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2C2D2E;
}

.registration-form .form-check-input:checked[type=checkbox] {
  background-image: url(../img/V.svg);
  background-size: 24px;
  border-radius: 4px;
  background-color: #00CCCC;
  border: none;
}

.restore-password {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0088CC;
  margin-bottom: 16px;
  display: block;
}

.restore-password:hover {
  cursor: pointer;
  color: #0088CC;
}

.registration-btn {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: white;
  border-radius: 8px;
  width: 100%;
  padding: 12px 0;
  border: none;
}

.registration-btn:hover {
  color: white;
}

.registration-btn._login {
  background: #0088CC;
  margin-bottom: 16px;
}

.registration-btn._registration {
  background: #00CCCC;
  display: block;
  text-align: center;
}

.links-agreement {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0088CC;
  cursor: pointer;
}

.links-agreement:hover {
  color: #0088CC;
}

.field-error {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #f35;
  display: block;
  margin-top: -8px;
  margin-bottom: 16px;
  margin-left: 8px;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.register-checkbox p {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2C2D2E;
  color: #2C2D2E !important;
}

body {
  background-color: white;
}

.cabinet__header {
  background: linear-gradient(90.85deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
}

.cabinet__info {
  display: flex;
  flex-direction: column;
  padding: 32px 0 48px;
}

.cabinet__cabinet {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin: 0;
}

.cabinet__name {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #fff;
  margin: 0;
}

.cabinet__areas {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 32px;
  margin-top: -32px;
  margin-bottom: 64px;
}

.cabinet__areas .area {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 32px;
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.15);
}

.cabinet__areas .area__img {
  position: absolute;
  height: 96px;
  width: 96px;
  right: 16px;
  bottom: 16px;
  z-index: 1;
}

.cabinet__areas .area__header {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #919399;
  margin: 0;
  z-index: 2;
}

.cabinet__areas .area__cadastre {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin: 0;
  z-index: 2;
}

.cabinet__areas .area__address {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 8px 0 0;
  z-index: 2;
}

.cabinet__areas .area__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #919399;
  margin: 0;
  z-index: 2;
}

.cabinet__actions {
  display: flex;
  grid-column: 1/4;
}

.cabinet__actions .action {
  display: flex;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #5B87A6;
  padding: 7px 15px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  background-color: #fff;
  border-radius: 20px;
  margin-right: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  transition: box-shadow .3s ease-out;
}

.cabinet__actions .action__img {
  margin-right: 4px;
}

.cabinet__actions .action:last-child {
  margin-right: 0;
}

.cabinet__actions .action:hover {
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.15);
}

.cabinet__feedback {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
}

.cabinet__feedback .feedback__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #2C2D2E;
  margin: 0;
}

.cabinet__feedback .feedback__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 0 0 8px;
}

.cabinet__feedback .feedback__post-description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #919399;
  margin: 0 0 24px;
}

.cabinet__feedback .feedback__button {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  display: inline-block;
  padding: 12px 24px;
  background-color: #ED0A34;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  border: none;
  display: block;
  width: fit-content;
}

.cabinet__feedback .feedback__button:hover {
  background-color: #dc0023;
}

.e-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #2C2D2E;
  margin-top: 24px;
  margin-bottom: 0;
}

.e-collapse__btn {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.e-collapse__btn:after {
  white-space: nowrap;
  content: 'Скрыть';
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #919399;
}

.e-collapse__btn.collapsed:after {
  content: 'Подробнее';
}

.e-collapse__btn:before {
  content: '';
  display: block;
  height: 10px;
  width: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 7L5 3L1 7' stroke='%23BFC1C7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 4px;
  transition: transform 0.3s ease-out;
}

.e-collapse__btn.collapsed:before {
  transform: rotate(-180deg);
}

.form__item .e-label {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
  margin-left: 12px;
}

.filter_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.e-organisations {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 24px;
  margin-top: 40px;
}

.e-organisations .e-organisation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 16px;
  background: #FFF;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.e-organisations .e-organisation__category {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  padding: 0 8px;
  background-color: #56CCF2;
}

.e-organisations .e-organisation__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 8px 0 0;
}

.e-organisations .e-organisation__address {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
  margin: 0;
}

.e-organisations .e-organisation__item-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #919399;
  margin: 12px 0 0;
}

.e-organisations .e-organisation__item {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 0;
}

.e-organisations .e-organisation__site {
  color: #5B87A6;
}

.filter__categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;
  max-width: 640px;
}

.category__radio {
  display: none;
}

.category__label {
  display: block;
  width: fit-content;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #5B87A6;
  background-color: transparent;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 16px;
  margin: 0 8px 8px 0;
  padding: 3px 11px;
  cursor: pointer;
  transition: color .3s ease-out, background-color .2s ease-out, border-color .3s ease-out;
}

.category__label:hover {
  border-color: #5B87A6;
}

.category__radio:checked + .category__label {
  border-color: #5B87A6;
  background-color: #5B87A6;
  color: #fff;
}

.block-link {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 24px 32px;
  background: linear-gradient(91.93deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.block-link__title {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 0;
}

.block-link__text {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 16px;
}

.block-link__button {
  display: block;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding: 12px 24px;
  background-color: #F23054;
  margin: 0;
  border-radius: 8px;
  transition: background-color 0.3s ease-out;
}

.block-link__button:hover {
  background-color: #ff3b62;
}

@media screen and (max-width: 640px) {
  .filter_row {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    margin-bottom: 32px;
    grid-gap: 16px;
  }
  .filter_row .form__item {
    padding-bottom: 0;
  }
}

.vacancy_empty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 24px;
  width: 100%;
  max-width: 640px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #919399;
  border: 1px solid rgba(0, 16, 61, 0.12);
  border-radius: 16px;
  margin: 0;
}

.vacancy_empty::before {
  content: '';
  display: block;
  height: 24px;
  width: 24px;
  margin-right: 8px;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.25 14.25L9 12' stroke='%23BFC1C7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 10.5L11.25 14.25' stroke='%23BFC1C7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21C7.02944 21 3 16.9706 3 12V12C3 7.02944 7.02944 3 12 3V3C16.9706 3 21 7.02944 21 12V12C21 16.9706 16.9706 21 12 21V21Z' stroke='%23BFC1C7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.cookie {
  display: grid;
  position: fixed;
  bottom: 24px;
  right: 24px;
  grid-template-columns: auto 1fr;
  max-width: 480px;
  padding: 32px;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(12px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 999;
}

.cookie__title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 0 0 8px;
  grid-column: 1/3;
  grid-row: 1/2;
}

.cookie__description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #919399;
  margin: 0 0 16px;
  grid-column: 1/3;
  grid-row: 2/3;
}

.cookie__description a {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #919399;
}

.cookie__button {
  padding: 8px 24px 8px 16px;
  cursor: pointer;
}

.cookie__button._success {
  display: flex;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  background-color: #007ED9;
  border-radius: 4px;
  grid-row: 3/4;
  grid-column: 1/2;
  transition: background-color 0.3s ease-out;
}

.cookie__button._success::before {
  display: block;
  content: '';
  background: url("../img/button-check.svg") center no-repeat;
  background-size: contain;
  height: 24px;
  width: 24px;
  margin-right: 4px;
}

.cookie__button._success:hover {
  background-color: #006c9c;
}

.cookie__button._close {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #919399;
  margin: 0 0 0 16px;
  grid-column: 2/3;
  grid-row: 3/4;
  transition: color 0.3s ease-out;
}

.cookie__button._close:hover {
  color: #2C2D2E;
}

.cookie__button:hover {
  text-decoration: none;
}

.cookie__img {
  display: block;
  position: absolute;
  top: 0;
  right: 24px;
  transform: translateY(-50%);
  background: url("../img/cookie.png") center no-repeat;
  height: 120px;
  width: 120px;
  background-size: contain;
}

.snow_page {
  display: grid;
  place-items: center;
  position: relative;
  grid-gap: 24px;
  min-height: 100vh;
  width: 100%;
  padding: 0 16px;
  background: linear-gradient(180deg, #002A40 20%, #0088CC 80%, #00CCCC 100%);
  overflow: hidden;
}

.snow_page._success {
  background: linear-gradient(180deg, #33BBFF 0%, #1470CC 50%, #FD696E 100%);
}

.snow_page canvas {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  mix-blend-mode: lighten;
}

.snow_page:after {
  content: '';
  display: block;
  position: absolute;
  margin-top: -10%;
  min-width: 100%;
  min-height: 100%;
  background: url("../img/snow_page/Ray.svg") center no-repeat;
  background-size: cover;
  aspect-ratio: 1/1;
  transform-origin: center;
  animation: rotate 50s infinite linear;
  pointer-events: none;
  will-change: auto;
  z-index: 1;
}

.snow_page .form-wrap {
  display: grid;
  grid-template-columns: minmax(240px, 1fr) minmax(240px, 1fr);
  grid-gap: 24px;
  background: #fff;
  border-radius: 32px;
  padding: 48px;
  position: relative;
  margin: 64px 0 205px;
  z-index: 2;
  width: 696px;
  max-width: 100%;
}

.snow_page .form-wrap video {
  object-fit: cover;
  pointer-events: none;
}

.snow_page .form-wrap .form__decoration {
  position: absolute;
}

.snow_page .form-wrap .form__decoration._top-left {
  top: -28px;
  left: -16px;
}

.snow_page .form-wrap .form__decoration._top-right {
  top: -26px;
  right: -3px;
}

.snow_page .form-wrap .form__decoration._bottom-left {
  top: 100%;
  transform: translateY(-21px);
  left: -7px;
}

.snow_page .form-wrap .form__decoration._bottom-right {
  top: 100%;
  transform: translateY(-8px);
  right: 29px;
}

.snow_page .form-wrap .girland {
  margin: 0 -49px -80px -48px;
  aspect-ratio: 698/132;
  position: relative;
}

.snow_page .form-wrap .girland img {
  width: 100%;
}

.snow_page .form-wrap .girland ._base,
.snow_page .form-wrap .girland ._lights-blue,
.snow_page .form-wrap .girland ._lights-orange,
.snow_page .form-wrap .girland ._lights-red {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.snow_page .form-wrap .girland ._lights-blue,
.snow_page .form-wrap .girland ._lights-orange,
.snow_page .form-wrap .girland ._lights-red {
  opacity: 0;
  animation-name: flash;
  animation-iteration-count: infinite;
  filter: brightness(2);
  mix-blend-mode: hard-light;
}

.snow_page .form-wrap .girland ._lights-blue {
  animation-duration: 3s;
}

.snow_page .form-wrap .girland ._lights-orange {
  animation-duration: 2.5s;
}

.snow_page .form-wrap .girland ._lights-red {
  animation-duration: 2s;
}

.snow_page .form-wrap .submit-button {
  display: block;
  background: url("../img/snow_page/Ice-button.svg") center no-repeat;
  background-size: contain;
  padding: 20px 24px;
  width: fit-content;
  aspect-ratio: 218/64;
  text-align: center;
  filter: drop-shadow(0px 4px 16px #49C9F2);
  will-change: auto;
  margin: 32px auto 0;
  cursor: pointer;
}

.snow_page .form-wrap .submit-button:before {
  content: 'Получить приз!';
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #fff;
  mix-blend-mode: overlay;
  text-shadow: -1px 2px 2px #4e4e4e;
}

.snow_page .form-wrap .text-half {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.snow_page .form-wrap .form-half {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.snow_page .form-wrap h1, .snow_page .form-wrap .h1 {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: transparent;
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
  margin: 0 0 8px;
}

.snow_page .form-wrap .subtitle {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #919399;
  text-align: center;
  margin: 0;
}

.snow_page .form-wrap .input-group {
  display: flex;
  flex-direction: column;
  margin: 16px 0 0;
  width: 100%;
}

.snow_page .form-wrap .input-group label {
  display: block;
  width: 100%;
  text-align: center;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #007ED9;
  opacity: 0.5;
  margin: 0;
}

.snow_page .form-wrap .input-group input {
  border: 2px solid rgba(0, 136, 204, 0.25);
  border-radius: 12px;
  padding: 10px 22px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #2C2D2E;
  margin: 0;
  width: 100%;
  text-align: center;
}

.snow_page .form-wrap .form-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2C2D2E;
  text-align: center;
  margin: 0;
}

.snow_page .form-wrap .form-error {
  margin-top: 12px;
}

.snow_page .form-wrap._success {
  grid-template-columns: 100%;
  grid-gap: 0;
}

.snow_page .form-wrap._success .subtitle {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #007ED9;
  opacity: 0.5;
  margin: 4px 0 24px;
  width: 100%;
  text-align: center;
}

.snow_page .form-wrap._success h1, .snow_page .form-wrap._success .h1 {
  font-size: 32px;
  line-height: 40px;
  margin: 0 !important;
}

.snow_page .form-wrap._success .congrads {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #FF6680;
  text-align: center;
  margin: 24px 0 32px;
}

.snow_page .form-wrap._success .prize-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 8px dotted #FF6680;
  border-radius: 90px;
  padding: 28px;
  width: 100%;
  max-width: 480px;
}

.snow_page .form-wrap._success .prize-title {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin: 0 0 8px;
  width: 100%;
  text-align: center;
}

.snow_page .form-wrap._success .prize-description {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #2C2D2E;
  margin: 0;
  width: 100%;
  text-align: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.snow {
  position: absolute;
  width: 10px;
  height: 10px;
  background: radial-gradient(#ffff 10%, #fff4 46%, #fff0 99%);
  border-radius: 50%;
}

.snow:nth-child(1) {
  opacity: 0.868;
  transform: translate(21.3969vw, -10px) scale(1.0563);
  animation: fall-1 25s -19s linear infinite;
}

@keyframes fall-1 {
  36.279% {
    transform: translate(15.6797vw, 36.279vh) scale(1.0563);
  }
  to {
    transform: translate(18.5383vw, 100vh) scale(1.0563);
  }
}

.snow:nth-child(2) {
  opacity: 0.6107;
  transform: translate(33.0599vw, -10px) scale(0.3813);
  animation: fall-2 20s -19s linear infinite;
}

@keyframes fall-2 {
  62.959% {
    transform: translate(24.8251vw, 62.959vh) scale(0.3813);
  }
  to {
    transform: translate(28.9425vw, 100vh) scale(0.3813);
  }
}

.snow:nth-child(3) {
  opacity: 0.7535;
  transform: translate(27.4043vw, -10px) scale(0.1935);
  animation: fall-3 12s -23s linear infinite;
}

@keyframes fall-3 {
  77.295% {
    transform: translate(36.4267vw, 77.295vh) scale(0.1935);
  }
  to {
    transform: translate(31.9155vw, 100vh) scale(0.1935);
  }
}

.snow:nth-child(4) {
  opacity: 0.5707;
  transform: translate(54.0129vw, -10px) scale(1.3011);
  animation: fall-4 25s -28s linear infinite;
}

@keyframes fall-4 {
  43.473% {
    transform: translate(62.4031vw, 43.473vh) scale(1.3011);
  }
  to {
    transform: translate(58.208vw, 100vh) scale(1.3011);
  }
}

.snow:nth-child(5) {
  opacity: 0.8678;
  transform: translate(30.6181vw, -10px) scale(0.62325);
  animation: fall-5 22s -26s linear infinite;
}

@keyframes fall-5 {
  32.297% {
    transform: translate(31.7322vw, 32.297vh) scale(0.62325);
  }
  to {
    transform: translate(31.17515vw, 100vh) scale(0.62325);
  }
}

.snow:nth-child(6) {
  opacity: 0.662;
  transform: translate(28.3593vw, -10px) scale(1.0869);
  animation: fall-6 22s -6s linear infinite;
}

@keyframes fall-6 {
  48.237% {
    transform: translate(20.8011vw, 48.237vh) scale(1.0869);
  }
  to {
    transform: translate(24.5802vw, 100vh) scale(1.0869);
  }
}

.snow:nth-child(7) {
  opacity: 0.3846;
  transform: translate(32.4435vw, -10px) scale(0.51945);
  animation: fall-7 14s -3s linear infinite;
}

@keyframes fall-7 {
  75.661% {
    transform: translate(39.2455vw, 75.661vh) scale(0.51945);
  }
  to {
    transform: translate(35.8445vw, 100vh) scale(0.51945);
  }
}

.snow:nth-child(8) {
  opacity: 0.3903;
  transform: translate(68.7226vw, -10px) scale(0.9309);
  animation: fall-8 18s -4s linear infinite;
}

@keyframes fall-8 {
  41.774% {
    transform: translate(70.4796vw, 41.774vh) scale(0.9309);
  }
  to {
    transform: translate(69.6011vw, 100vh) scale(0.9309);
  }
}

.snow:nth-child(9) {
  opacity: 0.7769;
  transform: translate(32.8982vw, -10px) scale(1.0311);
  animation: fall-9 18s -30s linear infinite;
}

@keyframes fall-9 {
  45.245% {
    transform: translate(34.2977vw, 45.245vh) scale(1.0311);
  }
  to {
    transform: translate(33.59795vw, 100vh) scale(1.0311);
  }
}

.snow:nth-child(10) {
  opacity: 0.3741;
  transform: translate(43.4744vw, -10px) scale(1.43355);
  animation: fall-10 26s -24s linear infinite;
}

@keyframes fall-10 {
  53.525% {
    transform: translate(38.7546vw, 53.525vh) scale(1.43355);
  }
  to {
    transform: translate(41.1145vw, 100vh) scale(1.43355);
  }
}

.snow:nth-child(11) {
  opacity: 0.3675;
  transform: translate(9.9926vw, -10px) scale(1.2774);
  animation: fall-11 20s -10s linear infinite;
}

@keyframes fall-11 {
  46.064% {
    transform: translate(14.5238vw, 46.064vh) scale(1.2774);
  }
  to {
    transform: translate(12.2582vw, 100vh) scale(1.2774);
  }
}

.snow:nth-child(12) {
  opacity: 0.5824;
  transform: translate(28.2743vw, -10px) scale(0.7392);
  animation: fall-12 21s -29s linear infinite;
}

@keyframes fall-12 {
  45.414% {
    transform: translate(27.1751vw, 45.414vh) scale(0.7392);
  }
  to {
    transform: translate(27.7247vw, 100vh) scale(0.7392);
  }
}

.snow:nth-child(13) {
  opacity: 0.8115;
  transform: translate(85.518vw, -10px) scale(1.4502);
  animation: fall-13 28s -25s linear infinite;
}

@keyframes fall-13 {
  73.458% {
    transform: translate(80.6016vw, 73.458vh) scale(1.4502);
  }
  to {
    transform: translate(83.0598vw, 100vh) scale(1.4502);
  }
}

.snow:nth-child(14) {
  opacity: 0.7392;
  transform: translate(42.6015vw, -10px) scale(0.03435);
  animation: fall-14 26s -5s linear infinite;
}

@keyframes fall-14 {
  37.431% {
    transform: translate(35.5684vw, 37.431vh) scale(0.03435);
  }
  to {
    transform: translate(39.08495vw, 100vh) scale(0.03435);
  }
}

.snow:nth-child(15) {
  opacity: 0.2304;
  transform: translate(97.2287vw, -10px) scale(0.23565);
  animation: fall-15 23s -22s linear infinite;
}

@keyframes fall-15 {
  43.626% {
    transform: translate(87.7202vw, 43.626vh) scale(0.23565);
  }
  to {
    transform: translate(92.47445vw, 100vh) scale(0.23565);
  }
}

.snow:nth-child(16) {
  opacity: 0.8341;
  transform: translate(88.6381vw, -10px) scale(1.45845);
  animation: fall-16 24s -9s linear infinite;
}

@keyframes fall-16 {
  53.693% {
    transform: translate(91.9109vw, 53.693vh) scale(1.45845);
  }
  to {
    transform: translate(90.2745vw, 100vh) scale(1.45845);
  }
}

.snow:nth-child(17) {
  opacity: 0.255;
  transform: translate(89.7919vw, -10px) scale(0.2766);
  animation: fall-17 27s -23s linear infinite;
}

@keyframes fall-17 {
  52.921% {
    transform: translate(88.9151vw, 52.921vh) scale(0.2766);
  }
  to {
    transform: translate(89.3535vw, 100vh) scale(0.2766);
  }
}

.snow:nth-child(18) {
  opacity: 0.0334;
  transform: translate(14.7397vw, -10px) scale(1.3572);
  animation: fall-18 23s -14s linear infinite;
}

@keyframes fall-18 {
  62.416% {
    transform: translate(7.2227vw, 62.416vh) scale(1.3572);
  }
  to {
    transform: translate(10.9812vw, 100vh) scale(1.3572);
  }
}

.snow:nth-child(19) {
  opacity: 0.8617;
  transform: translate(53.2126vw, -10px) scale(0.38955);
  animation: fall-19 12s -8s linear infinite;
}

@keyframes fall-19 {
  73.039% {
    transform: translate(46.8161vw, 73.039vh) scale(0.38955);
  }
  to {
    transform: translate(50.01435vw, 100vh) scale(0.38955);
  }
}

.snow:nth-child(20) {
  opacity: 0.898;
  transform: translate(32.7791vw, -10px) scale(1.37355);
  animation: fall-20 12s -8s linear infinite;
}

@keyframes fall-20 {
  46.336% {
    transform: translate(42.0972vw, 46.336vh) scale(1.37355);
  }
  to {
    transform: translate(37.43815vw, 100vh) scale(1.37355);
  }
}

.snow:nth-child(21) {
  opacity: 0.5042;
  transform: translate(42.0641vw, -10px) scale(0.7074);
  animation: fall-21 19s -26s linear infinite;
}

@keyframes fall-21 {
  32.462% {
    transform: translate(49.6649vw, 32.462vh) scale(0.7074);
  }
  to {
    transform: translate(45.8645vw, 100vh) scale(0.7074);
  }
}

.snow:nth-child(22) {
  opacity: 0.3234;
  transform: translate(8.2261vw, -10px) scale(0.40365);
  animation: fall-22 15s -4s linear infinite;
}

@keyframes fall-22 {
  64.67% {
    transform: translate(18.1946vw, 64.67vh) scale(0.40365);
  }
  to {
    transform: translate(13.21035vw, 100vh) scale(0.40365);
  }
}

.snow:nth-child(23) {
  opacity: 0.9418;
  transform: translate(56.0561vw, -10px) scale(0.3657);
  animation: fall-23 20s -18s linear infinite;
}

@keyframes fall-23 {
  55.143% {
    transform: translate(61.834vw, 55.143vh) scale(0.3657);
  }
  to {
    transform: translate(58.94505vw, 100vh) scale(0.3657);
  }
}

.snow:nth-child(24) {
  opacity: 0.5946;
  transform: translate(55.5611vw, -10px) scale(0.74595);
  animation: fall-24 15s -19s linear infinite;
}

@keyframes fall-24 {
  62.587% {
    transform: translate(50.2501vw, 62.587vh) scale(0.74595);
  }
  to {
    transform: translate(52.9056vw, 100vh) scale(0.74595);
  }
}

.snow:nth-child(25) {
  opacity: 0.6379;
  transform: translate(61.422vw, -10px) scale(1.33095);
  animation: fall-25 19s -15s linear infinite;
}

@keyframes fall-25 {
  64.744% {
    transform: translate(59.3187vw, 64.744vh) scale(1.33095);
  }
  to {
    transform: translate(60.37035vw, 100vh) scale(1.33095);
  }
}

.snow:nth-child(26) {
  opacity: 0.3165;
  transform: translate(36.7706vw, -10px) scale(0.93345);
  animation: fall-26 21s -22s linear infinite;
}

@keyframes fall-26 {
  65.508% {
    transform: translate(42.0052vw, 65.508vh) scale(0.93345);
  }
  to {
    transform: translate(39.3879vw, 100vh) scale(0.93345);
  }
}

.snow:nth-child(27) {
  opacity: 0.3081;
  transform: translate(34.0512vw, -10px) scale(0.0651);
  animation: fall-27 11s -20s linear infinite;
}

@keyframes fall-27 {
  57.765% {
    transform: translate(42.0739vw, 57.765vh) scale(0.0651);
  }
  to {
    transform: translate(38.06255vw, 100vh) scale(0.0651);
  }
}

.snow:nth-child(28) {
  opacity: 0.589;
  transform: translate(21.0692vw, -10px) scale(1.04745);
  animation: fall-28 28s -24s linear infinite;
}

@keyframes fall-28 {
  32.532% {
    transform: translate(18.4888vw, 32.532vh) scale(1.04745);
  }
  to {
    transform: translate(19.779vw, 100vh) scale(1.04745);
  }
}

.snow:nth-child(29) {
  opacity: 0.4722;
  transform: translate(24.7196vw, -10px) scale(0.6414);
  animation: fall-29 26s -22s linear infinite;
}

@keyframes fall-29 {
  52.138% {
    transform: translate(16.7989vw, 52.138vh) scale(0.6414);
  }
  to {
    transform: translate(20.75925vw, 100vh) scale(0.6414);
  }
}

.snow:nth-child(30) {
  opacity: 0.1202;
  transform: translate(46.9832vw, -10px) scale(1.07985);
  animation: fall-30 24s -2s linear infinite;
}

@keyframes fall-30 {
  54.615% {
    transform: translate(41.1285vw, 54.615vh) scale(1.07985);
  }
  to {
    transform: translate(44.05585vw, 100vh) scale(1.07985);
  }
}

.snow:nth-child(31) {
  opacity: 0.0072;
  transform: translate(94.4808vw, -10px) scale(0.87765);
  animation: fall-31 21s -26s linear infinite;
}

@keyframes fall-31 {
  56.331% {
    transform: translate(92.956vw, 56.331vh) scale(0.87765);
  }
  to {
    transform: translate(93.7184vw, 100vh) scale(0.87765);
  }
}

.snow:nth-child(32) {
  opacity: 0.1829;
  transform: translate(14.2276vw, -10px) scale(0.3129);
  animation: fall-32 27s -27s linear infinite;
}

@keyframes fall-32 {
  52.653% {
    transform: translate(11.6428vw, 52.653vh) scale(0.3129);
  }
  to {
    transform: translate(12.9352vw, 100vh) scale(0.3129);
  }
}

.snow:nth-child(33) {
  opacity: 0.8353;
  transform: translate(81.9699vw, -10px) scale(1.1349);
  animation: fall-33 15s -20s linear infinite;
}

@keyframes fall-33 {
  42.421% {
    transform: translate(76.7867vw, 42.421vh) scale(1.1349);
  }
  to {
    transform: translate(79.3783vw, 100vh) scale(1.1349);
  }
}

.snow:nth-child(34) {
  opacity: 0.1703;
  transform: translate(7.7637vw, -10px) scale(0.70275);
  animation: fall-34 10s -6s linear infinite;
}

@keyframes fall-34 {
  67.151% {
    transform: translate(14.5979vw, 67.151vh) scale(0.70275);
  }
  to {
    transform: translate(11.1808vw, 100vh) scale(0.70275);
  }
}

.snow:nth-child(35) {
  opacity: 0.6604;
  transform: translate(21.7842vw, -10px) scale(0.45705);
  animation: fall-35 27s -13s linear infinite;
}

@keyframes fall-35 {
  79.045% {
    transform: translate(25.17vw, 79.045vh) scale(0.45705);
  }
  to {
    transform: translate(23.4771vw, 100vh) scale(0.45705);
  }
}

.snow:nth-child(36) {
  opacity: 0.0915;
  transform: translate(7.6461vw, -10px) scale(1.4742);
  animation: fall-36 13s -30s linear infinite;
}

@keyframes fall-36 {
  65.16% {
    transform: translate(15.7076vw, 65.16vh) scale(1.4742);
  }
  to {
    transform: translate(11.67685vw, 100vh) scale(1.4742);
  }
}

.snow:nth-child(37) {
  opacity: 0.3559;
  transform: translate(80.4793vw, -10px) scale(1.0128);
  animation: fall-37 28s -14s linear infinite;
}

@keyframes fall-37 {
  44.438% {
    transform: translate(89.0004vw, 44.438vh) scale(1.0128);
  }
  to {
    transform: translate(84.73985vw, 100vh) scale(1.0128);
  }
}

.snow:nth-child(38) {
  opacity: 0.9277;
  transform: translate(41.4525vw, -10px) scale(0.45195);
  animation: fall-38 15s -22s linear infinite;
}

@keyframes fall-38 {
  76.048% {
    transform: translate(49.5697vw, 76.048vh) scale(0.45195);
  }
  to {
    transform: translate(45.5111vw, 100vh) scale(0.45195);
  }
}

.snow:nth-child(39) {
  opacity: 0.2105;
  transform: translate(60.8135vw, -10px) scale(0.89265);
  animation: fall-39 26s -9s linear infinite;
}

@keyframes fall-39 {
  55.409% {
    transform: translate(51.1988vw, 55.409vh) scale(0.89265);
  }
  to {
    transform: translate(56.00615vw, 100vh) scale(0.89265);
  }
}

.snow:nth-child(40) {
  opacity: 0.2186;
  transform: translate(80.426vw, -10px) scale(1.0779);
  animation: fall-40 15s -9s linear infinite;
}

@keyframes fall-40 {
  69.927% {
    transform: translate(87.012vw, 69.927vh) scale(1.0779);
  }
  to {
    transform: translate(83.719vw, 100vh) scale(1.0779);
  }
}

.snow:nth-child(41) {
  opacity: 0.2974;
  transform: translate(12.8519vw, -10px) scale(0.1497);
  animation: fall-41 27s -27s linear infinite;
}

@keyframes fall-41 {
  53.563% {
    transform: translate(19.5726vw, 53.563vh) scale(0.1497);
  }
  to {
    transform: translate(16.21225vw, 100vh) scale(0.1497);
  }
}

.snow:nth-child(42) {
  opacity: 0.752;
  transform: translate(86.6488vw, -10px) scale(1.0425);
  animation: fall-42 22s -10s linear infinite;
}

@keyframes fall-42 {
  32.447% {
    transform: translate(83.6605vw, 32.447vh) scale(1.0425);
  }
  to {
    transform: translate(85.15465vw, 100vh) scale(1.0425);
  }
}

.snow:nth-child(43) {
  opacity: 0.4249;
  transform: translate(62.0673vw, -10px) scale(1.22175);
  animation: fall-43 20s -7s linear infinite;
}

@keyframes fall-43 {
  43.388% {
    transform: translate(53.1714vw, 43.388vh) scale(1.22175);
  }
  to {
    transform: translate(57.61935vw, 100vh) scale(1.22175);
  }
}

.snow:nth-child(44) {
  opacity: 0.0915;
  transform: translate(89.8625vw, -10px) scale(0.69915);
  animation: fall-44 27s -16s linear infinite;
}

@keyframes fall-44 {
  55.065% {
    transform: translate(88.1652vw, 55.065vh) scale(0.69915);
  }
  to {
    transform: translate(89.01385vw, 100vh) scale(0.69915);
  }
}

.snow:nth-child(45) {
  opacity: 0.5411;
  transform: translate(12.2816vw, -10px) scale(0.84615);
  animation: fall-45 21s -9s linear infinite;
}

@keyframes fall-45 {
  42.269% {
    transform: translate(10.4875vw, 42.269vh) scale(0.84615);
  }
  to {
    transform: translate(11.38455vw, 100vh) scale(0.84615);
  }
}

.snow:nth-child(46) {
  opacity: 0.9029;
  transform: translate(71.1822vw, -10px) scale(0.8529);
  animation: fall-46 19s -29s linear infinite;
}

@keyframes fall-46 {
  47.713% {
    transform: translate(78.0546vw, 47.713vh) scale(0.8529);
  }
  to {
    transform: translate(74.6184vw, 100vh) scale(0.8529);
  }
}

.snow:nth-child(47) {
  opacity: 0.8164;
  transform: translate(79.4963vw, -10px) scale(0.0276);
  animation: fall-47 29s -2s linear infinite;
}

@keyframes fall-47 {
  34.295% {
    transform: translate(77.339vw, 34.295vh) scale(0.0276);
  }
  to {
    transform: translate(78.41765vw, 100vh) scale(0.0276);
  }
}

.snow:nth-child(48) {
  opacity: 0.1916;
  transform: translate(3.0485vw, -10px) scale(1.42455);
  animation: fall-48 22s -4s linear infinite;
}

@keyframes fall-48 {
  71.955% {
    transform: translate(8.2571vw, 71.955vh) scale(1.42455);
  }
  to {
    transform: translate(5.6528vw, 100vh) scale(1.42455);
  }
}

.snow:nth-child(49) {
  opacity: 0.6831;
  transform: translate(79.3516vw, -10px) scale(0.66465);
  animation: fall-49 25s -16s linear infinite;
}

@keyframes fall-49 {
  51.795% {
    transform: translate(71.4305vw, 51.795vh) scale(0.66465);
  }
  to {
    transform: translate(75.39105vw, 100vh) scale(0.66465);
  }
}

.snow:nth-child(50) {
  opacity: 0.6448;
  transform: translate(0.7067vw, -10px) scale(0.3702);
  animation: fall-50 23s -12s linear infinite;
}

@keyframes fall-50 {
  78.316% {
    transform: translate(-6.5182vw, 78.316vh) scale(0.3702);
  }
  to {
    transform: translate(-2.90575vw, 100vh) scale(0.3702);
  }
}

.snow:nth-child(51) {
  opacity: 0.6277;
  transform: translate(73.2776vw, -10px) scale(1.0506);
  animation: fall-51 12s -3s linear infinite;
}

@keyframes fall-51 {
  64.685% {
    transform: translate(66.864vw, 64.685vh) scale(1.0506);
  }
  to {
    transform: translate(70.0708vw, 100vh) scale(1.0506);
  }
}

.snow:nth-child(52) {
  opacity: 0.687;
  transform: translate(72.4249vw, -10px) scale(0.7641);
  animation: fall-52 11s -18s linear infinite;
}

@keyframes fall-52 {
  72.927% {
    transform: translate(77.8812vw, 72.927vh) scale(0.7641);
  }
  to {
    transform: translate(75.15305vw, 100vh) scale(0.7641);
  }
}

.snow:nth-child(53) {
  opacity: 0.0547;
  transform: translate(84.6367vw, -10px) scale(0.14685);
  animation: fall-53 26s -21s linear infinite;
}

@keyframes fall-53 {
  41.433% {
    transform: translate(75.9832vw, 41.433vh) scale(0.14685);
  }
  to {
    transform: translate(80.30995vw, 100vh) scale(0.14685);
  }
}

.snow:nth-child(54) {
  opacity: 0.9653;
  transform: translate(11.3303vw, -10px) scale(1.49505);
  animation: fall-54 17s -8s linear infinite;
}

@keyframes fall-54 {
  43.241% {
    transform: translate(1.7594vw, 43.241vh) scale(1.49505);
  }
  to {
    transform: translate(6.54485vw, 100vh) scale(1.49505);
  }
}

.snow:nth-child(55) {
  opacity: 0.2746;
  transform: translate(67.8156vw, -10px) scale(0.50715);
  animation: fall-55 14s -13s linear infinite;
}

@keyframes fall-55 {
  75.142% {
    transform: translate(63.9406vw, 75.142vh) scale(0.50715);
  }
  to {
    transform: translate(65.8781vw, 100vh) scale(0.50715);
  }
}

.snow:nth-child(56) {
  opacity: 0.9365;
  transform: translate(3.2396vw, -10px) scale(0.0846);
  animation: fall-56 10s -23s linear infinite;
}

@keyframes fall-56 {
  41.719% {
    transform: translate(-6.0398vw, 41.719vh) scale(0.0846);
  }
  to {
    transform: translate(-1.4001vw, 100vh) scale(0.0846);
  }
}

.snow:nth-child(57) {
  opacity: 0.5884;
  transform: translate(76.533vw, -10px) scale(0.43095);
  animation: fall-57 18s -10s linear infinite;
}

@keyframes fall-57 {
  45.032% {
    transform: translate(75.4492vw, 45.032vh) scale(0.43095);
  }
  to {
    transform: translate(75.9911vw, 100vh) scale(0.43095);
  }
}

.snow:nth-child(58) {
  opacity: 0.2529;
  transform: translate(6.2634vw, -10px) scale(0.02955);
  animation: fall-58 22s -16s linear infinite;
}

@keyframes fall-58 {
  39.599% {
    transform: translate(9.0836vw, 39.599vh) scale(0.02955);
  }
  to {
    transform: translate(7.6735vw, 100vh) scale(0.02955);
  }
}

.snow:nth-child(59) {
  opacity: 0.3356;
  transform: translate(97.3566vw, -10px) scale(1.02105);
  animation: fall-59 19s -14s linear infinite;
}

@keyframes fall-59 {
  76.95% {
    transform: translate(93.2173vw, 76.95vh) scale(1.02105);
  }
  to {
    transform: translate(95.28695vw, 100vh) scale(1.02105);
  }
}

.snow:nth-child(60) {
  opacity: 0.5153;
  transform: translate(61.8849vw, -10px) scale(0.22185);
  animation: fall-60 24s -25s linear infinite;
}

@keyframes fall-60 {
  71.984% {
    transform: translate(65.1955vw, 71.984vh) scale(0.22185);
  }
  to {
    transform: translate(63.5402vw, 100vh) scale(0.22185);
  }
}

.snow:nth-child(61) {
  opacity: 0.7058;
  transform: translate(72.2124vw, -10px) scale(0.2748);
  animation: fall-61 23s -18s linear infinite;
}

@keyframes fall-61 {
  54.83% {
    transform: translate(81.826vw, 54.83vh) scale(0.2748);
  }
  to {
    transform: translate(77.0192vw, 100vh) scale(0.2748);
  }
}

.snow:nth-child(62) {
  opacity: 0.0449;
  transform: translate(55.6458vw, -10px) scale(0.9012);
  animation: fall-62 11s -2s linear infinite;
}

@keyframes fall-62 {
  61.322% {
    transform: translate(49.3391vw, 61.322vh) scale(0.9012);
  }
  to {
    transform: translate(52.49245vw, 100vh) scale(0.9012);
  }
}

.snow:nth-child(63) {
  opacity: 0.7519;
  transform: translate(90.5774vw, -10px) scale(0.19365);
  animation: fall-63 23s -12s linear infinite;
}

@keyframes fall-63 {
  30.36% {
    transform: translate(93.8108vw, 30.36vh) scale(0.19365);
  }
  to {
    transform: translate(92.1941vw, 100vh) scale(0.19365);
  }
}

.snow:nth-child(64) {
  opacity: 0.4477;
  transform: translate(72.9239vw, -10px) scale(1.1553);
  animation: fall-64 28s -21s linear infinite;
}

@keyframes fall-64 {
  76.732% {
    transform: translate(75.6961vw, 76.732vh) scale(1.1553);
  }
  to {
    transform: translate(74.31vw, 100vh) scale(1.1553);
  }
}

.snow:nth-child(65) {
  opacity: 0.5593;
  transform: translate(7.3256vw, -10px) scale(1.1133);
  animation: fall-65 13s -6s linear infinite;
}

@keyframes fall-65 {
  39.571% {
    transform: translate(13.7343vw, 39.571vh) scale(1.1133);
  }
  to {
    transform: translate(10.52995vw, 100vh) scale(1.1133);
  }
}

.snow:nth-child(66) {
  opacity: 0.8198;
  transform: translate(52.7074vw, -10px) scale(0.46485);
  animation: fall-66 26s -3s linear infinite;
}

@keyframes fall-66 {
  45.523% {
    transform: translate(55.502vw, 45.523vh) scale(0.46485);
  }
  to {
    transform: translate(54.1047vw, 100vh) scale(0.46485);
  }
}

.snow:nth-child(67) {
  opacity: 0.107;
  transform: translate(50.1752vw, -10px) scale(1.21605);
  animation: fall-67 11s -10s linear infinite;
}

@keyframes fall-67 {
  70.532% {
    transform: translate(49.4313vw, 70.532vh) scale(1.21605);
  }
  to {
    transform: translate(49.80325vw, 100vh) scale(1.21605);
  }
}

.snow:nth-child(68) {
  opacity: 0.4593;
  transform: translate(66.7971vw, -10px) scale(0.07575);
  animation: fall-68 29s -3s linear infinite;
}

@keyframes fall-68 {
  51.37% {
    transform: translate(65.4936vw, 51.37vh) scale(0.07575);
  }
  to {
    transform: translate(66.14535vw, 100vh) scale(0.07575);
  }
}

.snow:nth-child(69) {
  opacity: 0.2432;
  transform: translate(6.198vw, -10px) scale(0.1434);
  animation: fall-69 19s -19s linear infinite;
}

@keyframes fall-69 {
  72.472% {
    transform: translate(-0.519vw, 72.472vh) scale(0.1434);
  }
  to {
    transform: translate(2.8395vw, 100vh) scale(0.1434);
  }
}

.snow:nth-child(70) {
  opacity: 0.373;
  transform: translate(2.4523vw, -10px) scale(0.9399);
  animation: fall-70 30s -29s linear infinite;
}

@keyframes fall-70 {
  74.288% {
    transform: translate(6.4112vw, 74.288vh) scale(0.9399);
  }
  to {
    transform: translate(4.43175vw, 100vh) scale(0.9399);
  }
}

.snow:nth-child(71) {
  opacity: 0.9695;
  transform: translate(13.7123vw, -10px) scale(0.78495);
  animation: fall-71 19s -4s linear infinite;
}

@keyframes fall-71 {
  37.345% {
    transform: translate(20.4998vw, 37.345vh) scale(0.78495);
  }
  to {
    transform: translate(17.10605vw, 100vh) scale(0.78495);
  }
}

.snow:nth-child(72) {
  opacity: 0.6842;
  transform: translate(87.9151vw, -10px) scale(1.4019);
  animation: fall-72 27s -22s linear infinite;
}

@keyframes fall-72 {
  68.829% {
    transform: translate(87.8824vw, 68.829vh) scale(1.4019);
  }
  to {
    transform: translate(87.89875vw, 100vh) scale(1.4019);
  }
}

.snow:nth-child(73) {
  opacity: 0.7886;
  transform: translate(24.0298vw, -10px) scale(0.34965);
  animation: fall-73 30s -18s linear infinite;
}

@keyframes fall-73 {
  72.466% {
    transform: translate(14.403vw, 72.466vh) scale(0.34965);
  }
  to {
    transform: translate(19.2164vw, 100vh) scale(0.34965);
  }
}

.snow:nth-child(74) {
  opacity: 0.9684;
  transform: translate(51.2547vw, -10px) scale(0.87255);
  animation: fall-74 23s -12s linear infinite;
}

@keyframes fall-74 {
  71.686% {
    transform: translate(43.3363vw, 71.686vh) scale(0.87255);
  }
  to {
    transform: translate(47.2955vw, 100vh) scale(0.87255);
  }
}

.snow:nth-child(75) {
  opacity: 0.6399;
  transform: translate(53.7248vw, -10px) scale(0.14355);
  animation: fall-75 26s -22s linear infinite;
}

@keyframes fall-75 {
  39.339% {
    transform: translate(56.6462vw, 39.339vh) scale(0.14355);
  }
  to {
    transform: translate(55.1855vw, 100vh) scale(0.14355);
  }
}

.snow:nth-child(76) {
  opacity: 0.9022;
  transform: translate(54.3374vw, -10px) scale(0.6903);
  animation: fall-76 11s -8s linear infinite;
}

@keyframes fall-76 {
  39.064% {
    transform: translate(45.6638vw, 39.064vh) scale(0.6903);
  }
  to {
    transform: translate(50.0006vw, 100vh) scale(0.6903);
  }
}

.snow:nth-child(77) {
  opacity: 0.0022;
  transform: translate(1.6848vw, -10px) scale(0.17115);
  animation: fall-77 26s -20s linear infinite;
}

@keyframes fall-77 {
  34.186% {
    transform: translate(7.8327vw, 34.186vh) scale(0.17115);
  }
  to {
    transform: translate(4.75875vw, 100vh) scale(0.17115);
  }
}

.snow:nth-child(78) {
  opacity: 0.6893;
  transform: translate(96.4776vw, -10px) scale(0.0687);
  animation: fall-78 29s -29s linear infinite;
}

@keyframes fall-78 {
  53.608% {
    transform: translate(104.8872vw, 53.608vh) scale(0.0687);
  }
  to {
    transform: translate(100.6824vw, 100vh) scale(0.0687);
  }
}

.snow:nth-child(79) {
  opacity: 0.3182;
  transform: translate(9.4219vw, -10px) scale(0.3984);
  animation: fall-79 23s -19s linear infinite;
}

@keyframes fall-79 {
  71.444% {
    transform: translate(14.2848vw, 71.444vh) scale(0.3984);
  }
  to {
    transform: translate(11.85335vw, 100vh) scale(0.3984);
  }
}

.snow:nth-child(80) {
  opacity: 0.6433;
  transform: translate(82.9621vw, -10px) scale(0.2202);
  animation: fall-80 14s -4s linear infinite;
}

@keyframes fall-80 {
  66.53% {
    transform: translate(85.7457vw, 66.53vh) scale(0.2202);
  }
  to {
    transform: translate(84.3539vw, 100vh) scale(0.2202);
  }
}

.snow:nth-child(81) {
  opacity: 0.9143;
  transform: translate(81.5449vw, -10px) scale(1.4814);
  animation: fall-81 16s -7s linear infinite;
}

@keyframes fall-81 {
  50.989% {
    transform: translate(88.0907vw, 50.989vh) scale(1.4814);
  }
  to {
    transform: translate(84.8178vw, 100vh) scale(1.4814);
  }
}

.snow:nth-child(82) {
  opacity: 0.8375;
  transform: translate(13.381vw, -10px) scale(0.1362);
  animation: fall-82 14s -30s linear infinite;
}

@keyframes fall-82 {
  39.742% {
    transform: translate(9.809vw, 39.742vh) scale(0.1362);
  }
  to {
    transform: translate(11.595vw, 100vh) scale(0.1362);
  }
}

.snow:nth-child(83) {
  opacity: 0.7559;
  transform: translate(20.7498vw, -10px) scale(0.975);
  animation: fall-83 12s -4s linear infinite;
}

@keyframes fall-83 {
  31.728% {
    transform: translate(28.1479vw, 31.728vh) scale(0.975);
  }
  to {
    transform: translate(24.44885vw, 100vh) scale(0.975);
  }
}

.snow:nth-child(84) {
  opacity: 0.5501;
  transform: translate(98.3502vw, -10px) scale(1.41);
  animation: fall-84 12s -21s linear infinite;
}

@keyframes fall-84 {
  68.335% {
    transform: translate(107.0173vw, 68.335vh) scale(1.41);
  }
  to {
    transform: translate(102.68375vw, 100vh) scale(1.41);
  }
}

.snow:nth-child(85) {
  opacity: 0.3154;
  transform: translate(3.9065vw, -10px) scale(0.04335);
  animation: fall-85 22s -10s linear infinite;
}

@keyframes fall-85 {
  70.028% {
    transform: translate(5.501vw, 70.028vh) scale(0.04335);
  }
  to {
    transform: translate(4.70375vw, 100vh) scale(0.04335);
  }
}

.snow:nth-child(86) {
  opacity: 0.1175;
  transform: translate(18.7433vw, -10px) scale(0.85515);
  animation: fall-86 29s -15s linear infinite;
}

@keyframes fall-86 {
  35.332% {
    transform: translate(12.627vw, 35.332vh) scale(0.85515);
  }
  to {
    transform: translate(15.68515vw, 100vh) scale(0.85515);
  }
}

.snow:nth-child(87) {
  opacity: 0.117;
  transform: translate(40.4093vw, -10px) scale(0.27825);
  animation: fall-87 27s -21s linear infinite;
}

@keyframes fall-87 {
  64.029% {
    transform: translate(45.2214vw, 64.029vh) scale(0.27825);
  }
  to {
    transform: translate(42.81535vw, 100vh) scale(0.27825);
  }
}

.snow:nth-child(88) {
  opacity: 0.5121;
  transform: translate(4.8482vw, -10px) scale(0.93885);
  animation: fall-88 30s -18s linear infinite;
}

@keyframes fall-88 {
  45.299% {
    transform: translate(8.7449vw, 45.299vh) scale(0.93885);
  }
  to {
    transform: translate(6.79655vw, 100vh) scale(0.93885);
  }
}

.snow:nth-child(89) {
  opacity: 0.2288;
  transform: translate(61.7714vw, -10px) scale(0.93135);
  animation: fall-89 22s -26s linear infinite;
}

@keyframes fall-89 {
  34.169% {
    transform: translate(60.4255vw, 34.169vh) scale(0.93135);
  }
  to {
    transform: translate(61.09845vw, 100vh) scale(0.93135);
  }
}

.snow:nth-child(90) {
  opacity: 0.0941;
  transform: translate(48.2983vw, -10px) scale(0.8154);
  animation: fall-90 25s -3s linear infinite;
}

@keyframes fall-90 {
  71.349% {
    transform: translate(52.2635vw, 71.349vh) scale(0.8154);
  }
  to {
    transform: translate(50.2809vw, 100vh) scale(0.8154);
  }
}

.snow:nth-child(91) {
  opacity: 0.9364;
  transform: translate(80.9973vw, -10px) scale(1.2759);
  animation: fall-91 14s -6s linear infinite;
}

@keyframes fall-91 {
  66.079% {
    transform: translate(86.2323vw, 66.079vh) scale(1.2759);
  }
  to {
    transform: translate(83.6148vw, 100vh) scale(1.2759);
  }
}

.snow:nth-child(92) {
  opacity: 0.7487;
  transform: translate(32.9606vw, -10px) scale(1.43595);
  animation: fall-92 19s -20s linear infinite;
}

@keyframes fall-92 {
  49.889% {
    transform: translate(31.213vw, 49.889vh) scale(1.43595);
  }
  to {
    transform: translate(32.0868vw, 100vh) scale(1.43595);
  }
}

.snow:nth-child(93) {
  opacity: 0.7608;
  transform: translate(67.5642vw, -10px) scale(0.13155);
  animation: fall-93 25s -18s linear infinite;
}

@keyframes fall-93 {
  71.212% {
    transform: translate(63.7409vw, 71.212vh) scale(0.13155);
  }
  to {
    transform: translate(65.65255vw, 100vh) scale(0.13155);
  }
}

.snow:nth-child(94) {
  opacity: 0.8779;
  transform: translate(63.5181vw, -10px) scale(0.675);
  animation: fall-94 27s -24s linear infinite;
}

@keyframes fall-94 {
  52.339% {
    transform: translate(57.0992vw, 52.339vh) scale(0.675);
  }
  to {
    transform: translate(60.30865vw, 100vh) scale(0.675);
  }
}

.snow:nth-child(95) {
  opacity: 0.7188;
  transform: translate(24.1925vw, -10px) scale(0.31695);
  animation: fall-95 18s -13s linear infinite;
}

@keyframes fall-95 {
  41.052% {
    transform: translate(23.0902vw, 41.052vh) scale(0.31695);
  }
  to {
    transform: translate(23.64135vw, 100vh) scale(0.31695);
  }
}

.snow:nth-child(96) {
  opacity: 0.3759;
  transform: translate(36.574vw, -10px) scale(0.9558);
  animation: fall-96 20s -6s linear infinite;
}

@keyframes fall-96 {
  50.844% {
    transform: translate(42.4383vw, 50.844vh) scale(0.9558);
  }
  to {
    transform: translate(39.50615vw, 100vh) scale(0.9558);
  }
}

.snow:nth-child(97) {
  opacity: 0.5562;
  transform: translate(27.1846vw, -10px) scale(1.1088);
  animation: fall-97 13s -28s linear infinite;
}

@keyframes fall-97 {
  49.517% {
    transform: translate(25.1036vw, 49.517vh) scale(1.1088);
  }
  to {
    transform: translate(26.1441vw, 100vh) scale(1.1088);
  }
}

.snow:nth-child(98) {
  opacity: 0.3845;
  transform: translate(85.0635vw, -10px) scale(0.7668);
  animation: fall-98 11s -2s linear infinite;
}

@keyframes fall-98 {
  62.175% {
    transform: translate(92.65vw, 62.175vh) scale(0.7668);
  }
  to {
    transform: translate(88.85675vw, 100vh) scale(0.7668);
  }
}

.snow:nth-child(99) {
  opacity: 0.4803;
  transform: translate(58.6181vw, -10px) scale(0.7413);
  animation: fall-99 17s -9s linear infinite;
}

@keyframes fall-99 {
  70.704% {
    transform: translate(61.9636vw, 70.704vh) scale(0.7413);
  }
  to {
    transform: translate(60.29085vw, 100vh) scale(0.7413);
  }
}

.snow:nth-child(100) {
  opacity: 0.0931;
  transform: translate(16.124vw, -10px) scale(0.7305);
  animation: fall-100 29s -20s linear infinite;
}

@keyframes fall-100 {
  52.336% {
    transform: translate(16.7906vw, 52.336vh) scale(0.7305);
  }
  to {
    transform: translate(16.4573vw, 100vh) scale(0.7305);
  }
}

.snow:nth-child(101) {
  opacity: 0.3332;
  transform: translate(14.8536vw, -10px) scale(0.55995);
  animation: fall-101 21s -13s linear infinite;
}

@keyframes fall-101 {
  67.738% {
    transform: translate(24.1536vw, 67.738vh) scale(0.55995);
  }
  to {
    transform: translate(19.5036vw, 100vh) scale(0.55995);
  }
}

.snow:nth-child(102) {
  opacity: 0.2874;
  transform: translate(2.0301vw, -10px) scale(0.7152);
  animation: fall-102 16s -30s linear infinite;
}

@keyframes fall-102 {
  71.584% {
    transform: translate(3.7933vw, 71.584vh) scale(0.7152);
  }
  to {
    transform: translate(2.9117vw, 100vh) scale(0.7152);
  }
}

.snow:nth-child(103) {
  opacity: 0.5478;
  transform: translate(95.2225vw, -10px) scale(0.69435);
  animation: fall-103 30s -23s linear infinite;
}

@keyframes fall-103 {
  73.936% {
    transform: translate(92.6276vw, 73.936vh) scale(0.69435);
  }
  to {
    transform: translate(93.92505vw, 100vh) scale(0.69435);
  }
}

.snow:nth-child(104) {
  opacity: 0.5004;
  transform: translate(81.8557vw, -10px) scale(1.2849);
  animation: fall-104 20s -11s linear infinite;
}

@keyframes fall-104 {
  33.386% {
    transform: translate(87.7557vw, 33.386vh) scale(1.2849);
  }
  to {
    transform: translate(84.8057vw, 100vh) scale(1.2849);
  }
}

.snow:nth-child(105) {
  opacity: 0.4728;
  transform: translate(52.3753vw, -10px) scale(1.3602);
  animation: fall-105 21s -9s linear infinite;
}

@keyframes fall-105 {
  66.596% {
    transform: translate(44.5186vw, 66.596vh) scale(1.3602);
  }
  to {
    transform: translate(48.44695vw, 100vh) scale(1.3602);
  }
}

.snow:nth-child(106) {
  opacity: 0.0526;
  transform: translate(68.4821vw, -10px) scale(1.29405);
  animation: fall-106 12s -17s linear infinite;
}

@keyframes fall-106 {
  58.312% {
    transform: translate(59.9189vw, 58.312vh) scale(1.29405);
  }
  to {
    transform: translate(64.2005vw, 100vh) scale(1.29405);
  }
}

.snow:nth-child(107) {
  opacity: 0.2354;
  transform: translate(87.2778vw, -10px) scale(0.58785);
  animation: fall-107 28s -27s linear infinite;
}

@keyframes fall-107 {
  77.161% {
    transform: translate(83.1489vw, 77.161vh) scale(0.58785);
  }
  to {
    transform: translate(85.21335vw, 100vh) scale(0.58785);
  }
}

.snow:nth-child(108) {
  opacity: 0.1009;
  transform: translate(34.9863vw, -10px) scale(1.20915);
  animation: fall-108 12s -24s linear infinite;
}

@keyframes fall-108 {
  69.134% {
    transform: translate(36.23vw, 69.134vh) scale(1.20915);
  }
  to {
    transform: translate(35.60815vw, 100vh) scale(1.20915);
  }
}

.snow:nth-child(109) {
  opacity: 0.8048;
  transform: translate(42.0251vw, -10px) scale(0.34395);
  animation: fall-109 23s -6s linear infinite;
}

@keyframes fall-109 {
  77.75% {
    transform: translate(50.2744vw, 77.75vh) scale(0.34395);
  }
  to {
    transform: translate(46.14975vw, 100vh) scale(0.34395);
  }
}

.snow:nth-child(110) {
  opacity: 0.5585;
  transform: translate(81.4512vw, -10px) scale(0.71025);
  animation: fall-110 17s -2s linear infinite;
}

@keyframes fall-110 {
  64.16% {
    transform: translate(90.2519vw, 64.16vh) scale(0.71025);
  }
  to {
    transform: translate(85.85155vw, 100vh) scale(0.71025);
  }
}

.snow:nth-child(111) {
  opacity: 0.642;
  transform: translate(85.4687vw, -10px) scale(0.89415);
  animation: fall-111 15s -1s linear infinite;
}

@keyframes fall-111 {
  50.594% {
    transform: translate(78.9021vw, 50.594vh) scale(0.89415);
  }
  to {
    transform: translate(82.1854vw, 100vh) scale(0.89415);
  }
}

.snow:nth-child(112) {
  opacity: 0.1741;
  transform: translate(28.5618vw, -10px) scale(0.5202);
  animation: fall-112 14s -3s linear infinite;
}

@keyframes fall-112 {
  45.32% {
    transform: translate(35.0258vw, 45.32vh) scale(0.5202);
  }
  to {
    transform: translate(31.7938vw, 100vh) scale(0.5202);
  }
}

.snow:nth-child(113) {
  opacity: 0.4136;
  transform: translate(80.8881vw, -10px) scale(0.78705);
  animation: fall-113 11s -7s linear infinite;
}

@keyframes fall-113 {
  73.771% {
    transform: translate(80.8082vw, 73.771vh) scale(0.78705);
  }
  to {
    transform: translate(80.84815vw, 100vh) scale(0.78705);
  }
}

.snow:nth-child(114) {
  opacity: 0.3579;
  transform: translate(88.0902vw, -10px) scale(1.3881);
  animation: fall-114 30s -19s linear infinite;
}

@keyframes fall-114 {
  58.274% {
    transform: translate(88.0128vw, 58.274vh) scale(1.3881);
  }
  to {
    transform: translate(88.0515vw, 100vh) scale(1.3881);
  }
}

.snow:nth-child(115) {
  opacity: 0.8409;
  transform: translate(19.0912vw, -10px) scale(1.3533);
  animation: fall-115 12s -29s linear infinite;
}

@keyframes fall-115 {
  38.596% {
    transform: translate(18.4236vw, 38.596vh) scale(1.3533);
  }
  to {
    transform: translate(18.7574vw, 100vh) scale(1.3533);
  }
}

.snow:nth-child(116) {
  opacity: 0.0735;
  transform: translate(93.7829vw, -10px) scale(0.83955);
  animation: fall-116 19s -19s linear infinite;
}

@keyframes fall-116 {
  39.675% {
    transform: translate(97.5396vw, 39.675vh) scale(0.83955);
  }
  to {
    transform: translate(95.66125vw, 100vh) scale(0.83955);
  }
}

.snow:nth-child(117) {
  opacity: 0.3583;
  transform: translate(37.6727vw, -10px) scale(0.6792);
  animation: fall-117 23s -23s linear infinite;
}

@keyframes fall-117 {
  48.016% {
    transform: translate(46.6174vw, 48.016vh) scale(0.6792);
  }
  to {
    transform: translate(42.14505vw, 100vh) scale(0.6792);
  }
}

.snow:nth-child(118) {
  opacity: 0.0034;
  transform: translate(66.2194vw, -10px) scale(0.75795);
  animation: fall-118 22s -4s linear infinite;
}

@keyframes fall-118 {
  45.202% {
    transform: translate(65.1373vw, 45.202vh) scale(0.75795);
  }
  to {
    transform: translate(65.67835vw, 100vh) scale(0.75795);
  }
}

.snow:nth-child(119) {
  opacity: 0.8968;
  transform: translate(16.8343vw, -10px) scale(0.75315);
  animation: fall-119 13s -12s linear infinite;
}

@keyframes fall-119 {
  38.944% {
    transform: translate(23.4799vw, 38.944vh) scale(0.75315);
  }
  to {
    transform: translate(20.1571vw, 100vh) scale(0.75315);
  }
}

.snow:nth-child(120) {
  opacity: 0.2777;
  transform: translate(18.7168vw, -10px) scale(0.38745);
  animation: fall-120 10s -3s linear infinite;
}

@keyframes fall-120 {
  76.278% {
    transform: translate(11.4609vw, 76.278vh) scale(0.38745);
  }
  to {
    transform: translate(15.08885vw, 100vh) scale(0.38745);
  }
}

.snow:nth-child(121) {
  opacity: 0.6447;
  transform: translate(90.9717vw, -10px) scale(1.07475);
  animation: fall-121 18s -7s linear infinite;
}

@keyframes fall-121 {
  50.4% {
    transform: translate(84.5182vw, 50.4vh) scale(1.07475);
  }
  to {
    transform: translate(87.74495vw, 100vh) scale(1.07475);
  }
}

.snow:nth-child(122) {
  opacity: 0.5485;
  transform: translate(7.114vw, -10px) scale(0.3402);
  animation: fall-122 13s -25s linear infinite;
}

@keyframes fall-122 {
  51.243% {
    transform: translate(9.5619vw, 51.243vh) scale(0.3402);
  }
  to {
    transform: translate(8.33795vw, 100vh) scale(0.3402);
  }
}

.snow:nth-child(123) {
  opacity: 0.8284;
  transform: translate(33.7641vw, -10px) scale(1.40775);
  animation: fall-123 27s -25s linear infinite;
}

@keyframes fall-123 {
  71.673% {
    transform: translate(27.119vw, 71.673vh) scale(1.40775);
  }
  to {
    transform: translate(30.44155vw, 100vh) scale(1.40775);
  }
}

.snow:nth-child(124) {
  opacity: 0.7035;
  transform: translate(62.552vw, -10px) scale(0.8778);
  animation: fall-124 20s -20s linear infinite;
}

@keyframes fall-124 {
  55.091% {
    transform: translate(66.4592vw, 55.091vh) scale(0.8778);
  }
  to {
    transform: translate(64.5056vw, 100vh) scale(0.8778);
  }
}

.snow:nth-child(125) {
  opacity: 0.7644;
  transform: translate(43.2981vw, -10px) scale(1.4004);
  animation: fall-125 23s -16s linear infinite;
}

@keyframes fall-125 {
  55.719% {
    transform: translate(35.7173vw, 55.719vh) scale(1.4004);
  }
  to {
    transform: translate(39.5077vw, 100vh) scale(1.4004);
  }
}

.snow:nth-child(126) {
  opacity: 0.1332;
  transform: translate(52.9326vw, -10px) scale(1.42995);
  animation: fall-126 30s -1s linear infinite;
}

@keyframes fall-126 {
  79.01% {
    transform: translate(52.9868vw, 79.01vh) scale(1.42995);
  }
  to {
    transform: translate(52.9597vw, 100vh) scale(1.42995);
  }
}

.snow:nth-child(127) {
  opacity: 0.977;
  transform: translate(92.019vw, -10px) scale(1.0428);
  animation: fall-127 14s -21s linear infinite;
}

@keyframes fall-127 {
  57.821% {
    transform: translate(97.2854vw, 57.821vh) scale(1.0428);
  }
  to {
    transform: translate(94.6522vw, 100vh) scale(1.0428);
  }
}

.snow:nth-child(128) {
  opacity: 0.9965;
  transform: translate(32.9049vw, -10px) scale(0.71595);
  animation: fall-128 13s -12s linear infinite;
}

@keyframes fall-128 {
  71.64% {
    transform: translate(33.377vw, 71.64vh) scale(0.71595);
  }
  to {
    transform: translate(33.14095vw, 100vh) scale(0.71595);
  }
}

.snow:nth-child(129) {
  opacity: 0.5839;
  transform: translate(16.9795vw, -10px) scale(0.10515);
  animation: fall-129 29s -15s linear infinite;
}

@keyframes fall-129 {
  57.247% {
    transform: translate(26.4133vw, 57.247vh) scale(0.10515);
  }
  to {
    transform: translate(21.6964vw, 100vh) scale(0.10515);
  }
}

.snow:nth-child(130) {
  opacity: 0.8392;
  transform: translate(69.2124vw, -10px) scale(0.4596);
  animation: fall-130 17s -23s linear infinite;
}

@keyframes fall-130 {
  49.024% {
    transform: translate(65.1272vw, 49.024vh) scale(0.4596);
  }
  to {
    transform: translate(67.1698vw, 100vh) scale(0.4596);
  }
}

.snow:nth-child(131) {
  opacity: 0.0124;
  transform: translate(17.5871vw, -10px) scale(0.4863);
  animation: fall-131 23s -28s linear infinite;
}

@keyframes fall-131 {
  60.902% {
    transform: translate(17.3828vw, 60.902vh) scale(0.4863);
  }
  to {
    transform: translate(17.48495vw, 100vh) scale(0.4863);
  }
}

.snow:nth-child(132) {
  opacity: 0.8948;
  transform: translate(47.2354vw, -10px) scale(0.5814);
  animation: fall-132 28s -10s linear infinite;
}

@keyframes fall-132 {
  57.595% {
    transform: translate(52.856vw, 57.595vh) scale(0.5814);
  }
  to {
    transform: translate(50.0457vw, 100vh) scale(0.5814);
  }
}

.snow:nth-child(133) {
  opacity: 0.3187;
  transform: translate(69.9694vw, -10px) scale(0.96675);
  animation: fall-133 23s -12s linear infinite;
}

@keyframes fall-133 {
  61.371% {
    transform: translate(63.4414vw, 61.371vh) scale(0.96675);
  }
  to {
    transform: translate(66.7054vw, 100vh) scale(0.96675);
  }
}

.snow:nth-child(134) {
  opacity: 0.8067;
  transform: translate(89.6778vw, -10px) scale(0.43095);
  animation: fall-134 19s -2s linear infinite;
}

@keyframes fall-134 {
  64.236% {
    transform: translate(92.6998vw, 64.236vh) scale(0.43095);
  }
  to {
    transform: translate(91.1888vw, 100vh) scale(0.43095);
  }
}

.snow:nth-child(135) {
  opacity: 0.1526;
  transform: translate(98.1025vw, -10px) scale(0.483);
  animation: fall-135 21s -18s linear infinite;
}

@keyframes fall-135 {
  34.641% {
    transform: translate(97.4094vw, 34.641vh) scale(0.483);
  }
  to {
    transform: translate(97.75595vw, 100vh) scale(0.483);
  }
}

.snow:nth-child(136) {
  opacity: 0.8542;
  transform: translate(7.8635vw, -10px) scale(0.624);
  animation: fall-136 26s -29s linear infinite;
}

@keyframes fall-136 {
  36.447% {
    transform: translate(14.7625vw, 36.447vh) scale(0.624);
  }
  to {
    transform: translate(11.313vw, 100vh) scale(0.624);
  }
}

.snow:nth-child(137) {
  opacity: 0.8088;
  transform: translate(92.3082vw, -10px) scale(1.34655);
  animation: fall-137 19s -9s linear infinite;
}

@keyframes fall-137 {
  63.063% {
    transform: translate(98.4833vw, 63.063vh) scale(1.34655);
  }
  to {
    transform: translate(95.39575vw, 100vh) scale(1.34655);
  }
}

.snow:nth-child(138) {
  opacity: 0.4171;
  transform: translate(94.5634vw, -10px) scale(0.80295);
  animation: fall-138 17s -9s linear infinite;
}

@keyframes fall-138 {
  57.197% {
    transform: translate(102.7995vw, 57.197vh) scale(0.80295);
  }
  to {
    transform: translate(98.68145vw, 100vh) scale(0.80295);
  }
}

.snow:nth-child(139) {
  opacity: 0.282;
  transform: translate(94.4211vw, -10px) scale(0.74025);
  animation: fall-139 12s -16s linear infinite;
}

@keyframes fall-139 {
  68.815% {
    transform: translate(88.344vw, 68.815vh) scale(0.74025);
  }
  to {
    transform: translate(91.38255vw, 100vh) scale(0.74025);
  }
}

.snow:nth-child(140) {
  opacity: 0.9317;
  transform: translate(59.4987vw, -10px) scale(1.14615);
  animation: fall-140 26s -30s linear infinite;
}

@keyframes fall-140 {
  34.335% {
    transform: translate(55.5023vw, 34.335vh) scale(1.14615);
  }
  to {
    transform: translate(57.5005vw, 100vh) scale(1.14615);
  }
}

.snow:nth-child(141) {
  opacity: 0.2675;
  transform: translate(89.7318vw, -10px) scale(0.6852);
  animation: fall-141 10s -2s linear infinite;
}

@keyframes fall-141 {
  40.501% {
    transform: translate(96.9602vw, 40.501vh) scale(0.6852);
  }
  to {
    transform: translate(93.346vw, 100vh) scale(0.6852);
  }
}

.snow:nth-child(142) {
  opacity: 0.7293;
  transform: translate(62.7624vw, -10px) scale(0.0111);
  animation: fall-142 20s -8s linear infinite;
}

@keyframes fall-142 {
  78.372% {
    transform: translate(65.1592vw, 78.372vh) scale(0.0111);
  }
  to {
    transform: translate(63.9608vw, 100vh) scale(0.0111);
  }
}

.snow:nth-child(143) {
  opacity: 0.2537;
  transform: translate(45.6604vw, -10px) scale(1.4595);
  animation: fall-143 15s -19s linear infinite;
}

@keyframes fall-143 {
  78.377% {
    transform: translate(43.6714vw, 78.377vh) scale(1.4595);
  }
  to {
    transform: translate(44.6659vw, 100vh) scale(1.4595);
  }
}

.snow:nth-child(144) {
  opacity: 0.8211;
  transform: translate(48.471vw, -10px) scale(0.57435);
  animation: fall-144 13s -6s linear infinite;
}

@keyframes fall-144 {
  30.497% {
    transform: translate(52.6976vw, 30.497vh) scale(0.57435);
  }
  to {
    transform: translate(50.5843vw, 100vh) scale(0.57435);
  }
}

.snow:nth-child(145) {
  opacity: 0.0661;
  transform: translate(64.0036vw, -10px) scale(0.048);
  animation: fall-145 19s -15s linear infinite;
}

@keyframes fall-145 {
  60.359% {
    transform: translate(55.7587vw, 60.359vh) scale(0.048);
  }
  to {
    transform: translate(59.88115vw, 100vh) scale(0.048);
  }
}

.snow:nth-child(146) {
  opacity: 0.9182;
  transform: translate(90.2906vw, -10px) scale(0.77745);
  animation: fall-146 26s -10s linear infinite;
}

@keyframes fall-146 {
  63.223% {
    transform: translate(88.5763vw, 63.223vh) scale(0.77745);
  }
  to {
    transform: translate(89.43345vw, 100vh) scale(0.77745);
  }
}

.snow:nth-child(147) {
  opacity: 0.9103;
  transform: translate(98.1019vw, -10px) scale(0.8931);
  animation: fall-147 20s -4s linear infinite;
}

@keyframes fall-147 {
  55.383% {
    transform: translate(99.0377vw, 55.383vh) scale(0.8931);
  }
  to {
    transform: translate(98.5698vw, 100vh) scale(0.8931);
  }
}

.snow:nth-child(148) {
  opacity: 0.9798;
  transform: translate(74.6794vw, -10px) scale(1.18935);
  animation: fall-148 12s -17s linear infinite;
}

@keyframes fall-148 {
  75.418% {
    transform: translate(79.7174vw, 75.418vh) scale(1.18935);
  }
  to {
    transform: translate(77.1984vw, 100vh) scale(1.18935);
  }
}

.snow:nth-child(149) {
  opacity: 0.7324;
  transform: translate(8.6056vw, -10px) scale(0.46635);
  animation: fall-149 10s -5s linear infinite;
}

@keyframes fall-149 {
  39.965% {
    transform: translate(3.3904vw, 39.965vh) scale(0.46635);
  }
  to {
    transform: translate(5.998vw, 100vh) scale(0.46635);
  }
}

.snow:nth-child(150) {
  opacity: 0.7736;
  transform: translate(50.8887vw, -10px) scale(1.31175);
  animation: fall-150 23s -7s linear infinite;
}

@keyframes fall-150 {
  36.337% {
    transform: translate(49.5731vw, 36.337vh) scale(1.31175);
  }
  to {
    transform: translate(50.2309vw, 100vh) scale(1.31175);
  }
}

.snow:nth-child(151) {
  opacity: 0.5347;
  transform: translate(3.8587vw, -10px) scale(1.47585);
  animation: fall-151 30s -6s linear infinite;
}

@keyframes fall-151 {
  33.614% {
    transform: translate(11.0603vw, 33.614vh) scale(1.47585);
  }
  to {
    transform: translate(7.4595vw, 100vh) scale(1.47585);
  }
}

.snow:nth-child(152) {
  opacity: 0.5354;
  transform: translate(22.6049vw, -10px) scale(1.15005);
  animation: fall-152 12s -14s linear infinite;
}

@keyframes fall-152 {
  34.105% {
    transform: translate(15.393vw, 34.105vh) scale(1.15005);
  }
  to {
    transform: translate(18.99895vw, 100vh) scale(1.15005);
  }
}

.snow:nth-child(153) {
  opacity: 0.8821;
  transform: translate(13.8632vw, -10px) scale(0.1062);
  animation: fall-153 12s -10s linear infinite;
}

@keyframes fall-153 {
  61.72% {
    transform: translate(14.5011vw, 61.72vh) scale(0.1062);
  }
  to {
    transform: translate(14.18215vw, 100vh) scale(0.1062);
  }
}

.snow:nth-child(154) {
  opacity: 0.3235;
  transform: translate(89.5787vw, -10px) scale(0.4917);
  animation: fall-154 16s -11s linear infinite;
}

@keyframes fall-154 {
  75.39% {
    transform: translate(92.8463vw, 75.39vh) scale(0.4917);
  }
  to {
    transform: translate(91.2125vw, 100vh) scale(0.4917);
  }
}

.snow:nth-child(155) {
  opacity: 0.2713;
  transform: translate(16.3476vw, -10px) scale(0.06285);
  animation: fall-155 27s -17s linear infinite;
}

@keyframes fall-155 {
  65.1% {
    transform: translate(17.2867vw, 65.1vh) scale(0.06285);
  }
  to {
    transform: translate(16.81715vw, 100vh) scale(0.06285);
  }
}

.snow:nth-child(156) {
  opacity: 0.6882;
  transform: translate(0.0068vw, -10px) scale(1.3515);
  animation: fall-156 13s -13s linear infinite;
}

@keyframes fall-156 {
  30.142% {
    transform: translate(-2.1893vw, 30.142vh) scale(1.3515);
  }
  to {
    transform: translate(-1.09125vw, 100vh) scale(1.3515);
  }
}

.snow:nth-child(157) {
  opacity: 0.441;
  transform: translate(56.7525vw, -10px) scale(1.11615);
  animation: fall-157 23s -16s linear infinite;
}

@keyframes fall-157 {
  54.699% {
    transform: translate(56.7238vw, 54.699vh) scale(1.11615);
  }
  to {
    transform: translate(56.73815vw, 100vh) scale(1.11615);
  }
}

.snow:nth-child(158) {
  opacity: 0.1538;
  transform: translate(12.8476vw, -10px) scale(0.8424);
  animation: fall-158 22s -14s linear infinite;
}

@keyframes fall-158 {
  68.311% {
    transform: translate(13.7413vw, 68.311vh) scale(0.8424);
  }
  to {
    transform: translate(13.29445vw, 100vh) scale(0.8424);
  }
}

.snow:nth-child(159) {
  opacity: 0.613;
  transform: translate(68.6662vw, -10px) scale(1.0734);
  animation: fall-159 10s -1s linear infinite;
}

@keyframes fall-159 {
  77.635% {
    transform: translate(67.602vw, 77.635vh) scale(1.0734);
  }
  to {
    transform: translate(68.1341vw, 100vh) scale(1.0734);
  }
}

.snow:nth-child(160) {
  opacity: 0.6783;
  transform: translate(78.6806vw, -10px) scale(1.0929);
  animation: fall-160 11s -24s linear infinite;
}

@keyframes fall-160 {
  46.015% {
    transform: translate(86.0184vw, 46.015vh) scale(1.0929);
  }
  to {
    transform: translate(82.3495vw, 100vh) scale(1.0929);
  }
}

.snow:nth-child(161) {
  opacity: 0.0166;
  transform: translate(5.7272vw, -10px) scale(0.35625);
  animation: fall-161 17s -30s linear infinite;
}

@keyframes fall-161 {
  66.47% {
    transform: translate(13.0042vw, 66.47vh) scale(0.35625);
  }
  to {
    transform: translate(9.3657vw, 100vh) scale(0.35625);
  }
}

.snow:nth-child(162) {
  opacity: 0.1538;
  transform: translate(41.5474vw, -10px) scale(0.21975);
  animation: fall-162 24s -9s linear infinite;
}

@keyframes fall-162 {
  32.104% {
    transform: translate(34.2149vw, 32.104vh) scale(0.21975);
  }
  to {
    transform: translate(37.88115vw, 100vh) scale(0.21975);
  }
}

.snow:nth-child(163) {
  opacity: 0.9017;
  transform: translate(29.6563vw, -10px) scale(1.4664);
  animation: fall-163 13s -8s linear infinite;
}

@keyframes fall-163 {
  36.493% {
    transform: translate(37.1882vw, 36.493vh) scale(1.4664);
  }
  to {
    transform: translate(33.42225vw, 100vh) scale(1.4664);
  }
}

.snow:nth-child(164) {
  opacity: 0.9543;
  transform: translate(47.6361vw, -10px) scale(0.6126);
  animation: fall-164 18s -4s linear infinite;
}

@keyframes fall-164 {
  74.92% {
    transform: translate(52.5205vw, 74.92vh) scale(0.6126);
  }
  to {
    transform: translate(50.0783vw, 100vh) scale(0.6126);
  }
}

.snow:nth-child(165) {
  opacity: 0.8865;
  transform: translate(32.1492vw, -10px) scale(1.31025);
  animation: fall-165 19s -2s linear infinite;
}

@keyframes fall-165 {
  31.566% {
    transform: translate(29.363vw, 31.566vh) scale(1.31025);
  }
  to {
    transform: translate(30.7561vw, 100vh) scale(1.31025);
  }
}

.snow:nth-child(166) {
  opacity: 0.4058;
  transform: translate(10.3806vw, -10px) scale(1.12725);
  animation: fall-166 30s -8s linear infinite;
}

@keyframes fall-166 {
  70.002% {
    transform: translate(9.0529vw, 70.002vh) scale(1.12725);
  }
  to {
    transform: translate(9.71675vw, 100vh) scale(1.12725);
  }
}

.snow:nth-child(167) {
  opacity: 0.0713;
  transform: translate(42.2213vw, -10px) scale(0.8109);
  animation: fall-167 19s -1s linear infinite;
}

@keyframes fall-167 {
  44.048% {
    transform: translate(37.8263vw, 44.048vh) scale(0.8109);
  }
  to {
    transform: translate(40.0238vw, 100vh) scale(0.8109);
  }
}

.snow:nth-child(168) {
  opacity: 0.3568;
  transform: translate(50.7343vw, -10px) scale(0.999);
  animation: fall-168 19s -3s linear infinite;
}

@keyframes fall-168 {
  64.296% {
    transform: translate(59.92vw, 64.296vh) scale(0.999);
  }
  to {
    transform: translate(55.32715vw, 100vh) scale(0.999);
  }
}

.snow:nth-child(169) {
  opacity: 0.3413;
  transform: translate(9.8135vw, -10px) scale(1.3104);
  animation: fall-169 19s -7s linear infinite;
}

@keyframes fall-169 {
  45.708% {
    transform: translate(0.6618vw, 45.708vh) scale(1.3104);
  }
  to {
    transform: translate(5.23765vw, 100vh) scale(1.3104);
  }
}

.snow:nth-child(170) {
  opacity: 0.9886;
  transform: translate(18.3295vw, -10px) scale(0.32565);
  animation: fall-170 15s -3s linear infinite;
}

@keyframes fall-170 {
  59.111% {
    transform: translate(12.1894vw, 59.111vh) scale(0.32565);
  }
  to {
    transform: translate(15.25945vw, 100vh) scale(0.32565);
  }
}

.snow:nth-child(171) {
  opacity: 0.9529;
  transform: translate(34.0836vw, -10px) scale(0.40035);
  animation: fall-171 30s -15s linear infinite;
}

@keyframes fall-171 {
  50.129% {
    transform: translate(43.142vw, 50.129vh) scale(0.40035);
  }
  to {
    transform: translate(38.6128vw, 100vh) scale(0.40035);
  }
}

.snow:nth-child(172) {
  opacity: 0.4123;
  transform: translate(54.2504vw, -10px) scale(1.23765);
  animation: fall-172 21s -19s linear infinite;
}

@keyframes fall-172 {
  33.219% {
    transform: translate(58.4166vw, 33.219vh) scale(1.23765);
  }
  to {
    transform: translate(56.3335vw, 100vh) scale(1.23765);
  }
}

.snow:nth-child(173) {
  opacity: 0.3795;
  transform: translate(64.578vw, -10px) scale(0.4734);
  animation: fall-173 24s -23s linear infinite;
}

@keyframes fall-173 {
  46.864% {
    transform: translate(56.8536vw, 46.864vh) scale(0.4734);
  }
  to {
    transform: translate(60.7158vw, 100vh) scale(0.4734);
  }
}

.snow:nth-child(174) {
  opacity: 0.967;
  transform: translate(26.5744vw, -10px) scale(0.4584);
  animation: fall-174 30s -4s linear infinite;
}

@keyframes fall-174 {
  54.595% {
    transform: translate(34.8465vw, 54.595vh) scale(0.4584);
  }
  to {
    transform: translate(30.71045vw, 100vh) scale(0.4584);
  }
}

.snow:nth-child(175) {
  opacity: 0.4711;
  transform: translate(26.6432vw, -10px) scale(0.93735);
  animation: fall-175 17s -26s linear infinite;
}

@keyframes fall-175 {
  46.604% {
    transform: translate(21.6363vw, 46.604vh) scale(0.93735);
  }
  to {
    transform: translate(24.13975vw, 100vh) scale(0.93735);
  }
}

.snow:nth-child(176) {
  opacity: 0.1571;
  transform: translate(67.3073vw, -10px) scale(1.03785);
  animation: fall-176 15s -24s linear infinite;
}

@keyframes fall-176 {
  71.726% {
    transform: translate(69.7892vw, 71.726vh) scale(1.03785);
  }
  to {
    transform: translate(68.54825vw, 100vh) scale(1.03785);
  }
}

.snow:nth-child(177) {
  opacity: 0.382;
  transform: translate(86.9621vw, -10px) scale(1.45125);
  animation: fall-177 28s -8s linear infinite;
}

@keyframes fall-177 {
  48.61% {
    transform: translate(84.3395vw, 48.61vh) scale(1.45125);
  }
  to {
    transform: translate(85.6508vw, 100vh) scale(1.45125);
  }
}

.snow:nth-child(178) {
  opacity: 0.3054;
  transform: translate(54.8677vw, -10px) scale(1.07625);
  animation: fall-178 19s -9s linear infinite;
}

@keyframes fall-178 {
  69.205% {
    transform: translate(51.9575vw, 69.205vh) scale(1.07625);
  }
  to {
    transform: translate(53.4126vw, 100vh) scale(1.07625);
  }
}

.snow:nth-child(179) {
  opacity: 0.7297;
  transform: translate(63.6709vw, -10px) scale(0.8811);
  animation: fall-179 22s -17s linear infinite;
}

@keyframes fall-179 {
  33.871% {
    transform: translate(72.2684vw, 33.871vh) scale(0.8811);
  }
  to {
    transform: translate(67.96965vw, 100vh) scale(0.8811);
  }
}

.snow:nth-child(180) {
  opacity: 0.4825;
  transform: translate(87.3645vw, -10px) scale(0.68115);
  animation: fall-180 10s -6s linear infinite;
}

@keyframes fall-180 {
  73.329% {
    transform: translate(84.5568vw, 73.329vh) scale(0.68115);
  }
  to {
    transform: translate(85.96065vw, 100vh) scale(0.68115);
  }
}

.snow:nth-child(181) {
  opacity: 0.7405;
  transform: translate(74.5475vw, -10px) scale(0.7173);
  animation: fall-181 13s -28s linear infinite;
}

@keyframes fall-181 {
  70.73% {
    transform: translate(81.5297vw, 70.73vh) scale(0.7173);
  }
  to {
    transform: translate(78.0386vw, 100vh) scale(0.7173);
  }
}

.snow:nth-child(182) {
  opacity: 0.9984;
  transform: translate(59.73vw, -10px) scale(1.45425);
  animation: fall-182 28s -12s linear infinite;
}

@keyframes fall-182 {
  63.176% {
    transform: translate(51.8487vw, 63.176vh) scale(1.45425);
  }
  to {
    transform: translate(55.78935vw, 100vh) scale(1.45425);
  }
}

.snow:nth-child(183) {
  opacity: 0.4758;
  transform: translate(17.5193vw, -10px) scale(1.1289);
  animation: fall-183 27s -1s linear infinite;
}

@keyframes fall-183 {
  59.697% {
    transform: translate(22.2782vw, 59.697vh) scale(1.1289);
  }
  to {
    transform: translate(19.89875vw, 100vh) scale(1.1289);
  }
}

.snow:nth-child(184) {
  opacity: 0.4108;
  transform: translate(13.3315vw, -10px) scale(1.28925);
  animation: fall-184 27s -8s linear infinite;
}

@keyframes fall-184 {
  71.909% {
    transform: translate(23.0846vw, 71.909vh) scale(1.28925);
  }
  to {
    transform: translate(18.20805vw, 100vh) scale(1.28925);
  }
}

.snow:nth-child(185) {
  opacity: 0.0886;
  transform: translate(97.2336vw, -10px) scale(1.2528);
  animation: fall-185 19s -27s linear infinite;
}

@keyframes fall-185 {
  57.361% {
    transform: translate(90.4091vw, 57.361vh) scale(1.2528);
  }
  to {
    transform: translate(93.82135vw, 100vh) scale(1.2528);
  }
}

.snow:nth-child(186) {
  opacity: 0.8318;
  transform: translate(58.0554vw, -10px) scale(0.82965);
  animation: fall-186 18s -18s linear infinite;
}

@keyframes fall-186 {
  61.885% {
    transform: translate(55.6671vw, 61.885vh) scale(0.82965);
  }
  to {
    transform: translate(56.86125vw, 100vh) scale(0.82965);
  }
}

.snow:nth-child(187) {
  opacity: 0.2533;
  transform: translate(82.0754vw, -10px) scale(1.46985);
  animation: fall-187 17s -14s linear infinite;
}

@keyframes fall-187 {
  53.082% {
    transform: translate(84.8874vw, 53.082vh) scale(1.46985);
  }
  to {
    transform: translate(83.4814vw, 100vh) scale(1.46985);
  }
}

.snow:nth-child(188) {
  opacity: 0.7093;
  transform: translate(83.1711vw, -10px) scale(0.09645);
  animation: fall-188 13s -25s linear infinite;
}

@keyframes fall-188 {
  42.792% {
    transform: translate(75.6606vw, 42.792vh) scale(0.09645);
  }
  to {
    transform: translate(79.41585vw, 100vh) scale(0.09645);
  }
}

.snow:nth-child(189) {
  opacity: 0.5119;
  transform: translate(2.4411vw, -10px) scale(1.0722);
  animation: fall-189 13s -30s linear infinite;
}

@keyframes fall-189 {
  34.579% {
    transform: translate(9.0293vw, 34.579vh) scale(1.0722);
  }
  to {
    transform: translate(5.7352vw, 100vh) scale(1.0722);
  }
}

.snow:nth-child(190) {
  opacity: 0.8622;
  transform: translate(85.9779vw, -10px) scale(0.86955);
  animation: fall-190 25s -12s linear infinite;
}

@keyframes fall-190 {
  72.74% {
    transform: translate(95.0689vw, 72.74vh) scale(0.86955);
  }
  to {
    transform: translate(90.5234vw, 100vh) scale(0.86955);
  }
}

.snow:nth-child(191) {
  opacity: 0.2923;
  transform: translate(83.5665vw, -10px) scale(1.16685);
  animation: fall-191 18s -24s linear infinite;
}

@keyframes fall-191 {
  31.314% {
    transform: translate(87.5587vw, 31.314vh) scale(1.16685);
  }
  to {
    transform: translate(85.5626vw, 100vh) scale(1.16685);
  }
}

.snow:nth-child(192) {
  opacity: 0.3498;
  transform: translate(62.4459vw, -10px) scale(1.2687);
  animation: fall-192 19s -25s linear infinite;
}

@keyframes fall-192 {
  73.479% {
    transform: translate(57.7177vw, 73.479vh) scale(1.2687);
  }
  to {
    transform: translate(60.0818vw, 100vh) scale(1.2687);
  }
}

.snow:nth-child(193) {
  opacity: 0.6101;
  transform: translate(35.4576vw, -10px) scale(0.5556);
  animation: fall-193 18s -10s linear infinite;
}

@keyframes fall-193 {
  58.065% {
    transform: translate(25.4608vw, 58.065vh) scale(0.5556);
  }
  to {
    transform: translate(30.4592vw, 100vh) scale(0.5556);
  }
}

.snow:nth-child(194) {
  opacity: 0.7511;
  transform: translate(35.2759vw, -10px) scale(0.79995);
  animation: fall-194 11s -7s linear infinite;
}

@keyframes fall-194 {
  69.763% {
    transform: translate(42.3919vw, 69.763vh) scale(0.79995);
  }
  to {
    transform: translate(38.8339vw, 100vh) scale(0.79995);
  }
}

.snow:nth-child(195) {
  opacity: 0.7989;
  transform: translate(10.3015vw, -10px) scale(0.35835);
  animation: fall-195 23s -2s linear infinite;
}

@keyframes fall-195 {
  62.438% {
    transform: translate(6.5405vw, 62.438vh) scale(0.35835);
  }
  to {
    transform: translate(8.421vw, 100vh) scale(0.35835);
  }
}

.snow:nth-child(196) {
  opacity: 0.1595;
  transform: translate(57.6272vw, -10px) scale(0.6966);
  animation: fall-196 29s -7s linear infinite;
}

@keyframes fall-196 {
  42.537% {
    transform: translate(54.9716vw, 42.537vh) scale(0.6966);
  }
  to {
    transform: translate(56.2994vw, 100vh) scale(0.6966);
  }
}

.snow:nth-child(197) {
  opacity: 0.4849;
  transform: translate(39.9682vw, -10px) scale(1.2129);
  animation: fall-197 29s -16s linear infinite;
}

@keyframes fall-197 {
  45.25% {
    transform: translate(32.1448vw, 45.25vh) scale(1.2129);
  }
  to {
    transform: translate(36.0565vw, 100vh) scale(1.2129);
  }
}

.snow:nth-child(198) {
  opacity: 0.1043;
  transform: translate(88.1137vw, -10px) scale(1.2429);
  animation: fall-198 28s -9s linear infinite;
}

@keyframes fall-198 {
  56.883% {
    transform: translate(86.1995vw, 56.883vh) scale(1.2429);
  }
  to {
    transform: translate(87.1566vw, 100vh) scale(1.2429);
  }
}

.snow:nth-child(199) {
  opacity: 0.6184;
  transform: translate(30.0132vw, -10px) scale(0.92835);
  animation: fall-199 21s -19s linear infinite;
}

@keyframes fall-199 {
  34.129% {
    transform: translate(26.7977vw, 34.129vh) scale(0.92835);
  }
  to {
    transform: translate(28.40545vw, 100vh) scale(0.92835);
  }
}

.snow:nth-child(200) {
  opacity: 0.4893;
  transform: translate(92.793vw, -10px) scale(1.32135);
  animation: fall-200 23s -7s linear infinite;
}

@keyframes fall-200 {
  52.079% {
    transform: translate(102.6419vw, 52.079vh) scale(1.32135);
  }
  to {
    transform: translate(97.71745vw, 100vh) scale(1.32135);
  }
}

.snow:nth-child(201) {
  opacity: 0.8557;
  transform: translate(43.6915vw, -10px) scale(0.0096);
  animation: fall-201 10s -23s linear infinite;
}

@keyframes fall-201 {
  56.106% {
    transform: translate(44.6008vw, 56.106vh) scale(0.0096);
  }
  to {
    transform: translate(44.14615vw, 100vh) scale(0.0096);
  }
}

.snow:nth-child(202) {
  opacity: 0.5471;
  transform: translate(6.6896vw, -10px) scale(0.69);
  animation: fall-202 22s -22s linear infinite;
}

@keyframes fall-202 {
  65.982% {
    transform: translate(6.7409vw, 65.982vh) scale(0.69);
  }
  to {
    transform: translate(6.71525vw, 100vh) scale(0.69);
  }
}

.snow:nth-child(203) {
  opacity: 0.6007;
  transform: translate(36.4669vw, -10px) scale(0.45435);
  animation: fall-203 16s -4s linear infinite;
}

@keyframes fall-203 {
  61.597% {
    transform: translate(38.3684vw, 61.597vh) scale(0.45435);
  }
  to {
    transform: translate(37.41765vw, 100vh) scale(0.45435);
  }
}

.snow:nth-child(204) {
  opacity: 0.8359;
  transform: translate(49.2373vw, -10px) scale(0.69315);
  animation: fall-204 15s -24s linear infinite;
}

@keyframes fall-204 {
  50.373% {
    transform: translate(46.1331vw, 50.373vh) scale(0.69315);
  }
  to {
    transform: translate(47.6852vw, 100vh) scale(0.69315);
  }
}

.snow:nth-child(205) {
  opacity: 0.1654;
  transform: translate(38.9845vw, -10px) scale(1.4688);
  animation: fall-205 13s -27s linear infinite;
}

@keyframes fall-205 {
  48.758% {
    transform: translate(47.044vw, 48.758vh) scale(1.4688);
  }
  to {
    transform: translate(43.01425vw, 100vh) scale(1.4688);
  }
}

.snow:nth-child(206) {
  opacity: 0.948;
  transform: translate(70.8218vw, -10px) scale(0.85095);
  animation: fall-206 26s -14s linear infinite;
}

@keyframes fall-206 {
  52.735% {
    transform: translate(77.3671vw, 52.735vh) scale(0.85095);
  }
  to {
    transform: translate(74.09445vw, 100vh) scale(0.85095);
  }
}

.snow:nth-child(207) {
  opacity: 0.3749;
  transform: translate(13.9273vw, -10px) scale(0.38865);
  animation: fall-207 29s -9s linear infinite;
}

@keyframes fall-207 {
  74.096% {
    transform: translate(10.8531vw, 74.096vh) scale(0.38865);
  }
  to {
    transform: translate(12.3902vw, 100vh) scale(0.38865);
  }
}

.snow:nth-child(208) {
  opacity: 0.9765;
  transform: translate(58.2556vw, -10px) scale(0.76905);
  animation: fall-208 10s -18s linear infinite;
}

@keyframes fall-208 {
  55.559% {
    transform: translate(54.4517vw, 55.559vh) scale(0.76905);
  }
  to {
    transform: translate(56.35365vw, 100vh) scale(0.76905);
  }
}

.snow:nth-child(209) {
  opacity: 0.0995;
  transform: translate(7.8749vw, -10px) scale(1.13985);
  animation: fall-209 29s -22s linear infinite;
}

@keyframes fall-209 {
  54.758% {
    transform: translate(9.1976vw, 54.758vh) scale(1.13985);
  }
  to {
    transform: translate(8.53625vw, 100vh) scale(1.13985);
  }
}

.snow:nth-child(210) {
  opacity: 0.9585;
  transform: translate(90.7894vw, -10px) scale(1.2723);
  animation: fall-210 15s -17s linear infinite;
}

@keyframes fall-210 {
  58.654% {
    transform: translate(89.6084vw, 58.654vh) scale(1.2723);
  }
  to {
    transform: translate(90.1989vw, 100vh) scale(1.2723);
  }
}

.snow:nth-child(211) {
  opacity: 0.0476;
  transform: translate(55.389vw, -10px) scale(0.9051);
  animation: fall-211 28s -13s linear infinite;
}

@keyframes fall-211 {
  30.198% {
    transform: translate(60.4812vw, 30.198vh) scale(0.9051);
  }
  to {
    transform: translate(57.9351vw, 100vh) scale(0.9051);
  }
}

.snow:nth-child(212) {
  opacity: 0.9826;
  transform: translate(13.2574vw, -10px) scale(1.49325);
  animation: fall-212 21s -3s linear infinite;
}

@keyframes fall-212 {
  40.034% {
    transform: translate(14.7626vw, 40.034vh) scale(1.49325);
  }
  to {
    transform: translate(14.01vw, 100vh) scale(1.49325);
  }
}

.snow:nth-child(213) {
  opacity: 0.5513;
  transform: translate(17.7462vw, -10px) scale(0.4881);
  animation: fall-213 23s -16s linear infinite;
}

@keyframes fall-213 {
  35.065% {
    transform: translate(12.7298vw, 35.065vh) scale(0.4881);
  }
  to {
    transform: translate(15.238vw, 100vh) scale(0.4881);
  }
}

.snow:nth-child(214) {
  opacity: 0.811;
  transform: translate(14.7678vw, -10px) scale(0.37905);
  animation: fall-214 27s -3s linear infinite;
}

@keyframes fall-214 {
  68.323% {
    transform: translate(22.8885vw, 68.323vh) scale(0.37905);
  }
  to {
    transform: translate(18.82815vw, 100vh) scale(0.37905);
  }
}

.snow:nth-child(215) {
  opacity: 0.049;
  transform: translate(56.7707vw, -10px) scale(0.90195);
  animation: fall-215 21s -11s linear infinite;
}

@keyframes fall-215 {
  61.131% {
    transform: translate(61.187vw, 61.131vh) scale(0.90195);
  }
  to {
    transform: translate(58.97885vw, 100vh) scale(0.90195);
  }
}

.snow:nth-child(216) {
  opacity: 0.4823;
  transform: translate(35.098vw, -10px) scale(0.8925);
  animation: fall-216 30s -22s linear infinite;
}

@keyframes fall-216 {
  47.707% {
    transform: translate(28.727vw, 47.707vh) scale(0.8925);
  }
  to {
    transform: translate(31.9125vw, 100vh) scale(0.8925);
  }
}

.snow:nth-child(217) {
  opacity: 0.3755;
  transform: translate(56.9012vw, -10px) scale(1.07175);
  animation: fall-217 30s -2s linear infinite;
}

@keyframes fall-217 {
  46.283% {
    transform: translate(64.3154vw, 46.283vh) scale(1.07175);
  }
  to {
    transform: translate(60.6083vw, 100vh) scale(1.07175);
  }
}

.snow:nth-child(218) {
  opacity: 0.8409;
  transform: translate(19.0239vw, -10px) scale(0.52965);
  animation: fall-218 25s -28s linear infinite;
}

@keyframes fall-218 {
  75.08% {
    transform: translate(11.5755vw, 75.08vh) scale(0.52965);
  }
  to {
    transform: translate(15.2997vw, 100vh) scale(0.52965);
  }
}

.snow:nth-child(219) {
  opacity: 0.7649;
  transform: translate(81.6093vw, -10px) scale(0.8916);
  animation: fall-219 27s -13s linear infinite;
}

@keyframes fall-219 {
  50.935% {
    transform: translate(81.9539vw, 50.935vh) scale(0.8916);
  }
  to {
    transform: translate(81.7816vw, 100vh) scale(0.8916);
  }
}

.snow:nth-child(220) {
  opacity: 0.0112;
  transform: translate(27.8481vw, -10px) scale(0.7773);
  animation: fall-220 21s -7s linear infinite;
}

@keyframes fall-220 {
  70.601% {
    transform: translate(35.6719vw, 70.601vh) scale(0.7773);
  }
  to {
    transform: translate(31.76vw, 100vh) scale(0.7773);
  }
}

.snow:nth-child(221) {
  opacity: 0.2373;
  transform: translate(16.8053vw, -10px) scale(1.1043);
  animation: fall-221 16s -22s linear infinite;
}

@keyframes fall-221 {
  56.567% {
    transform: translate(8.8277vw, 56.567vh) scale(1.1043);
  }
  to {
    transform: translate(12.8165vw, 100vh) scale(1.1043);
  }
}

.snow:nth-child(222) {
  opacity: 0.564;
  transform: translate(34.7405vw, -10px) scale(0.8862);
  animation: fall-222 11s -9s linear infinite;
}

@keyframes fall-222 {
  73.771% {
    transform: translate(32.1101vw, 73.771vh) scale(0.8862);
  }
  to {
    transform: translate(33.4253vw, 100vh) scale(0.8862);
  }
}

.snow:nth-child(223) {
  opacity: 0.7959;
  transform: translate(87.0707vw, -10px) scale(0.3774);
  animation: fall-223 24s -17s linear infinite;
}

@keyframes fall-223 {
  62.093% {
    transform: translate(78.1144vw, 62.093vh) scale(0.3774);
  }
  to {
    transform: translate(82.59255vw, 100vh) scale(0.3774);
  }
}

.snow:nth-child(224) {
  opacity: 0.1569;
  transform: translate(42.3961vw, -10px) scale(0.9495);
  animation: fall-224 23s -13s linear infinite;
}

@keyframes fall-224 {
  55.503% {
    transform: translate(42.6744vw, 55.503vh) scale(0.9495);
  }
  to {
    transform: translate(42.53525vw, 100vh) scale(0.9495);
  }
}

.snow:nth-child(225) {
  opacity: 0.2187;
  transform: translate(23.3647vw, -10px) scale(0.50775);
  animation: fall-225 24s -11s linear infinite;
}

@keyframes fall-225 {
  40.308% {
    transform: translate(23.5245vw, 40.308vh) scale(0.50775);
  }
  to {
    transform: translate(23.4446vw, 100vh) scale(0.50775);
  }
}

.snow:nth-child(226) {
  opacity: 0.9303;
  transform: translate(33.2507vw, -10px) scale(0.60675);
  animation: fall-226 20s -18s linear infinite;
}

@keyframes fall-226 {
  44.001% {
    transform: translate(39.0905vw, 44.001vh) scale(0.60675);
  }
  to {
    transform: translate(36.1706vw, 100vh) scale(0.60675);
  }
}

.snow:nth-child(227) {
  opacity: 0.5778;
  transform: translate(77.7817vw, -10px) scale(1.08255);
  animation: fall-227 11s -8s linear infinite;
}

@keyframes fall-227 {
  59.501% {
    transform: translate(77.6696vw, 59.501vh) scale(1.08255);
  }
  to {
    transform: translate(77.72565vw, 100vh) scale(1.08255);
  }
}

.snow:nth-child(228) {
  opacity: 0.2452;
  transform: translate(71.7902vw, -10px) scale(1.2789);
  animation: fall-228 11s -3s linear infinite;
}

@keyframes fall-228 {
  63.602% {
    transform: translate(74.4856vw, 63.602vh) scale(1.2789);
  }
  to {
    transform: translate(73.1379vw, 100vh) scale(1.2789);
  }
}

.snow:nth-child(229) {
  opacity: 0.8722;
  transform: translate(81.0908vw, -10px) scale(1.45785);
  animation: fall-229 16s -9s linear infinite;
}

@keyframes fall-229 {
  59.133% {
    transform: translate(83.2664vw, 59.133vh) scale(1.45785);
  }
  to {
    transform: translate(82.1786vw, 100vh) scale(1.45785);
  }
}

.snow:nth-child(230) {
  opacity: 0.672;
  transform: translate(46.9482vw, -10px) scale(0.56175);
  animation: fall-230 12s -19s linear infinite;
}

@keyframes fall-230 {
  32.519% {
    transform: translate(55.3408vw, 32.519vh) scale(0.56175);
  }
  to {
    transform: translate(51.1445vw, 100vh) scale(0.56175);
  }
}

.snow:nth-child(231) {
  opacity: 0.4634;
  transform: translate(52.6148vw, -10px) scale(0.14985);
  animation: fall-231 11s -16s linear infinite;
}

@keyframes fall-231 {
  71.116% {
    transform: translate(52.5357vw, 71.116vh) scale(0.14985);
  }
  to {
    transform: translate(52.57525vw, 100vh) scale(0.14985);
  }
}

.snow:nth-child(232) {
  opacity: 0.06;
  transform: translate(52.9556vw, -10px) scale(0.06735);
  animation: fall-232 15s -12s linear infinite;
}

@keyframes fall-232 {
  69.256% {
    transform: translate(43.5929vw, 69.256vh) scale(0.06735);
  }
  to {
    transform: translate(48.27425vw, 100vh) scale(0.06735);
  }
}

.snow:nth-child(233) {
  opacity: 0.9316;
  transform: translate(5.3602vw, -10px) scale(0.5859);
  animation: fall-233 11s -2s linear infinite;
}

@keyframes fall-233 {
  35.649% {
    transform: translate(-1.0609vw, 35.649vh) scale(0.5859);
  }
  to {
    transform: translate(2.14965vw, 100vh) scale(0.5859);
  }
}

.snow:nth-child(234) {
  opacity: 0.0029;
  transform: translate(72.5935vw, -10px) scale(0.7176);
  animation: fall-234 25s -30s linear infinite;
}

@keyframes fall-234 {
  38.649% {
    transform: translate(78.7647vw, 38.649vh) scale(0.7176);
  }
  to {
    transform: translate(75.6791vw, 100vh) scale(0.7176);
  }
}

.snow:nth-child(235) {
  opacity: 0.414;
  transform: translate(39.8348vw, -10px) scale(1.41615);
  animation: fall-235 25s -5s linear infinite;
}

@keyframes fall-235 {
  69.938% {
    transform: translate(32.7194vw, 69.938vh) scale(1.41615);
  }
  to {
    transform: translate(36.2771vw, 100vh) scale(1.41615);
  }
}

.snow:nth-child(236) {
  opacity: 0.3118;
  transform: translate(56.0708vw, -10px) scale(0.5487);
  animation: fall-236 16s -17s linear infinite;
}

@keyframes fall-236 {
  50.783% {
    transform: translate(56.2967vw, 50.783vh) scale(0.5487);
  }
  to {
    transform: translate(56.18375vw, 100vh) scale(0.5487);
  }
}

.snow:nth-child(237) {
  opacity: 0.3327;
  transform: translate(86.0267vw, -10px) scale(0.0723);
  animation: fall-237 12s -3s linear infinite;
}

@keyframes fall-237 {
  52.051% {
    transform: translate(82.846vw, 52.051vh) scale(0.0723);
  }
  to {
    transform: translate(84.43635vw, 100vh) scale(0.0723);
  }
}

.snow:nth-child(238) {
  opacity: 0.4507;
  transform: translate(83.2058vw, -10px) scale(1.3479);
  animation: fall-238 16s -27s linear infinite;
}

@keyframes fall-238 {
  48.96% {
    transform: translate(73.3542vw, 48.96vh) scale(1.3479);
  }
  to {
    transform: translate(78.28vw, 100vh) scale(1.3479);
  }
}

.snow:nth-child(239) {
  opacity: 0.7203;
  transform: translate(48.4379vw, -10px) scale(1.3434);
  animation: fall-239 10s -9s linear infinite;
}

@keyframes fall-239 {
  56.239% {
    transform: translate(46.5882vw, 56.239vh) scale(1.3434);
  }
  to {
    transform: translate(47.51305vw, 100vh) scale(1.3434);
  }
}

.snow:nth-child(240) {
  opacity: 0.1202;
  transform: translate(57.1145vw, -10px) scale(0.873);
  animation: fall-240 13s -6s linear infinite;
}

@keyframes fall-240 {
  63.001% {
    transform: translate(50.7062vw, 63.001vh) scale(0.873);
  }
  to {
    transform: translate(53.91035vw, 100vh) scale(0.873);
  }
}

.snow:nth-child(241) {
  opacity: 0.6907;
  transform: translate(13.8484vw, -10px) scale(0.7977);
  animation: fall-241 16s -5s linear infinite;
}

@keyframes fall-241 {
  59.672% {
    transform: translate(20.1707vw, 59.672vh) scale(0.7977);
  }
  to {
    transform: translate(17.00955vw, 100vh) scale(0.7977);
  }
}

.snow:nth-child(242) {
  opacity: 0.4315;
  transform: translate(46.2994vw, -10px) scale(1.4907);
  animation: fall-242 14s -17s linear infinite;
}

@keyframes fall-242 {
  37.893% {
    transform: translate(38.7357vw, 37.893vh) scale(1.4907);
  }
  to {
    transform: translate(42.51755vw, 100vh) scale(1.4907);
  }
}

.snow:nth-child(243) {
  opacity: 0.8253;
  transform: translate(51.147vw, -10px) scale(1.4865);
  animation: fall-243 30s -16s linear infinite;
}

@keyframes fall-243 {
  77.612% {
    transform: translate(51.9335vw, 77.612vh) scale(1.4865);
  }
  to {
    transform: translate(51.54025vw, 100vh) scale(1.4865);
  }
}

.snow:nth-child(244) {
  opacity: 0.2489;
  transform: translate(5.6193vw, -10px) scale(0.86775);
  animation: fall-244 17s -20s linear infinite;
}

@keyframes fall-244 {
  58.623% {
    transform: translate(-0.2803vw, 58.623vh) scale(0.86775);
  }
  to {
    transform: translate(2.6695vw, 100vh) scale(0.86775);
  }
}

.snow:nth-child(245) {
  opacity: 0.1386;
  transform: translate(59.1644vw, -10px) scale(0.75825);
  animation: fall-245 30s -30s linear infinite;
}

@keyframes fall-245 {
  50.953% {
    transform: translate(51.4598vw, 50.953vh) scale(0.75825);
  }
  to {
    transform: translate(55.3121vw, 100vh) scale(0.75825);
  }
}

.snow:nth-child(246) {
  opacity: 0.43;
  transform: translate(30.4274vw, -10px) scale(1.2666);
  animation: fall-246 22s -9s linear infinite;
}

@keyframes fall-246 {
  65.465% {
    transform: translate(35.2212vw, 65.465vh) scale(1.2666);
  }
  to {
    transform: translate(32.8243vw, 100vh) scale(1.2666);
  }
}

.snow:nth-child(247) {
  opacity: 0.1447;
  transform: translate(48.414vw, -10px) scale(0.3717);
  animation: fall-247 24s -17s linear infinite;
}

@keyframes fall-247 {
  66.226% {
    transform: translate(56.7925vw, 66.226vh) scale(0.3717);
  }
  to {
    transform: translate(52.60325vw, 100vh) scale(0.3717);
  }
}

.snow:nth-child(248) {
  opacity: 0.2674;
  transform: translate(98.6868vw, -10px) scale(0.867);
  animation: fall-248 14s -23s linear infinite;
}

@keyframes fall-248 {
  79.144% {
    transform: translate(94.9887vw, 79.144vh) scale(0.867);
  }
  to {
    transform: translate(96.83775vw, 100vh) scale(0.867);
  }
}

.snow:nth-child(249) {
  opacity: 0.0931;
  transform: translate(48.5455vw, -10px) scale(1.31805);
  animation: fall-249 13s -14s linear infinite;
}

@keyframes fall-249 {
  66.205% {
    transform: translate(41.1072vw, 66.205vh) scale(1.31805);
  }
  to {
    transform: translate(44.82635vw, 100vh) scale(1.31805);
  }
}

.snow:nth-child(250) {
  opacity: 0.3611;
  transform: translate(25.6078vw, -10px) scale(0.10065);
  animation: fall-250 17s -17s linear infinite;
}

@keyframes fall-250 {
  49.288% {
    transform: translate(16.6545vw, 49.288vh) scale(0.10065);
  }
  to {
    transform: translate(21.13115vw, 100vh) scale(0.10065);
  }
}

.snow:nth-child(251) {
  opacity: 0.1461;
  transform: translate(95.8698vw, -10px) scale(1.2051);
  animation: fall-251 12s -3s linear infinite;
}

@keyframes fall-251 {
  43.116% {
    transform: translate(99.7697vw, 43.116vh) scale(1.2051);
  }
  to {
    transform: translate(97.81975vw, 100vh) scale(1.2051);
  }
}

.snow:nth-child(252) {
  opacity: 0.2125;
  transform: translate(97.4344vw, -10px) scale(0.4158);
  animation: fall-252 28s -13s linear infinite;
}

@keyframes fall-252 {
  46.257% {
    transform: translate(96.763vw, 46.257vh) scale(0.4158);
  }
  to {
    transform: translate(97.0987vw, 100vh) scale(0.4158);
  }
}

.snow:nth-child(253) {
  opacity: 0.6168;
  transform: translate(50.2641vw, -10px) scale(0.57435);
  animation: fall-253 26s -25s linear infinite;
}

@keyframes fall-253 {
  50.629% {
    transform: translate(48.8696vw, 50.629vh) scale(0.57435);
  }
  to {
    transform: translate(49.56685vw, 100vh) scale(0.57435);
  }
}

.snow:nth-child(254) {
  opacity: 0.5995;
  transform: translate(81.3703vw, -10px) scale(1.47315);
  animation: fall-254 30s -8s linear infinite;
}

@keyframes fall-254 {
  74.322% {
    transform: translate(77.802vw, 74.322vh) scale(1.47315);
  }
  to {
    transform: translate(79.58615vw, 100vh) scale(1.47315);
  }
}

.snow:nth-child(255) {
  opacity: 0.8246;
  transform: translate(96.3081vw, -10px) scale(0.219);
  animation: fall-255 10s -5s linear infinite;
}

@keyframes fall-255 {
  52.004% {
    transform: translate(86.7378vw, 52.004vh) scale(0.219);
  }
  to {
    transform: translate(91.52295vw, 100vh) scale(0.219);
  }
}

.snow:nth-child(256) {
  opacity: 0.2779;
  transform: translate(37.3154vw, -10px) scale(0.3678);
  animation: fall-256 23s -2s linear infinite;
}

@keyframes fall-256 {
  35.148% {
    transform: translate(46.3464vw, 35.148vh) scale(0.3678);
  }
  to {
    transform: translate(41.8309vw, 100vh) scale(0.3678);
  }
}

.snow:nth-child(257) {
  opacity: 0.0822;
  transform: translate(85.1268vw, -10px) scale(0.06615);
  animation: fall-257 18s -11s linear infinite;
}

@keyframes fall-257 {
  63.536% {
    transform: translate(78.064vw, 63.536vh) scale(0.06615);
  }
  to {
    transform: translate(81.5954vw, 100vh) scale(0.06615);
  }
}

.snow:nth-child(258) {
  opacity: 0.1334;
  transform: translate(7.6261vw, -10px) scale(0.6054);
  animation: fall-258 14s -2s linear infinite;
}

@keyframes fall-258 {
  74.544% {
    transform: translate(0.7185vw, 74.544vh) scale(0.6054);
  }
  to {
    transform: translate(4.1723vw, 100vh) scale(0.6054);
  }
}

.snow:nth-child(259) {
  opacity: 0.1789;
  transform: translate(46.7454vw, -10px) scale(0.1824);
  animation: fall-259 22s -27s linear infinite;
}

@keyframes fall-259 {
  44.657% {
    transform: translate(41.119vw, 44.657vh) scale(0.1824);
  }
  to {
    transform: translate(43.9322vw, 100vh) scale(0.1824);
  }
}

.snow:nth-child(260) {
  opacity: 0.0104;
  transform: translate(81.6506vw, -10px) scale(0.0192);
  animation: fall-260 16s -28s linear infinite;
}

@keyframes fall-260 {
  44.893% {
    transform: translate(73.3312vw, 44.893vh) scale(0.0192);
  }
  to {
    transform: translate(77.4909vw, 100vh) scale(0.0192);
  }
}

.snow:nth-child(261) {
  opacity: 0.7293;
  transform: translate(94.4922vw, -10px) scale(1.0866);
  animation: fall-261 15s -14s linear infinite;
}

@keyframes fall-261 {
  30.634% {
    transform: translate(92.8578vw, 30.634vh) scale(1.0866);
  }
  to {
    transform: translate(93.675vw, 100vh) scale(1.0866);
  }
}

.snow:nth-child(262) {
  opacity: 0.0775;
  transform: translate(78.2431vw, -10px) scale(0.01665);
  animation: fall-262 10s -2s linear infinite;
}

@keyframes fall-262 {
  54.546% {
    transform: translate(87.7761vw, 54.546vh) scale(0.01665);
  }
  to {
    transform: translate(83.0096vw, 100vh) scale(0.01665);
  }
}

.snow:nth-child(263) {
  opacity: 0.3885;
  transform: translate(81.8663vw, -10px) scale(0.96795);
  animation: fall-263 27s -17s linear infinite;
}

@keyframes fall-263 {
  40.385% {
    transform: translate(75.7733vw, 40.385vh) scale(0.96795);
  }
  to {
    transform: translate(78.8198vw, 100vh) scale(0.96795);
  }
}

.snow:nth-child(264) {
  opacity: 0.2337;
  transform: translate(65.5461vw, -10px) scale(1.41855);
  animation: fall-264 24s -25s linear infinite;
}

@keyframes fall-264 {
  68.749% {
    transform: translate(57.6044vw, 68.749vh) scale(1.41855);
  }
  to {
    transform: translate(61.57525vw, 100vh) scale(1.41855);
  }
}

.snow:nth-child(265) {
  opacity: 0.1916;
  transform: translate(97.9508vw, -10px) scale(0.82155);
  animation: fall-265 28s -17s linear infinite;
}

@keyframes fall-265 {
  59.833% {
    transform: translate(107.9477vw, 59.833vh) scale(0.82155);
  }
  to {
    transform: translate(102.94925vw, 100vh) scale(0.82155);
  }
}

.snow:nth-child(266) {
  opacity: 0.8793;
  transform: translate(96.1552vw, -10px) scale(0.2721);
  animation: fall-266 17s -25s linear infinite;
}

@keyframes fall-266 {
  62.791% {
    transform: translate(98.7974vw, 62.791vh) scale(0.2721);
  }
  to {
    transform: translate(97.4763vw, 100vh) scale(0.2721);
  }
}

.snow:nth-child(267) {
  opacity: 0.1678;
  transform: translate(74.8589vw, -10px) scale(1.4484);
  animation: fall-267 30s -21s linear infinite;
}

@keyframes fall-267 {
  77.216% {
    transform: translate(65.2868vw, 77.216vh) scale(1.4484);
  }
  to {
    transform: translate(70.07285vw, 100vh) scale(1.4484);
  }
}

.snow:nth-child(268) {
  opacity: 0.7785;
  transform: translate(68.8394vw, -10px) scale(0.3177);
  animation: fall-268 12s -21s linear infinite;
}

@keyframes fall-268 {
  54.605% {
    transform: translate(68.4612vw, 54.605vh) scale(0.3177);
  }
  to {
    transform: translate(68.6503vw, 100vh) scale(0.3177);
  }
}

.snow:nth-child(269) {
  opacity: 0.9395;
  transform: translate(91.4836vw, -10px) scale(0.8394);
  animation: fall-269 11s -22s linear infinite;
}

@keyframes fall-269 {
  63.603% {
    transform: translate(84.1433vw, 63.603vh) scale(0.8394);
  }
  to {
    transform: translate(87.81345vw, 100vh) scale(0.8394);
  }
}

.snow:nth-child(270) {
  opacity: 0.8005;
  transform: translate(74.9156vw, -10px) scale(0.41205);
  animation: fall-270 14s -3s linear infinite;
}

@keyframes fall-270 {
  30.469% {
    transform: translate(81.1348vw, 30.469vh) scale(0.41205);
  }
  to {
    transform: translate(78.0252vw, 100vh) scale(0.41205);
  }
}

.snow:nth-child(271) {
  opacity: 0.8112;
  transform: translate(0.9025vw, -10px) scale(1.36095);
  animation: fall-271 19s -5s linear infinite;
}

@keyframes fall-271 {
  41.405% {
    transform: translate(5.7865vw, 41.405vh) scale(1.36095);
  }
  to {
    transform: translate(3.3445vw, 100vh) scale(1.36095);
  }
}

.snow:nth-child(272) {
  opacity: 0.6142;
  transform: translate(87.3649vw, -10px) scale(0.1416);
  animation: fall-272 16s -2s linear infinite;
}

@keyframes fall-272 {
  57.37% {
    transform: translate(78.1448vw, 57.37vh) scale(0.1416);
  }
  to {
    transform: translate(82.75485vw, 100vh) scale(0.1416);
  }
}

.snow:nth-child(273) {
  opacity: 0.7243;
  transform: translate(77.1964vw, -10px) scale(0.40425);
  animation: fall-273 17s -25s linear infinite;
}

@keyframes fall-273 {
  55.062% {
    transform: translate(79.9686vw, 55.062vh) scale(0.40425);
  }
  to {
    transform: translate(78.5825vw, 100vh) scale(0.40425);
  }
}

.snow:nth-child(274) {
  opacity: 0.6381;
  transform: translate(78.7849vw, -10px) scale(0.8955);
  animation: fall-274 10s -8s linear infinite;
}

@keyframes fall-274 {
  36.942% {
    transform: translate(83.8781vw, 36.942vh) scale(0.8955);
  }
  to {
    transform: translate(81.3315vw, 100vh) scale(0.8955);
  }
}

.snow:nth-child(275) {
  opacity: 0.2497;
  transform: translate(94.9965vw, -10px) scale(0.60075);
  animation: fall-275 23s -27s linear infinite;
}

@keyframes fall-275 {
  56.22% {
    transform: translate(90.0386vw, 56.22vh) scale(0.60075);
  }
  to {
    transform: translate(92.51755vw, 100vh) scale(0.60075);
  }
}

.snow:nth-child(276) {
  opacity: 0.3062;
  transform: translate(22.7339vw, -10px) scale(1.2291);
  animation: fall-276 11s -24s linear infinite;
}

@keyframes fall-276 {
  50.037% {
    transform: translate(32.0887vw, 50.037vh) scale(1.2291);
  }
  to {
    transform: translate(27.4113vw, 100vh) scale(1.2291);
  }
}

.snow:nth-child(277) {
  opacity: 0.0663;
  transform: translate(9.8467vw, -10px) scale(0.60465);
  animation: fall-277 12s -30s linear infinite;
}

@keyframes fall-277 {
  34.161% {
    transform: translate(16.2993vw, 34.161vh) scale(0.60465);
  }
  to {
    transform: translate(13.073vw, 100vh) scale(0.60465);
  }
}

.snow:nth-child(278) {
  opacity: 0.8251;
  transform: translate(60.8704vw, -10px) scale(0.9603);
  animation: fall-278 11s -5s linear infinite;
}

@keyframes fall-278 {
  71.712% {
    transform: translate(61.311vw, 71.712vh) scale(0.9603);
  }
  to {
    transform: translate(61.0907vw, 100vh) scale(0.9603);
  }
}

.snow:nth-child(279) {
  opacity: 0.0974;
  transform: translate(48.0915vw, -10px) scale(1.12395);
  animation: fall-279 11s -27s linear infinite;
}

@keyframes fall-279 {
  38.181% {
    transform: translate(42.7982vw, 38.181vh) scale(1.12395);
  }
  to {
    transform: translate(45.44485vw, 100vh) scale(1.12395);
  }
}

.snow:nth-child(280) {
  opacity: 0.0194;
  transform: translate(88.5166vw, -10px) scale(0.73785);
  animation: fall-280 10s -29s linear infinite;
}

@keyframes fall-280 {
  37.21% {
    transform: translate(80.9882vw, 37.21vh) scale(0.73785);
  }
  to {
    transform: translate(84.7524vw, 100vh) scale(0.73785);
  }
}

.snow:nth-child(281) {
  opacity: 0.4762;
  transform: translate(34.8967vw, -10px) scale(0.0102);
  animation: fall-281 20s -4s linear infinite;
}

@keyframes fall-281 {
  68.686% {
    transform: translate(43.5317vw, 68.686vh) scale(0.0102);
  }
  to {
    transform: translate(39.2142vw, 100vh) scale(0.0102);
  }
}

.snow:nth-child(282) {
  opacity: 0.5069;
  transform: translate(90.5054vw, -10px) scale(1.46325);
  animation: fall-282 23s -19s linear infinite;
}

@keyframes fall-282 {
  60.21% {
    transform: translate(92.9294vw, 60.21vh) scale(1.46325);
  }
  to {
    transform: translate(91.7174vw, 100vh) scale(1.46325);
  }
}

.snow:nth-child(283) {
  opacity: 0.4668;
  transform: translate(95.8065vw, -10px) scale(0.6603);
  animation: fall-283 14s -30s linear infinite;
}

@keyframes fall-283 {
  74.011% {
    transform: translate(86.2813vw, 74.011vh) scale(0.6603);
  }
  to {
    transform: translate(91.0439vw, 100vh) scale(0.6603);
  }
}

.snow:nth-child(284) {
  opacity: 0.2071;
  transform: translate(41.1296vw, -10px) scale(0.11595);
  animation: fall-284 27s -9s linear infinite;
}

@keyframes fall-284 {
  34.344% {
    transform: translate(44.4113vw, 34.344vh) scale(0.11595);
  }
  to {
    transform: translate(42.77045vw, 100vh) scale(0.11595);
  }
}

.snow:nth-child(285) {
  opacity: 0.5147;
  transform: translate(73.7392vw, -10px) scale(0.42585);
  animation: fall-285 18s -22s linear infinite;
}

@keyframes fall-285 {
  57.496% {
    transform: translate(77.2069vw, 57.496vh) scale(0.42585);
  }
  to {
    transform: translate(75.47305vw, 100vh) scale(0.42585);
  }
}

.snow:nth-child(286) {
  opacity: 0.0565;
  transform: translate(84.6377vw, -10px) scale(0.9975);
  animation: fall-286 23s -13s linear infinite;
}

@keyframes fall-286 {
  32.351% {
    transform: translate(87.0315vw, 32.351vh) scale(0.9975);
  }
  to {
    transform: translate(85.8346vw, 100vh) scale(0.9975);
  }
}

.snow:nth-child(287) {
  opacity: 0.407;
  transform: translate(10.6758vw, -10px) scale(1.28085);
  animation: fall-287 22s -2s linear infinite;
}

@keyframes fall-287 {
  73.133% {
    transform: translate(5.4702vw, 73.133vh) scale(1.28085);
  }
  to {
    transform: translate(8.073vw, 100vh) scale(1.28085);
  }
}

.snow:nth-child(288) {
  opacity: 0.6729;
  transform: translate(17.0066vw, -10px) scale(0.03435);
  animation: fall-288 10s -27s linear infinite;
}

@keyframes fall-288 {
  74.918% {
    transform: translate(18.5747vw, 74.918vh) scale(0.03435);
  }
  to {
    transform: translate(17.79065vw, 100vh) scale(0.03435);
  }
}

.snow:nth-child(289) {
  opacity: 0.2855;
  transform: translate(64.7681vw, -10px) scale(0.70875);
  animation: fall-289 11s -5s linear infinite;
}

@keyframes fall-289 {
  44.698% {
    transform: translate(67.7608vw, 44.698vh) scale(0.70875);
  }
  to {
    transform: translate(66.26445vw, 100vh) scale(0.70875);
  }
}

.snow:nth-child(290) {
  opacity: 0.1279;
  transform: translate(81.1836vw, -10px) scale(1.29255);
  animation: fall-290 29s -27s linear infinite;
}

@keyframes fall-290 {
  72% {
    transform: translate(82.0125vw, 72vh) scale(1.29255);
  }
  to {
    transform: translate(81.59805vw, 100vh) scale(1.29255);
  }
}

.snow:nth-child(291) {
  opacity: 0.851;
  transform: translate(99.3284vw, -10px) scale(0.1002);
  animation: fall-291 14s -10s linear infinite;
}

@keyframes fall-291 {
  45.966% {
    transform: translate(92.78vw, 45.966vh) scale(0.1002);
  }
  to {
    transform: translate(96.0542vw, 100vh) scale(0.1002);
  }
}

.snow:nth-child(292) {
  opacity: 0.8811;
  transform: translate(79.3393vw, -10px) scale(0.23505);
  animation: fall-292 15s -12s linear infinite;
}

@keyframes fall-292 {
  43.261% {
    transform: translate(72.3978vw, 43.261vh) scale(0.23505);
  }
  to {
    transform: translate(75.86855vw, 100vh) scale(0.23505);
  }
}

.snow:nth-child(293) {
  opacity: 0.4296;
  transform: translate(37.7067vw, -10px) scale(0.3648);
  animation: fall-293 14s -27s linear infinite;
}

@keyframes fall-293 {
  67.591% {
    transform: translate(39.6577vw, 67.591vh) scale(0.3648);
  }
  to {
    transform: translate(38.6822vw, 100vh) scale(0.3648);
  }
}

.snow:nth-child(294) {
  opacity: 0.1477;
  transform: translate(91.3236vw, -10px) scale(0.57375);
  animation: fall-294 25s -17s linear infinite;
}

@keyframes fall-294 {
  37.173% {
    transform: translate(89.4969vw, 37.173vh) scale(0.57375);
  }
  to {
    transform: translate(90.41025vw, 100vh) scale(0.57375);
  }
}

.snow:nth-child(295) {
  opacity: 0.9238;
  transform: translate(51.7999vw, -10px) scale(0.19995);
  animation: fall-295 11s -18s linear infinite;
}

@keyframes fall-295 {
  77.225% {
    transform: translate(53.628vw, 77.225vh) scale(0.19995);
  }
  to {
    transform: translate(52.71395vw, 100vh) scale(0.19995);
  }
}

.snow:nth-child(296) {
  opacity: 0.3522;
  transform: translate(8.6605vw, -10px) scale(0.40005);
  animation: fall-296 20s -27s linear infinite;
}

@keyframes fall-296 {
  76.722% {
    transform: translate(4.9323vw, 76.722vh) scale(0.40005);
  }
  to {
    transform: translate(6.7964vw, 100vh) scale(0.40005);
  }
}

.snow:nth-child(297) {
  opacity: 0.321;
  transform: translate(92.0968vw, -10px) scale(1.1934);
  animation: fall-297 27s -12s linear infinite;
}

@keyframes fall-297 {
  48.615% {
    transform: translate(84.7734vw, 48.615vh) scale(1.1934);
  }
  to {
    transform: translate(88.4351vw, 100vh) scale(1.1934);
  }
}

.snow:nth-child(298) {
  opacity: 0.0743;
  transform: translate(95.3553vw, -10px) scale(1.0143);
  animation: fall-298 13s -6s linear infinite;
}

@keyframes fall-298 {
  52.477% {
    transform: translate(91.2856vw, 52.477vh) scale(1.0143);
  }
  to {
    transform: translate(93.32045vw, 100vh) scale(1.0143);
  }
}

.snow:nth-child(299) {
  opacity: 0.5968;
  transform: translate(80.7351vw, -10px) scale(0.972);
  animation: fall-299 16s -7s linear infinite;
}

@keyframes fall-299 {
  49.935% {
    transform: translate(85.0616vw, 49.935vh) scale(0.972);
  }
  to {
    transform: translate(82.89835vw, 100vh) scale(0.972);
  }
}

.snow:nth-child(300) {
  opacity: 0.7975;
  transform: translate(55.5419vw, -10px) scale(0.34005);
  animation: fall-300 18s -13s linear infinite;
}

@keyframes fall-300 {
  41.293% {
    transform: translate(52.51vw, 41.293vh) scale(0.34005);
  }
  to {
    transform: translate(54.02595vw, 100vh) scale(0.34005);
  }
}

.snow_container {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .snow_page .form-wrap {
    width: calc(100% - 18px);
    grid-template-columns: 100%;
  }
}

@media screen and (max-width: 600px) {
  .snow_page .form-wrap .text-half {
    margin: 0 -16px;
  }
  .snow_page .form-wrap._success .girland {
    aspect-ratio: 358/132;
  }
  .snow_page .form-wrap._success .prize {
    padding: 16px;
  }
}

.assistant {
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(300px, 3fr) minmax(0, 1fr);
  height: calc(100vh - 165px - 22px);
  grid-gap: 32px;
}

.assistant-bg {
  width: 100%;
  background: linear-gradient(0deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  overflow: hidden;
}

.assistant__cat {
  display: grid;
  place-items: end center;
  grid-template-rows: auto 1fr;
}

.assistant__cat .cat__info {
  display: none;
}

.assistant__cat .cat__image {
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: top center;
}

.assistant__cat .cat__image-wrap {
  width: 100%;
  aspect-ratio: 5/9;
  overflow: hidden;
}

.assistant__chat {
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  height: fit-content;
  min-height: 100%;
  overflow-y: hidden;
  padding: 0 0 0 8px;
}

.assistant__chat_wrap {
  height: 100%;
  overflow-y: auto;
  padding: 0 24px 0 0;
}

.assistant__chat_wrap::-webkit-scrollbar {
  width: 8px;
}

.assistant__chat_wrap::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.assistant__chat_wrap::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.assistant__chat_wrap::-webkit-scrollbar-button {
  height: 64px;
}

.assistant__chat .chat__bubble._question {
  margin: 8px 0 0 auto;
  transform: translateX(0);
}

.assistant__chat .chat__bubble._question._actual:hover {
  transform: translateX(-8px);
}

.assistant__chat .chat__bubble._answer {
  margin: 24px auto 0 0;
}

.assistant__chat .chat__history,
.assistant__chat .chat__answers,
.assistant__chat .chat__questions {
  display: contents;
}

.assistant__chat .chat__navigation {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 64px auto;
}

.assistant__chat .chat__history {
  overflow: hidden;
}

.assistant__chat .chat__history .chat__bubble {
  transition: opacity .3s, transform .3s;
  opacity: 0;
}

.assistant__chat .chat__history .chat__bubble._actual {
  opacity: 1;
}

.assistant__chat .chat__history .chat__bubble._history {
  opacity: 0.6;
}

.assistant__chat .chat__history .chat__bubble._history:hover {
  opacity: 1;
}

.assistant__chat .chat__history .chat__bubble:first-child {
  margin-top: 64px;
}

.assistant .assistant__chat_wrap .assistant__chat .loading {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin: 16px 0 0;
  background: transparent !important;
}

.assistant .assistant__chat_wrap .assistant__chat .loading:after {
  content: '.';
  animation: ellipsis 1s infinite;
}

@keyframes ellipsis {
  0% {
    content: '.';
  }
  50% {
    content: '..';
  }
  100% {
    content: '...';
  }
}

.to-main._white {
  display: flex;
  justify-self: start;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin: 16px 0;
  white-space: nowrap;
}

.to-main._white::before {
  content: '';
  display: block;
  height: 24px;
  width: 24px;
  background: url("../img/assistant/arrow-right-alt.svg") center no-repeat;
  margin: 0 4px 0 0;
}

.chat__bubble {
  flex: none;
  padding: 10px 16px;
  width: fit-content;
  max-width: 100%;
}

.chat__bubble .bubble__name {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #007ED9;
  margin: 0;
}

.chat__bubble .bubble__text p {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2C2D2E;
  cursor: inherit;
  margin: 0;
}

.chat__bubble .bubble__text p a {
  font-weight: 600;
  color: #007ED9;
  text-decoration: none;
}

.chat__bubble._answer {
  background-color: #fff;
  border-radius: 0px 12px 12px 8px;
  margin: 24px auto 0 0;
}

.chat__bubble._question {
  background-color: #CCFFCC;
  border-radius: 12px 8px 0px 12px;
  margin: 0 0 12px 12px;
  transition: box-shadow 0.3s;
}

.chat__bubble._question:hover {
  box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.25);
}

.chat__bubble._button {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2C2D2E;
  margin: 12px 0 0 8px;
}

.chat__bubble._button img {
  margin: 0 4px 0 0;
}

.assistant-card {
  display: grid;
  grid-template-columns: 3fr 8fr;
  background: linear-gradient(88.56deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  width: 100%;
  margin-top: calc(82px + 64px);
}

.assistant-card__cat, .assistant-card__bubble {
  margin-top: -82px;
}

.assistant-card__cat {
  grid-column: 1/2;
  grid-row: 1/3;
  display: block;
  width: 100%;
  height: calc(100% + 82px);
  overflow: hidden;
  position: relative;
}

.assistant-card__cat .cat__image {
  position: absolute;
  max-width: 100%;
  top: 0;
  right: 0;
}

.assistant-card .cat__info {
  display: none;
}

.assistant-card__bubble {
  grid-column: 2/3;
}

.assistant-card__bubble .chat__bubble._answer {
  margin: 32px 0 0;
  border-radius: 8px 12px 12px 0;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
}

.assistant-card__questions {
  padding: 30px 64px 32px 0;
  display: flex;
  align-items: center;
}

.assistant-card__questions-wrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.assistant-card__subtitle {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #2C2D2E;
  margin: 8px 0 0 76px;
  margin-bottom: 0;
}

.assistant-card__subtitle span {
  font-family: "Golos", sans-srif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #00CCCC;
  position: relative;
}

.assistant-card__subtitle span:before {
  content: '';
  display: block;
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: #00CCCC;
  left: -10px;
  top: 6px;
  animation: blinker 1000ms infinite linear;
}

@keyframes blinker {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1.0;
  }
}

@media screen and (max-width: 1023px) {
  .assistant {
    height: calc(100vh - 88px);
  }
}

@media screen and (max-width: 920px) {
  .assistant-card {
    grid-template-columns: 104px 1fr;
    grid-template-rows: auto auto auto;
    padding: 16px;
    border-radius: 16px;
    margin-top: 64px;
    background: linear-gradient(0deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  }
  .assistant-card__subtitle {
    display: none;
  }
  .assistant-card__cat {
    grid-column: 1/2;
    grid-row: 1/2;
    height: 104px;
    width: 104px;
    display: flex;
    margin-top: 0;
    background-color: #fff;
    border-radius: 52px;
  }
  .assistant-card__cat .cat__image {
    max-width: unset;
    width: 180px;
    height: 422px;
    top: 8px;
    right: 7px;
  }
  .assistant-card .cat__info {
    grid-column: 2/3;
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
  }
  .assistant-card .cat__info .info__name {
    font-family: "Golos", sans-srif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    margin: 0;
  }
  .assistant-card .cat__info .info__position {
    font-family: "Golos", sans-srif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    margin: 0;
  }
  .assistant-card .cat__info .info__status {
    font-family: "Golos", sans-srif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #00CCCC;
    margin: 0;
  }
  .assistant-card__bubble {
    grid-column: 1/3;
    grid-row: 2/3;
    margin-top: 0;
  }
  .assistant-card__bubble .chat__bubble._answer {
    margin: 24px 0 16px;
  }
  .assistant-card__questions {
    grid-column: 1/3;
    grid-row: 3/4;
    padding: 0;
  }
  .assistant-card__questions-wrap {
    justify-content: end;
  }
}

@media screen and (max-width: 820px) {
  .assistant {
    grid-gap: 16px;
    grid-template-columns: minmax(200px, 1fr) minmax(300px, 3fr);
  }
}

@media screen and (max-width: 600px) {
  .assistant {
    grid-gap: 0;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
  }
  .assistant__cat {
    grid-template-columns: auto 1fr;
    grid-column-gap: 16px;
    padding-bottom: 20px;
  }
  .assistant__cat .to-main {
    grid-column: 1/3;
  }
  .assistant__cat .cat__image {
    height: auto;
    width: 124px;
    margin-left: -62px;
    margin-top: 5px;
  }
  .assistant__cat .cat__image-wrap {
    height: 64px;
    width: 64px;
    background-color: #fff;
    border-radius: 52px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  }
  .assistant__cat .cat__image use {
    width: 100%;
    height: 100%;
  }
  .assistant__cat .cat__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .assistant__cat .cat__info .info__name {
    font-family: "Golos", sans-srif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    margin: 0;
  }
  .assistant__cat .cat__info .info__position {
    font-family: "Golos", sans-srif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    margin: 0;
  }
  .assistant__cat .cat__info .info__status {
    font-family: "Golos", sans-srif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #00CCCC;
    margin: 0;
  }
  .assistant__chat_wrap {
    border-top: 1px solid #FFFFFF;
    padding: 0 8px 0 0;
  }
  .assistant__chat_wrap::-webkit-scrollbar {
    width: 4px;
  }
  .assistant__chat_wrap .chat__history .chat__bubble {
    opacity: 1;
  }
}

/*XL*/
/*LG*/
/*MD*/
/* ~~SM - MD~~ */
@media screen and (max-width: 1440px) {
  .top-block__title {
    font-size: calc(40px + (64 - 40) * ((100vw - 360px) / (1440 - 360)));
    line-height: calc(40px + (64 - 40) * ((100vw - 360px) / (1440 - 360)));
  }
  .top-block__title:before {
    width: calc(35px + (56 - 35) * ((100vw - 360px) / (1440 - 360)));
    height: calc(35px + (56 - 35) * ((100vw - 360px) / (1440 - 360)));
    margin-right: calc(12px + (20 - 12) * ((100vw - 360px) / (1440 - 360)));
  }
  .top-block__subtitle {
    font-size: calc(16px + (32 - 16) * ((100vw - 480px) / (1440 - 480)));
    line-height: calc(24px + (40 - 24) * ((100vw - 480px) / (1440 - 480)));
  }
  .top-block__grid {
    grid-row-gap: clamp(24px, calc(24px + (48 - 24) * ((100vw - 1280px) / (1440 - 1280))), 48px);
  }
  .bik-market-banner__text {
    font-size: clamp(14px, calc(14px + (16 - 14) * ((100vw - 1024px) / (1440 - 1024))), 16px);
    line-height: clamp(20px, calc(20px + (24 - 20) * ((100vw - 1024px) / (1440 - 1024))), 24px);
  }
  .bik-market-banner {
    background-size: clamp(20px, calc(20px + (24 - 20) * ((100vw - 1024px) / (1440 - 1024))), 24px);
  }
}

@media screen and (max-width: 1366px) {
  .container-center,
  .service-container {
    padding: 0 16px;
  }
}

@media screen and (max-width: 1280px) {
  .bik-market-banner .market-logo {
    max-width: clamp(300px, calc(300px + (528 - 300) * ((100vw - 1024px) / (1280 - 1024))), 528px);
  }
  .top-block__container {
    padding: 32px 32px 0 32px;
  }
  .top-block__img {
    margin-bottom: -48px;
  }
  .top-block__grid-right {
    justify-content: flex-start;
  }
  .cabinet__specials {
    grid-template-columns: repeat(2, 1fr);
  }
  .entity-container._sale {
    grid-template-columns: repeat(2, 1fr);
  }
}

/*SM*/
@media screen and (max-width: 1024px) {
  .service-container {
    grid-template-columns: 100%;
    grid-gap: 24px 0px;
  }
  .service-container__swiper {
    grid-row: 2/3;
  }
  .service-container__title-block {
    grid-row: 1/2;
  }
  .service-container__description-block {
    grid-row: 3/4;
  }
  .service-container .modal-btn {
    width: 100%;
    border-radius: 0;
    text-align: center;
  }
  .service-container .service-description + p {
    margin-bottom: 8px;
  }
  .service-container .service-kadastr {
    display: none;
  }
  .service-container .service-kadastr._mob {
    display: block;
    margin-bottom: 24px;
  }
  .sale-card__slider .hover-pagination-wrap {
    display: none;
  }
  .bik-market-banner {
    aspect-ratio: 82/24;
  }
  .bik-market-banner__img {
    background: url("../img/market-background-small.png") #E6E7EB right no-repeat;
    background-size: cover;
  }
  .bik-market-banner__text {
    display: none;
  }
  .alert {
    display: none !important;
  }
  .header-logo {
    height: 28px;
  }
  .content__container-center,
  .container-geos {
    padding: 0 32px;
  }
  .top-block__img {
    margin-bottom: clamp(-64px, calc(-24px + (-64 + 24) * ((100vw - 320px) / (1024 - 320))), -24px);
  }
  .top-block__grid-right {
    margin-top: 0;
  }
  .change__btn {
    display: flex;
  }
  .top-block__grid {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    margin-bottom: clamp(24px, calc(24px + (64 - 24) * ((100vw - 320px) / (1024 - 320))), 64px);
  }
  .grid-box-1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    justify-content: center;
  }
  .grid-box-2 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .grid-box-3 {
    display: none;
  }
  .container-center {
    padding: 0 16px;
  }
  .sub-banner {
    margin-right: -16px;
    margin-left: -16px;
    padding: 24px 16px;
  }
  .banner-wrap {
    grid-template-columns: 100%;
  }
  .card-leader__body,
  .leader__body {
    grid-template-columns: 100%;
  }
  .entity-container._block {
    grid-template-columns: repeat(2, 1fr);
  }
  .entity-container._block .block-item:first-child {
    grid-column: 1/3;
  }
  .sub-banner__item:hover {
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: 0 8px 16px rgba(1, 1, 1, 0.1);
  }
  .register-block__btn:hover {
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: none;
  }
  .banner__next-btn {
    right: 16px;
  }
  .banner__prev-btn {
    right: 56px;
  }
  .footer__navigation > .footer__page > .footer__page-wrap {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 8px;
  }
  .cabinet__areas {
    grid-template-columns: repeat(2, 1fr);
  }
  .category-wrap .entity-container._block .sale_block-item .sale-card__slider .swiper-wrapper {
    max-width: unset;
  }
  .category-wrap._banner-block,
  .category-wrap._form-block {
    grid-template-columns: 100%;
  }
  .entity-container._block .sale_block-item:first-child {
    grid-column: unset;
  }
  .category-wrap .entity-container._block .sale_block-item {
    border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  }
  .category-wrap .entity-container._block .sale_block-item:nth-last-child(-n + 1) {
    border: none;
  }
  .category-wrap .entity-container._block .block-item {
    border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  }
  .category-wrap .entity-container._block .block-item:nth-last-child(-n + 1) {
    border: none;
  }
  .entity-container._block .block-item:nth-last-child(-n+3) {
    border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  }
  .entity-container._block .block-item {
    border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  }
  .entity-container._block .block-item:nth-last-child(-n+2) {
    border: none;
  }
  .entity-container._banner-block {
    grid-column: unset;
  }
}

@media screen and (max-width: 960px) {
  .footer {
    margin-top: 48px;
  }
  .slider-block {
    height: unset;
    width: 100%;
    aspect-ratio: 4/3;
  }
  .slider-block .slider {
    grid-template-rows: auto auto 1fr 1fr;
  }
  .slider-block .slider__title {
    grid-column: 1/3;
    grid-row: 1/2;
    margin-top: 16px;
  }
  .slider-block .slider__button {
    grid-row: 2/3;
  }
  .slider-block .slider__img-block {
    grid-row: 2/5;
    grid-column: 1/3;
    padding-top: 0;
  }
  .slider-block .slider__img {
    object-position: bottom right;
  }
  .market-banner {
    flex-direction: column;
    padding: 20px;
  }
  .market-banner__title {
    text-align: center;
  }
  .market-banner__caption {
    text-align: center;
  }
  .market-banner__img-separator, .market-banner__link {
    height: 48px;
  }
  .market-banner__img {
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
  .market-banner__img-wrap {
    grid-gap: 24px;
    padding: 24px 0 0;
    max-width: 100%;
  }
  .bik-market__header-filter {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .bik-market__header-filter .modal-btn {
    width: 100%;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 800px) {
  .bik-market-banner__img {
    background: unset;
  }
  .bik-market-banner {
    aspect-ratio: 358/204;
    display: block;
    background: url("../img/market-background-small.png") #E6E7EB right no-repeat;
    background-size: cover;
  }
  .entity-container._sale {
    grid-template-columns: repeat(1, 1fr);
  }
  .reg-text {
    font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (800 - 320)));
    line-height: calc(24px + (32 - 24) * ((100vw - 320px) / (800 - 320)));
  }
  .message-white,
  .message-green {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (800 - 320)));
  }
  .chat-text {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (800 - 320)));
    line-height: calc(20px + (24 - 20) * ((100vw - 320px) / (800 - 320)));
  }
  .gray-text {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (800 - 320)));
    line-height: calc(20px + (24 - 20) * ((100vw - 320px) / (800 - 320)));
  }
  .problems-block .gray-subtitle,
  .register-block__text {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (800 - 320)));
    line-height: calc(24px + (32 - 24) * ((100vw - 320px) / (800 - 320)));
  }
  .connection-list__title {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (800 - 320)));
  }
  .connection-list__item,
  .connection-list__item a {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (800 - 320)));
  }
  h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    font-family: "Golos", sans-srif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2C2D2E;
  }
  .banner-block {
    padding: 24px 0 0;
  }
  .banner-block .banner__prev-btn,
  .banner-block .banner__next-btn {
    display: none;
  }
  .content__container-center {
    padding: 0 24px;
  }
  .landing__register-block {
    flex-direction: column;
    padding: 24px;
    align-items: center;
  }
  .register-block__text {
    margin-bottom: 16px;
    margin-right: 0;
    text-align: center;
  }
  .slider-block .slider__title {
    margin-bottom: 16px;
  }
  .footer__navigation > .footer__page > .footer__page-wrap {
    grid-template-columns: 100%;
  }
  .cabinet__areas {
    grid-template-columns: 1fr;
  }
  .cabinet__specials {
    grid-template-columns: 1fr;
  }
  .a-card .a-card__header {
    display: flex;
    flex-direction: column-reverse;
    align-items: start;
    justify-content: normal;
  }
  .a-card .a-card__img {
    margin: 0 0 16px !important;
  }
  .text .picture,
  .picture {
    margin: 32px 0 48px;
  }
  .bik-market-banner__title {
    padding: 16px;
  }
}

/*XS*/
@media screen and (max-width: 600px) {
  .container-geos {
    padding: 0 16px;
  }
  .form_bik-market .form__item,
  .new-type__form .form__item {
    padding: 0;
  }
  .form_bik-market .form__item.grid-start-1, .form_bik-market .form__item.grid-start-2, .form_bik-market .form__item.grid-start-3, .form_bik-market .form__item.grid-start-4, .form_bik-market .form__item.grid-end-2, .form_bik-market .form__item.grid-end-3, .form_bik-market .form__item.grid-end-4, .form_bik-market .form__item.grid-end-5,
  .new-type__form .form__item.grid-start-1,
  .new-type__form .form__item.grid-start-2,
  .new-type__form .form__item.grid-start-3,
  .new-type__form .form__item.grid-start-4,
  .new-type__form .form__item.grid-end-2,
  .new-type__form .form__item.grid-end-3,
  .new-type__form .form__item.grid-end-4,
  .new-type__form .form__item.grid-end-5 {
    grid-column-start: unset;
    grid-column-end: unset;
  }
  .entity-container._banner-block,
  .entity-container._form-block {
    grid-template-columns: 100%;
  }
  .entity-container._block .block-item:nth-last-child(-n+3) {
    border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  }
  .entity-container._block .block-item {
    border-bottom: 1px solid rgba(0, 16, 61, 0.12);
  }
  .entity-container._block .block-item:nth-last-child(-n+1) {
    border: none;
  }
  .bik-market._grid {
    grid-template-columns: 1fr;
  }
  .market-logo {
    display: none;
  }
  .bik-market-banner .market-logo__white {
    display: block;
  }
  .bik-market-banner .market-logo__white {
    max-width: clamp(240px, calc(240px + (300 - 240) * ((100vw - 390px) / (600 - 390))), 300px);
  }
  .footer__phone-wrap {
    align-items: flex-start;
  }
  .connection-list {
    margin-top: 48px;
  }
  .landing__register-block {
    margin-top: 32px;
  }
  .landing__problems-list {
    flex-direction: column;
  }
  .problems-block {
    margin-top: 32px;
  }
  .chat-window {
    margin-top: 32px;
  }
  .pt-48 {
    padding-top: 24px;
  }
  .change__btn:before,
  .register-block__btn:before {
    width: 24px;
    height: 24px;
    background-size: 24px;
    margin-right: 12px;
  }
  .change__btn,
  .register-block__btn {
    font-size: 16px;
    line-height: 24px;
    padding: 12px 32px 12px 16px;
  }
  .top-block__container {
    padding: 16px 20px 0 20px;
  }
  .content__container-center {
    padding: 0 20px;
  }
  .sub-banner {
    grid-gap: 16px;
  }
  .banner-block {
    padding: 32px 0 0;
    height: 400px;
  }
  .entity-container._block {
    grid-template-columns: 100%;
  }
  .entity-container._block .sale_block-item:first-child,
  .entity-container._block .block-item:first-child {
    grid-column: 1/2;
  }
  .category-wrap .category-title,
  .category-wrap .category-title__is-link {
    grid-column: 1/3;
  }
  .category-wrap .category-title__button {
    grid-row: 3/4;
    grid-column: 2/3;
  }
  .category-wrap .paginator-container {
    grid-column: 1/2;
    justify-self: self-start;
  }
  .event-card__title_block {
    font-size: 18px;
  }
  .banner {
    grid-template-columns: auto 60px;
    grid-auto-rows: auto;
  }
  .slider-banner__square-img,
  .banner__square-img {
    height: 120px;
    width: 120px;
    grid-row: 3/4;
    grid-column: 1/3;
    margin-left: 0;
    justify-self: self-end;
  }
  .slider-banner__title,
  .banner__title {
    grid-column: 1/3;
    font-size: 16px;
  }
  .slider-banner__caption,
  .banner__caption {
    grid-column: 1/3;
    grid-row: 2/3;
    align-self: self-start;
    line-height: 20px;
  }
  .dev-page__title {
    font-size: 24px;
    line-height: 32px;
  }
  a.sale._list {
    grid-auto-flow: row;
    grid-template-columns: 100%;
    grid-template-rows: min-content auto min-content;
  }
  a.sale .sale__info {
    grid-template-columns: 100%;
  }
  .slider-block {
    aspect-ratio: 1/1;
  }
  .breadcrumb {
    padding-bottom: 0;
  }
  .breadcrumb > * {
    display: none !important;
  }
  .cookie {
    max-width: unset;
    width: calc(100% - 32px);
    bottom: 16px;
    right: 16px;
    padding: 48px 20px 20px;
  }
  .cookie__img {
    right: unset;
    left: 20px;
    height: 96px;
    width: 96px;
  }
}

@media screen and (max-width: 420px) {
  .mailing-form {
    grid-template-columns: auto 96px;
  }
  .mail-box__img {
    width: 96px;
    height: 96px;
  }
}

/*# sourceMappingURL=bootstrap.css.map */