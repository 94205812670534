@import "vars";

.category-page__filter-toggle {
  @include size(56px, 56px);
  display: flex;
  align-items: center;
  background-color: $gray-40;
  border-radius: 8px;
  margin-top: 8px;
  justify-content: center;
  flex: none;
  transition: 0.2s;
}
.category-page__filter-toggle.collapsed {
  background-color: transparent;
}

.category-page__filter-toggle span {
  position: relative;
  width: 14px;
}

.category-page__filter-toggle span::before,
.category-page__filter-toggle span::after {
  content: '';
  position: absolute;
  top: 0;
  transform-origin: center;
}
.category-page__filter-toggle span::before,
.category-page__filter-toggle span::after,
.category-page__filter-toggle span {
  display: block;
  height: 2px;
  background-color: #BFC1C7;
  transition: 0.2s;
}

.category-page__filter-toggle span::before {
  transform: translateY(-6px);
  width: 20px;
  left: -3px;
}
.category-page__filter-toggle span::after {
  transform: translateY(6px);
  width: 8px;
  left: 3px;
}
.category-wrap {
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: auto;
  grid-column-gap: 24px;
  //@include scale(margin-top, 32, 72);
  //margin: 0 0 72px;
  &._banner-block{
    grid-template-columns: repeat(3, 1fr);
    margin: 16px 0 32px;
    grid-gap: 32px;

  }
  &._form-block{
    grid-template-columns: 1fr 2fr;
    margin: 0 0 32px;
    grid-gap: 32px;
    .form_block-item{
      border-top: 1px solid rgba(0,16,61,.12);
      padding-top: 32px;
    }
    .category-form_form-item{
      border-top: 1px solid rgba(0,16,61,.12);
      padding-top: 32px;
    }
  }
  .entity-container {
    grid-column: 1/3;
    grid-row: 2/3;
  }
  .paginator-container {
    display: flex;
    align-items: center;
    align-self: center;
    justify-self: end;
    grid-row-start: 3;
    grid-column: 2/3;
    padding: 8px 0;
  }
}

.category-title {
  @include font($font, 600, 32px, 40px, $text-color);
  &:hover {
    color: $text-color;
  }
  &_side,
  &_side__is-link {
    @include font($font, 600, 24px, 32px, $text-color);
  }
  &_side__is-link {

  }
  &__is-link {
    @include font($font, 400, 40px, 56px, $text-color);
    display: flex;
    align-items: center;
    transition: .2s;
    margin-bottom: 24px;
    h2{
      @include font($font, 400, 40px, 56px, $text-color);
    }
    &:hover {
      color: $main;
    }
  }
}
.category-title__button_side {
  @include font($font, 600, 16px, 24x, $text-color);
  display: flex;
  align-items: center;
  &:hover {
    color: $main;
  }
}
.text a.category-title__button,
.category-title__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  @include font($font, 400, 20px, 28px, $icon-color-secondary);
  //padding: 7px 23px;
  //border: 1px solid $main;
  //transition: .2s;
  //border-radius: 40px;
  //background-color: transparent;
  white-space: nowrap;
  align-self: center;
  justify-self: end;
  transition: color .2s;
  &:hover{
    color: $text-color;
    .category-title__arrow {
      background: url("../img/arrow-up-right.svg") white center no-repeat;
    }
  }
  .category-title__arrow {
    display: block;
    background: url("../img/arrow-up-right-gray.svg") white center no-repeat;
    background-size: 24px;
    @include size(24px, 24px);
    margin-left: 4px;
    transition: background 0.2s;
    //height: 8px;
    //width: 8px;
    //border-top: 1px solid $main;
    //border-right: 1px solid $main;
    //transform: rotate(45deg);
    //margin-top: 2px;
    //margin-left: 8px;
  }
}
.category-title__button_side,
.category-title__button {
  .category-title__button-svg {
    stroke: $main;
    margin-right: 12px;
  }
}
.category-title__is-link {
  .category-title__button-svg {
    stroke: $main;
    margin-left: 20px;
    transform: scale(1.25);
    margin-top: -2px;
  }
  h1, h2, h3 {
    transition: 0.2s;
  }
  &:hover {
    color: $main;
    h1, h2, h3 {
      color: $main;
    }
  }
}
.category-title__is-link_side,
.category-small-title__is-link {
  @include font($font, 600, 24px, 32px, $text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s;
  h4, h3, h2, span, p {
    @include font($font, 600, 24px, 32px, $text-color);
    &:hover {
      color: $main;
    }
  }
  &:hover {
    color: $main;
    h1, h2, h3 {
      color: $main;
    }
  }
  .category-title__button-svg {
    stroke: $main;
    margin-left: 16px;
    transform: scale(1.2);
    margin-top: -4px;
  }
}
.entity-container {
  display: grid;
  grid-auto-rows: auto;
  &._block {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
    .sale_block-item{
      border-bottom: 1px solid rgba(0, 16, 61, 0.12);
      &:nth-last-child(-n + 3) {
        border: none;
      }
    }
    .block-item{
      border-bottom: 1px solid rgba(0, 16, 61, 0.12);
      padding-bottom: 32px;
      &:nth-last-child(-n + 3) {
        border: none;
      }
    }
  }
  &._text-block {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
  }
  &._sale{
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 32px;
    .sale-item{
      border-bottom: 1px solid rgba(0, 16, 61, 0.12);
      &:nth-last-child(-n + 3) {
        border: none;
      }
    }
  }
  &._list {
    grid-template-columns: 100%;
    grid-gap: 32px;
  }
  &._banner-block{
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
    grid-column: 2/4;
    grid-row: unset;
    .banner_block-item{
      border-bottom: 1px solid rgba(0, 16, 61, 0.12);
      padding-bottom: 32px;
      a.sale._list{
        padding-bottom: 0;
      }
    }
  }
  &._form-block{
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
    grid-column: unset;
    grid-row: unset;
  }
}
.banner-block_banner-item{
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(0,16,61,.12);
  .banner-container{
    width: 100%;
    aspect-ratio: 416/520;
    .banner-block_arrow{
      position: absolute;
      right: 24px;
      bottom: 24px;
      z-index: 2;
      width: 24px;
      background: url("../img/arrow-narrow-right.svg") white center no-repeat;
      padding: 24px;
      border-radius: 50%;
      background-size: 24px;
      flex: none;
    }
    .banner{
      background-color: $gray-40;
    }
  }
}

