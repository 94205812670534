@import "vars";

.page__navigation {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid rgba(0, 16, 61, 0.12);
  background: #F9F9FA;
  margin-bottom: 16px;
  &-item {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    border: 1px solid rgba(0, 16, 61, 0.12);
    background: #FFF;
    @include fnt(14px, 24px, $text-color);
    transition: border-color .2s ease-in-out, background .2s ease-in-out;
    &:hover {
      color: $text-color;
      border-color:#007ED9;
      background: rgba(0, 126, 217, 0.15);
    }
  }
}
