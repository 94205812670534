@import "vars";

.table{
  margin-bottom: 56px;
}
.table-wrap{
  overflow-x: auto;
}
.table-wrap::-webkit-scrollbar{
  width: 0 !important;
  display: none!important;
}
.table::-webkit-scrollbar {
  display: none!important;
}
.table>:not(:last-child)>:last-child>* {
  white-space: nowrap!important;
  color: $main;
}
.table>:not(caption)>*>* {
  padding: 12px 24px;
}
td:nth-child(2){
  text-align: center;
}
td:nth-child(3){
  text-align: center;
}
td:nth-child(4){
  text-align: end;
}
th:nth-child(2){
  text-align: center;
}
th:nth-child(3){
  text-align: center;
}
th:nth-child(4){
  text-align: end;
}
.page {
  table {
    @include font($font, normal, 16px, 24px, $text-color);
    border-collapse: collapse;
    border: 1px solid $border-color;
    thead {
      th {
        text-align: center;
        border: 1px solid $border-color;
      }
    }
    td {
      border: 1px solid $border-color;
    }
    a {
      display: block;
      text-decoration: none;
      color: $main;
      font-weight: 600;
      margin-bottom: 8px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
