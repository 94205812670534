@import "vars";

a.service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $border-color;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: $shadow;
  min-height: 100%;
  background-color: #fff;
  transition: .2s ease-out;
  &>div {
    @include scale(padding, 16, 32);
    width: 100%;
  }
  .service__title {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    @include scale(font-size, 18, 24);
    @include scale(line-height, 24, 32);
    color: $text-color;
    margin-bottom: 8px;
  }
  .service__description {
    font-family: $font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: $text-color;
    margin-bottom: 8px;
  }
  .service__organisation {
    font-family: $font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $main;
    margin-bottom: 8px;
  }
  .service__img {
    display: block;
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: center;
    flex: none;
  }
  &._list {
    flex-direction: row;
    .service__img {
      width: clamp(100px, 30%, 300px);
      padding: 24px;
      object-fit: contain;
    }
  }
  &:hover {
    box-shadow: $shadow-active;
  }
}
.service-container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  //grid-auto-rows: auto;
  grid-template-rows: auto 1fr auto;
  grid-gap: 12px 32px;
  max-width: 1440px;
  padding: 0 64px;
  margin: 0 auto;
  flex-grow: 1;
  &__swiper{
    grid-row: 1/3;
  }
  .service-title{
    @include font($font, 600, 24px, 32px, $text-color);
    margin: 0;
  }
  .service-area{
    @include font($font, 400, 20px, 32px, $text-color);
    background: #f3f3f5;
    padding: 0 12px;
    border-radius: 16px;
    width: fit-content;
    height: fit-content;
    margin-top: 12px;
  }
  .service-subtitle{
    @include font($font, 400, 24px, 32px, $text-color);
    //margin-bottom: 12px;
  }
  .service-kadastr{
    @include font($font, 400, 16px, 16px, $text-color-secondary);
    margin-bottom: 12px;
    &._mob{
      display: none;
    }
  }
  .service-description + p {
    @include font($font, 400, 16px, 24px, $text-color);
    margin-bottom: 24px;
  }
  .service-price{
    @include font($font, 400, 40px, 40px, $text-color);
    margin-bottom: 24px;
  }
  .modal-btn{
    padding: 16px 32px;
    color: white;
    background-color: $main;
    border: unset;
    &:hover{
      background-color: #005999;
    }
  }
  .sale-card__slider{
    aspect-ratio: 640/428;
    .bik-market-swiper{
      border-radius: 12px;
    }
  }
  p{
    @include font($font, 400, 16px, 24px, $text-color);
  }
  //.row>*{
  //  padding-right: 0;
  //  padding-left: 0;
  //}
}
