@import "vars";
.snow_page {
  display: grid;
  place-items: center;
  position: relative;
  grid-gap: 24px;
  min-height: 100vh;
  width: 100%;
  padding: 0 16px;
  background: linear-gradient(180deg, #002A40 20%, #0088CC 80%, #00CCCC 100%);
  overflow: hidden;
  &._success {
    background: linear-gradient(180deg,#33BBFF 0%, #1470CC 50%, #FD696E 100%);
  }
  canvas {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    mix-blend-mode: lighten;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    margin-top: -10%;
    min-width: 100%;
    min-height: 100%;
    background: url("../img/snow_page/Ray.svg") center no-repeat;
    background-size: cover;
    aspect-ratio: 1/1;
    transform-origin: center;
    animation: rotate 50s infinite linear;
    pointer-events: none;
    will-change: auto;
    z-index: 1;
  }

  .form-wrap {
    display: grid;
    grid-template-columns: minmax(240px, 1fr) minmax(240px, 1fr);
    grid-gap: 24px;
    background: #fff;
    border-radius: 32px;
    padding: 48px;
    position: relative;
    margin: 64px 0 205px;
    z-index: 2;
    width: 696px;
    max-width: 100%;
    video {
      object-fit: cover;
      pointer-events: none;
    }
    .form__decoration {
      position: absolute;
      &._top-left {
        top: -28px;
        left: -16px;
      }
      &._top-right {
        top: -26px;
        right: -3px;
      }
      &._bottom-left {
        top: 100%;
        transform: translateY(-21px);
        left: -7px;
      }
      &._bottom-right {
        top: 100%;
        transform: translateY(-8px);
        right: 29px;
      }
    }
    .girland {
      margin: 0 -49px -80px -48px;
      aspect-ratio: 698/132;
      position: relative;
      img  {
        width: 100%;
      }
      ._base,
      ._lights-blue,
      ._lights-orange,
      ._lights-red {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      ._lights-blue,
      ._lights-orange,
      ._lights-red {
        opacity: 0;
        animation-name: flash;
        animation-iteration-count: infinite;
        filter: brightness(2);
        mix-blend-mode: hard-light;
      }
      ._lights-blue {
        animation-duration: 3s;
      }
      ._lights-orange {
        animation-duration: 2.5s;
      }
      ._lights-red {
        animation-duration: 2s;
      }
    }

    .submit-button {
      display: block;
      background: url("../img/snow_page/Ice-button.svg") center no-repeat;
      background-size: contain;
      padding: 20px 24px;
      width: fit-content;
      aspect-ratio: 218/64;
      text-align: center;
      filter: drop-shadow(0px 4px 16px #49C9F2);
      will-change: auto;
      margin: 32px auto 0;
      cursor: pointer;
      &:before {
        content: 'Получить приз!';
        @include fnt(22px, 24px, #fff, 700);
        mix-blend-mode: overlay;
        text-shadow: -1px 2px 2px #4e4e4e;
      }
    }
    .text-half {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .form-half {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    h1 {
      @include fnt(28px, 32px, transparent, 700);
      background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-align: center;
      margin: 0 0 8px;
    }
    .subtitle {
      @include fnt(14px, 20px, $text-color-secondary);
      text-align: center;
      margin: 0;
    }
    .input-group {
      display: flex;
      flex-direction: column;
      margin: 16px 0 0;
      width: 100%;
      label {
        display: block;
        width: 100%;
        text-align: center;
        @include fnt(14px, 24px, $main);
        opacity: 0.5;
        margin: 0;
      }
      input {
        border: 2px solid rgba(0, 136, 204, 0.25);
        border-radius: 12px;
        padding: 10px 22px;
        @include fnt(20px, 24px);
        margin: 0;
        width: 100%;
        text-align: center;
      }
    }
    .form-title {
      @include fnt(20px, 24px, $text-color, 600);
      text-align: center;
      margin: 0;
    }
    .form-error {
      margin-top: 12px;
    }
    &._success {
      grid-template-columns: 100%;
      grid-gap: 0;
      .subtitle {
        @include fnt(20px, 24px, $main);
        opacity: 0.5;
        margin: 4px 0 24px;
        width: 100%;
        text-align: center;
      }
      h1 {
        font-size: 32px;
        line-height: 40px;
        margin: 0 !important;
      }
      .congrads {
        @include fnt(24px, 32px, #FF6680, 600);
        text-align: center;
        margin: 24px 0 32px;
      }
      .prize {
        &-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 8px dotted #FF6680;
          border-radius: 90px;
          padding: 28px;
          width: 100%;
          max-width: 480px;
        }
        &-title {
          @include fnt(24px, 32px, $text-color, 700);
          margin: 0 0 8px;
          width: 100%;
          text-align: center;
        }
        &-description {
          @include fnt(24px, 32px);
          margin: 0;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0%, 100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

.snow {
  $total: 300;
  position: absolute;
  width: 10px;
  height: 10px;
  //background: white;
  background: radial-gradient(
      #ffff 10%,
      #fff4 46%,
      #fff0 99%);
  border-radius: 50%;

  @for $i from 1 through $total {
    $random-x: random(1000000) * 0.0001vw;
    $random-offset: random_range(-100000, 100000) * 0.0001vw;
    $random-x-end: $random-x + $random-offset;
    $random-x-end-yoyo: $random-x + ($random-offset / 2);
    $random-yoyo-time: random_range(30000, 80000) / 100000;
    $random-yoyo-y: $random-yoyo-time * 100vh;
    $random-scale: random(10000) * 0.00015;
    $fall-duration: random_range(10, 30) * 1s;
    $fall-delay: random(30) * -1s;

    &:nth-child(#{$i}) {
      opacity: random(10000) * 0.0001;
      transform: translate($random-x, -10px) scale($random-scale);
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }

    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
      }

      to {
        transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
      }
    }
  }
}
.snow_container {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 800px) {
  .snow_page {
    .form-wrap {
      width: calc(100% - 18px);
      grid-template-columns: 100%;
    }
  }
}
@media screen and (max-width: 600px) {
  .snow_page {
    .form-wrap {
      .text-half {
        margin: 0 -16px;
      }
      &._success{
        .girland {
          aspect-ratio: 358/132;
        }
        .prize {
          padding: 16px;
        }
      }
    }
  }
}
