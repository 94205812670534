@import "vars";
.calculator {
  &__title {
    @include fnt(32px, 40px, $text-color, 700);
    margin: 0;
  }
  &__description {
    @include fnt(14, 24 $text-color);
    margin: 0 0 24px;
  }
}
.calculator__card {
  background-color: #fff;
  padding: 32px 48px;
  box-shadow: $shadow;
  border-radius: 16px;
  max-width: 814px;
}
@media screen and (max-width: 768px) {
  .calculator__card {
    padding: 16px 24px;
  }
}
.calculator__table {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  margin: 32px 0;
  border-radius: 12px;
  overflow: hidden;
  overflow-x: auto;
  box-shadow: $shadow;
}
.calculator__header {
  background-color: $main;
  border-bottom: none;
}
.calculator__body,
.calculator__header {
  min-width: 646px;
}
.calculator__row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-rows: 1fr;
  border-bottom: 1px solid $border-color;
  &:last-child {
    border-bottom: none;
  }
}
.calculator__body {
  background-color: $gray-20;
}
.calculator__button {
  @include btn();
  color: #fff;
  width: 100%;
  margin-top: 24px;
  border-radius: 8px;
  background: linear-gradient(90deg, #0CC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20.00%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40.00%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60.00%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80.00%, #008ACC 86.67%, #0089CC 93.33%, #08C 100%);
  &:hover{
    color:#fff;
  }
}
.table__cell {
  @include fnt();
  padding: 8px 16px;
  &._title {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #fff;
  }
}
.calculator__result {
  .result {
    &__wrap {
      padding: 4px 22px;
      border-left: 2px solid $main-text;
    }
    &__item {
      display: flex;
      flex-direction: column;
      margin: 0 0 16px;
      &:last-child {
        margin: 0;
      }
    }
    &__label {
      @include fnt(12px, 24px, $text-color-secondary);
    }
    &__value {
      @include fnt(24px, 24px, $text-color, $weight: 600);
    }
  }
  .calculator__download-btn {
    display: flex;
    padding: 4px 12px 4px 8px;
    width: fit-content;
    @include fnt(14px, 24px, #5B87A6, $weight: 600);
    border: 1px solid $border-color;
    border-radius: 20px;
    margin-top: 16px;
    gap: 4px;
    &:before {
      content: '';
      display: block;
      height: 24px;
      width: 24px;
      background: url('../img/documents-file-text.svg') no-repeat center;
      background-size: 24px;
    }
  }
}
