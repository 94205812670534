@import "vars";

/* PAGINATION */


.pagination {
  margin: 0;
}
.pagination-block {
  display: flex;
  justify-content: center;
  padding: 16px 0 0;
  margin: 16px 0 32px;
  width: 100%;
}
.paginate-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  min-width: 38px;
  border: 1px solid transparent;
  border-radius: 19px;
  padding: 7px 10px;
  margin: 0 4px;
  font-size: 14px;
  line-height: 20px;
  color: $text-color;
  text-align: center;
  text-decoration: none;

}
.pagination__arrow use {
  stroke: $border-color-active;
}
.pagination__prev-arrow {
  transform: rotate(180deg) translate(1px, 0);
}
.pagination__next-arrow {
  transform: translate(1px, 0);
}
.paginate-item.active {
  border-color: $border-color;
}
.plugin-paginate .paginate-item {
  border-color: #333;
  margin: 0 6px;
}
.paginate-item:hover,
.paginate-item:hover .pagination__arrow use{
  text-decoration: none;
  border-color: $border-color-active;
  color: $main;
  stroke: $border-color-active;
}
.paginate-item.disabled,
.disabled .pagination__arrow use,
.paginate-item.disabled:hover,
.paginate-item.disabled .pagination__arrow use
{
  border-color: transparent;
  color: $border-color-active;
  stroke: $border-color-active;
  cursor: unset;
}
.plugin-paginate .paginate-item.disabled {
  border-color: $gray-20;
}
