@import "vars";
.main-background{
  background: #F3F3F5;
}
.top-block__background{
  width: 100%;
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%,
    #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
}
.top-block__container{
  max-width: 1440px;
  padding: 32px 64px 0 64px;
  width: 100%;
  margin: 0 auto;
}
.top-block__img{
  width: 100%;
  max-width: 752px;
  height: auto;
  margin-bottom: -80px;
}
.top-block__grid{
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-template-rows: auto 32px;
  position: relative;
  grid-column-gap: 32px;
  grid-row-gap: 48px;
  margin-bottom: 80px;
}
.grid-box-1{
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  display: flex;
  align-items: flex-end;
}
.grid-box-2{
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}
.grid-box-3{
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  margin-bottom: -32px;
  display: flex;
  align-items: flex-end;
}

.top-block__grid-right{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 32px;
}
.top-block__title{
  @include font($font, 700, 64px, 64px, white);
  display: flex;
  margin-bottom: 8px;
  &:before{
    content:"";
    background: url("../img/landing/home-vector.svg") center no-repeat;
    background-size: cover;
    display: block;
    width: 56px;
    height: 56px;
    margin-right: 20px;
  }
}
.top-block__subtitle {
  @include font($font, 400, 32px, 40px, white);
}
.top-block__btn{
  transition: .3s ease-out;
  background: #FF9E00;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 16px 32px 16px 24px;
  width: fit-content;
  @include font($font, 400, 20px, 32px, white);
  display: flex;
  margin-top: 48px;
  &:before{
    content: url("../img/landing/tg.svg");
    display: block;
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  &:hover{
    color: white;
    text-decoration: none;
    margin-top: -6px;
    margin-bottom: 6px;
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
  }
}
.change__btn{
  background: #FF9E00;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 16px 32px 16px 24px;
  width: fit-content;
  @include font($font, 400, 20px, 32px, white);
  display: none;
  margin-top: 16px;
  &:before{
    content:"";
    background:  url("../img/landing/tg.svg") center no-repeat;
    display: block;
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  &:hover{
    color: white;
    text-decoration: none;
  }
}
.register-block__btn{
  transition: .3s ease-out;
  background: #0088CC;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 16px 32px 16px 24px;
  width: fit-content;
  height: fit-content;
  @include font($font, 400, 20px, 32px, white);
  display: flex;
  &:before{
    content:"";
    background:  url("../img/landing/tg.svg") center no-repeat;
    display: block;
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  &:hover{
    color: white;
    text-decoration: none;
    margin-top: -6px;
    margin-bottom: 6px;
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
  }
}
.pt-48{
  padding-top: 48px;
}
.content__container-center{
  max-width: 992px;
  padding: 0 64px;
  width: 100%;
  margin: 0 auto;
}
.reg-text{
  @include font($font, 400, 24px, 32px, $text-color);
}
.chat-window{
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.15));
  border-radius: 32px;
  overflow: hidden;
  width: 100%;
  margin-top: 64px;
  border: 1px solid rgba(0, 16, 61, 0.05);
}
.chat-window__top{
  background: white;
  padding: 16px;
  height: fit-content;
  display: flex;
}
.chat-logo{
  display: block;
  align-items: center;
  justify-content: center;
  flex: none;
  height: 48px;
  width: 48px;
  margin-right: 12px;
  align-self: center;
  border-radius: 50%;
  background: url("../img/landing/home-vector-colored.svg") #ECEDF0 no-repeat center;
}
.chat-window__top-title{
  @include font($font, 600, 20px, 24px, $text-color);
}
.chat-window__top-subtitle{
  @include font($font, 400, 14px, 24px, #919399);
}
.chat-window__content{
  padding: 32px 16px;
  background: #DFE4E8;
  display: flex;
  flex-direction: column;
}
.message-white{
  @include font($font, 400, 20px, 20px, $text-color);
  padding: 12px 16px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 0px;
  width: fit-content;
  align-self: flex-start;
  margin-bottom: 4px;
}
.message-green{
  @include font($font, 400, 20px, 20px, $text-color);
  padding: 12px 32px 12px 16px;
  background: #EFFDDE;
  border-radius: 8px 8px 0px 8px;
  width: fit-content;
  align-self: flex-end;
  margin-top: 12px;
  position: relative;
  &:after{
    content: url("../img/landing/Done.svg");
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 8px;
    bottom: 0;
  }
}
.chat-text{
  @include font($font, 400, 16px, 24px, #919399);
  text-align: center;
  margin-top: 24px;
  max-width: 640px;
}
.gray-text{
  @include font($font, 400, 16px, 24px, #919399);
  margin-top: 8px;
}
.problems-block{
  margin-top: 80px;
}
.problems-block__title{
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.problems-block{
.gray-subtitle{
  @include font($font, 400, 20px, 32px,#919399);
}
}
.landing__problems-list{
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}
.landing__problem-item{
  @include font($font, 400, 20px, 24px, $text-color);
  background: #FFFFFF;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 12px 24px;
  width: fit-content;
  margin-right: 12px;
  margin-bottom: 12px;
}
.landing__register-block{
  margin-top: 80px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 16, 61, 0.12);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 24px 24px 24px 32px;
  display: flex;
  justify-content: space-between;
}
.register-block__text{
  @include font($font, 400, 20px, 32px, $text-color);
  margin-right: 24px;
}
.connection-list{
  margin-top: 84px;
}
.connection-list__title{
  @include font($font, 600, 20px, 24px, $text-color);
}
.connection-list__item{
  @include font($font, 400, 20px, 24px, $text-color);
  margin-top: 12px;
  a{
    @include font($font, 400, 20px, 24px, #0088CC);
    text-decoration: underline;
  }
}
