@import "vars";

/* EVENTS */

.event-card_list {
  display: flex;
  padding: 0 0 32px;
  border-bottom: 1px solid $border-color;
}
.event-card_side {
  display: flex;
  padding: 12px 0;
  color: $text-color;
}
.event-card_side:hover {
  color: $text-color;
}
.event-card__date_list {
  @include font($font, normal, 12px, 16px, $text-color);
  color: $text-color-secondary;
  margin-bottom: 8px;
}
.event-card__date_side {
  @include font($font, normal, 12px, 16px, $text-color-secondary);
  margin-bottom: 8px;
}
.event-card__title_side {
  @include font($font, normal, calc(16px + 4 * ((100vw - 320px)/ 1600)), calc(24px + 8 * ((100vw - 320px)/ 1600)), $text-color);
  margin-bottom: 0;
}
.event-card__title_list {
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: $text-color;
}
.event-card__img-container_list {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 54%;
}
.event-card__img_list {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-position: center;
  object-fit: cover;
}

.event-card_block {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  //border: 1px solid rgba(0,16,61,.12);
  //border-radius: 12px;
  //box-shadow: $shadow;
  overflow: hidden;
  transition: 0.2s ease-out;
  &:hover {
    .event-card__more_block{
      &:after{
        background: url("../img/dots-horizontal-bl.svg") center right no-repeat;
      }
    }
    .event-card__title_block{
      color: $main;
      &:after{
        background: url("../img/arrow-up-right-blue.svg") center right no-repeat;
      }
    }
    .event-card__img_block{
      border-radius: 0;
    }
  }
}
.event-card__img-container_block {
  display: block;
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 55.8%;
}
.event-card__img_block {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  border-radius: 24px;
  transition: border-radius 0.3s;
}
.event-card__body_block {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 12px;
  //@include scale(padding, 24, 32)
}
.event-card__title_block {
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $text-color;
  margin-bottom: 4px;
  margin-top: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: flex;
  justify-content: space-between;
  transition: color 0.3s;
  &:after {
    content: '';
    background: url("../img/arrow-up-right.svg") center right no-repeat;
    display: block;
    background-size: 24px 28px;
    width: 24px;
    height: 28px;
    flex: none;
    margin-left: 4px;
    transition: background 0.3s;
  }
}
.event-card__subtitle_block{
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $text-color-secondary;
  margin-bottom: 16px;
  margin-top: 0;

}
.event-card__date_block {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 0;
  padding: 0 8px;
  border-radius: 8px;
  background: $gray-40;
}
.event-card__more_block{
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 0;
  display: flex;
  &:after {
    content: '';
    background: url("../img/dots-horizontal.svg") center right no-repeat;
    display: block;
    background-size: 24px;
    width: 24px;
    height: 24px;
    flex: none;
    margin-left: 4px;
    transition: background 0.3s;
  }
}
.preview-event-card_block {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.preview-event-card__img-container_block {
  display: block;
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 58%;
  &._hide-anons {
    display: none;
  }
}
.preview-event-card__img_block {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}
.preview-event-card__body_block {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 24px;
}
.preview-event-card__title_block,
.preview-event-card__title_list {
  @include font ($font, 500, 20px, 32px, $text-color);
  margin-bottom: 8px;
}
.preview-event-card__date_block {
  @include font ($font, normal, 14px, 16px, $text-color-secondary);
  margin-bottom: 0;
}
.preview-event-card_list {
  display: block;
  margin-bottom: 32px;
}

.preview-event-card__date_list {
  display: block;
  @include font ($font, normal, 14px, 16px, $text-color-secondary);
}

.sub-events__title {
  @include font($font, 600, 32px, 40px, $text-color);
}
.sub-event {
  margin: 32px 0;
}
.sub-event__title {
  @include font($font, 500, 24px, 32px, $text-color);
  margin-bottom: 8px;
}
.sub-event__date {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $text-color-secondary;
}
.event-card__body_block.side {
  background-color: $gray-20;
  padding: 24px 32px 32px;
  margin-top: 0;
}

.top-news {
  margin-bottom: 16px;
}
.top-news .right-col .news-item:first-child {
  display: none;
}


.more-news.side_wraper {
  display: block;
  width: 100%;
  padding: 32px;
  background: $gray-20;
  border-radius: 8px;
}
.more-news__title {
  display: block;
  width: 100%;
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $text-color;
  margin-bottom: 16px;
}
.more-news__item {
  display: block;
  width: 100%;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 16px;
}
.more-news__item:last-child {
  margin-bottom: 0;
}
.more-news__item.active,
.more-news__item:hover {
  text-decoration: underline;
}
.event-card_block.side {
  height: unset;
  margin: 16px 0;
}
