@import "vars";

$blue: #5B87A6;
$local-border-color: rgba(0, 16, 61, 0.12);

.e-title {
  @include fnt(32px, 48px, $weight: 600);
  margin-top: 24px;
  margin-bottom: 0;
}
.e-collapse__btn {
  display: flex;
  align-items: center;
  margin-top: 12px;
  &:after {
    white-space: nowrap;
    content: 'Скрыть';
    @include fnt(12px, 24px, $text-color-secondary);
  }
  &.collapsed:after {
    content: 'Подробнее'
  }
  &:before {
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 7L5 3L1 7' stroke='%23BFC1C7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 4px;
    transition: transform 0.3s ease-out;
  }
  &.collapsed:before {
    transform: rotate(-180deg);
  }
}
.form__item {
  .e-label {
    @include fnt(14px, 24px, $text-color-secondary);
    margin-left: 12px;
  }
}
.filter_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}
.e-organisations {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 24px;
  margin-top: 40px;
  .e-organisation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 24px 16px;
    background: #FFF;
    border: 1px solid $local-border-color;
    box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    &__category {
      display: block;
      @include fnt(14px, 24px, #fff, 600);
      padding: 0 8px;
      background-color: #56CCF2;
    }
    &__title {
      @include fnt(20px, 24px, $weight: 600);
      margin: 8px 0 0;
    }
    &__address {
      @include fnt(14px, $color: $text-color-secondary);
      margin: 0;
    }
    &__item-title {
      @include fnt(14px, 16px, $color: $text-color-secondary);
      margin: 12px 0 0;
    }
    &__item {
      @include fnt();
      margin: 0;
    }
    &__site {
      color: $blue;
    }
  }
}
.filter__categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;
  max-width: 640px;
}
.category {
  &__radio {
    display: none;
  }
  &__label {
    display: block;
    width: fit-content;
    @include fnt(14px, 24px, $blue, 600);
    background-color: transparent;
    border: 1px solid $local-border-color;
    border-radius: 16px;
    margin: 0 8px 8px 0;
    padding: 3px 11px;
    cursor: pointer;
    transition: color .3s ease-out, background-color .2s ease-out, border-color .3s ease-out;
  }
  &__label:hover {
    border-color: $blue;
  }
  &__radio:checked + &__label {
    border-color: $blue;
    background-color: $blue;
    color: #fff;
  }
}

.block-link {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 24px 32px;
  background: linear-gradient(91.93deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  &__title {
    display: block;
    @include fnt(24px, 32px, #fff, 600);
    margin-bottom: 0;
  }
  &__text {
    display: block;
    @include fnt(16px, 24px, #fff);
    margin-bottom: 16px;
  }
  &__button {
    display: block;
    @include fnt(16px, 24px, #fff, 600);
    padding: 12px 24px;
    background-color: #F23054;
    margin: 0;
    border-radius: 8px;
    transition: background-color 0.3s ease-out;
    &:hover {
      background-color: #ff3b62;
    }
  }
}

@media screen and (max-width: 640px) {
  .filter_row {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    margin-bottom: 32px;
    grid-gap: 16px;
    .form__item {
      padding-bottom: 0;
    }
  }
}
